import McDataServices from './McDataServices'
import { isReferrerAllowed } from '../pages/mc-components/McPageHelpers'

import CONSTANTS from '../utils/Constants'

export const getUserDetailsAndProcess = (isLoggedIn, user, setUser, getUserEvenIfNotEmpty = false) => {
  if (
    isLoggedIn &&
        (Object.keys(user)?.length === 0 || getUserEvenIfNotEmpty) &&
        user.constructor === Object
  ) {
    const storageUserName = localStorage.getItem('username')
    const username = isReferrerAllowed() ? storageUserName || 'anonymous' : storageUserName
    if (username !== '') {
      McDataServices.getUser(username).then(userData => {
        const searchCountrySet = userData.rules.find(item => item.type === 'SEARCH_COUNTRY_SET')

        if (searchCountrySet !== undefined) {
          const searchCountrySetParameters = searchCountrySet.parameters

          if (!searchCountrySetParameters.allowedSearchCountries?.trim()) {
            searchCountrySetParameters.allowedSearchCountries = CONSTANTS.WORLD_COUNTRY_CODE
          }
          searchCountrySetParameters.allowedSearchCountries = searchCountrySetParameters.allowedSearchCountries.replace(/\s+/g, '').split(',')
        }
        setUser(userData)
      })
    }
  } else if (!isLoggedIn) {
    localStorage.setItem('username', '')
  }
}
