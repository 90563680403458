import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import {
  AnnualAverageImportUnitPricesSection,
  AverageMonthlyImportUnitPricesByCountriesSection,
  LineChartsSection,
  QuantityButtonsSection
} from './mc-components/McPageComponents'
import McPageHeader from '../components/page-header/McPageHeader'
import McPdfExport from './mc-components/McPdfExport'
import {
  useDSpriceMap,
  useDSunits,
  useDSglobalParams,
  useDSallExplanations
} from '../store/dataStore'
import { useSDSsearchParams } from '../store/searchDataStore'
import McPageHelpers from './mc-components/McPageHelpers'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const PriceAnalysis = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const priceMap = useDSpriceMap()
  const units = useDSunits()
  const globalParams = useDSglobalParams()
  const allExplanations = useDSallExplanations()

  const selectedComponentIds = usePDFselectedComponentIds()

  const searchParams = useSDSsearchParams()

  const priceTableRef = useRef(null)
  const unitVal = units[globalParams.selectedUnit]
  const headerPriceTable = t('ai_sp_price_page_table_title') + ' ($/' + (unitVal?.substr(0, 3) === '***' ? unitVal : t(`units.${unitVal?.toLowerCase()}`)) + ')'
  const searchHeader = searchParams.hsCode + ' - ' + McPageHelpers.findCountryName(searchParams.countryToExport) + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)
  const unitParams = ' ($/' + (unitVal?.substr(0, 3) === '***' ? unitVal : t(`units.${unitVal?.toLowerCase()}`)) + ')'
  const subTitle = McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode

  const pdfDownload = (onlyContent = false) => {
    const priceTablePdfContent = priceTableRef.current.generatePriceTablePdf('priceTablePdfContent')
    const filterPriceMap = priceMap.filter(item => item.value !== null && item.value !== undefined && !isNaN(item.value) && item.value.toFixed(2) !== '0.00')

    return McPdfExport.PriceAnalysis(onlyContent, searchHeader, filterPriceMap, priceTablePdfContent, headerPriceTable, unitParams, subTitle, selectedComponentIds, allExplanations)
  }

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true).then(content => content)
      return content
    }
  }))

  const spTable = useEndpointStatusData.spTable()
  const spGraph = useEndpointStatusData.spGraph()
  const monthly = useEndpointStatusData.monthly()

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_TABLE + McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES, spTable), [spTable])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_GRAPH + McPageHelpers.SECTION_IDS.ANNUAL_AVERAGE_IMPORT_UNIT_PRICES_MAP_GRAPH, spGraph), [spGraph])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.MONTHLY, monthly), [monthly])

  return (
    <React.Fragment>
      <Head title={t('menu.price_analysis')}/>
      <Content>
        <McPageHeader title={t('price_analysis_header')} desc={t('price_analysis_desc')}/>
        <QuantityButtonsSection/>
        <AnnualAverageImportUnitPricesSection/>
        <AverageMonthlyImportUnitPricesByCountriesSection ref={priceTableRef}/>
        <LineChartsSection/>
      </Content>
    </React.Fragment>
  )
})

export default PriceAnalysis
