import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardText, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../store/globalStore'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { Icon } from '../Component'
import { useDSglobalParams, useDSselectedFirmDetails, useDSshowFirmDetailsModal, useDataStoreActions } from '../../store/dataStore'
import McBarChart from '../amcharts/BarChart'
import McMap from '../amcharts/Map'
import { MarketCalculusCard } from '../preview/Preview'

const initialState = {
  productList: [], countryList: [], yearChartData: [], sector_list: []
}

const FirmDetailsModal = ({ pageName, ...props }) => {
  const { t } = useTranslation()
  const languageGSD = useGlobalStoreData.language()
  const countriesGSD = useGlobalStoreData.countries()

  const globalParams = useDSglobalParams()
  const selectedFirmDetails = useDSselectedFirmDetails()
  const showFirmDetailsModal = useDSshowFirmDetailsModal()
  const { setShowFirmDetailsModal } = useDataStoreActions()

  const key = 'hsTotalQuantity' + globalParams.selectedUnitNumber
  const avgKey = 'avgPrice' + globalParams.selectedUnitNumber

  const [state, setState] = useState(initialState)

  useEffect(() => {
    let tmpProductList = []
    let tmpCountryList = []
    let sectorList = []

    if (selectedFirmDetails && Object.keys(selectedFirmDetails) && selectedFirmDetails?.productList) {
      tmpProductList = selectedFirmDetails?.productList.map((product, key) => <Col md="2" key={'productList-' + key}>
        <Card className="card-bordered" color="light">
          <CardHeader
            style={{ padding: '0.3rem' }}><b>{product.hs_code}</b> - {McPageHelpers.numToLocaleString(product.percentage, 'percent')}
          </CardHeader>
          <CardBody style={{ padding: '0.3rem' }} className="card-inner">
            <CardText className="card-inner-text"
                      title={product.description}>{product.description}</CardText>
          </CardBody>
        </Card>
      </Col>)
      setState(prevState => ({ ...prevState, productList: tmpProductList }))
    }

    if (selectedFirmDetails && Object.keys(selectedFirmDetails) && selectedFirmDetails?.sector_list) {
      sectorList = selectedFirmDetails?.sector_list.map((product, key) => {
        const managedSector = product.sector?.substr(0, 3) === '***' ? product.sector : t(`sectors.${product.sector}`)

        return (
          <Col md="2" key={'sectorlist-' + key}>
            <Card className="card-bordered" color="light">
              <CardHeader
                style={{ padding: '0.3rem' }}><b>{McPageHelpers.numToLocaleString(product.percentage, 'percent')}</b>
              </CardHeader>
              <CardBody style={{ padding: '0.3rem' }} className="card-inner">
                <CardText
                  className="card-inner-text"
                  title={managedSector}
                >
                  {managedSector}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        )
      })
      setState(prevState => ({ ...prevState, sector_list: sectorList }))
    }

    if (selectedFirmDetails && Object.keys(selectedFirmDetails) && selectedFirmDetails?.countryList) {
      tmpCountryList = selectedFirmDetails?.countryList.map(country => ({
        id: country.country_code,
        name: McPageHelpers.findCountryName(country.country_code, countriesGSD),
        value: McPageHelpers.numToLocaleString(country.percentage, 'percent')
      }))
      setState(prevState => ({ ...prevState, countryList: tmpCountryList }))
    }

    const yearChartData = McPageHelpers.generateYearModalChartData(selectedFirmDetails?.yearPercentage, languageGSD)

    setState(prevState => ({
      ...prevState, yearChartData
    }))
  }, [selectedFirmDetails])

  return (<>
    <Modal toggle={() => setShowFirmDetailsModal(false)} isOpen={showFirmDetailsModal} size="xl" id="firmDetailsModal">
      <ModalHeader toggle={() => setShowFirmDetailsModal(false)}
                   close={<button className="close" onClick={() => setShowFirmDetailsModal(false)}>
                     <Icon name="cross"/></button>}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedFirmDetails?.importerName && selectedFirmDetails?.importerName?.length > 70 ? selectedFirmDetails?.importerName.substring(0, 70) + '...' : selectedFirmDetails?.importerName}
          </div>
          <div>
            <div className="firm-details-social-icons">
              <a href={selectedFirmDetails?.facebook !== '' ? selectedFirmDetails?.facebook : null} target="_blank"
                 rel="noreferrer">
                <Icon name="facebook-fill"
                      className={`social-icons ${selectedFirmDetails?.facebook !== null && selectedFirmDetails?.facebook ? 'social-icons-facebook' : ''}`}
                />
              </a>

              <a href={selectedFirmDetails?.twitter !== '' ? selectedFirmDetails?.twitter : null} target="_blank"
                 rel="noreferrer">
                <Icon name="twitter-round"
                      className={`social-icons ${selectedFirmDetails?.twitter !== null && selectedFirmDetails?.twitter ? 'social-icons-twitter' : ''}`}
                />
              </a>

              <a href={selectedFirmDetails?.instagram !== '' ? selectedFirmDetails?.instagram : null} target="_blank"
                 rel="noreferrer">
                <Icon
                  name={selectedFirmDetails?.instagram !== null && selectedFirmDetails?.instagram !== '' ? 'instagram' : 'instagram-round'}
                  className={`social-icons ${selectedFirmDetails?.instagram !== null && selectedFirmDetails?.instagram ? 'social-icons-instagram' : ''}`}
                />
              </a>

              <a href={selectedFirmDetails?.linkedin !== '' ? selectedFirmDetails?.linkedin : null} target="_blank"
                 rel="noreferrer">
                <Icon name="linkedin-round"
                      className={`social-icons ${selectedFirmDetails?.linkedin !== null && selectedFirmDetails?.linkedin ? 'social-icons-linkedin' : ''}`}
                />
              </a>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row className="g-3">
          <Col md="6">
            <Card className="card-bordered">
              <ul className="data-list is-compact" id="firmDetailsId">
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_suitable_importer_name')}</div>
                    <div className="data-value">{selectedFirmDetails?.importerName ?? ''}</div>
                  </div>
                </li>

                {selectedFirmDetails?.email
                  ? <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('email')}</div>
                    <div className="data-value">{selectedFirmDetails?.email ??
                      ''}</div>
                  </div>
                </li>
                  : ''}

                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_web')}</div>
                    <div className="data-value">{<a target="_blank" rel="noreferrer"
                                                    href={selectedFirmDetails?.web !== undefined ? (selectedFirmDetails?.web.startsWith('.') ? selectedFirmDetails?.web.replace('.', '') : (selectedFirmDetails?.web.startsWith('http') ? selectedFirmDetails?.web : 'http://' + selectedFirmDetails?.web)) : ''}>{selectedFirmDetails?.web}</a> ?? ''}</div>
                  </div>
                </li>
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_hs_total_value')}</div>
                    <div
                      className="data-value">{McPageHelpers.numToLocaleString(selectedFirmDetails?.hsTotalValue, 'currency') ?? ''}</div>
                  </div>
                </li>}
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_sp_total_value')}</div>
                    <div
                      className="data-value">{McPageHelpers.numToLocaleString(selectedFirmDetails?.totalValue, 'currency') ?? ''}</div>
                  </div>
                </li>
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_market_share')}</div>
                    <div
                      className="data-value">{McPageHelpers.numToLocaleString(selectedFirmDetails?.marketShare, 'percent', true) ?? ''}</div>
                  </div>
                </li>}
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_sp_number_of_shipments')}</div>
                    <div
                      className="data-value">{McPageHelpers.numToLocaleString(selectedFirmDetails?.numberOfShipments) ?? ''}</div>
                  </div>
                </li>
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_avg_price_quantity')}</div>
                    <div
                      className="data-value">{selectedFirmDetails && McPageHelpers.numToLocaleString(selectedFirmDetails[avgKey], 'currency')}</div>
                  </div>
                </li>}
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_hs_total_quantity')}</div>
                    <div
                      className="data-value">{selectedFirmDetails && McPageHelpers.numToLocaleString(selectedFirmDetails[key])}</div>
                  </div>
                </li>}
                <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('table_sp_growth')}</div>
                    <div className="data-value">{selectedFirmDetails?.growth ?? ''}</div>
                  </div>
                </li>
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('importer_country')}</div>
                    <div className="data-value">{selectedFirmDetails?.cityState ?? ''}</div>
                  </div>
                </li>}
                {pageName === McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES
                  ? ''
                  : <li className="data-item">
                  <div className="data-col">
                    <div className="data-label">{t('importer_address')}</div>
                    <div className="data-value"
                         style={{ wordBreak: 'break-word' }}>{selectedFirmDetails?.importerAddress ?? ''}</div>
                  </div>
                </li>}
              </ul>
            </Card>
            <div>
            </div>
          </Col>
          <Col md="6">
            <Row className="g-3">
              <Col md="12">
                <MarketCalculusCard>
                  <McMap chartId={'countries' + new Date().getMilliseconds()} chartTitle={t('sp_countries')}
                         chartData={state.countryList}/>
                </MarketCalculusCard>
                <hr/>
              </Col>
            </Row>
            <Col md="12">
              <MarketCalculusCard>
                {selectedFirmDetails?.importerName && <McBarChart chartTitle={t('sp_import_percentage_by_year')}
                                                                  chartId={selectedFirmDetails?.importerName.replaceAll(' ', '').toLowerCase() + new Date().getMilliseconds()}
                                                                  chartData={state.yearChartData}
                                                                  yTooltip={true} xTitle={t('sp_years')}
                                                                  yTitle={t('sp_percentage')}/>}
              </MarketCalculusCard>
            </Col>
          </Col>
          <Col md="12">

            <MarketCalculusCard>
              <div className="card-title-group mb-1">
                <div className="card-title">
                  <h6 className="title">{t('table_sector_list')}</h6>
                </div>
              </div>
              <Row className="g-1">
                {state.sector_list}
              </Row>
            </MarketCalculusCard>
          </Col>

          <Col md="12">

            <MarketCalculusCard>
              <div className="card-title-group mb-1">
                <div className="card-title">
                  <h6 className="title">{t('sp_hs_codes')}</h6>
                </div>
              </div>
              <Row className="g-1">
                {state.productList}
              </Row>
            </MarketCalculusCard>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  </>)
}

export default FirmDetailsModal
