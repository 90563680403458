import McPageHelpers from '../pages/mc-components/McPageHelpers'

const initialMarketOverviewData = {
  importer: {
    country: { monthly: [], yearly: [] },
    time: { monthly: [], yearly: [] },
    data: { monthly: [], yearly: [] }
  },
  exporter: {
    country: { monthly: [], yearly: [] },
    time: { monthly: [], yearly: [] },
    data: { monthly: [], yearly: [] }
  },
  sourceCountry: {
    country: { monthly: [], yearly: [] },
    time: { monthly: [], yearly: [] },
    data: { monthly: [], yearly: [] }
  },
  destinationCountry: {
    country: { monthly: [], yearly: [] },
    time: { monthly: [], yearly: [] },
    data: { monthly: [], yearly: [] }
  }
}
const initialDataStore = {
  errors: {},
  globalParams: {
    hsCode: '',
    countryCode: '',
    countryName: '',
    dataCoverage: false,
    selectedUnit: McPageHelpers.UNITS.QUANTITY_1,
    selectedUnitNumber: McPageHelpers.UNIT_NUMBERS.N1
  },
  units: {
    quantity1: null,
    quantity2: null,
    quantity3: null,
    quantity4: null
  },

  selectedFirmDetails: {},
  selectedTableName: null,
  showFirmDetailsModal: false,

  rawImporterTable: [],
  filteredImporterTable: [],
  importerTableExplanation: [],
  importerGraphMapSectionExplanation: [],
  importerSize: {
    raw: 0,
    filtered: 0
  },
  rawImporterMap: [],
  rawImporterBar: [],
  totalImporterCompanyCount: 0,

  rawPotentialTable: [],
  filteredPotentialTable: [],
  filteredPotentialTableExplanation: [],
  potentialGraphMapSectionDataExplanation: [],
  potentialSize: {
    raw: 0,
    filtered: 0
  },
  rawPotentialMap: [],
  rawPotentialBar: [],
  totalPotentialCompanyCount: 0,

  companyValueBar: [],
  onlyHsCountPie: [],
  companyCountBar: [],
  countryCountPie: [],
  companyShipmentCountBar: [],
  growth: {},

  priceMap: [],
  priceBar: [],
  worldPriceMap: [],
  worldPriceBar: [],
  avgMonthlyLine: [],
  avgMonthlyTable: [],

  hsTotalMountain: {
    raw: { data: [], min: '', max: '' },
    out: { data: [], min: '', max: '' }
  },
  hsCountMountain: {
    raw: { data: [], min: '', max: '' },
    out: { data: [], min: '', max: '' }
  },
  avgPriceMountain: {
    raw: { data: [], min: '', max: '' },
    out: { data: [], min: '', max: '' }
  },
  avgCurrencyMountain: {
    raw: { data: [], min: '', max: '' },
    out: { data: [], min: '', max: '' }
  },
  avgInterestMountain: {
    raw: { data: [], min: '', max: '' },
    out: { data: [], min: '', max: '' }
  },
  allExplanations: {
    avgPriceExplanations: [''],
    tableExplanations: [''],
    parityGraphExplanations: [''],
    hsTotalValFirmNumExplanation: [''],
    avgPriceHsTotalValExplanations: [''],
    avgPriceFirmNumExplanations: [''],
    hsCountHsTotalValExplanation: [''],
    avgPriceHsTotalValPercentageExplanations: ['']
  },

  marketOverviewData: {
    value: initialMarketOverviewData,
    quantity: initialMarketOverviewData,
    averagePrice: initialMarketOverviewData
  },

  marketChangesData: {
    clustered: {},
    stacked: {},
    table: [],
    series: {
      value: [],
      quantity: [],
      excludedValue: [],
      excludedQuantity: []
    },
    explanations: {
      marketShareChangeExplanation: [],
      marketShareChangeDetailsExplanation: [],
      importerMonthlyValueExplanation: [],
      importerValueChangeExplanation: [],
      worldMonthlyImportValueExplanation: [],
      worldImportValueChangeExplanation: [],
      importerMonthlyQuantityExplanation: [],
      importerQuantityChangeExplanation: [],
      worldMonthlyImportQuantityExplanation: [],
      worldImportQuantityChangeExplanation: [],
      importerMonthlyTop5ValueExplanation: [],
      importerTop5ValueChangeExplanation: [],
      importerMonthlyTop5QuantityExplanation: [],
      importerTop5QuantityChangeExplanation: []
    }
  },

  customsDuties: [],

  summaryTablesData: {
    import: {
      yearly: {},
      monthly: {},
      year: null,
      month: null
    },
    export: {
      yearly: {},
      monthly: {},
      year: null,
      month: null
    },
    world: {
      import: {
        yearly: {},
        monthly: {},
        year: null,
        month: null
      },
      export: {
        yearly: {},
        monthly: {},
        year: null,
        month: null
      }
    }
  }
}

export const initialRawStore = {
  spTable: {},
  spGraph: {},
  rfTable: {},
  spSummary: {},
  monthly: {},
  marketChanges: {},
  comtradeMarketChange: {},
  comtradeMarketOverview: {},
  comtradeSummary: {},
  duties: []
}

export default {
  initialMarketOverviewData,
  initialDataStore
}
