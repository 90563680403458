import React from 'react'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import ImporterFilterMapBarHeader from './_header'
import ImporterFilterMapBarContent from './_content'

const McCardMB = ({ id, code, subCode, show, header, sub, map, graph, xTitle, yTitle, height, subElement, footer }) => {
  const headerContent = { code, header, sub }
  const mapBarContent = { id, code, subCode, map, graph, xTitle, yTitle, height, footer }
  return (
    <MarketCalculusCard id={id} className={show ? '' : 'loading-div'}>
      <ImporterFilterMapBarHeader content={headerContent}/>
      <ImporterFilterMapBarContent content={mapBarContent}/>
      <hr/>
      {subElement}
    </MarketCalculusCard>
  )
}

export default McCardMB
