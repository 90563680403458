import React from 'react'
import LogoLight2x from '../../images/logo2x.png'
import LogoDark2x from '../../images/logo-dark2x.png'
import { Link } from 'react-router-dom'
import { useGlobalStoreData } from '../../store/globalStore'
import { useSDSfirstMenuItemLink, useSDSimaginalUrlSearchParams } from '../../store/searchDataStore'

const Logo = () => {
  const languageGSD = useGlobalStoreData.language()

  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const firstMenuItemLink = useSDSfirstMenuItemLink()

  return (
    <Link
      to={`${process.env.PUBLIC_URL}/${languageGSD}${firstMenuItemLink}${urlSearchParams ? '?' + urlSearchParams : ''}`}
      className="logo-link">
      <img className="logo-light logo-img" src={LogoLight2x} alt="logo"/>
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo"/>
    </Link>
  )
}

export default Logo
