import i18n from '../../utils/i18n'

const CL = (duties, defaultObj, defaultColumns, result) => {
  for (const item of (duties?.defaultDutiesList || [])) {
    const defaultItem = { ...item, ...item.dutiesValuesMap }
    defaultObj.data.push(defaultItem)
  }

  for (const item of defaultObj.data) {
    const columns = Object.keys(item).filter(column => !['id', 'parentId', 'dutiesValuesMap', 'memberCountryDuties'].includes(column))
    columns.forEach((column) => defaultColumns.add(column))
  }
  defaultColumns.add('memberCountryDuties')
  defaultObj.columns = Array.from(defaultColumns)
  result.push(defaultObj)

  for (const key in duties?.agreementDutiesMap) {
    const dataList = duties.agreementDutiesMap[key]
    const agreementObj = {
      title: key ?? i18n.t('tax_rates') + ' 2023',
      data: [],
      columns: [],
      keyExpr: 'id',
      parentIdExpr: 'parentId',
      expandedKeys: [1]
    }

    const allColumns = new Set()

    for (const item of dataList) {
      if (item.dutiesValuesMap !== null) {
        for (const prop in item.dutiesValuesMap) {
          item[prop] = item.dutiesValuesMap[prop]
        }
      } else {
        item.dutiesValuesMap = {}
      }

      const columns = Object.keys(item).filter(column => !['id', 'parentId', 'dutiesValuesMap', 'memberCountryDuties'].includes(column))
      agreementObj.data.push(item)
      columns.forEach((column) => allColumns.add(column))
    }
    allColumns.add('memberCountryDuties')
    agreementObj.columns = Array.from(allColumns)
    result.push(agreementObj)
  }
}

const US = (duties, defaultObj, result) => {
  defaultObj.data = duties
  defaultObj.columns = ['hsCode', 'description', 'general', 'special', 'other', 'memberCountryDuties']
  result.push(defaultObj)
}

const QA = (duties, defaultObj, defaultColumns, result) => {
  getDefaultDuties(duties, defaultObj, defaultColumns, result, '2024')
}

const KW = (duties, defaultObj, defaultColumns, result) => {
  getDefaultDuties(duties, defaultObj, defaultColumns, result, '2024')
}

const JO = (duties, defaultObj, defaultColumns, result) => {
  getDefaultDuties(duties, defaultObj, defaultColumns, result, '2024')
}

const SA = (duties, defaultObj, defaultColumns, result) => {
  getDefaultDuties(duties, defaultObj, defaultColumns, result, '2024')
}

const CA = (duties, defaultObj, defaultColumns, result) => {
  defaultObj.title = i18n.t('tax_rates') + ' ' + '2024'
  for (const item of (duties || [])) {
    const defaultItem = { ...item, ...item.dutiesValuesMap }
    defaultObj.data.push(defaultItem)
  }
  for (const item of defaultObj.data) {
    const columns = Object.keys(item).filter(column => !['id', 'parentId', 'dutiesValuesMap'].includes(column))
    columns.forEach((column) => defaultColumns.add(column))
  }
  defaultObj.columns = Array.from(defaultColumns)
  result.push(defaultObj)
}

const LB = (duties, defaultObj, defaultColumns, result) => {
  defaultObj.title = i18n.t('tax_rates') + ' ' + '2024'
  for (const item of (duties || [])) {
    const defaultItem = { ...item, ...item.dutiesValuesMap }
    defaultObj.data.push(defaultItem)
  }
  defaultObj.columns = ['hsCode', 'description', 'rateOfDuty', 'vat', 'additionalDuty', 'complementaryNotes', 'governmentControls', 'tradeAgreements']
  result.push(defaultObj)
}

function getDefaultDuties (duties, defaultObj, defaultColumns, result, year) {
  defaultObj.title = i18n.t('tax_rates') + ' ' + year
  for (const item of (duties || [])) {
    const defaultItem = { ...item, ...item.dutiesValuesMap }
    defaultObj.data.push(defaultItem)
  }
  for (const item of defaultObj.data) {
    const columns = Object.keys(item).filter(column => !['id', 'parentId', 'dutiesValuesMap'].includes(column))
    columns.forEach((column) => defaultColumns.add(column))
  }
  defaultObj.columns = Array.from(defaultColumns)
  result.push(defaultObj)
}

const EU = (duties, defaultObj, result) => {
  for (const key in duties) {
    const dataList = duties[key]
    const dutiesObj = {
      title: key ?? i18n.t('tax_rates') + ' 2023',
      data: dataList,
      columns: ['hsCode', 'description', 'measureType', 'legalBase', 'duty'],
      keyExpr: 'id',
      parentIdExpr: 'parentId',
      expandedKeys: [1]
    }
    result.push(dutiesObj)
  }
}

const ECOWAS = (duties, defaultObj, result) => {
  const dutiesObj = {
    title: i18n.t('tax_rates') + ' 2024',
    data: duties,
    columns: ['hsCode', 'description', 'unit', 'importDuty', 'valueAddedTax', 'importAdjustmentTax', 'exciseTax', 'dateOfValidity'],
    keyExpr: 'id',
    parentIdExpr: 'parentId',
    expandedKeys: [1]
  }
  result.push(dutiesObj)
}

const SACU = (duties, defaultObj, defaultColumns, result) => {
  getDefaultDuties(duties, defaultObj, defaultColumns, result, '2024')
}

export const DutiesTransformer = { CL, US, QA, KW, JO, SA, CA, LB, EU, ECOWAS, SACU }
