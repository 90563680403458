import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { Icon, McDataTable } from '../Component'
import { usePDFselectedComponentIds } from '../../store/pdfStore'

const MarketShareTable = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const selectedComponentIds = usePDFselectedComponentIds()

  const {
    data,
    responseCountry,
    yearlyHsSums
  } = props

  const customStyles = {
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '10px'
      }
    }
  }

  const calculateValue = (item, monthData, lastMonthData) => {
    const calculate = monthData - lastMonthData
    return McPageHelpers.numToLocaleString(calculate * (item[1] / 10000), 'percent', true, 1, 1)
      .replace('-', '')
  }

  const getCountryWithValue = (row, monthData, lastMonthData) => {
    if (row) {
      return Object.entries(row).sort((a, b) => b[1] - a[1])
        .map(item => `${McPageHelpers.findCountryName(item[0], responseCountry)} (${(calculateValue(item, monthData, lastMonthData))})`)
        .join(' - ')
    }
  }

  const columns = [
    {
      name: (<div>{t('market_share_changes')}</div>),
      selector: row => row.previous12Months < row.last12Months
        ? <Icon name="arrow-up" style={{ color: '#00c000' }}/>
        : <Icon name="arrow-down" style={{ color: '#ff0000' }}/>,
      center: true,
      width: '5%',
      wrap: true
    },
    {
      name: (<div>{t('market_change_country')}</div>),
      selector: row => McPageHelpers.findCountryName(row.countryCode, responseCountry),
      left: true,
      width: '12%',
      wrap: true
    },
    {
      name: (<div>{yearlyHsSums ? McPageHelpers.getYearAndMonth(Object.keys(yearlyHsSums)[0]) : ''}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.previous12Months / 100, 'percent', false, 1, 1),
      right: true,
      width: '12%',
      sortable: true
    },
    {
      name: (<div>{yearlyHsSums ? McPageHelpers.getYearAndMonth(Object.keys(yearlyHsSums)[1]) : ''}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.last12Months / 100, 'percent', false, 1, 1),
      right: true,
      width: '12%',
      sortable: true
    },
    {
      name: (<div>{t('market_share_rate_change')}</div>),
      selector: row => McPageHelpers.numToLocaleString((row.last12Months - row.previous12Months) / 100, 'percent', false, 1, 1),
      right: true,
      width: '12%',
      sortable: true
    },
    {
      name: (<div>{t('market_share_take_countries')}</div>),
      selector: row => getCountryWithValue(row.countryPercentages, row.last12Months, row.previous12Months),
      left: true,
      width: '40%',
      wrap: true
    }
  ]

  useImperativeHandle(ref, () => ({
    generateMarketSharePdf (id) {
      if (selectedComponentIds.includes(id)) {
        const result = [
          McPageHelpers.generateDataTableContentForPdf(columns, data?.sort((a, b) => (b.last12Months - b.previous12Months) - (a.last12Months - a.previous12Months)), false, 'after')
        ]

        if (Array.isArray(props.footer) && props.footer.length > 0) {
          const footerItems = props.footer.map((item, index) => ({
            text: item,
            fontSize: 10,
            margin: index !== props.footer.length - 1 ? [0, 0, 0, 10] : [0, 0, 0, 20],
            color: 'gray',
            italics: true
          }))

          result.push(...footerItems)
        }

        return result
      } else return null
    }
  }))

  return (
    <>
      <div className={'g-3 w-fit'}>
        <McDataTable
          data={data?.sort((a, b) => (b.last12Months - b.previous12Months) - (a.last12Months - a.previous12Months))}
          columns={columns}
          id="marketShareTable"
          customSort={McPageHelpers.mcDataTableCustomSort}
          customStyles={customStyles}
          hideHeader
          footer={props.footer}
        /></div>
    </>
  )
})
export default MarketShareTable
