import ClusteredBarChart from '../../../components/amcharts/ClusteredBarChart'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../../store/globalStore'
import { useDSglobalParams, useDSmarketChangesData } from '../../../store/dataStore'
import McPageHelpers from '../McPageHelpers'
import { Col, Row } from 'reactstrap'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { useSDSsearchParams, useSDSsearchedDataCoverage } from '../../../store/searchDataStore'
import StackedBarChart from '../../../components/amcharts/StackedBarChart'
import MarketShareTable from '../../../components/table/MarketShareTable'
import ComTradeMarketChangeChart from '../../../components/amcharts/ComTradeMarketChangeChart'
import CONSTANTS from '../../../utils/Constants'
import React, { forwardRef } from 'react'
import MCTitleCard from '../../../components/title-card/MCTitleCard'
import { useRAWmarketChanges } from '../../../store/rawStore'
import { MARKET_CHANGE_CHART_TYPES } from '../../../utils/AMChartsSettings'

const MarketChangesSection = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const globalParams = useDSglobalParams()
  const marketChangesData = useDSmarketChangesData()

  const marketChanges = useRAWmarketChanges()

  const searchedDataCoverage = useSDSsearchedDataCoverage()
  const searchParams = useSDSsearchParams()

  const importerCountryName = McPageHelpers.findCountryName(searchParams.countryToExport)
  const worldCountryName = McPageHelpers.findCountryName('World')

  const header = t('market_changes_table_title')
  const subHeader = importerCountryName + ' ' + searchParams.hsCode

  const NoSearchedDataCoverage = () => (
    <div className="search-trigger-title">
      <span className="subTitle"> {t('mc_no_search_trigger')}</span>
    </div>
  )

  return (
    <>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard id={'clusteredBarChart' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={!marketChangesData.clustered?.stackedData?.length || !searchedDataCoverage ? 'loading-div' : ''}>
            <div id={'clusteredBarChart' + McPageHelpers.FOR_PDF_ID}>
              <MCTitleCard code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_CLUSTERED}
                           chartSubTitle={t('country_code_subtitle', {
                             country: McPageHelpers.findCountryName(globalParams.countryCode),
                             hsCode: globalParams.hsCode
                           })} chartTitle={t('clustered_bar_chart_change_of_rates_title')}/>
              {searchedDataCoverage
                ? <div

                  className={!marketChangesData.clustered?.stackedData?.length || !searchedDataCoverage ? 'loading-div' : ''}>
                  <ClusteredBarChart response={marketChangesData.clustered}
                                     xTitle={t('sp_percentage')}
                                     yTitle={t('clustered_bar_chart_yTitle')}
                                     footer={marketChangesData.explanations.marketShareChangeExplanation}
                  />
                </div>
                : <NoSearchedDataCoverage/>}
            </div>

            {McPageHelpers.generateExplanationFooter(marketChangesData.explanations.marketShareChangeExplanation)}
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard id={'stackedBarChart' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`${!marketChangesData.stacked?.stackedData?.length || !searchedDataCoverage ? 'loading-div' : ''} h-100`}>
            <div id={'stackedBarChart' + McPageHelpers.FOR_PDF_ID} className="h-100">
              <MCTitleCard code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_STACKED}
                           chartSubTitle={t('country_code_subtitle', {
                             country: importerCountryName,
                             hsCode: globalParams.hsCode
                           })} chartTitle={t('stacked_bar_chart_change_of_rates_title')}
              />
              {searchedDataCoverage
                ? <div

                  className={`${!marketChangesData.stacked?.stackedData?.length ? 'loading-div' : ''} h-100`}>
                  <StackedBarChart response={marketChangesData.stacked}
                                   xTitle={t('stacked_bar_chart_xTitle')}
                                   yTitle={t('clustered_bar_chart_yTitle')}
                                   country={countriesGSD}
                  />
                </div>
                : <NoSearchedDataCoverage/>
              }
            </div>

          </MarketCalculusCard>
        </Col>
      </Row>
      <MarketCalculusCard id={'marketShareTable' + McPageHelpers.FOR_PDF_CONTENT_ID}
                          className={`${!marketChangesData.table?.length ? 'loading-div' : ''}`}>
        <MCTitleCard code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_TABLE_1} chartSubTitle={subHeader} chartTitle={header}
                     codeColor="info"
        />
        <MarketShareTable data={searchedDataCoverage ? marketChangesData.table : []}
                          yearlyHsSums={marketChanges?.yearlyHsSums}
                          responseCountry={countriesGSD}
                          ref={ref}
                          footer={marketChangesData.explanations.marketShareChangeDetailsExplanation}
        />
      </MarketCalculusCard>

      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeValueSearchedCountry"
              chartTitle={t('market_change_value_searched_country_title')}
              chartData={marketChangesData?.series?.excludedValue?.filter(item => item[0]?._country === searchParams.countryToExport)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_dollar_t')}
              chartType={MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_3}
              footer={marketChangesData.explanations.importerMonthlyValueExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeValueSearchedCountryPercentage"
              chartTitle={t('market_change_value_searched_country_title_percentage')}
              chartData={marketChangesData?.series?.excludedValue?.filter(item => item[0]?._country === searchParams.countryToExport)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_4}
              footer={marketChangesData.explanations.importerValueChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeValueWorld"
              chartTitle={t('market_change_value_searched_country_title')}
              chartData={marketChangesData?.series?.excludedValue?.filter(item => item[0]?._country === CONSTANTS.WORLD_COUNTRY_CODE)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_dollar_t')}
              chartType={MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY}
              chartSubTitle={`${worldCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_5}
              footer={marketChangesData.explanations.worldMonthlyImportValueExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeValueWorldPercentage"
              chartTitle={t('market_change_value_searched_country_title_percentage')}
              chartData={marketChangesData?.series?.excludedValue?.filter(item => item[0]?._country === CONSTANTS.WORLD_COUNTRY_CODE)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${worldCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_6}
              footer={marketChangesData.explanations.worldImportValueChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeQuantitySearchedCountry"
              chartTitle={t('market_change_quantity_searched_country_title')}
              chartData={marketChangesData?.series?.excludedQuantity?.filter(item => item[0]?._country === searchParams.countryToExport)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_ton')}
              chartType={MARKET_CHANGE_CHART_TYPES.QUANTITY}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_7}
              footer={marketChangesData.explanations.importerMonthlyQuantityExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeQuantitySearchedCountryPercentage"
              chartTitle={t('market_change_quantity_searched_country_title_percentage')}
              chartData={marketChangesData?.series?.excludedQuantity?.filter(item => item[0]?._country === searchParams.countryToExport)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_8}
              footer={marketChangesData.explanations.importerQuantityChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeQuantityWorld"
              chartTitle={t('market_change_quantity_searched_country_title')}
              chartData={marketChangesData?.series?.excludedQuantity?.filter(item => item[0]?._country === CONSTANTS.WORLD_COUNTRY_CODE)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_ton')}
              chartType={MARKET_CHANGE_CHART_TYPES.QUANTITY}
              chartSubTitle={`${worldCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_9}
              footer={marketChangesData.explanations.worldMonthlyImportQuantityExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeQuantityWorldPercentage"
              chartTitle={t('market_change_quantity_searched_country_title_percentage')}
              chartData={marketChangesData?.series?.excludedQuantity?.filter(item => item[0]?._country === CONSTANTS.WORLD_COUNTRY_CODE)}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${worldCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_10}
              footer={marketChangesData.explanations.worldImportQuantityChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeValueSeries"
              chartTitle={t('market_change_value_series_title')}
              chartData={marketChangesData?.series?.value}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_dollar_t')}
              chartType={MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_11}
              footer={marketChangesData.explanations.importerMonthlyTop5ValueExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangePercentageValueSeries"
              chartTitle={t('market_change_value_series_title_percentage')}
              chartData={marketChangesData?.series?.value}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('value_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_12}
              footer={marketChangesData.explanations.importerTop5ValueChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangeQuantitySeries"
              chartTitle={t('market_change_quantity_series_title')}
              chartData={marketChangesData?.series?.quantity}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_ton')}
              chartType={MARKET_CHANGE_CHART_TYPES.QUANTITY}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_13}
              footer={marketChangesData.explanations.importerMonthlyTop5QuantityExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="6">
          <MarketCalculusCard>
            <ComTradeMarketChangeChart
              chartId="comtradeMarketChangePercentageQuantitySeries"
              chartTitle={t('market_change_quantity_series_title_percentage')}
              chartData={marketChangesData?.series?.quantity}
              percentageTitle={t('percentage_of_change')}
              quantityTitle={t('quantity_with_percent')}
              chartType={MARKET_CHANGE_CHART_TYPES.PERCENTAGE}
              chartSubTitle={`${importerCountryName} ${searchParams.hsCode}`}
              code={CONSTANTS.BADGE_CODES.MARKET_CHANGE_GRAPH_14}
              footer={marketChangesData.explanations.importerTop5QuantityChangeExplanation}
              countries={countriesGSD}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
    </>
  )
})

export default MarketChangesSection
