import React, { useState } from 'react'
import UserAvatar from '../../../../components/user/UserAvatar'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Icon } from '../../../../components/Component'
import { LinkList } from '../../../../components/links/Links'
import { handleSignout } from '../../../../utils/Utils'
import { useGlobalStoreData } from '../../../../store/globalStore'
import { useTranslation } from 'react-i18next'

// import { Link } from 'react-router-dom'
// import { getBooleanUserRolesByUser } from '../../../../mailTemplate/src/utils/helpers'

const User = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((prevState) => !prevState)

  const userGSD = useGlobalStoreData.user()
  // const languageGSD = useGlobalStoreData.language()
  const isAnonymous = userGSD?.userName === 'ANONYMOUS'

  // const userRoles = getBooleanUserRolesByUser(userGSD)

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault()
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm"/>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              {!isAnonymous && <span>{userGSD?.nameSurname?.match(/(^|\s)(\p{L})/gu).map(m => m.trim()).join('').toUpperCase()}</span>}
            </div>
            <div className="user-info">
              <span className="lead-text text-capitalize">{isAnonymous ? t('demo_user') : userGSD?.nameSurname}</span>
              {!isAnonymous && <span className="sub-text">{userGSD?.email}</span>}
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/*
              //TODO:INFO: B2B linking moved to the Menu & MobileMenu components
              userRoles.B2B &&
              <Link to={`${process.env.PUBLIC_URL}/${languageGSD}/mailing${userRoles.B2B_PERSONNEL ? '/users' : ''}`}>
                <Icon name="mail"></Icon>
                <span>{t('mailTemplate.headerLinkTemplate')}</span>
              </Link>
            */}
            <a href="#signout" onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>{t('logout')}</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default User
