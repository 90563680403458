import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import LogoDark from '../../images/logo-dark.png'
import Head from '../../layout/head/Head'
import AuthFooter from './AuthFooter'
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard
} from '../../components/Component'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import McPageHelpers from '../mc-components/McPageHelpers'
import McDataServices from '../../services/McDataServices'
import { Form } from 'reactstrap'
import { useGlobalStoreData } from '../../store/globalStore'

const NewPassword = () => {
  const { t } = useTranslation()
  const [passState, setPassState] = useState(false)
  const { register, formState: { errors } } = useForm()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isMatch, setIsMatch] = useState(true)
  const navigate = useNavigate()
  const languageGSD = useGlobalStoreData.language()

  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams
    const token = searchParams.get('token')
    McDataServices.getResetTokenValidity(token)
      .then(result => !result ? navigate(`${process.env.PUBLIC_URL}/${languageGSD}`) : '')
      .catch(() => navigate(`${process.env.PUBLIC_URL}/${languageGSD}`))
  }, [])

  const submitNewPassword = () => {
    const payload = {
      token: new URL(window.location.href).searchParams.get('token'),
      newPassword: document.getElementById('password').value,
      confirmPassword: document.getElementById('confirmPassword').value
    }

    McDataServices.resetPassword(payload)
      .then(result => {
        if (result) navigate(`${process.env.PUBLIC_URL}/${languageGSD}`)
      })
      .catch(error => console.error('error', error))
  }

  return (
    <>
      <Head title={t('no_password')}/>
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + '/'} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">{t('reset_password_header')}</BlockTitle>
              <BlockDes>
                <p>{t('renew_password_desc')}</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <Form>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  {t('password')}
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setPassState(!passState)
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? 'text' : 'password'}
                  id="password"
                  name="password"
                  {...register('password', {
                    required: true,
                    maxLength: 30,
                    minLength: 8,
                    pattern: {
                      value: '^(?=.[A-Z])(?=.d).{8,}$,'
                    }
                  })}
                  onChange={event => {
                    const target = event.target.value
                    setPassword(target)
                    if (target !== confirmPassword) {
                      return setIsMatch(false)
                    } else return setIsMatch(true)
                  }}
                  placeholder={t('password')}
                  className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}/>
                {isMatch && errors.password &&
                  <span className="error-invalid">{t('required_pass_control')}</span>}
                {isMatch && !McPageHelpers.checkPasswordValidity(password) && password &&
                  <span className="error-invalid">{t('required_pass_control')}</span>}
                {!isMatch && confirmPassword && <span className="error-invalid">{t('not_match_password')}</span>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="confirmPassword">
                  {t('password_again')}
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setPassState(!passState)
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  {...register('confirmPassword', {
                    required: t('required_pass_control'),
                    maxLength: 30,
                    minLength: 8,
                    pattern: {
                      value: '^(?=.[A-Z])(?=.d).{8,}$,'
                    }
                  })}
                  aria-describedby="-error"
                  onChange={event => {
                    const target = event.target.value
                    setConfirmPassword(target)
                    if (target !== password) {
                      return setIsMatch(false)
                    } else setIsMatch(true)
                  }
                  }
                  placeholder={t('password_again')}
                  className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}/>
              </div>
            </div>

            <div className="form-group">
              <Button color="primary" size="lg" className="btn-block" type="button" onClick={submitNewPassword}>
                {t('renew_password')}
              </Button>
            </div>
          </Form>
        </PreviewCard>
      </Block>
      <AuthFooter/>
    </>
  )
}
export default NewPassword
