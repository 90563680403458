import QuantityButtons from '../quantity-button-groups/QuantityButtons'
import { BlockBetween, BlockHead, BlockHeadContent } from '../../../components/Component'
import React from 'react'

const QuantityButtonsSection = () => {
  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
        </BlockHeadContent>
        <BlockHeadContent>
          <QuantityButtons/>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  )
}

export default QuantityButtonsSection
