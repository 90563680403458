const menu = [
  // {
  //   text: 'menu.dashboard',
  //   link: '',
  // },
  {
    text: 'menu.summary_tables',
    link: '/summary-tables',
    endpoints: ['comtradeSummary']
  },
  {
    text: 'menu.market_overview',
    link: '/market-overview',
    endpoints: ['comtradeMarketOverview']
  },
  {
    text: 'menu.market_changes',
    link: '/market-changes',
    endpoints: ['marketChange', 'comtradeMarketChange']
  },
  {
    text: 'menu.product_analysis',
    link: '/product-analysis',
    endpoints: ['spGraph']
  },
  {
    text: 'menu.price_analysis',
    link: '/price-analysis',
    endpoints: ['spTable', 'spGraph', 'monthly']
  },
  {
    text: 'menu.importer_companies',
    link: '/importer-companies',
    endpoints: ['spTable', 'spGraph', 'rfTable', 'spSummary']
  },
  {
    text: 'menu.duties',
    link: '/custom-duties',
    disabled: true,
    endpoints: ['duties']
  }
]
export default menu
