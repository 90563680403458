import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import LogoDark from '../../images/logo-dark.png'
import Head from '../../layout/head/Head'
import AuthFooter from './AuthFooter'
import { Block, BlockContent, BlockHead } from '../../components/Component'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import McPageHelpers from '../mc-components/McPageHelpers'

const Success = () => {
  const { t } = useTranslation()
  const [externalSuccess, setExternalSuccess] = useState(false)

  useEffect(() => {
    if (!window.history.state || (window.history.state?.form !== McPageHelpers.REGISTER_STATES.EXTERNAL && window.history.state?.form !== McPageHelpers.REGISTER_STATES.SUBMITTED)) {
      return (
        window.history.pushState({}, '', '/'),
        window.location.reload()
      )
    }

    if (window.history.state?.form === McPageHelpers.REGISTER_STATES.EXTERNAL) setExternalSuccess(true)
  }, [])

  return (
    <>
      <Head title={t('success')}/>
      <Block className="nk-block-middle nk-auth-body">
        <div className="brand-logo pb-5">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
          </Link>
        </div>
        <BlockHead>
          <BlockContent>
            <p>
              {window.history?.state?.form !== 'reject'
                ? <Trans
                  i18nKey="register_success"
                  values={{
                    email: new URL(window.location.href).searchParams.get('email')
                  }}
                  components={[<strong key={1}/>]}
                />
                : t('active_user_exist')}</p>
          </BlockContent>
        </BlockHead>
        {window.history?.state?.form === 'reject' && (
          <div className="form-note-s2 ">
            {t('login_account')}
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <strong>{t('login.c')}</strong>
            </Link>
          </div>
        )}
      </Block>
      {!externalSuccess ? <AuthFooter/> : ''}
    </>
  )
}
export default Success
