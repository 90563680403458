import React from 'react'
import { Bar } from 'react-chartjs-2'

export const FirmCardChart = ({ chartData, ...props }) => {
  return (
    <Bar
      className="iv-data-chart"
      data={chartData}
      options={{
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: () => { return '' }
            },
            enabled: true,
            backgroundColor: '#eff6ff',
            titleFont: { size: '11px' },
            titleColor: '#6783b8',
            titleMarginBottom: 0,
            bodyColor: '#9eaecf',
            bodyFont: { size: '10px' },
            bodySpacing: 10,
            yPadding: 1,
            xPadding: 1,
            displayColors: false
          }
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            ticks: {
              beginAtZero: true
            }
          },
          x: {
            display: false
          }
        }
      }}
    ></Bar>
  )
}
