import React, { useState, useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  CircularProgress
} from '@mui/material'
import {
  Send,
  Close
} from '@mui/icons-material'

// Component Imports
import RichTextEditor from '../lexical/RichTextEditor'
import CustomAutocomplete from '../atomic/atoms/CustomAutocomplete'
import CustomTextField from '../atomic/atoms/CustomTextField'

import { emailRegExp } from '../../utils/constants'

const emailValidation = (val) => emailRegExp.test(val)

const borderlessInputStyles = {
  '& .MuiInputBase-root': {
    '&:not(.Mui-error)': {
      borderColor: 'transparent !important'

    },
    borderWidth: '1px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '&.MuiAutocomplete-inputRoot.MuiInputBase-sizeSmall': {
      paddingBlock: 'unset !important',
      paddingInlineStart: 0
    },
    '&.Mui-focused': {
      boxShadow: 'none !important'
    },
    '& .MuiFilledInput-input': {
      padding: '2.5px 4px !important'
    }
  }
}

const ComposeMail = props => {
  const {
    title,
    openCompose,
    setOpenCompose,
    isBelowSmScreen,
    isBelowMdScreen,
    onSubmit,
    submitting,
    initialEmail,
    focusSubjectAfterInit,
    replyMessageId
  } = props

  const { t } = useTranslation()

  const [visibility, setVisibility] = useState({ cc: false })
  const [toField, setToField] = useState([])
  const [ccField, setCcField] = useState([])
  const [subject, setSubject] = useState('')
  const [toFieldErr, setToFieldErr] = useState(false)
  const [subjectErr, setSubjectErr] = useState(false)

  const initialHtml = initialEmail?.html

  const toggleVisibility = value => {
    setVisibility(prev => ({ ...prev, [value]: !prev[value] }))
  }

  const exportHtmlRef = useRef()

  const successCallback = () => {
    setOpenCompose(null)
  }

  const handleSubmit = () => {
    if (toField?.length <= 0) {
      setToFieldErr(true)
    } else if (!subject) {
      setSubjectErr(true)
    } else {
      const exportedHtml = exportHtmlRef.current.exportHtml()

      onSubmit(
        {
          subject,
          to: toField,
          cc: ccField,
          html: exportedHtml
        },
        successCallback,
        replyMessageId
      )
    }
  }

  useEffect(() => {
    if (openCompose) {
      setToField(initialEmail?.from_address ? [initialEmail?.from_address] : [])
      setCcField(initialEmail?.cc?.length ? [...initialEmail.cc] : [])
      setSubject(initialEmail?.subject || '')
      setToFieldErr(false)
      setSubjectErr(false)

      if (focusSubjectAfterInit) {
        setTimeout(() => {
          const elem = document.getElementById('focusWorkaroundId')
          elem && elem.focus()
        }, 100)
      }
    }
  }, [openCompose, initialEmail])

  return (
    <Drawer
      anchor='bottom'
      open={openCompose}
      onClose={() => setOpenCompose(null)}
      PaperProps={{
        sx: {
          width: isBelowMdScreen ? 'calc(100% - 2 * 1.5rem)' : '100%',
          maxWidth: 750,
          position: 'absolute',
          height: 'auto',
          insetInlineStart: 'auto',
          insetInlineEnd: '1.5rem',
          insetBlockEnd: '1.5rem',
          borderRadius: 'var(--mui-shape-borderRadius)',
          borderTop: 0,
          boxShadow: 'var(--mui-customShadows-xl)',
          border: undefined,
          zIndex: 12,
          bottom: 100
        }
      }}
    >
      <div className='flex items-center justify-between plb-3.5 pli-6 bg-actionHover'>
        <Typography variant='h5' color='text.secondary'>
          {title}
        </Typography>
        <div className='flex gap-2'>
          <IconButton size='small' onClick={() => setOpenCompose(null)}>
            <Close/>
          </IconButton>
        </div>
      </div>
      <div
        className='flex items-center gap-2 pli-6 plb-1'
        style={{ borderBottom: 'thin solid var(--mui-palette-divider)' }}
      >
        <div className='flex items-center grow'>
          <Typography className='font-medium mie-2' color='text.disabled'>
            {t('mailTemplate.mailbox.send.toLabel')}
          </Typography>
          <CustomAutocomplete
            multiple
            options={[]}
            value={toField}
            freeSolo
            autoSelect
            fullWidth
            onChange={(e, val) => {
              if (e.target.value === undefined || emailValidation(e.target.value)) {
                setToField(val)
                setToFieldErr(false)
              }
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                sx={borderlessInputStyles}
                value={toField}
                error={toFieldErr}
              />
            )}
          />
        </div>
        <div className='text-textSecondary'>
          <span className='cursor-pointer' onClick={() => toggleVisibility('cc')}>
            {t('mailTemplate.mailbox.send.cc')}
          </span>
        </div>
      </div>
      {visibility.cc && (
        <div
          className='flex items-center pli-6 plb-1'
          style={{ borderBottom: 'thin solid var(--mui-palette-divider)' }}
        >
          <Typography className='font-medium mie-2' color='text.disabled'>
            {t('mailTemplate.mailbox.send.ccLabel')}
          </Typography>
          <CustomAutocomplete
            multiple
            options={[]}
            value={ccField}
            freeSolo
            autoSelect
            fullWidth
            onChange={(e, val) => {
              if (e.target.value === undefined || emailValidation(e.target.value)) {
                setCcField(val)
              }
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                sx={borderlessInputStyles}
                value={ccField}
              />
            )}
          />
        </div>
      )}
      <div
        className='flex items-center pli-6 plb-1'
        style={{ borderBottom: 'thin solid var(--mui-palette-divider)' }}
      >
        <Typography className='font-medium mie-2' color='text.disabled'>
          {t('mailTemplate.mailbox.send.subjectLabel')}
        </Typography>
        <CustomTextField
          id='focusWorkaroundId'
          fullWidth
          value={subject}
          sx={borderlessInputStyles}
          onChange={(e) => {
            setSubject(e.target.value)
            if (e.target.value && subjectErr) {
              setSubjectErr(false)
            }
          }}
          error={subjectErr}
        />
      </div>

      <RichTextEditor
        templateHtml={initialHtml}
        ref={exportHtmlRef}
        textareaStyles={{
          maxHeight: isBelowSmScreen ? 'calc(100vh - 600px)' : 'calc(100vh - 550px)',
          resize: 'none',
          boxShadow: 'inset 0 -1px 5px -2px rgba(0, 0, 0, 0.4)'
        }}
      />

      <div className='plb-4 pli-5 flex justify-between items-center gap-4'>
        <div className='flex items-center gap-4 max-sm:gap-3'>
          {isBelowSmScreen
            ? (
                <IconButton color='primary' variant='contained'>
                  <Send/>
                </IconButton>
              )
            : (
                <Button
                  variant='contained'
                  endIcon={submitting ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : <Send/>}
                  onClick={() => handleSubmit()}
                  disabled={submitting}
                >
                  {t('mailTemplate.mailbox.send.send')}
                </Button>
              )
          }
        </div>
      </div>
    </Drawer>
  )
}

export default ComposeMail
