import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// MUI Imports
import { useMediaQuery } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

// Component Imports
import SidebarLeft from '../../mailbox/SidebarLeft'
import MailContent from '../../mailbox/MailContent'

import { getUrlParamsObj } from '../../../services/urlServices'
import { getMessages, sendEmailReply, getCampaign } from '../../../services/B2BServices'

const sortByDate = (arr, field, asc = true) => {
  return arr.sort((a, b) => asc
    ? (new Date(a?.[field]) - new Date(b?.[field]))
    : (new Date(b?.[field]) - new Date(a?.[field]))
  )
}

const getMessageObjExceptReplies = (message) => {
  return (({ replies, ...o }) => o)(message)
}

const getFlatMessages = (message, asc = true) => {
  let result = []
  result.push(getMessageObjExceptReplies(message))
  message?.replies?.length && message.replies.forEach((reply) => {
    const childFlat = getFlatMessages(reply)
    result = result.concat(childFlat)
  })

  return sortByDate(result, 'sentTimeMillis', asc)
}

const getManagedMessages = (arr) => {
  const flatted = arr?.map(item => getFlatMessages(item, false))
  const sortedFlatted = flatted.sort((a, b) => (new Date(b?.[0]?.sentTimeMillis) - new Date(a?.[0]?.sentTimeMillis)))

  return sortedFlatted
}

const Mailbox = () => {
  const { t } = useTranslation()

  const queryParams = getUrlParamsObj()

  const b2bCampaignId = queryParams?.b2bCampaignId

  const [emailArrays, setEmailArrays] = useState([])
  const [activeEmailArr, setActiveEmailArr] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)

  // Hooks
  const isBelowMdScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isBelowSmScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // Handle backdrop on click
  const handleBackdropClick = () => {
    setSidebarOpen(false)
    setBackdropOpen(false)
  }

  const getEmails = () => {
    if (b2bCampaignId) {
      setFetching(true)

      getMessages({ b2bCampaignId }).then((res) => {
        const managedMessages = getManagedMessages(res?.data)

        setEmailArrays(managedMessages)
      })
        .catch((err) => {
          err && toast.error(t('mailTemplate.mailbox.getMessagesErr'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }

  const getCampaignDetails = () => {
    if (b2bCampaignId) {
      getCampaign(b2bCampaignId).then((res) => {
        setCampaignDetails(res?.data)
      })
    }
  }

  const handleSubmitMail = (email, successCallback, replyMessageId = null) => {
    setSubmitting(true)

    const replyPayload = {
      campaignId: b2bCampaignId,
      to: email.to,
      cc: email.cc,
      subject: email.subject,
      bodyHtml: email.html,
      replyMessageId
    }

    sendEmailReply(replyPayload).then((res) => {
      toast.success(t('mailTemplate.mailbox.send.toastSuccess'), {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
      successCallback()
    }).catch((err) => {
      err && toast.error(t('mailTemplate.mailbox.send.toastErr'), {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
    }).finally(() => {
      setSubmitting(false)
    })
  }

  useEffect(() => {
    getEmails()
    getCampaignDetails()
  }, [])

  // Hide backdrop when left sidebar is closed
  useEffect(() => {
    if (backdropOpen && !sidebarOpen) {
      setBackdropOpen(false)
    }
  }, [sidebarOpen])

  // Hide backdrop when screen size is above md
  useEffect(() => {
    if (backdropOpen && !isBelowMdScreen) {
      setBackdropOpen(false)
    }

    if (sidebarOpen && !isBelowMdScreen) {
      setSidebarOpen(false)
    }
  }, [isBelowMdScreen])

  return (
    <div className='flex is-full h-full overflow-hidden rounded relative grow shadow-md'>
      <SidebarLeft
        isBelowMdScreen={isBelowMdScreen}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        campaignDetails={campaignDetails}
      />
      <Backdrop
        open={backdropOpen}
        onClick={handleBackdropClick}
        className='z-10'
        sx={{
          position: 'absolute'
        }}
      />
      <MailContent
        fetching={fetching}
        setSidebarOpen={setSidebarOpen}
        isBelowMdScreen={isBelowMdScreen}
        isBelowSmScreen={isBelowSmScreen}
        setBackdropOpen={setBackdropOpen}
        emailArrays={emailArrays}
        activeEmailArr={activeEmailArr}
        setActiveEmailArr={setActiveEmailArr}
        reloadMessages={getEmails}
        submitting={submitting}
        handleSubmitReply={handleSubmitMail}
        onSubmitNewMail={handleSubmitMail}
      />
    </div>
  )
}

export default Mailbox
