import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { useDataStoreActions, useDSglobalParams, useDSunits } from '../../../store/dataStore'
import { useTranslation } from 'react-i18next'
import McPageHelpers from '../McPageHelpers'

const QuantityButtons = () => {
  const { t } = useTranslation()

  const globalParams = useDSglobalParams()
  const units = useDSunits()
  const { setSelectedUnit } = useDataStoreActions()

  const quantities = [
    { unit: McPageHelpers.UNITS.QUANTITY_1, key: 'quantity1' },
    { unit: McPageHelpers.UNITS.QUANTITY_2, key: 'quantity2' },
    { unit: McPageHelpers.UNITS.QUANTITY_3, key: 'quantity3' },
    { unit: McPageHelpers.UNITS.QUANTITY_4, key: 'quantity4' }
  ]

  const hasAnyQuantity = quantities.some(q => units[q.key])

  return (
    <>
      {hasAnyQuantity && (
        <ul className="nk-block-tools g-3">
          <li><h6>{t('quantity_unit')}</h6></li>
          <li>
            <ButtonGroup className="btn-block btn-group-ai">
              {quantities.map(q =>
                units[q.key] && (
                    <Button
                      key={q.key}
                      color="primary"
                      onClick={() => setSelectedUnit(q.unit)}
                      outline={globalParams.selectedUnit !== q.unit}
                    >
                      {units[q.key]?.substr(0, 3) === '***' ? units[q.key] : t(`units.${units[q.key].toLowerCase()}`)}
                    </Button>
                )
              )}
            </ButtonGroup>
          </li>
        </ul>
      )}
    </>
  )
}

export default QuantityButtons
