import React, { useLayoutEffect, useRef } from 'react'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5 from '@amcharts/amcharts5'
import { localizationAMChart, numberFormatter } from '../../utils/AMChartsSettings'
import { CUSTOM_PALETTE_HEX } from '../../utils/AmChartsUtils'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const valueFields = [
  'value',
  'value2',
  'value3',
  'value4',
  'value5'
]
const initialCountries = [
  'Polonya',
  'Almanya',
  'Amerika',
  'Japonya',
  'Çin'
]

const MultipleLineChart = ({ chartId, chartTitle, chartData, yTitle, yFormatterType }) => {
  const reversedChartData = chartData.slice().reverse()
  const rootRef = useRef(null)

  const dummyData = []

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        pinchZoomX: true,
        layout: root.verticalLayout,
        exportable: true,
        paddingBottom: 80
      })
    )

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: numberFormatter(yFormatterType)
      })
    )

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 })
    xRenderer.labels.template.setAll({
      rotation: -20,
      minGridDistance: 10,
      fontSize: 10
    })

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: xRenderer,
        zoomX: false,
        startLocation: 0,
        endLocation: 1
      })
    )

    chart.get('colors').set('colors', CUSTOM_PALETTE_HEX)

    function createSeries (name, field) {
      const series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name,
          xAxis,
          yAxis,
          valueYField: field,
          valueXField: 'date',
          calculateAggregates: true,
          exportable: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            getFillFromSprite: true,
            labelText: '[bold]{name}[/]\n [bold]{valueY}[/]'
            // showTooltipOn: 'always'
          })
          // snapTooltip: true
        })
      )

      series.strokes.template.setAll({
        strokeWidth: 3
      })
      // series.get('tooltip').label.set('text', '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}')
      reversedChartData ? series.data.setAll(reversedChartData) : series.data.setAll(dummyData)
    }

    valueFields.forEach((valField, i) => {
      createSeries(chartData && chartData[0] ? chartData[0].countriesArray[i] : initialCountries[i], valField)
    })

    chart.set('cursor', am5xy.XYCursor.new(root, {
      behavior: 'zoomXY',
      xAxis
    }))

    xAxis.set('tooltip', am5.Tooltip.new(root, {
      themeTags: ['axis']
    }))

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      y: am5.percent(95),
      height: 80
    }))

    legend.data.setAll(chart.series.values)

    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [reversedChartData])

  return (
    <div id={chartId + McPageHelpers.FOR_PDF_ID}>
      <div className={'card-title-group justify-center mb-1 mt-2'}>
        <div className="card-title">
          <h6 className="title" style={{ whiteSpace: 'pre-line' }}>{chartTitle}</h6>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p
            style={{
              transform: 'rotate(-90deg)',
              fontSize: '10px',
              whiteSpace: 'nowrap',
              marginBottom: '40px'
            }}
          >{yTitle}</p>
        </div>

        <div
          id={chartId}
          style={{
            flex: '1 100%',
            width: '90%',
            height: '500px',
            cursor: 'pointer'
          }}
        ></div>
      </div>
    </div>
  )
}

export default MultipleLineChart
