import { forwardRef, useImperativeHandle, useEffect } from 'react'

import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createParagraphNode, $getRoot } from 'lexical'

export const ExportHtml = forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext()

  useImperativeHandle(ref, () => ({
    exportHtml: () => {
      const editorState = editor.getEditorState()
      let htmlString

      editorState.read(() => {
        htmlString = $generateHtmlFromNodes(editor)
      })

      return htmlString
    }
  }))

  return null
})

export const LoadInitialHtml = ({ html }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!html) { return }
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(html, 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      const paragraphNode = $createParagraphNode()
      nodes.forEach(n => paragraphNode.append(n))
      $getRoot().append(paragraphNode)
    })
  }, [])
  return null
}
