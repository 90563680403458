import React from 'react'

import { useTranslation } from 'react-i18next'

import McPageHelpers from '../../pages/mc-components/McPageHelpers'

import { McDataTable } from '../Component'

const TableSkeleton = () => {
  return (
    <table className='skeletonTable'>
      <thead>
        <tr>
          <th>
              <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
          <td>
            <div></div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const customStyles = {
  table: {
    style: {
      margin: 0
    }
  },
  rows: {
    style: {
      minHeight: '20px'
    }
  },
  headCells: {
    style: {
      fontSize: '0.7rem !important'
    }
  },
  cells: {
    style: {
      fontSize: '0.7rem'
    }
  },
  pagination: {
    style: {
      fontSize: '10px',
      minHeight: '30px'
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '24px',
      width: '24px',
      padding: '0px'
    }
  }
}

const CompactSummaryTable = (props) => {
  const {
    data,
    loading,
    onClick,
    isTableSkeleton
  } = props
  const { t } = useTranslation()

  const columns = [
    {
      name: (t('importer')),
      selector: row => McPageHelpers.findCountryName(row.partnerCode),
      conditionalCellStyles: [
        {
          when: row => row.partnerCode !== 0,
          style: {
            color: '#798bff',
            textDecoration: 'underline'
          }
        }
      ],
      left: true,
      sortable: true,
      width: '25%'
    },
    {
      name: (<div style={{ fontSize: '0.45rem !important' }}>{t('st_pdf_columns.total_value')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalValue / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true,
      width: '25%'
    },
    {
      name: (<div>{t('st_pdf_columns.total_weight')}</div>),
      selector: row => McPageHelpers.isLessThan((row.totalWeight / 1000), 1, 'decimal', 0, 0, true),
      right: true,
      sortable: true,
      width: '25%'
    },
    {
      name: (<div>{t('st_columns_name.unit_price')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.unitPrice, 'decimal', false, 2, 2),
      right: true,
      sortable: true,
      width: '25%'
    }
  ]

  return (
    ((!data?.length && loading) || isTableSkeleton)
      ? <TableSkeleton/>
      : <McDataTable
          data={data ?? []}
          columns={columns}
          id={'compactTableSummary'}
          hideHeader
          pagination
          defaultPagination
          onClick={onClick}
          customStyles={customStyles}
          paginationComponentOptions={{
            rangeSeparatorText: '/',
            noRowsPerPage: true
          }}
          paginationPerPage={25}
          fixedHeader
          fixedHeaderScrollHeight='269px'
        />
  )
}

export default CompactSummaryTable
