import React from 'react'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { useTranslation } from 'react-i18next'
import {
  useDScompanyValueBar,
  useDScompanyValueBarExplanation,
  useDSonlyHsCountPie,
  useDSonlyHsCountPieExplanation,
  useDScompanyCountBar,
  useDScompanyCountBarExplanation,
  useDScountryCountPie,
  useDScountryCountPieExplanation,
  useDScompanyShipmentCountBar,
  useDScompanyShipmentCountBarExplanation,
  useDSgrowth
} from '../../../store/dataStore'
import { Col, Row } from 'reactstrap'
import { GrowthGraph, McPieChart } from '../../../components/Component'
import McBarChart from '../../../components/amcharts/BarChart'
import { useFSimporterFilters, useFilterStoreActions } from '../../../store/filterStore'
import McPageHelpers from '../McPageHelpers'
import CONSTANTS from '../../../utils/Constants'

const ProductAnalysisMapBarSection = () => {
  const { t } = useTranslation()

  const companyValueBar = useDScompanyValueBar()
  const companyValueBarExplanation = useDScompanyValueBarExplanation()
  const onlyHsCountPie = useDSonlyHsCountPie()
  const onlyHsCountPieExplanation = useDSonlyHsCountPieExplanation()
  const companyCountBar = useDScompanyCountBar()
  const companyCountBarExplanation = useDScompanyCountBarExplanation()
  const countryCountPie = useDScountryCountPie()
  const countryCountPieExplanation = useDScountryCountPieExplanation()
  const companyShipmentCountBar = useDScompanyShipmentCountBar()
  const companyShipmentCountBarExplanation = useDScompanyShipmentCountBarExplanation()
  const growth = useDSgrowth()

  const { setImporterFilters } = useFilterStoreActions()
  const importerFilters = useFSimporterFilters()

  const getDataFromBarChartClickEvent = (chartId, data) => {
    const values = McPageHelpers.getBarChartFilterValues(data.xValue)

    setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.NUMBER_OF_SHIPMENTS, {
      min: values.min,
      max: values.max
    })
  }

  const filterPieChart = (paramName, valuesArray) => {
    setImporterFilters(paramName, {
      min: valuesArray.min,
      max: valuesArray.max
    })
  }

  return (<>
      <Row>
        <Col md="4">
          <MarketCalculusCard id={'companyValueChart' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!companyValueBar?.length && 'loading-div'}`}
                              bodyClass="p-3">
            <McBarChart chartId={'companyValueChart'}
                        chartTitle={t('company_value')}
                        chartData={companyValueBar}
                        isPercentage={true}
                        yTitle={t('sp_percentage')}
                        xTitle={t('hs_code')}
                        code={CONSTANTS.BADGE_CODES.COMPANY_VALUE_BAR}
                        explanation={companyValueBarExplanation}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="4">
          <MarketCalculusCard id={'onlyHsCount' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!onlyHsCountPie?.length && 'loading-div'}`}
                              bodyClass="p-3">
            <McPieChart chartId={'onlyHsCount'}
                        chartTitle={t('only_hs_code')}
                        chartTooltipLabel={t('sp_number_of_hs_codes_imported')}
                        chartData={onlyHsCountPie ?? []}
                        paramName={McPageHelpers.FILTER_BUTTON_IDS.HS_COUNT}
                        filterPieChart={filterPieChart}
                        code={CONSTANTS.BADGE_CODES.ONLY_HS_COUNT_PIE}
                        filters={importerFilters}
                        explanation={onlyHsCountPieExplanation}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="4">
          <MarketCalculusCard id={'companyCountChart' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!companyCountBar?.length && 'loading-div'}`}
                              bodyClass="p-3">
            <McBarChart chartId={'companyCountChart'}
                        chartTitle={t('company_count')}
                        chartData={companyCountBar}
                        isPercentage={true}
                        yTitle={t('sp_percentage')}
                        xTitle={t('hs_code')}
                        code={CONSTANTS.BADGE_CODES.COMPANY_COUNT_BAR}
                        explanation={companyCountBarExplanation}
            />
          </MarketCalculusCard>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="4">
          <MarketCalculusCard id={'countryCount' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!countryCountPie?.length ? 'loading-div' : ''}`}
                              bodyClass="p-3">
            <McPieChart chartId={'countryCount'}
                        chartTitle={t('country_count')}
                        chartTooltipLabel={t('sp_number_of_importing_countries')}
                        chartData={countryCountPie}
                        paramName={McPageHelpers.FILTER_BUTTON_IDS.DISTINCT_COUNTRY}
                        filterPieChart={filterPieChart}
                        code={CONSTANTS.BADGE_CODES.COUNTRY_COUNT_PIE}
                        filters={importerFilters}
                        explanation={countryCountPieExplanation}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="4">
          <MarketCalculusCard id={'companyShipmentCount' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!companyShipmentCountBar?.length ? 'loading-div' : ''}`}
                              bodyClass="p-3">
            <McBarChart chartId={'companyShipmentCount'}
                        chartTitle={t('sp_company_shipment_count')}
                        chartData={companyShipmentCountBar}
                        yTitle={t('sp_company_shipment_y')}
                        xTitle={t('sp_company_shipment_x')}
                        addSelectionClickEvent={true}
                        cursorPointer={true}
                        yTooltip={true}
                        getClickEventData={getDataFromBarChartClickEvent}
                        code={CONSTANTS.BADGE_CODES.COMPANY_SHIPMENT_COUNT_BAR}
                        paramName={McPageHelpers.FILTER_BUTTON_IDS.NUMBER_OF_SHIPMENTS}
                        filters={importerFilters}
                        explanation={companyShipmentCountBarExplanation}
            />
          </MarketCalculusCard>
        </Col>
        <Col md="4">
          <MarketCalculusCard id={'growth' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={`h-100 ${!growth ? 'loading-div' : ''}`}
                              bodyClass="p-3">
            <GrowthGraph/>
          </MarketCalculusCard>
        </Col>
      </Row>
    </>
  )
}

export default ProductAnalysisMapBarSection
