import React, { useLayoutEffect, useRef } from 'react'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5 from '@amcharts/amcharts5'
import { localizationAMChart, numberFormatter } from '../../utils/AMChartsSettings'
import { CUSTOM_PALETTE_HEX } from '../../utils/AmChartsUtils'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const MultipleBarChart = ({
  chartId,
  chartData,
  yTitle,
  yFormatterType,
  slice,
  hideAlternativeText
}) => {
  const dummyBarData = [{
    country: 'Italy',
    2019: 2.5,
    2020: 2.5,
    2021: 2.1

  }, {
    country: 'Poland',
    2019: 2.6,
    2020: 2.7,
    2021: 2.2

  }, {
    country: 'China',
    2019: 2.8,
    2020: 2.9,
    2021: 2.4
  }]
  const rootRef = useRef(null)

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      layout: root.verticalLayout,
      exportable: true
    }))

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        height: 100
      })
    )
    chart.get('colors').set('colors', CUSTOM_PALETTE_HEX)

    chart.zoomOutButton.set('forceHidden', true)

    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.7,
      minGridDistance: 10
    })
    xRenderer.labels.template.setAll({
      fontSize: 10,
      minGridDistance: 10
    })

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'country',
      renderer: xRenderer,
      zoomX: false,
      tooltip: am5.Tooltip.new(root, {}),
      minWidth: 600
    }))

    xAxis.get('renderer').labels.template.setAll({
      paddingRight: 20,
      oversizedBehavior: 'wrap',
      maxWidth: 95,
      textAlign: 'center'
    })

    xRenderer.grid.template.setAll({
      location: 1
    })

    chartData && chartData ? xAxis.data.setAll(chartData) : xAxis.data.setAll(dummyBarData)

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      }),
      numberFormat: numberFormatter(yFormatterType)
    }))

    root.numberFormatter.setAll({
      numberFormat: numberFormatter(yFormatterType)
    })

    function makeSeries (name, fieldName) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        xAxis,
        yAxis,
        valueYField: fieldName,
        categoryXField: 'country'
      }))

      series.columns.template.setAll({
        tooltipText: `${fieldName}: {valueY}`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0
      })

      chartData && chartData ? series.data.setAll(chartData) : series.data.setAll(dummyBarData)

      series.appear()
      if (!hideAlternativeText) {
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: '{valueY}',
              fill: root.interfaceColors.get('alternativeText'),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          })
        })
      }

      legend.data.push(series)
    }

    chartData && chartData[0] && Object.keys(chartData[0])?.slice(0, slice || 3).forEach(element =>
      element !== 'country' ? makeSeries(element, element) : ''
    )

    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [chartData])

  return (
    <div id={chartId + McPageHelpers.FOR_PDF_ID}>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p
            style={{
              transform: 'rotate(-90deg)',
              fontSize: '10px',
              whiteSpace: 'nowrap',
              marginBottom: '40px'
            }}
          >{yTitle}</p>
        </div>

        <div
          id={chartId}
          style={{
            flex: '1 100%',
            width: '90%',
            height: '500px',
            cursor: 'pointer'
          }}
        />
      </div>
    </div>
  )
}

export default MultipleBarChart
