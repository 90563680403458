import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  CardHeader,
  CardContent,
  Card,
  Button,
  Grid,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SettingsIcon from '@mui/icons-material/Settings'

import CreateCampaignModal from '../organisms/CreateCampaignModal'
import CampaignCardList from '../molecules/CampaignCardList'
import DebouncedInput from '../atoms/DebouncedInput'

import { useGlobalStoreData } from '../../../../../store/globalStore'

import McDataServices from '../../../../../services/McDataServices'

const filterValuesSearch = (arr, search) => {
  search = String(search).toLowerCase()
  return arr.filter(object =>
    Object.values({
      currentCountryCode: object.currentCountryCode,
      importerCountryCode: object.importerCountryCode,
      hsCodes: object.hsCodes,
      name: object.name,
      productName: object.productName
    }).some(objValue =>
      String(objValue).toLowerCase().includes(search)
    )
  )
}

const CampaignList = forwardRef((props, ref) => {
  const {
    b2bAccountId,
    campaigns,
    campaignReports,
    fetchCampaigns,
    hasEmailSettings,
    setSettingsModalOpen,
    refreshCampaignReport,
    campaignReportsFetcing
  } = props

  const { t } = useTranslation()

  const languageGSD = useGlobalStoreData.language()

  const [addCampaignOpen, setAddCampaignOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [countries, setCountries] = useState([])

  useImperativeHandle(ref, () => ({
    openCampaignModal: handleOpenClick
  }))

  const fetchInputData = () => {
    McDataServices.getCountries({
      search_countries: 'false',
      user_countries: 'true'
    })
      .then((result) => {
        setCountries(result.user_countries)
      })
  }

  useEffect(() => {
    fetchInputData()
  }, [languageGSD])

  const handleOpenClick = () => {
    if (hasEmailSettings) {
      setAddCampaignOpen(true)
    } else {
      toast.error(t('mailTemplate.campaigns.settingsMissingErr'), {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('mailTemplate.campaigns.campaignsTitle')} className='pbe-4' />
            <CardContent>
              <Grid container justifyContent='space-between' gap={2}>
                <div className='flex'>
                  <Button
                    variant='contained'
                    startIcon={<AddIcon/>}
                    onClick={handleOpenClick}
                    className='is-full sm:is-auto'
                  >
                    {t('mailTemplate.campaigns.createCampaign')}
                  </Button>
                  <IconButton
                    onClick={(e) => {
                      setSettingsModalOpen(true)
                    }}
                    style={{ marginLeft: 10 }}
                    color='primary'
                  >
                    <SettingsIcon/>
                  </IconButton>
                </div>
                <DebouncedInput
                  value={searchTerm || ''}
                  onChange={(val) => setSearchTerm(val)}
                  placeholder={t('mailTemplate.campaigns.search')}
                  className='is-full sm:is-auto'
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <CampaignCardList
          data={filterValuesSearch(campaigns, searchTerm)}
          fetchCampaigns={fetchCampaigns}
          campaignReports={campaignReports}
          refreshCampaignReport={refreshCampaignReport}
          campaignReportsFetcing={campaignReportsFetcing}
        />
      </Grid>
      <CreateCampaignModal
        b2bAccountId={b2bAccountId}
        open={addCampaignOpen}
        setOpen={() => setAddCampaignOpen(!addCampaignOpen)}
        fetchCampaigns={fetchCampaigns}
        countries={countries}
      />
    </>
  )
})

export default CampaignList
