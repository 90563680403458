import React, { useState } from 'react'

import UserListTable from '../organisms/UserListTable'
import EditUserEmailSettingsModal from '../organisms/EditUserEmailSettingsModal'

const Users = ({ userGSD }) => {
  const [emailEditState, setEmailEditState] = useState(null)

  const users = userGSD?.followedB2bAccountIds
  const b2bAccountIds = Object.keys(users) || []
  const data = b2bAccountIds?.map((item) => ({
    id: item,
    userName: users[item].userName,
    nameSurname: users[item].nameSurname,
    email: users[item].email,
    companyName: users[item].companyName,
    emailSettings: users[item].emailSettings
  }))

  return (
    <>
      <UserListTable tableData={data} setEmailEditState={setEmailEditState}/>
      <EditUserEmailSettingsModal
        userGSD={userGSD}
        userEditState={emailEditState}
        open={!!emailEditState}
        close={() => setEmailEditState(null)}
      />
    </>
  )
}

export default Users
