import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useDSrawPotentialMap,
  useDSrawPotentialBar,
  useDSglobalParams,
  useDStotalPotentialCompanyCount,
  useDSpotentialGraphMapSectionDataExplanation
} from '../../../store/dataStore'
import McCardMB from '../mc-map-and-bar/McCardMB'
import McPageHelpers from '../McPageHelpers'
import CONSTANTS from '../../../utils/Constants'

const PotentialMapGraphSection = () => {
  const { t } = useTranslation()

  const rawPotentialMap = useDSrawPotentialMap()
  const rawPotentialBar = useDSrawPotentialBar()
  const globalParams = useDSglobalParams()
  const totalPotentialCompanyCount = useDStotalPotentialCompanyCount()
  const potentialGraphMapSectionDataExplanation = useDSpotentialGraphMapSectionDataExplanation()

  const header = t('graph_potential_analysis') + ' (' + totalPotentialCompanyCount + ' ' + t('piece') + ')'
  const subHeader = t('world') + ' ' + globalParams.hsCode

  return (
    <McCardMB id={McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES_MAP_GRAPH}
              code={CONSTANTS.BADGE_CODES.POTENTIAL_IMPORTER_MAP_BAR_SECTION}
              subCode={CONSTANTS.BADGE_CODES.POTENTIAL_BAR}
              show={rawPotentialMap?.length && rawPotentialBar?.length}
              header={header}
              sub={subHeader}
              map={rawPotentialMap}
              graph={rawPotentialBar}
              xTitle={t('country_code')}
              yTitle={t('number_of_importer_companies')}
              height="30rem"
              footer={potentialGraphMapSectionDataExplanation}
    />
  )
}

export default PotentialMapGraphSection
