import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useTranslation } from 'react-i18next'
import { localizationAMChart, numberFormatter } from '../../utils/AMChartsSettings'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const MultiValueLineChart = ({
  chartId,
  yFormatterType,
  chartData,
  secondChartData,
  yTitle,
  yTitleSecond,
  footer
}) => {
  const [data, setData] = useState([])
  const [secondData, setSecondData] = useState([])
  const { t } = useTranslation()
  const title = t('sp_filter_by_avg_price')
  const titleSecond = yTitleSecond
  const prevChartDataRef = useRef()
  const prevSecondChartDataRef = useRef()
  const rootRef = useRef(null)

  useEffect(() => {
    const isDataChanged = JSON.stringify(prevChartDataRef.current) !== JSON.stringify(chartData)
    const isSecondDataChanged = JSON.stringify(prevSecondChartDataRef.current) !== JSON.stringify(secondChartData)

    if (isDataChanged) {
      setData([...chartData].reverse())
      prevChartDataRef.current = chartData
    }

    if (isSecondDataChanged) {
      setSecondData(secondChartData)
      prevSecondChartDataRef.current = secondChartData
    }
  }, [chartData, secondChartData])

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: 'none',
        wheelY: 'none',
        pinchZoomX: true,
        exportable: true
      })
    )

    chart.get('colors').set('step', 3)

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 })
    xRenderer.labels.template.setAll({
      rotation: -20,
      minGridDistance: 10,
      fontSize: 10
    })

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        zoomX: false,
        startLocation: 0,
        endLocation: 1,
        maxDeviation: 0.1,
        groupData: false
      })
    )

    function createAxisAndSeries (startValue, opposite, label, isAvg) {
      const yRenderer = am5xy.AxisRendererY.new(root, {
        opposite
      })
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
          numberFormat: numberFormatter(yFormatterType)
        })
      )
      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set('syncWithAxis', chart.yAxes.getIndex(0))
      }
      const series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          xAxis,
          yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            getFillFromSprite: true,
            labelText: !isAvg ? `[bold]${label}:[/]\n[bold]{valueY}[/]` : '[bold]{tooltip}'
          }),
          legendLabelText: `${label}`,
          legendRangeLabelText: `${label}`
        }
        )
      )

      series.get('tooltip').label.set('text', '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}')
      series.strokes.template.setAll({ strokeWidth: 3 })

      yRenderer.grid.template.set('strokeOpacity', 0.05)
      yRenderer.labels.template.set('fill', series.get('fill'))
      yRenderer.setAll({
        stroke: series.get('fill'),
        strokeOpacity: 1,
        opacity: 1
      })

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: 'yyyy-MM-dd',
        dateFields: ['date']
      })

      series.data.setAll(startValue)
    }

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
      xAxis,
      behavior: 'zoomXY'
    }))

    cursor.lineY.set('visible', false)

    createAxisAndSeries(data, false, title, true)
    createAxisAndSeries(secondData, true, titleSecond, false)

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      y: am5.percent(98),
      height: 100
    }))

    legend.data.setAll(chart.series.values)
    chart.appear()
    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [data, secondData]
  )

  return (
    <>
      <div style={{ display: 'flex' }} id={chartId + McPageHelpers.FOR_PDF_ID}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-90deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitle}</p>
        </div>

        <div id={chartId}
             style={{
               width: '90%',
               height: '300px',
               cursor: 'pointer',
               flex: '1 100%'
             }}>

        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-270deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitleSecond}</p>
        </div>
      </div>
      {McPageHelpers.generateExplanationFooter(footer)}
    </>
  )
}

export default MultiValueLineChart
