import React from 'react'

// MUI Imports
import { useTheme } from '@mui/material/styles'
import {
  Typography,
  Tooltip
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const EmailStatusItem = (props) => {
  const {
    title,
    text,
    highlightedTextPart,
    Icon,
    infoText
  } = props
  const theme = useTheme()

  return (
    <div className='flex items-center gap-1'>
      <Icon sx={{ width: 18, height: 18 }} color='primary'/>
      <div className='flex justify-between'>
        <div>
          <Typography
            noWrap
            variant='body2'
            color='text.primary'
            style={{ lineHeight: 1.2, fontSize: 12, fontWeight: '400', verticalAlign: 'center' }}
          >
            {text}
            <span style={{ color: theme.palette.success.main, fontSize: 10 }}>{highlightedTextPart}</span>
          </Typography>

          <Typography
            noWrap
            variant='body2'
            style={{ lineHeight: 1.2, fontSize: 10 }}
          >
            {title}
            {infoText
              ? <Tooltip
                  title={infoText}
                  arrow
                >
                  <InfoIcon
                    sx={{ width: 14, height: 14, marginLeft: 0.2, marginBottom: 0.2 }}
                    onClick={(e) => { e.preventDefault() }}
                  />
                </Tooltip>
              : null
            }
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default EmailStatusItem
