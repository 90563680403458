import React, { useLayoutEffect, useRef } from 'react'
import BadgeCode from '../badge/BadgeCode'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import { localizationAMChart, PALETTE_1 } from '../../utils/AMChartsSettings'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const McPieChart = ({
  chartId,
  chartData,
  filterPieChart,
  paramName,
  resetPieAll,
  chartTitle,
  chartTooltipLabel,
  code,
  filters,
  explanation,
  ...props
}) => {
  const seriesRef = useRef(null)
  const rootRef = useRef(null)

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      exportable: true
    }))

    const series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: 'value',
      categoryField: 'name'
    }))

    series.labels.template.set('text', '[bold]{name}; [normal] {tooltipValue}')

    series.slices.template.events.on('click', function (ev) {
      const value = ev.target.dataItem.dataContext.name
      const arr = !value.includes('+') ? value.split('-') : value.replace('+', '').split()
      const filterObject = { min: arr[0], max: !value.includes('+') ? (arr[1] ?? arr[0]) : '' }

      filterPieChart(paramName, filterObject)

      series.slices.each(function (slice) {
        if (slice !== ev.target && slice.get('active')) {
          slice.set('active', false)
        }
      })
    })

    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      labelText: `[bold]${chartTooltipLabel}: {name};\n[normal] {tooltipValue}`
    })

    tooltip.get('background').setAll({
      fill: am5.color(0x6771dc),
      fillOpacity: 0.8
    })

    series.get('colors').set('colors', PALETTE_1)

    tooltip.label.setAll({
      oversizedBehavior: 'wrap',
      fontSize: 12
    })

    series.set('tooltip', tooltip)

    seriesRef.current = series
    // series.appear(1000, 100)

    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [])

  useLayoutEffect(() => {
    seriesRef.current.data.setAll(chartData)

    if (chartData !== null && filters) {
      let text = filters[paramName]?.min + (filters[paramName]?.max ? '-' + filters[paramName]?.max : '+')

      if (filters[paramName]?.min === filters[paramName]?.max) {
        text = filters[paramName]?.min
      }

      seriesRef.current.slices.each(function (slice) {
        if (slice._dataItem.dataContext.name === text) {
          slice.set('active', true)
        }
      })
    }
  }, [chartData])

  useLayoutEffect(() => {
    if (resetPieAll) {
      seriesRef.current.data.setAll(chartData)
    }
  }, [resetPieAll])

  return <>
    <div id={chartId + McPageHelpers.FOR_PDF_ID}>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <div className="d-flex align-items-center">
            <BadgeCode color="light" text={code}/>
            <h6 className="title ml-2" style={{ whiteSpace: 'pre-line' }}>{chartTitle}</h6>
          </div>
        </div>
      </div>

      <div id={chartId} style={{
        width: '90%',
        height: '200px',
        cursor: 'pointer'
      }}></div>
    </div>

    {explanation && Array.isArray(explanation) && explanation.length > 0 && (
      <div className="card-title-group mt-2">
        <div className="card-title">
          <hr/>
          {explanation.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    )}
  </>
}

export default McPieChart
