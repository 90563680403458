import React from 'react'

import { Typography } from '@mui/material'

import CustomAvatar from './CustomAvatar'

const getAvatar = params => {
  const { avatar, text } = params

  if (avatar) {
    return <CustomAvatar src={avatar} size={34} />
  } else {
    return <CustomAvatar size={34}>{text?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '')}</CustomAvatar>
  }
}

const AvatarWithTexts = (props) => {
  const { img = null, text, subText, ...restProps } = props

  return (
    <div className='flex items-center gap-2' {...restProps}>
      {getAvatar({ avatar: img, text: text || subText })}
      <div className='flex flex-col overflow-hidden'>
        <Typography color='text.primary' className='font-medium' noWrap>
          {text}
        </Typography>
        <Typography variant='body2' style={{ lineHeight: '0.8125rem' }} noWrap>{subText}</Typography>
      </div>
    </div>
  )
}

export default AvatarWithTexts
