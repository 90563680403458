import React, { useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { Card, Button } from 'reactstrap'
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { OverlineTitle } from '../text/Text'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'

export const MarketCalculusCard = ({ id, className, bodyClass, ...props }) => {
  return (
    <Card id={id} className={`card-bordered ${className || ''}`}>
      <div className={`card-inner ${bodyClass || ''}`}>{props.children}</div>
    </Card>
  )
}
export const MarketCalculusLinkCard = ({ link, className, bodyClass, disabled, ...props }) => {
  return (
    <NavLink to={link} className={disabled ? 'menu-disabled-link' : ''}>
      <Card className={`card-bordered center py-5 dashboard-menu ${className || ''}`}>
        <div className={`card-inner ${bodyClass || ''}`}>{props.children}</div>
      </Card>
    </NavLink>
  )
}

export const PreviewCard = ({ className, bodyClass, ...props }) => {
  return (
    <Card className={`card-preview ${className || ''}`}>
      <div className={`card-inner ${bodyClass || ''}`}>{props.children}</div>
    </Card>
  )
}

export const PreviewAltCard = ({ className, bodyClass, ...props }) => {
  return (
    <Card className={`card-bordered ${className || ''}`}>
      <div className={`card-inner ${bodyClass || ''}`}>{props.children}</div>
    </Card>
  )
}

export const PreviewTable = ({ className, ...props }) => {
  return (
    <Card className={`card-preview ${className || ''}`}>
      <table className={`table preview-reference ${props.size ? `table-${props.size}` : ''}`}>{props.children}</table>
    </Card>
  )
}
export const CodeBlock = ({ language, ...props }) => {
  const [copyText] = useState(props.children)
  const [copyState, setCopyState] = useState(false)
  const onCopyClick = () => {
    setCopyState(true)
    setTimeout(() => setCopyState(false), 2000)
  }
  return (
    <div className={`code-block code-block-clean ${copyState ? 'clipboard-success' : ''}`}>
      <OverlineTitle className="title">{props.title ? props.title : 'Code Example'}</OverlineTitle>
      <CopyToClipboard text={copyText} onCopy={onCopyClick}>
        <Button color="blank" size="sm" className="clipboard-init">
          {copyState ? 'Copied' : 'Copy'}
        </Button>
      </CopyToClipboard>
      <SyntaxHighlighter language="javascript" className="bg-lighter h-max-150px m-0" style={a11yLight}>
        {props.children}
      </SyntaxHighlighter>
    </div>
  )
}
