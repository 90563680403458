import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import { ProductAnalysisMapBarSection } from './mc-components/McPageComponents'
import McPageHeader from '../components/page-header/McPageHeader'
import McPageHelpers from './mc-components/McPageHelpers'
import {
  useDSglobalParams,
  useDScompanyValueBarExplanation,
  useDSonlyHsCountPieExplanation,
  useDScompanyCountBarExplanation,
  useDScountryCountPieExplanation,
  useDScompanyShipmentCountBarExplanation,
  useDSgrowthExplanation
} from '../store/dataStore'
import McPdfExport from './mc-components/McPdfExport'
import { useSDSsearchParams } from '../store/searchDataStore'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const ProductAnalysis = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const globalParams = useDSglobalParams()
  const companyValueBarExplanation = useDScompanyValueBarExplanation()
  const onlyHsCountPieExplanation = useDSonlyHsCountPieExplanation()
  const companyCountBarExplanation = useDScompanyCountBarExplanation()
  const countryCountPieExplanation = useDScountryCountPieExplanation()
  const companyShipmentCountBarExplanation = useDScompanyShipmentCountBarExplanation()
  const growthExplanation = useDSgrowthExplanation()

  const searchParams = useSDSsearchParams()

  const selectedComponentIds = usePDFselectedComponentIds()

  const searchHeader = searchParams.hsCode + ' - ' + McPageHelpers.findCountryName(searchParams.countryToExport) + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)

  const pdfDownload = (onlyContent = false) => (
    McPdfExport.ProductAnalysis(
      onlyContent,
      searchHeader,
      selectedComponentIds,
      companyValueBarExplanation,
      onlyHsCountPieExplanation,
      companyCountBarExplanation,
      countryCountPieExplanation,
      companyShipmentCountBarExplanation,
      growthExplanation
    )
  )

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true).then(content => content)
      return content
    }
  }))

  const spGraph = useEndpointStatusData.spGraph()

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_GRAPH, spGraph), [spGraph])

  return (
    <React.Fragment>
      <Head title={t('menu.product_analysis')}/>
      <Content>
        <McPageHeader
          title={t('product_analysis_header') + ' - ' + McPageHelpers.findCountryName(globalParams?.countryCode) + ' - ' + globalParams?.hsCode}
          desc={t('product_analysis_desc')}
        />
        <ProductAnalysisMapBarSection/>
      </Content>
    </React.Fragment>
  )
})

export default ProductAnalysis
