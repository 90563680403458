import React from 'react'
import { PreviewAltCard } from '../preview/Preview'
import { TooltipComponent } from '../Component'
import { useTranslation } from 'react-i18next'
import { FirmCardChart } from './FirmCardChart'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { useDataStoreActions } from '../../store/dataStore'

const FirmCard = ({
  id,
  firmName,
  helpText,
  hsTotalValue,
  growth,
  hsTotalQuantity,
  isOpen,
  numberOfShipments,
  dummy,
  tableName,
  growthValue,
  yearChartData,
  totalValue,
  ...props
}) => {
  const { t } = useTranslation()
  const { setShowFirmDetailsModal } = useDataStoreActions()

  return <>
    <div onClick={() => setShowFirmDetailsModal(isOpen, id, tableName)}>
      <PreviewAltCard className="card firm-card">
        <div className="card-title-group align-start mb-0">
          <div className="card-title">
            <h6 title={firmName} className="subtitle">{firmName}</h6>
          </div>
          <div className="card-tools">
            <TooltipComponent
              iconClass="card-hint"
              icon="help-fill"
              direction="left"
              id={'firmCardTooltip-' + id}
              text={helpText === '.' ? '.' : helpText}
            ></TooltipComponent>
          </div>
        </div>
        <div className="card-amount">
                      <span className="amount">
                        {hsTotalValue ? McPageHelpers.numToLocaleString(hsTotalValue, 'currency') : '-'}
                      </span>
          <span style={{ marginLeft: '5px' }}>
                       {growth} {McPageHelpers.numToLocaleString(growthValue, 'percent')}
                      </span>
        </div>
        <div className="invest-data" id="firmCardData">
          <div className="invest-data-amount g-2">
            {
              tableName === McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES
                ? <div className="invest-data-history">
                  <div className="title">{t('table_hs_total_quantity')}</div>
                  <span className="amount">
                            {hsTotalQuantity ? McPageHelpers.numToLocaleString(hsTotalQuantity) : '-'}
                        </span>
                </div>
                : ''}
            <div className="invest-data-history">
              <div className="title">{t('table_sp_number_of_shipments')}</div>
              <span className="amount">
                            {numberOfShipments ? McPageHelpers.numToLocaleString(numberOfShipments) : '-'}
                          </span>
            </div>
          </div>
          <div className="invest-data-ck">
            {yearChartData ? <FirmCardChart chartData={yearChartData}/> : ''}
          </div>
        </div>
      </PreviewAltCard>
    </div>
  </>
}

export default FirmCard
