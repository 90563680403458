import React, { useEffect, useRef } from 'react'
import Content from '../../../layout/content/Content'
import Head from '../../../layout/head/Head'
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from '../../../components/Component'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../../store/globalStore'

const Terms = () => {
  const { t } = useTranslation()
  const myRef = useRef()
  const languageGSD = useGlobalStoreData.language()

  useEffect(() => {
    myRef.current.innerHTML = t('terms_html')
  }, [myRef, languageGSD])

  return (
    <React.Fragment>
      <Head title={t('terms')}/>
      <Content>
        <div className="content-page">
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                {t('terms')}
              </BlockTitle>
              <BlockDes>
                <p className="text-soft ff-italic">{t('last_update')}: 20 {t('months.july')} 2023</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <PreviewCard className="card-bordered" bodyClass="card-inner-xl">
              <div className="entry" ref={myRef}></div>
            </PreviewCard>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  )
}

export default Terms
