import React, { forwardRef } from 'react'

// MUI imports
import Paper from '@mui/material/Paper'
import Autocomplete from '@mui/material/Autocomplete'

const CustomAutocomplete = forwardRef((props, ref) => {
  return (
    <Autocomplete {...props} ref={ref} PaperComponent={props => <Paper {...props} />} />
  )
})

export default CustomAutocomplete
