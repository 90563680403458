import React from 'react'
import { useTranslation } from 'react-i18next'
import McCardMB from '../mc-map-and-bar/McCardMB'
import {
  useDSrawImporterMap,
  useDSrawImporterBar,
  useDSglobalParams,
  useDStotalImporterCompanyCount,
  useDSimporterGraphMapSectionExplanation
} from '../../../store/dataStore'
import CONSTANTS from '../../../utils/Constants'
import McPageHelpers from '../McPageHelpers'

const ImporterMapGraphSection = () => {
  const { t } = useTranslation()

  const rawImporterMap = useDSrawImporterMap()
  const rawImporterBar = useDSrawImporterBar()
  const globalParams = useDSglobalParams()
  const totalImporterCompanyCount = useDStotalImporterCompanyCount()
  const importerGraphMapSectionExplanation = useDSimporterGraphMapSectionExplanation()

  const header = t('graph_importer_analysis') + ' (' + totalImporterCompanyCount + ' ' + t('piece') + ')'
  const subHeader = McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode

  return (
    <McCardMB id={McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES_MAP_GRAPH}
              code={CONSTANTS.BADGE_CODES.IMPORTER_MAP_BAR_SECTION}
              subCode={CONSTANTS.BADGE_CODES.IMPORTER_BAR}
              show={rawImporterMap?.length && rawImporterBar?.length}
              header={header}
              sub={subHeader}
              map={rawImporterMap}
              graph={rawImporterBar}
              xTitle={t('country_code')}
              yTitle={t('number_of_importer_companies')}
              height="30rem"
              footer={importerGraphMapSectionExplanation}
    />
  )
}

export default ImporterMapGraphSection
