import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import Router from './route/Index'

import ThemeProvider from './layout/provider/Theme'
import Loading from './components/loader/Loading'
import React, { useEffect, useState } from 'react'
import { off } from 'devextreme/events'

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  // For detach all handlers of the wheel event (DevExtreme)
  off(document, 'wheel')

  return (
    <>
      <ThemeProvider>
        {loading ? <Loading/> : ''}
        <Router/>
      </ThemeProvider>
    </>
  )
}
export default App
