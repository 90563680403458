import React, { useEffect, useRef } from 'react'
import Head from '../layout/head/Head'
import ImporterCompanies from './ImporterCompanies'
import PriceAnalysis from './PriceAnalysis'
import ProductAnalysis from './ProductAnalysis'
import MarketChanges from './MarketChanges'
import MarketOverview from './MarketOverview'
import SummaryView from './SummaryView'
import CustomsDuties from './CustomsDuties'
import { useTranslation } from 'react-i18next'
import McPdfExport from './mc-components/McPdfExport'
import McPageHelpers from './mc-components/McPageHelpers'
import { useSDSfirstMenuItemLink, useSDSsearchParams, useSDSimaginalUrlSearchParams } from '../store/searchDataStore'
import { useNavigate } from 'react-router-dom'
import { useGlobalStoreData } from '../store/globalStore'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const PdfContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const languageGSD = useGlobalStoreData.language()

  const searchParams = useSDSsearchParams()
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const firstMenuItemLink = useSDSfirstMenuItemLink()

  const selectedComponentIds = usePDFselectedComponentIds()

  const ImporterCompaniesRef = useRef(null)
  const PriceAnalysisRef = useRef(null)
  const ProductAnalysisRef = useRef(null)
  const MarketChangesRef = useRef(null)
  const MarketOverviewRef = useRef(null)
  const SummaryTablesRef = useRef(null)
  const CustomsDutiesRef = useRef(null)

  useEffect(() => {
    McPageHelpers.toggleAllMarketOverview()
    document.body.classList.add('exporting')

    const pdfDownload = () => {
      const searchHeader = searchParams.hsCode + ' - ' + McPageHelpers.findCountryName(searchParams.countryToExport) + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)

      Promise.all([
        SummaryTablesRef.current?.getPdfContent(),
        ProductAnalysisRef.current?.getPdfContent(),
        PriceAnalysisRef.current?.getPdfContent(),
        MarketChangesRef.current?.getPdfContent(),
        MarketOverviewRef.current?.getPdfContent(),
        ImporterCompaniesRef.current?.getPdfContent(),
        CustomsDutiesRef.current?.getPdfContent()
      ])
        .then(content => McPdfExport.FullPdf(searchHeader, content[0], content[1], content[2], content[3], content[4], content[5], content[6]))
        .finally(() => {
          document.body.classList.remove('exporting')
          navigate(`${process.env.PUBLIC_URL}/${languageGSD}${firstMenuItemLink}${urlSearchParams ? '?' + urlSearchParams : ''}`)
        })
    }

    const timerId = setTimeout(pdfDownload, 700)

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <React.Fragment>
      {selectedComponentIds.includes('summaryTables') &&
        <SummaryView className="pdf-container-section" ref={SummaryTablesRef} isPdf={true}/>}
      {(selectedComponentIds.includes('marketOverview') || selectedComponentIds.includes('market_overview_tabs')) &&
        <MarketOverview className="pdf-container-section" ref={MarketOverviewRef}/>}
      {selectedComponentIds.includes('marketChanges') &&
        <MarketChanges className="pdf-container-section" ref={MarketChangesRef}/>}
      {selectedComponentIds.includes('productAnalysis') &&
        <ProductAnalysis className="pdf-container-section" ref={ProductAnalysisRef}/>}
      {selectedComponentIds.includes('priceAnalysis') &&
        <PriceAnalysis className="pdf-container-section" ref={PriceAnalysisRef}/>}
      {selectedComponentIds.includes('importerCompanies') &&
        <ImporterCompanies className="pdf-container-section" ref={ImporterCompaniesRef}/>}
      {selectedComponentIds.includes('duties') &&
        <CustomsDuties className="pdf-container-section" ref={CustomsDutiesRef}/>}

      <Head title={t('down_pdf')}/>
    </React.Fragment>
  )
}

export default PdfContainer
