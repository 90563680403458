import React from 'react'

// MUI Imports
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Component Imports
import DialogCloseButton from '../atoms/DialogCloseButton'

const Modal = (props) => {
  const {
    open,
    close,
    loading,
    submit,
    title,
    subTitle,
    text,
    submitText,
    cancelText,
    submitBtnColor,
    children
  } = props

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      maxWidth='xs'
      scroll='body'
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogCloseButton onClick={close} disableRipple>
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle
        variant='h4'
        className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-6 sm:pli-16'
      >
        {title}
        <Typography component='span' className='flex flex-col text-center'>
          {subTitle}
        </Typography>
        <Typography component='span' className='flex flex-col text-center'>
          {text}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions className='justify-center pbs-0 sm:pbe-16 sm:pli-16'>
        <Button
          variant='contained'
          onClick={submit}
          disabled={loading}
          startIcon={loading ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : null}
          color={submitBtnColor || 'primary'}
        >
          {submitText}
        </Button>
        <Button
          variant='tonal'
          color='secondary'
          type='reset'
          onClick={close}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
