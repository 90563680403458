import React from 'react'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from '../Component'

const McPageHeader = ({ title, desc }) => {
  return (
    <div className={'d-flex justify-content-between' + (!desc ? ' mb-3' : '')}>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>{title}</BlockTitle>
            {desc && (
              <BlockDes className="text-soft">
                <p>{desc}</p>
              </BlockDes>
            )}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </div>

  )
}

export default McPageHeader
