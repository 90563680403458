import React, { useState } from 'react'
import Logo from '../../images/logo.png'
import LogoDark from '../../images/logo-dark.png'
import Head from '../../layout/head/Head'
import AuthFooter from './AuthFooter'
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from '../../components/Component'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import McDataServices from '../../services/McDataServices'
import { useGlobalStoreData } from '../../store/globalStore'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const languageGSD = useGlobalStoreData.language()

  const handleReset = () => {
    const email = document.getElementById('emailForReset').value

    if (email) {
      McDataServices.sendResetPasswordMail(email)
      navigate(`${process.env.PUBLIC_URL}/${languageGSD}`)
    }
  }

  const handleEmailChange = (event) => {
    const newEmail = event.target.value
    setEmail(newEmail)
    setIsButtonDisabled(!isValidEmail(newEmail))
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,24}$/
    return emailPattern.test(email)
  }

  return (
    <>
      <Head title={t('no_password')}/>
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + '/'} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">{t('reset_password_header')}</BlockTitle>
              <BlockDes>
                <p>{t('reset_password_desc')}</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="emailForReset">
                  {t('email')}
                </label>
              </div>
              <input
                type="email"
                className="form-control form-control-lg"
                id="emailForReset"
                placeholder={t('email')}
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="form-group">
              <Button type="button" color="primary" size="lg" className="btn-block"
                      onClick={handleReset}
                      disabled={isButtonDisabled}>
                {t('send_reset_link')}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <strong>{t('return_to_login')}</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter/>
    </>
  )
}
export default ForgotPassword
