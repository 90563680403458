import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import MarketChangesSection from './mc-components/market-changes/MarketChangesSection'
import McPageHeader from '../components/page-header/McPageHeader'
import McPdfExport from './mc-components/McPdfExport'
import { useSDSsearchParams } from '../store/searchDataStore'
import McPageHelpers from './mc-components/McPageHelpers'
import { useDSmarketChangesData } from '../store/dataStore'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const MarketChanges = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const marketChangesTableRef = useRef(null)

  const searchParams = useSDSsearchParams()

  const marketChangesData = useDSmarketChangesData()

  const selectedComponentIds = usePDFselectedComponentIds()

  const importerCountryName = McPageHelpers.findCountryName(searchParams.countryToExport)

  const searchHeader = searchParams.hsCode + ' - ' + importerCountryName + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)
  const subTitle = importerCountryName + ' ' + searchParams.hsCode

  const pdfDownload = (onlyContent = false) => {
    const marketChangesTable = marketChangesTableRef?.current?.generateMarketSharePdf('marketChangesTable')

    const explanations = [
      marketChangesData.explanations.marketShareChangeExplanation,
      [],
      marketChangesData.explanations.importerMonthlyValueExplanation,
      marketChangesData.explanations.importerValueChangeExplanation,
      marketChangesData.explanations.worldMonthlyImportValueExplanation,
      marketChangesData.explanations.worldImportValueChangeExplanation,
      marketChangesData.explanations.importerMonthlyQuantityExplanation,
      marketChangesData.explanations.importerQuantityChangeExplanation,
      marketChangesData.explanations.worldMonthlyImportQuantityExplanation,
      marketChangesData.explanations.worldImportQuantityChangeExplanation,
      marketChangesData.explanations.importerMonthlyTop5ValueExplanation,
      marketChangesData.explanations.importerTop5ValueChangeExplanation,
      marketChangesData.explanations.importerMonthlyTop5QuantityExplanation,
      marketChangesData.explanations.importerTop5QuantityChangeExplanation
    ]

    return McPdfExport.MarketChanges(onlyContent, searchHeader, marketChangesTable, subTitle, selectedComponentIds, explanations)
  }

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true).then(content => content)
      return content
    }
  }))

  const marketChange = useEndpointStatusData.marketChange()
  const comtradeMarketChange = useEndpointStatusData.comtradeMarketChange()

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.MARKET_CHANGE, marketChange), [marketChange])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.COMTRADE_MARKET_CHANGE, comtradeMarketChange), [comtradeMarketChange])

  return (
    <React.Fragment>
      <Head title={t('menu.market_changes')}/>
      <Content>
        <McPageHeader title={t('menu.market_changes')}/>
        <MarketChangesSection ref={marketChangesTableRef}/>
      </Content>
    </React.Fragment>
  )
})

export default MarketChanges
