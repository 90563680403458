import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Head = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <Helmet>
      <title>{props.title ? props.title + ' | ' : null} {t('app_name')}</title>
    </Helmet>
  )
}
export default Head
