import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5 from '@amcharts/amcharts5'
import { numberFormatter, PALETTE_1, PALETTE_1_PALE } from './AMChartsSettings'

export const createAxisAndSeries = (root, chart, index, opposite, xAxis, valueYField, valueXField, yFormatterType, showBullet, fillSeries, paletteCode, yAxisStrictMinMax, refObj) => {
  const yRenderer = am5xy.AxisRendererY.new(root, {
    opposite
  })

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      maxDeviation: 0.1,
      numberFormat: numberFormatter(yFormatterType),
      strictMinMax: yAxisStrictMinMax ?? false,
      renderer: yRenderer
    })
  )

  const tooltip = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    pointerOrientation: 'horizontal',
    labelText: '{' + valueYField + '}'
  })

  tooltip.get('background').setAll({
    fill: PALETTE_1[paletteCode],
    fillOpacity: 0.8
  })

  const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      fill: PALETTE_1_PALE[paletteCode],
      stroke: PALETTE_1[paletteCode],
      xAxis,
      yAxis,
      valueYField: `${valueYField}`,
      valueXField: `${valueXField}`,
      minDistance: 0,
      locationY: 10,
      tooltip
    })
  )

  // eslint-disable-next-line no-unused-expressions
  showBullet
    ? series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 8,
          fill: series.get('fill')
        })
      })
    })
    : ''

  yRenderer.labels.template.set('fill', PALETTE_1[paletteCode])
  yRenderer.setAll({
    stroke: PALETTE_1[paletteCode],
    strokeWidth: 0.7,
    strokeOpacity: 1,
    opacity: 1
  })

  series.strokes.template.setAll({ strokeWidth: 3 })
  series.fills.template.setAll({ fillOpacity: 0.2, visible: fillSeries })

  refObj.current[index] = series
}

export const CUSTOM_PALETTE_HEX = [
  am5.color(0x952233),
  am5.color(0x50b082),
  am5.color(0xed7459),
  am5.color(0x224552),
  am5.color(0x74919d)
]
