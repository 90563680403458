import React from 'react'
import { McDataTable } from '../../../components/Component'

const FilterTable = ({ content: { id, data, columns, onRowClicked, uniqueId, footer } }) => {
  return <>
    <McDataTable
      id={id + '_table'}
      data={data}
      columns={columns}
      onRowClicked={onRowClicked}
      uniqueId={uniqueId}
      pagination actions
      showLastUpdate
      footer={footer}
    />

  </>
}

export default FilterTable
