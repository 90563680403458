import React from 'react'

// MUI Imports
import {
  Chip,
  Tooltip
} from '@mui/material'

const HsChip = (props) => {
  const {
    label,
    tooltipText
  } = props

  return (
    <Tooltip
      title={tooltipText}
      arrow
    >
      <Chip
        label={label}
        size='small'
      />
    </Tooltip>
  )
}

export default HsChip
