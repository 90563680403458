import React from 'react'
import { useStyles } from './Loading.style'

const Loading = () => {
  const classes = useStyles()
  return (
    <div className={classes.loadingOverlay}>
      <div className={classes.loadingSpinner}></div>
    </div>
  )
}

export default Loading
