import React, { useMemo } from 'react'

// MUI Imports
import { deepmerge } from '@mui/utils'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  lighten,
  darken
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import themeConfig from './themeConfig'

// Core Theme Imports
import defaultCoreTheme from './theme'
import primaryColorConfig from './primaryColorConfig'

const initialSettings = {
  mode: themeConfig.mode,
  skin: themeConfig.skin,
  primaryColor: primaryColorConfig[0].main
}

const ThemeProvider = props => {
  // Props
  const { children, systemMode } = props

  // Merge the primary color scheme override with the core theme
  const theme = useMemo(() => {
    const newColorScheme = {
      colorSchemes: {
        light: {
          palette: {
            primary: {
              main: initialSettings.primaryColor,
              light: lighten(initialSettings.primaryColor, 0.2),
              dark: darken(initialSettings.primaryColor, 0.1)
            }
          }
        },
        dark: {
          palette: {
            primary: {
              main: initialSettings.primaryColor,
              light: lighten(initialSettings.primaryColor, 0.2),
              dark: darken(initialSettings.primaryColor, 0.1)
            }
          }
        }
      }
    }

    const coreTheme = deepmerge(defaultCoreTheme(initialSettings, systemMode), newColorScheme)

    return extendTheme(coreTheme)
  }, [])

  return (
      <CssVarsProvider
        theme={theme}
      >
        <>
          <CssBaseline />
          {children}
        </>
      </CssVarsProvider>
  )
}

export default ThemeProvider
