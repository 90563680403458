export const countryTR =
[
  {
    countryCode: 'PR',
    countryName: 'Porto Riko (US)'
  },
  {
    countryCode: 'PS',
    countryName: 'Filistin'
  },
  {
    countryCode: 'PT',
    countryName: 'Portekiz'
  },
  {
    countryCode: 'PW',
    countryName: 'Palau'
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay'
  },
  {
    countryCode: 'QA',
    countryName: 'Katar'
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra'
  },
  {
    countryCode: 'AE',
    countryName: 'Birleşik Arap Emirlikleri'
  },
  {
    countryCode: 'AF',
    countryName: 'Afganistan'
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua ve Barbuda'
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla'
  },
  {
    countryCode: 'AL',
    countryName: 'Arnavutluk'
  },
  {
    countryCode: 'AM',
    countryName: 'Ermenistan'
  },
  {
    countryCode: 'AO',
    countryName: 'Angola'
  },
  {
    countryCode: 'AR',
    countryName: 'Arjantin'
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa'
  },
  {
    countryCode: 'AT',
    countryName: 'Avusturya'
  },
  {
    countryCode: 'AU',
    countryName: 'Avustralya'
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba'
  },
  {
    countryCode: 'AX',
    countryName: 'Aland Islands'
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaycan'
  },
  {
    countryCode: 'RO',
    countryName: 'Romanya'
  },
  {
    countryCode: 'BA',
    countryName: 'Bosna-Hersek'
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados'
  },
  {
    countryCode: 'RS',
    countryName: 'Sırbistan'
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladeş'
  },
  {
    countryCode: 'BE',
    countryName: 'Belçika'
  },
  {
    countryCode: 'RU',
    countryName: 'Rusya'
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso'
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaristan'
  },
  {
    countryCode: 'RW',
    countryName: 'Ruanda'
  },
  {
    countryCode: 'BH',
    countryName: 'Bahreyn'
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi'
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin'
  },
  {
    countryCode: 'BL',
    countryName: 'Saint Barthélemy (FR)'
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda'
  },
  {
    countryCode: 'BN',
    countryName: 'Bruney'
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivya'
  },
  {
    countryCode: 'SA',
    countryName: 'Suudi Arabistan'
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Adaları'
  },
  {
    countryCode: 'BR',
    countryName: 'Brezilya'
  },
  {
    countryCode: 'SC',
    countryName: 'Seyşeller'
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamalar'
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan'
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan'
  },
  {
    countryCode: 'SE',
    countryName: 'İsveç'
  },
  {
    countryCode: 'SG',
    countryName: 'Singapur'
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena, Ascension And Tristan Da Cunha'
  },
  {
    countryCode: 'BW',
    countryName: 'Botsvana'
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenya'
  },
  {
    countryCode: 'BY',
    countryName: 'Beyaz Rusya'
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize'
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakya Cumhuriyeti'
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone'
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino'
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal'
  },
  {
    countryCode: 'SO',
    countryName: 'Somali'
  },
  {
    countryCode: 'CA',
    countryName: 'Kanada'
  },
  {
    countryCode: 'SR',
    countryName: 'Surinam'
  },
  {
    countryCode: 'SS',
    countryName: 'South Sudan'
  },
  {
    countryCode: 'ST',
    countryName: 'São Tomé ve Príncipe'
  },
  {
    countryCode: 'CG',
    countryName: 'Kongo Cumhuriyeti'
  },
  {
    countryCode: 'CF',
    countryName: 'Orta Afrika Cumhuriyeti'
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador'
  },
  {
    countryCode: 'CD',
    countryName: 'Demokratik Kongo Cumhuriyeti'
  },
  {
    countryCode: 'CH',
    countryName: 'İsviçre'
  },
  {
    countryCode: 'SX',
    countryName: 'Sint Maarten (Dutch Part)'
  },
  {
    countryCode: 'CI',
    countryName: 'Fildişi Sahili'
  },
  {
    countryCode: 'SY',
    countryName: 'Suriye'
  },
  {
    countryCode: 'SZ',
    countryName: 'Svaziland'
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands'
  },
  {
    countryCode: 'CL',
    countryName: 'Şili'
  },
  {
    countryCode: 'CM',
    countryName: 'Kamerun'
  },
  {
    countryCode: 'CN',
    countryName: 'Çin'
  },
  {
    countryCode: 'CO',
    countryName: 'Kolombiya'
  },
  {
    countryCode: 'CR',
    countryName: 'Kosta Rika'
  },
  {
    countryCode: 'TC',
    countryName: 'Turks ve Caicos'
  },
  {
    countryCode: 'TD',
    countryName: 'Çad'
  },
  {
    countryCode: 'CU',
    countryName: 'Küba'
  },
  {
    countryCode: 'CV',
    countryName: 'Yeşil Burun Adaları'
  },
  {
    countryCode: 'TG',
    countryName: 'Togo'
  },
  {
    countryCode: 'CW',
    countryName: 'Curaçao'
  },
  {
    countryCode: 'TH',
    countryName: 'Tayland'
  },
  {
    countryCode: 'CY',
    countryName: 'Güney Kıbrıs Rum Yönetimi'
  },
  {
    countryCode: 'TJ',
    countryName: 'Tacikistan'
  },
  {
    countryCode: 'CZ',
    countryName: 'Çek Cumhuriyeti'
  },
  {
    countryCode: 'TL',
    countryName: 'Doğu Timor'
  },
  {
    countryCode: 'TM',
    countryName: 'Türkmenistan'
  },
  {
    countryCode: 'TN',
    countryName: 'Tunus'
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga'
  },
  {
    countryCode: 'TR',
    countryName: 'Türkiye'
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad ve Tobago'
  },
  {
    countryCode: 'DE',
    countryName: 'Almanya'
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu'
  },
  {
    countryCode: 'TW',
    countryName: 'Tayvan'
  },
  {
    countryCode: 'DJ',
    countryName: 'Cibuti'
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzanya'
  },
  {
    countryCode: 'DK',
    countryName: 'Danimarka'
  },
  {
    countryCode: 'DM',
    countryName: 'Dominika'
  },
  {
    countryCode: 'DO',
    countryName: 'Dominik Cumhuriyeti'
  },
  {
    countryCode: 'UA',
    countryName: 'Ukrayna'
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda'
  },
  {
    countryCode: 'DZ',
    countryName: 'Cezayir'
  },
  {
    countryCode: 'UK',
    countryName: 'İngiltere'
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands'
  },
  {
    countryCode: 'CYN',
    countryName: 'Kuzey Kıbrıs Türk Cumhuriyeti'
  },
  {
    countryCode: 'EC',
    countryName: 'Ekvador'
  },
  {
    countryCode: 'US',
    countryName: 'Amerika Birleşik Devletleri'
  },
  {
    countryCode: 'EE',
    countryName: 'Estonya'
  },
  {
    countryCode: 'EG',
    countryName: 'Mısır'
  },
  {
    countryCode: 'EH',
    countryName: 'Batı Sahra (MA)'
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay'
  },
  {
    countryCode: 'UZ',
    countryName: 'Özbekistan'
  },
  {
    countryCode: 'VA',
    countryName: 'Vatikan'
  },
  {
    countryCode: 'ER',
    countryName: 'Eritre'
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent ve Grenadinler'
  },
  {
    countryCode: 'ES',
    countryName: 'İspanya'
  },
  {
    countryCode: 'ET',
    countryName: 'Etiyopya'
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela'
  },
  {
    countryCode: 'VG',
    countryName: 'İngiliz Virjin Adaları'
  },
  {
    countryCode: 'VI',
    countryName: 'Us Virgin Islands'
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam'
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu'
  },
  {
    countryCode: 'FI',
    countryName: 'Finlandiya'
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji'
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Adaları'
  },
  {
    countryCode: 'FM',
    countryName: 'Mikronezya'
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Adaları (DK)'
  },
  {
    countryCode: 'FR',
    countryName: 'Fransa'
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis ve Futuna (FR)'
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon'
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa'
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada'
  },
  {
    countryCode: 'GE',
    countryName: 'Gürcistan'
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey (GB)'
  },
  {
    countryCode: 'GH',
    countryName: 'Gana'
  },
  {
    countryCode: 'GI',
    countryName: 'Cebelitarık (GB)'
  },
  {
    countryCode: 'GL',
    countryName: 'Grönland (DK)'
  },
  {
    countryCode: 'GM',
    countryName: 'Gambiya'
  },
  {
    countryCode: 'GN',
    countryName: 'Gine'
  },
  {
    countryCode: 'GQ',
    countryName: 'Ekvator Ginesi'
  },
  {
    countryCode: 'GR',
    countryName: 'Yunanistan'
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala'
  },
  {
    countryCode: 'GU',
    countryName: 'Guam'
  },
  {
    countryCode: 'GW',
    countryName: 'Gine Bissau'
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana'
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong (CN)'
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras'
  },
  {
    countryCode: 'HR',
    countryName: 'Hırvatistan'
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti'
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen'
  },
  {
    countryCode: 'HU',
    countryName: 'Macaristan'
  },
  {
    countryCode: 'ID',
    countryName: 'Endonezya'
  },
  {
    countryCode: 'IE',
    countryName: 'İrlanda'
  },
  {
    countryCode: 'IL',
    countryName: 'İsrail'
  },
  {
    countryCode: 'IM',
    countryName: 'Man Adası (GB)'
  },
  {
    countryCode: 'IN',
    countryName: 'Hindistan'
  },
  {
    countryCode: 'IO',
    countryName: 'Britanya Hint Okyanusu Toprakları'
  },
  {
    countryCode: 'ZA',
    countryName: 'Güney Afrika Cumhuriyeti'
  },
  {
    countryCode: 'IQ',
    countryName: 'Irak'
  },
  {
    countryCode: 'IR',
    countryName: 'İran'
  },
  {
    countryCode: 'IS',
    countryName: 'İzlanda'
  },
  {
    countryCode: 'IT',
    countryName: 'İtalya'
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambiya'
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey (GB)'
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabve'
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaika'
  },
  {
    countryCode: 'JO',
    countryName: 'Ürdün'
  },
  {
    countryCode: 'JP',
    countryName: 'Japonya'
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya'
  },
  {
    countryCode: 'KG',
    countryName: 'Kırgızistan'
  },
  {
    countryCode: 'KH',
    countryName: 'Kamboçya'
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati'
  },
  {
    countryCode: 'KM',
    countryName: 'Komorlar'
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts ve Nevis'
  },
  {
    countryCode: 'KP',
    countryName: 'Kuzey Kore'
  },
  {
    countryCode: 'KR',
    countryName: 'Güney Kore'
  },
  {
    countryCode: 'KW',
    countryName: 'Kuveyt'
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Adaları'
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakistan'
  },
  {
    countryCode: 'LA',
    countryName: 'Laos'
  },
  {
    countryCode: 'LB',
    countryName: 'Lübnan'
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia'
  },
  {
    countryCode: 'LI',
    countryName: 'Lihtenştayn'
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka'
  },
  {
    countryCode: 'LR',
    countryName: 'Liberya'
  },
  {
    countryCode: 'LS',
    countryName: 'Lesoto'
  },
  {
    countryCode: 'LT',
    countryName: 'Litvanya'
  },
  {
    countryCode: 'LU',
    countryName: 'Lüksemburg'
  },
  {
    countryCode: 'LV',
    countryName: 'Letonya'
  },
  {
    countryCode: 'LY',
    countryName: 'Libya'
  },
  {
    countryCode: 'MA',
    countryName: 'Fas'
  },
  {
    countryCode: 'MC',
    countryName: 'Monako'
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova'
  },
  {
    countryCode: 'ME',
    countryName: 'Karadağ'
  },
  {
    countryCode: 'MF',
    countryName: 'Ermiş Martin (FR)'
  },
  {
    countryCode: 'MG',
    countryName: 'Madagaskar'
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Adaları'
  },
  {
    countryCode: 'MK',
    countryName: 'Kuzey Makedonya'
  },
  {
    countryCode: 'ML',
    countryName: 'Mali'
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar'
  },
  {
    countryCode: 'MN',
    countryName: 'Moğolistan'
  },
  {
    countryCode: 'MO',
    countryName: 'Makao (CN)'
  },
  {
    countryCode: 'MP',
    countryName: 'Kuzey Mariana Adaları'
  },
  {
    countryCode: 'MR',
    countryName: 'Moritanya'
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat'
  },
  {
    countryCode: 'MT',
    countryName: 'Malta'
  },
  {
    countryCode: 'MU',
    countryName: 'Morityus'
  },
  {
    countryCode: 'MV',
    countryName: 'Maldivler'
  },
  {
    countryCode: 'MW',
    countryName: 'Malavi'
  },
  {
    countryCode: 'MX',
    countryName: 'Meksika'
  },
  {
    countryCode: 'MY',
    countryName: 'Malezya'
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambik'
  },
  {
    countryCode: 'NC',
    countryName: 'Yeni Kaledonya (FR)'
  },
  {
    countryCode: 'NE',
    countryName: 'Nijer'
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Adası'
  },
  {
    countryCode: 'NG',
    countryName: 'Nijerya'
  },
  {
    countryCode: 'NI',
    countryName: 'Nikaragua'
  },
  {
    countryCode: 'NL',
    countryName: 'Hollanda'
  },
  {
    countryCode: 'NO',
    countryName: 'Norveç'
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal'
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru'
  },
  {
    countryCode: 'NU',
    countryName: 'Niue'
  },
  {
    countryCode: 'NZ',
    countryName: 'Yeni Zelanda'
  },
  {
    countryCode: 'OM',
    countryName: 'Umman'
  },
  {
    countryCode: 'PA',
    countryName: 'Panama'
  },
  {
    countryCode: 'PE',
    countryName: 'Peru'
  },
  {
    countryCode: 'PF',
    countryName: 'Fransız Polinezyası (FR)'
  },
  {
    countryCode: 'PG',
    countryName: 'Papua Yeni Gine'
  },
  {
    countryCode: 'PH',
    countryName: 'Filipinler'
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan'
  },
  {
    countryCode: 'PL',
    countryName: 'Polonya'
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre ve Miquelon (FR)'
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn'
  }
]
