import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// MUI Imports
import {
  Typography,
  IconButton,
  styled
} from '@mui/material'
import {
  UnfoldMore,
  UnfoldLess,
  ArrowBack
} from '@mui/icons-material'

// Components Imports
import MailCard from './MailCard'

// Styles Imports
import styles from '../styles/mailbox.module.scss'

const DetailsDrawer = styled('div')(({ drawerOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  blockSize: '100%',
  inlineSize: '100%',
  position: 'absolute',
  top: 0,
  right: drawerOpen ? 0 : '-100%',
  zIndex: 11,
  overflow: 'hidden',
  background: 'var(--mui-palette-background-paper)',
  transition: 'right 0.3s ease'
}))

const MailDetails = props => {
  const {
    drawerOpen,
    setDrawerOpen,
    activeEmailArr,
    setRepliedMessage
  } = props

  const { t } = useTranslation()

  const [showReplies, setShowReplies] = useState(false)

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
    setShowReplies(false)
  }

  return (
    <DetailsDrawer drawerOpen={drawerOpen}>
      {activeEmailArr && (
        <>
          <div
            className='plb-4 pli-6'
            style={{ borderBottom: '1px solid var(--mui-palette-divider)' }}
          >
            <div className='flex justify-between gap-2'>
              <div className='flex gap-2 items-center overflow-hidden'>
                <IconButton
                  onClick={handleCloseDrawer}
                  color='primary'
                >
                  <ArrowBack/>
                </IconButton>
                <div className='flex items-center flex-wrap gap-2 overflow-hidden'>
                  <Typography color='text.primary' noWrap>
                    {activeEmailArr?.[0]?.subject}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {activeEmailArr?.length > 1
            ? <div
                className='flex items-center justify-between gap-4 plb-2 pli-6 border-y'
                style={{ borderBottom: '1px solid var(--mui-palette-divider)' }}
              >
                <div className='flex gap-1'>
                  {/* SPACE */}
                </div>
                <div className='flex gap-1'>
                  <IconButton
                    onClick={() => {
                      setShowReplies(!showReplies)
                    }}
                    color='primary'
                  >
                    {
                      showReplies
                        ? <UnfoldLess/>
                        : <UnfoldMore/>
                    }
                  </IconButton>
                </div>
              </div>
            : null
          }
          <div className='bs-full overflow-y-auto overflow-x-hidden bg-actionHover'>
            <div className='plb-5 pli-8 flex flex-col gap-4'>
              {activeEmailArr?.length > 1 && !showReplies
                ? (
                    <Typography className='self-center text-center cursor-pointer' onClick={() => setShowReplies(true)}>
                      {`${activeEmailArr.length - 1} ${t('mailTemplate.mailbox.earlierMessages')}`}
                    </Typography>
                  )
                : null
              }

              <div>
                {!showReplies && activeEmailArr?.length > 1
                  ? (
                      <>
                        <div
                          className={cx(styles.mailReplyLayer, styles.layer1)}
                          onClick={() => setShowReplies(true)}
                        />
                        <div
                          className={cx(styles.mailReplyLayer, styles.layer2)}
                          onClick={() => setShowReplies(true)}
                        />
                      </>
                    )
                  : null
                }
                <MailCard
                  data={activeEmailArr?.[0]}
                  isReplies={true}
                  setRepliedMessage={setRepliedMessage}
                />
              </div>

              {(showReplies && activeEmailArr?.length > 1) &&
                activeEmailArr?.slice(1, activeEmailArr.length)?.map(reply => (
                  <MailCard
                    key={reply.messageId}
                    data={reply}
                    isReplies={false}
                    setRepliedMessage={setRepliedMessage}
                  />
                ))
              }
            </div>
          </div>
        </>
      )}
    </DetailsDrawer>
  )
}

export default MailDetails
