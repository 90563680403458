import * as React from 'react'

import ColorPicker from './ColorPicker'
import DropDown from './DropDown'

export default function DropdownColorPicker ({
  disabled = false,
  stopCloseOnClickSelf = true,
  color,
  onChange,
  ...rest
}) {
  return (
    <DropDown
      {...rest}
      disabled={disabled}
      stopCloseOnClickSelf={stopCloseOnClickSelf}>
      <ColorPicker color={color} onChange={onChange} />
    </DropDown>
  )
}
