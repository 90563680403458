import React from 'react'
import { ButtonGroup, Col } from 'reactstrap'
import { Button, Row } from '../../../components/Component'
import McPageHelpers from '../McPageHelpers'
import { useFSimporterFilters, useFilterStoreActions } from '../../../store/filterStore'
import { useTranslation } from 'react-i18next'

const ImporterFilterButtons = () => {
  const { t } = useTranslation()

  const { setImporterFilters } = useFilterStoreActions()
  const importerFilters = useFSimporterFilters()

  return <Row>
    <Col>
      <h6>{t('filter_importer_status')}</h6>
      <ButtonGroup className="btn-block btn-group-ai">
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IMPORTER_COUNT, McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY)}
                outline={importerFilters.importer !== McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_all')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IMPORTER_COUNT, McPageHelpers.COUNT_FILTERS.PERMANENT)}
                outline={importerFilters.importer !== McPageHelpers.COUNT_FILTERS.PERMANENT}
                title={t('filter_perma_importers')}>{t('filter_steady')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IMPORTER_COUNT, McPageHelpers.COUNT_FILTERS.EXIT)}
                outline={importerFilters.importer !== McPageHelpers.COUNT_FILTERS.EXIT}
                title={t('filter_exit_importers')}>{t('filter_unsteady')}</Button>
      </ButtonGroup>
    </Col>
    <Col>
      <h6>{t('filter_supplier_count')}</h6>
      <ButtonGroup className="btn-block btn-group-ai">
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.SUPPLIER_COUNT, McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY)}
                outline={importerFilters.supplier !== McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_all')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.SUPPLIER_COUNT, McPageHelpers.COUNT_FILTERS.ONE)}
                outline={importerFilters.supplier !== McPageHelpers.COUNT_FILTERS.ONE}
                title={t('filter_imports_from_one')}>{t('filter_one')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.SUPPLIER_COUNT, McPageHelpers.COUNT_FILTERS.MULTIPLE)}
                outline={importerFilters.supplier !== McPageHelpers.COUNT_FILTERS.MULTIPLE}
                title={t('filter_imports_from_many')}>{t('filter_many')}</Button>
      </ButtonGroup>
    </Col>
    <Col>
      <h6>{t('filter_country_count')}</h6>
      <ButtonGroup className="btn-block btn-group-ai">
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.COUNTRY_COUNT, McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY)}
                outline={importerFilters.country !== McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_all')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.COUNTRY_COUNT, McPageHelpers.COUNT_FILTERS.ONE)}
                outline={importerFilters.country !== McPageHelpers.COUNT_FILTERS.ONE}
                title={t('filter_imports_from_one_country')}>{t('filter_one')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.COUNTRY_COUNT, McPageHelpers.COUNT_FILTERS.MULTIPLE)}
                outline={importerFilters.country !== McPageHelpers.COUNT_FILTERS.MULTIPLE}
                title={t('filter_imports_from_many_country')}>{t('filter_many')}</Button>
      </ButtonGroup>
    </Col>
    <Col>
      <h6>{t('filter_hs_code_count')}</h6>
      <ButtonGroup className="btn-block btn-group-ai">
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_COUNT, McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY)}
                outline={importerFilters.hsCode !== McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_all')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_COUNT, McPageHelpers.COUNT_FILTERS.ONE)}
                outline={importerFilters.hsCode !== McPageHelpers.COUNT_FILTERS.ONE}
                title={t('filter_imports_one_hs_codes')}>{t('filter_one')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_COUNT, McPageHelpers.COUNT_FILTERS.MULTIPLE)}
                outline={importerFilters.hsCode !== McPageHelpers.COUNT_FILTERS.MULTIPLE}
                title={t('filter_imports_many_hs_codes')}>{t('filter_many')}</Button>
      </ButtonGroup>
    </Col>
    <Col lg="auto" sm="8" className="col-xs">
      <h6>{t('filter_logistic_custom')}</h6>
      <ButtonGroup className="btn-block btn-group-ai">
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IS_LOGISTIC_FIRM, McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY)}
                outline={importerFilters.isLogistic !== McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_show')}</Button>
        <Button color="primary" className="btn-dim"
                onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IS_LOGISTIC_FIRM, false)}
                outline={importerFilters.isLogistic === McPageHelpers.COUNT_FILTERS.ALL_OR_EMPTY}>{t('filter_hide')}</Button>
      </ButtonGroup>
    </Col>
  </Row>
}

export default ImporterFilterButtons
