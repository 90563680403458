import React, { useMemo } from 'react'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

const GrowthBox = ({ growthName, growthTitle, growthData, selected, ...props }) => {
  const growth = useMemo(() => growthData[growthName] ? growthData[growthName] : 0, [growthData])

  return (
    <div
      className={`growth_box growth-box nk-order-ovwg-data text-center ${!growth ? 'disabled-full' : ''} ${growthName} ${selected ? 'active' : ''}`} {...props}>
      <div className="amount">
        {growth}
      </div>
      <div className="title growth-center">
        <div className="growth-box-icon">
          {McPageHelpers.GROWTH_ICONS[growthName]}
        </div>
        <div>
          {growthTitle}
        </div>
      </div>
    </div>
  )
}

export default GrowthBox
