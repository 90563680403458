import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

// Component Imports
import MailContentSearch from './MailContentSearch'
import MailContentList from './MailContentList'
import MailDetails from './MailDetails'
import MailContentActions from './MailContentActions'
import ComposeMail from './ComposeMail'

import { extractHtmlContent } from '../../utils/helpers'

const MailContent = props => {
  const {
    fetching,
    setSidebarOpen,
    isBelowMdScreen,
    isBelowSmScreen,
    setBackdropOpen,
    emailArrays,
    activeEmailArr,
    setActiveEmailArr,
    reloadMessages,
    submitting,
    handleSubmitReply,
    onSubmitNewMail
  } = props

  const { t } = useTranslation()

  // States
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [repliedMessage, setRepliedMessage] = useState(null)
  const [openCompose, setOpenCompose] = useState(false)

  const areFilteredEmailsNone =
    emailArrays.length === 0 ||
    emailArrays.filter(
      emailArr =>
        emailArr?.[0]?.subject?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        emailArr?.[0]?.from_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        emailArr?.[0]?.from_address?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        extractHtmlContent(emailArr?.[0]?.bodyHtml)?.toLowerCase().includes(searchTerm?.toLowerCase())
    )?.length === 0

  return (
    <div
      className='flex flex-col items-center justify-center is-full bs-full relative overflow-hidden bg-backgroundPaper'
      style={{ height: 'auto' }}
    >
      <MailContentSearch
        isBelowScreen={isBelowMdScreen}
        searchTerm={searchTerm}
        setSidebarOpen={setSidebarOpen}
        setBackdropOpen={setBackdropOpen}
        setSearchTerm={setSearchTerm}
      />
      <MailContentActions
        reloadMessages={reloadMessages}
        fetching={fetching}
        setOpenCompose={setOpenCompose}
      />
      <MailContentList
        fetching={fetching}
        isBelowSmScreen={isBelowSmScreen}
        areFilteredEmailsNone={areFilteredEmailsNone}
        searchTerm={searchTerm}
        emailArrays={emailArrays}
        setDrawerOpen={setDrawerOpen}
        setActiveEmailArr={setActiveEmailArr}
      />
      <MailDetails
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        activeEmailArr={activeEmailArr}
        setRepliedMessage={setRepliedMessage}
      />

      <ComposeMail
        title={t('mailTemplate.mailbox.send.replyTitle')}
        openCompose={!!repliedMessage}
        setOpenCompose={setRepliedMessage}
        isBelowSmScreen={isBelowSmScreen}
        isBelowMdScreen={isBelowMdScreen}
        onSubmit={handleSubmitReply}
        submitting={submitting}
        initialEmail={{
          ...repliedMessage,
          subject: `Re: ${repliedMessage?.subject}`,
          html: '<blockquote class="RichTextEditorTheme__quote" dir="ltr">' + repliedMessage?.bodyHtml + '</blockquote>'
        }}
        focusSubjectAfterInit
        replyMessageId={repliedMessage?.messageId}
      />

      <ComposeMail
        title={t('mailTemplate.mailbox.send.composeTitle')}
        openCompose={!!openCompose}
        setOpenCompose={setOpenCompose}
        isBelowSmScreen={isBelowSmScreen}
        isBelowMdScreen={isBelowMdScreen}
        onSubmit={onSubmitNewMail}
        submitting={submitting}
      />
    </div>
  )
}

export default MailContent
