import React, { forwardRef } from 'react'

import { LexicalComposer } from '@lexical/react/LexicalComposer'

import { ExportHtml, LoadInitialHtml } from './plugins/HtmlPlugin'

import { SharedHistoryContext } from './context/SharedHistoryContext'
import Editor from './Editor'
import EditorNodes from './nodes/EditorNodes'
import { TableContext } from './plugins/TablePlugin'
import RichTextEditorTheme from './themes/RichTextEditorTheme'

import './rte.scss'

const RichTextEditor = forwardRef((props, ref) => {
  const {
    templateHtml,
    textareaStyles
  } = props

  const initialConfig = {
    namespace: 'MC_RichTextEditor',
    nodes: [...EditorNodes],
    onError: (error) => {
      throw error
    },
    theme: RichTextEditorTheme
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
            <div id='mcRichTextEditor' className="editor-shell">
              <LoadInitialHtml html={templateHtml}/>
              <ExportHtml ref={ref}/>
              <Editor textareaStyles={textareaStyles}/>
            </div>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  )
})

export default RichTextEditor
