import React, { useEffect, useState } from 'react'
import { Icon } from '../Component'
import { useTranslation } from 'react-i18next'

function ScrollToTopButton () {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className="scroll-to-top">
      {isVisible &&
        <button onClick={scrollToTop}>
          <Icon name="arrow-up"/>
          <span className="tooltip">{t('scroll_to_top')}</span>
        </button>}
    </div>
  )
}

export default ScrollToTopButton
