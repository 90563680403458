import { create } from 'zustand'

import McPageHelpers from '../pages/mc-components/McPageHelpers'
import initialStates from './initialStates'

const useDataStore = create((set) => (
  {
    globalParams: { // TODO:ayrı bir store olarak çıkarılmalı
      hsCode: '',
      countryCode: '',
      countryName: '',
      dataCoverage: false,
      selectedUnit: McPageHelpers.UNITS.QUANTITY_1,
      selectedUnitNumber: McPageHelpers.UNIT_NUMBERS.N1
    },
    units: {
      quantity1: null,
      quantity2: null,
      quantity3: null,
      quantity4: null
    },

    selectedFirmDetails: {},
    selectedTableName: null,
    showFirmDetailsModal: false,

    rawImporterTable: [],
    filteredImporterTable: [],
    importerTableExplanation: [],
    importerGraphMapSectionExplanation: [],
    importerSize: {
      raw: 0,
      filtered: 0
    },
    rawImporterMap: [],
    rawImporterBar: [],
    totalImporterCompanyCount: 0,

    rawPotentialTable: [],
    filteredPotentialTable: [],
    filteredPotentialTableExplanation: [],
    potentialGraphMapSectionDataExplanation: [],
    potentialSize: {
      raw: 0,
      filtered: 0
    },
    rawPotentialMap: [],
    rawPotentialBar: [],
    totalPotentialCompanyCount: 0,

    companyValueBar: [],
    companyValueBarExplanation: [],
    onlyHsCountPie: [],
    onlyHsCountPieExplanation: [],
    companyCountBar: [],
    companyCountBarExplanation: [],
    countryCountPie: [],
    countryCountPieExplanation: [],
    companyShipmentCountBar: [],
    companyShipmentCountBarExplanation: [],
    growth: {},
    growthExplanation: [],

    priceMap: [],
    priceBar: [],
    worldPriceMap: [],
    worldPriceBar: [],
    avgMonthlyLine: [],
    avgMonthlyTable: [],

    hsTotalMountain: {
      raw: { data: [], min: '', max: '' },
      out: { data: [], min: '', max: '' }
    },
    hsCountMountain: {
      raw: { data: [], min: '', max: '' },
      out: { data: [], min: '', max: '' }
    },
    avgPriceMountain: {
      raw: { data: [], min: '', max: '' },
      out: { data: [], min: '', max: '' }
    },
    avgCurrencyMountain: {
      raw: { data: [], min: '', max: '' },
      out: { data: [], min: '', max: '' }
    },
    avgInterestMountain: {
      raw: { data: [], min: '', max: '' },
      out: { data: [], min: '', max: '' }
    },

    allExplanations: {
      avgPriceExplanations: [''],
      tableExplanations: [''],
      parityGraphExplanations: [''],
      hsTotalValFirmNumExplanation: [''],
      avgPriceHsTotalValExplanations: [''],
      avgPriceFirmNumExplanations: [''],
      hsCountHsTotalValExplanation: [''],
      avgPriceHsTotalValPercentageExplanations: ['']
    },

    marketOverviewData: {
      value: initialStates.initialMarketOverviewData,
      quantity: initialStates.initialMarketOverviewData,
      averagePrice: initialStates.initialMarketOverviewData
    },

    marketChangesData: {
      clustered: {},
      stacked: {},
      table: [],
      series: {
        value: [],
        quantity: [],
        excludedValue: [],
        excludedQuantity: []
      },
      explanations: {
        marketShareChangeExplanation: [],
        marketShareChangeDetailsExplanation: [],
        importerMonthlyValueExplanation: [],
        importerValueChangeExplanation: [],
        worldMonthlyImportValueExplanation: [],
        worldImportValueChangeExplanation: [],
        importerMonthlyQuantityExplanation: [],
        importerQuantityChangeExplanation: [],
        worldMonthlyImportQuantityExplanation: [],
        worldImportQuantityChangeExplanation: [],
        importerMonthlyTop5ValueExplanation: [],
        importerTop5ValueChangeExplanation: [],
        importerMonthlyTop5QuantityExplanation: [],
        importerTop5QuantityChangeExplanation: []
      }
    },

    customsDuties: [],

    summaryTablesData: {
      import: {
        yearly: {},
        monthly: {},
        year: null,
        month: null
      },
      export: {
        yearly: {},
        monthly: {},
        year: null,
        month: null
      },
      world: {
        import: {
          yearly: {},
          monthly: {},
          year: null,
          month: null
        },
        export: {
          yearly: {},
          monthly: {},
          year: null,
          month: null
        }
      }
    },

    actions: {
      setRawImporterSectionData: (
        rawImporterTable,
        importerTableExplanation,
        importerGraphMapSectionExplanation,
        units,
        rawImporterMap,
        rawImporterBar,
        totalImporterCompanyCount
      ) => set(
        {
          rawImporterTable,
          filteredImporterTable: rawImporterTable,
          importerTableExplanation,
          importerGraphMapSectionExplanation,
          importerSize: {
            raw: rawImporterTable?.length,
            filtered: rawImporterTable?.length
          },
          units,
          rawImporterMap,
          rawImporterBar,
          totalImporterCompanyCount
        }
      ),
      setRawPotentialSectionData: (
        rawPotentialTable,
        filteredPotentialTableExplanation,
        potentialGraphMapSectionDataExplanation,
        rawPotentialMap,
        rawPotentialBar,
        totalPotentialCompanyCount
      ) => set(
        {
          rawPotentialTable,
          filteredPotentialTable: rawPotentialTable,
          filteredPotentialTableExplanation,
          potentialGraphMapSectionDataExplanation,
          potentialSize: {
            raw: rawPotentialTable?.length,
            filtered: rawPotentialTable?.length
          },
          rawPotentialMap,
          rawPotentialBar,
          totalPotentialCompanyCount
        }
      ),
      setProductAnalysisSectionData: (
        companyValueBar,
        companyValueBarExplanation,
        onlyHsCountPie,
        onlyHsCountPieExplanation,
        companyCountBar,
        companyCountBarExplanation,
        countryCountPie,
        countryCountPieExplanation,
        companyShipmentCountBar,
        companyShipmentCountBarExplanation,
        growth,
        growthExplanation
      ) => set(
        {
          companyValueBar,
          companyValueBarExplanation,
          onlyHsCountPie,
          onlyHsCountPieExplanation,
          companyCountBar,
          companyCountBarExplanation,
          countryCountPie,
          countryCountPieExplanation,
          companyShipmentCountBar,
          companyShipmentCountBarExplanation,
          growth,
          growthExplanation
        }
      ),
      setPriceAnalysisSectionData: (
        priceMap,
        priceBar,
        worldPriceMap,
        worldPriceBar,
        avgMonthlyLine,
        avgMonthlyTable,
        hsTotalMountain,
        hsCountMountain,
        avgPriceMountain,
        avgCurrencyMountain,
        avgInterestMountain,
        allExplanations
      ) => set(
        {
          priceMap,
          priceBar,
          worldPriceMap,
          worldPriceBar,
          avgMonthlyLine,
          avgMonthlyTable,
          hsTotalMountain,
          hsCountMountain,
          avgPriceMountain,
          avgCurrencyMountain,
          avgInterestMountain,
          allExplanations
        }
      ),
      setMarketOverviewSectionData: (marketOverviewData) => set({ marketOverviewData }),
      setMarketChangesSectionData: (marketChangesData) => set({ marketChangesData }),
      setSummaryTablesSectionData: (summaryTablesData) => set({ summaryTablesData }),
      setCustomsDutiesSectionData: (customsDuties) => set({ customsDuties }),
      setRawImporterTable: (rawImporterTable, importerTableExplanation, units) => set(
        {
          rawImporterTable,
          filteredImporterTable: rawImporterTable,
          importerTableExplanation,
          importerSize: {
            raw: rawImporterTable?.length,
            filtered: rawImporterTable?.length
          },
          units
        }
      ),
      setRawImporterMap: (rawImporterMap, totalImporterCompanyCount) => set(
        {
          rawImporterMap,
          totalImporterCompanyCount
        }
      ),
      setFilteredImporterTable: (filteredImporterTable) => set(
        (prevState) => ({
          filteredImporterTable,
          importerSize: {
            ...prevState.importerSize,
            filtered: filteredImporterTable?.length
          }
        })
      ),
      setFilteredPotentialTable: (filteredPotentialTable) => set(
        (prevState) => ({
          filteredPotentialTable,
          potentialSize: {
            ...prevState.potentialSize,
            filtered: filteredPotentialTable?.length
          }
        })
      ),
      setGlobalSearchedParams: (hsCode, countryCode) => set(
        (prevState) => ({
          globalParams: {
            ...prevState.globalParams,
            hsCode,
            countryCode
          }
        })
      ),
      setCountryNameAndDataCoverage: (countryName = '', dataCoverage = false) => set(
        (prevState) => ({
          globalParams: {
            ...prevState.globalParams,
            countryName,
            dataCoverage
          }
        })
      ),
      setSelectedUnit: (selectedUnit) => set(
        (prevState) => ({
          globalParams: {
            ...prevState.globalParams,
            selectedUnit,
            selectedUnitNumber: McPageHelpers.unitToNumber(selectedUnit)
          }
        })
      ),
      setShowFirmDetailsModal: (showFirmDetailsModal, id, tableName) => {
        set((prevState) => ({
          showFirmDetailsModal,
          selectedFirmDetails: McPageHelpers.findSelectedFirmDetails(id, tableName, prevState),
          selectedTableName: tableName
        }))
      },
      resetData: () => set(initialStates.initialDataStore)
    }
  }
))

export const useDSrawData = () => useDataStore((state) => state.rawData)
export const useDSglobalParams = () => useDataStore((state) => state.globalParams)
export const useDSunits = () => useDataStore((state) => state.units)
export const useDSselectedFirmDetails = () => useDataStore((state) => state.selectedFirmDetails)
export const useDSselectedTableName = () => useDataStore((state) => state.selectedTableName)
export const useDSshowFirmDetailsModal = () => useDataStore((state) => state.showFirmDetailsModal)
export const useDSrawImporterTable = () => useDataStore((state) => state.rawImporterTable)
export const useDSfilteredImporterTable = () => useDataStore((state) => state.filteredImporterTable)
export const useDSimporterTableExplanation = () => useDataStore((state) => state.importerTableExplanation)
export const useDSimporterGraphMapSectionExplanation = () => useDataStore((state) => state.importerGraphMapSectionExplanation)
export const useDSimporterSize = () => useDataStore((state) => state.importerSize)
export const useDSrawImporterMap = () => useDataStore((state) => state.rawImporterMap)
export const useDSrawImporterBar = () => useDataStore((state) => state.rawImporterBar)
export const useDStotalImporterCompanyCount = () => useDataStore((state) => state.totalImporterCompanyCount)
export const useDSrawPotentialTable = () => useDataStore((state) => state.rawPotentialTable)
export const useDSfilteredPotentialTable = () => useDataStore((state) => state.filteredPotentialTable)
export const useDSfilteredPotentialTableExplanation = () => useDataStore((state) => state.filteredPotentialTableExplanation)
export const useDSpotentialGraphMapSectionDataExplanation = () => useDataStore((state) => state.potentialGraphMapSectionDataExplanation)
export const useDSpotentialSize = () => useDataStore((state) => state.potentialSize)
export const useDSrawPotentialMap = () => useDataStore((state) => state.rawPotentialMap)
export const useDSrawPotentialBar = () => useDataStore((state) => state.rawPotentialBar)
export const useDStotalPotentialCompanyCount = () => useDataStore((state) => state.totalPotentialCompanyCount)
export const useDScompanyValueBar = () => useDataStore((state) => state.companyValueBar)
export const useDScompanyValueBarExplanation = () => useDataStore((state) => state.companyValueBarExplanation)
export const useDSonlyHsCountPie = () => useDataStore((state) => state.onlyHsCountPie)
export const useDSonlyHsCountPieExplanation = () => useDataStore((state) => state.onlyHsCountPieExplanation)
export const useDScompanyCountBar = () => useDataStore((state) => state.companyCountBar)
export const useDScompanyCountBarExplanation = () => useDataStore((state) => state.companyCountBarExplanation)
export const useDScountryCountPie = () => useDataStore((state) => state.countryCountPie)
export const useDScountryCountPieExplanation = () => useDataStore((state) => state.countryCountPieExplanation)
export const useDScompanyShipmentCountBar = () => useDataStore((state) => state.companyShipmentCountBar)
export const useDScompanyShipmentCountBarExplanation = () => useDataStore((state) => state.companyShipmentCountBarExplanation)
export const useDSgrowth = () => useDataStore((state) => state.growth)
export const useDSgrowthExplanation = () => useDataStore((state) => state.growthExplanation)
export const useDSpriceMap = () => useDataStore((state) => state.priceMap)
export const useDSpriceBar = () => useDataStore((state) => state.priceBar)
export const useDSworldPriceMap = () => useDataStore((state) => state.worldPriceMap)
export const useDSworldPriceBar = () => useDataStore((state) => state.worldPriceBar)
export const useDSavgMonthlyLine = () => useDataStore((state) => state.avgMonthlyLine)
export const useDSavgMonthlyTable = () => useDataStore((state) => state.avgMonthlyTable)
export const useDShsTotalMountain = () => useDataStore((state) => state.hsTotalMountain)
export const useDShsCountMountain = () => useDataStore((state) => state.hsCountMountain)
export const useDSavgPriceMountain = () => useDataStore((state) => state.avgPriceMountain)
export const useDSavgCurrencyMountain = () => useDataStore((state) => state.avgCurrencyMountain)
export const useDSavgInterestMountain = () => useDataStore((state) => state.avgInterestMountain)
export const useDSallExplanations = () => useDataStore((state) => state.allExplanations)
export const useDSmarketOverviewData = () => useDataStore((state) => state.marketOverviewData)
export const useDSmarketChangesData = () => useDataStore((state) => state.marketChangesData)
export const useDScustomsDuties = () => useDataStore((state) => state.customsDuties)
export const useDSsummaryTablesData = () => useDataStore((state) => state.summaryTablesData)

export const useDataStoreActions = () => useDataStore((state) => state.actions)
