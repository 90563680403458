import React from 'react'

// MUI Imports
import Chip from '@mui/material/Chip'

// Components Imports
import CustomAutocomplete from './CustomAutocomplete'
import CustomTextField from './CustomTextField'

const AutoCompleteChip = (props) => {
  const {
    value,
    onChange,
    options,
    getOptionLabel,
    placeholder,
    label,
    chipLabelKey,
    inputProperties,
    ...restProps
  } = props

  return (
    <CustomAutocomplete
      multiple
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => <CustomTextField {...params} label={label} placeholder={placeholder} {...inputProperties}/>}
      onChange={onChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option?.[chipLabelKey]}
            {...getTagProps({ index })}
            key={index}
            size='small'
          />
        ))
      }
      {...restProps}
    />
  )
}

export default AutoCompleteChip
