import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

// MUI Imports
import {
  createFilterOptions,
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Component Imports
import DialogCloseButton from '../atoms/DialogCloseButton'
import CustomTextField from '../atoms/CustomTextField'
import AutoCompleteChip from '../atoms/AutoCompleteChip'
import AutoCompleteCountry from '../atoms/AutoCompleteCountry'

import { useGlobalStoreData } from '../../../../../store/globalStore'
import { useSDSsearchParams } from '../../../../../store/searchDataStore'

import { createCampaign } from '../../../services/B2BServices'

const filterOptions = createFilterOptions({
  ignoreCase: true,
  limit: 20
})

const CreateCampaignModal = (props) => {
  const { open, setOpen, b2bAccountId, fetchCampaigns, countries } = props

  const { t } = useTranslation()

  const hsCodesGSD = useGlobalStoreData.hsCodes()

  const searchParams = useSDSsearchParams()

  const [loading, setLoading] = useState(false)

  const defaultValues = {
    name: '',
    importerCountryCode: (searchParams?.countryToExport && countries?.length)
      ? {
          label: countries.find((item) => item.countryCode === searchParams.countryToExport)?.label,
          countryCode: searchParams.countryToExport
        }
      : null,
    currentCountryCode: (searchParams?.yourCountry && countries?.length)
      ? {
          label: countries.find((item) => item.countryCode === searchParams.yourCountry)?.label,
          countryCode: searchParams.yourCountry
        }
      : null,
    hsCodes: searchParams?.hsCode ? searchParams.hsCode.split(',').map((item) => ({ label: item, value: item })) : [],
    productName: ''
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues
  })

  useEffect(() => {
    reset(defaultValues)
  }, [countries])

  const handleClose = () => {
    reset()
    setOpen(false)
  }

  const onSubmit = (val) => {
    const payload = {
      name: val.name,
      importerCountryCode: val.importerCountryCode.countryCode,
      currentCountryCode: val.currentCountryCode.countryCode,
      hsCodes: val.hsCodes.map((x) => x.value),
      productName: val.productName,
      b2bAccountId
    }

    setLoading(true)
    createCampaign(payload)
      .then((res) => {
        fetchCampaigns()
      })
      .catch((err) => {
        err && toast.error(t('mailTemplate.campaigns.modal.toastErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
      .finally(() => {
        setLoading(false)
        handleClose()
      })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth='md'
      scroll='body'
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogCloseButton onClick={() => setOpen(false)} disableRipple>
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle
        variant='h4'
        className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-6 sm:pli-16'
      >
        {t('mailTemplate.campaigns.modal.title')}
        <Typography component='span' className='flex flex-col text-center'>
          {t('mailTemplate.campaigns.modal.subTitle')}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className='overflow-visible pbs-0 sm:pli-16'>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='name'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.campaigns.modal.name')}
                    placeholder={t('mailTemplate.campaigns.modal.nameP')}
                    {...(errors.name && {
                      error: true,
                      helperText: t('mailTemplate.campaigns.modal.errors.name')
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='hsCodes'
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) =>
                  <AutoCompleteChip
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    value={value}
                    onChange={(e, data) => onChange(data)}
                    options={hsCodesGSD}
                    getOptionLabel={(opt) => `${opt.value}-${opt.label}`}
                    label={t('mailTemplate.campaigns.modal.hsCodes')}
                    placeholder={t('mailTemplate.campaigns.modal.hsCodesP')}
                    chipLabelKey={'value'}
                    filterOptions={filterOptions}
                    inputProperties={
                      errors.hsCodes && {
                        error: true,
                        helperText: t(
                          'mailTemplate.campaigns.modal.errors.hsCodes'
                        )
                      }
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='importerCountryCode'
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteCountry
                    isOptionEqualToValue={(option, value) =>
                      option.countryCode === value.countryCode
                    }
                    value={value}
                    onChange={(e, data) => onChange(data)}
                    options={countries}
                    getOptionLabel={(opt) => opt?.label}
                    label={t(
                      'mailTemplate.campaigns.modal.importerCountryCode'
                    )}
                    placeholder={t(
                      'mailTemplate.campaigns.modal.importerCountryCodeP'
                    )}
                    codeKey={'countryCode'}
                    filterOptions={filterOptions}
                    inputProperties={
                      errors.importerCountryCode && {
                        error: true,
                        helperText: t(
                          'mailTemplate.campaigns.modal.errors.importerCountryCode'
                        )
                      }
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='productName'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.campaigns.modal.productName')}
                    placeholder={t('mailTemplate.campaigns.modal.productNameP')}
                    {...(errors.productName && {
                      error: true,
                      helperText: t(
                        'mailTemplate.campaigns.modal.errors.productName'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='currentCountryCode'
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteCountry
                    isOptionEqualToValue={(option, value) =>
                      option.countryCode === value.countryCode
                    }
                    value={value}
                    onChange={(e, data) => onChange(data)}
                    options={countries}
                    getOptionLabel={(opt) => opt.label}
                    label={t('mailTemplate.campaigns.modal.currentCountryCode')}
                    placeholder={t(
                      'mailTemplate.campaigns.modal.currentCountryCodeP'
                    )}
                    codeKey={'countryCode'}
                    filterOptions={filterOptions}
                    inputProperties={
                      errors.currentCountryCode && {
                        error: true,
                        helperText: t(
                          'mailTemplate.campaigns.modal.errors.currentCountryCode'
                        )
                      }
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='justify-center pbs-0 sm:pbe-16 sm:pli-16'>
          <Button
            variant='contained'
            type='submit'
            disabled={loading}
            startIcon={loading ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : null}
          >
            {t('mailTemplate.campaigns.modal.submit')}
          </Button>
          <Button
            variant='tonal'
            color='secondary'
            type='reset'
            onClick={handleClose}
          >
            {t('mailTemplate.campaigns.modal.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateCampaignModal
