import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import i18next from 'i18next'
import { localizationAMChart, numberFormatter, setExportingMarketChangeChart, MARKET_CHANGE_CHART_TYPES } from '../../utils/AMChartsSettings'
import { CUSTOM_PALETTE_HEX } from '../../utils/AmChartsUtils'

export const initializeChart = (chartId, chartData, yearMonthVisibility, countryCodes, chartType) => {
  const root = am5.Root.new(chartId)
  localizationAMChart(root)

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      pinchZoomX: true,
      layout: root.verticalLayout,
      exportable: true
      // maxTooltipDistance: 0
    })
  )
  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      // strictMinMax: true,
      extraTooltipPrecision: 1,
      renderer: am5xy.AxisRendererY.new(root, {}),
      numberFormat: numberFormatter(chartType)
    })
  )

  const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 })
  xRenderer.labels.template.setAll({
    rotation: -20,
    minGridDistance: 10,
    fontSize: 10
  })

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: 'month', count: 1 },
      renderer: xRenderer,
      zoomX: false,
      startLocation: 0,
      endLocation: 1
    })
  )

  function createSeries (data, name, field, tooltip, key) {
    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: data[0]?._countryName + name,
        xAxis,
        yAxis,
        valueYField: field,
        valueXField: 'date',
        calculateAggregates: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          getFillFromSprite: true,
          labelText: `[bold]{name}[/]\n [bold]{${tooltip}}[/]`
        }),
        stroke: CUSTOM_PALETTE_HEX[key],
        exportable: true
      })
    )

    series.set('fill', CUSTOM_PALETTE_HEX[key])

    if (field === 'monthlyValue') series.strokes.template.setAll({ strokeWidth: 3, strokeDasharray: 3 })
    else series.strokes.template.setAll({ strokeWidth: 3 })

    // series.get('tooltip').label.set('text', `[bold]{name}[/]\n{valueX.formatDate()}: {${'tooltip'}}`)
    data ? series.data.setAll(data) : series.data.setAll(COMTRADE_MARKET_CHANGE_CHART_DUMMY_DATA)

    if (countryCodes?.includes(data[0]?._country) && series.get('visible')) {
      series.show()
    } else {
      series.hide()
    }
  }

  if (chartData?.length) {
    switch (chartType) {
      case MARKET_CHANGE_CHART_TYPES.PERCENTAGE:
        chartData.forEach((data, key) => {
          if (yearMonthVisibility.year && data[0]?._country !== null) createSeries(data, ` (${i18next.t('yearly')})`, 'yearlyValue', 'yearly', key)
          if (yearMonthVisibility.month && data[0]?._country !== null) createSeries(data, ` (${i18next.t('monthly')})`, 'monthlyValue', 'monthly', key)
        })
        break
      case MARKET_CHANGE_CHART_TYPES.QUANTITY:
        chartData.forEach((data, key) => {
          createSeries(data, '', 'rawValue', 'raw', key)
        })
        break
      case MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY:
        chartData.forEach((data, key) => {
          createSeries(data, '', 'rawValue', 'raw', key)
        })
        break
      default:
        break
    }
  } else createSeries(COMTRADE_MARKET_CHANGE_CHART_DUMMY_DATA, '_country', 'value')

  chart.set('cursor', am5xy.XYCursor.new(root, {
    behavior: 'zoomXY',
    xAxis
  }))

  xAxis.set('tooltip', am5.Tooltip.new(root, {
    themeTags: ['axis']
  }))

  // let legend = chart.children.push(am5.Legend.new(root, {}))
  //
  // legend.data.setAll(chart.series.values)

  setExportingMarketChangeChart(chartData, chartId)

  return () => {
    root.dispose()
  }
}

export const COMTRADE_MARKET_CHANGE_CHART_DUMMY_DATA = [
  {
    date: new Date(2021, 0, 1).getTime(),
    value: 1,
    value2: 2.5,
    value3: 3.0,
    value4: 4.0,
    value5: 5.0
  }, {
    date: new Date(2021, 0, 2).getTime(),
    value: 3,
    value2: 2.1,
    value3: 4.0,
    value4: 4.0,
    value5: 6.0
  }, {
    date: new Date(2021, 0, 3).getTime(),
    value: 2,
    value2: 3,
    value3: 1,
    value4: 4.0,
    value5: 7.0
  }, {
    date: new Date(2021, 0, 4).getTime(),
    value: 1,
    value2: 2,
    value3: 2.5,
    value4: 1.5,
    value5: 8.0
  }, {
    date: new Date(2021, 0, 5).getTime(),
    value: 1.5,
    value2: 0.5,
    value3: 2.0,
    value4: 5.0,
    value5: 9.0
  }, {
    date: new Date(2021, 0, 6).getTime(),
    value: 1.1,
    value2: 1.5,
    value3: 2.5,
    value4: 6.0,
    value5: 10.0
  }, {
    date: new Date(2021, 0, 7).getTime(),
    value: 1.1,
    value2: 1.8,
    value3: 3.0,
    value4: 7.0,
    value5: 11.0
  }]
