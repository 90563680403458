import React, { forwardRef, useImperativeHandle } from 'react'
import {
  useDataStoreActions, useDSfilteredImporterTable,
  useDSimporterTableExplanation,
  useDSglobalParams,
  useDSimporterSize,
  useDSunits
} from '../../../store/dataStore'
import CONSTANTS from '../../../utils/Constants'
import { useTranslation } from 'react-i18next'
import { useFilterStoreActions } from '../../../store/filterStore'
import McPageHelpers from '../McPageHelpers'
import McCardTF from '../mc-table-with-filters/McCardTF'
import { FirmCard } from '../../../components/Component'
import { usePDFselectedComponentIds } from '../../../store/pdfStore'

const ImporterTableSection = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const { resetImporterFilters } = useFilterStoreActions()

  const filteredImporterTable = useDSfilteredImporterTable()
  const importerTableExplanation = useDSimporterTableExplanation()
  const globalParams = useDSglobalParams()
  const importerSize = useDSimporterSize()
  const units = useDSunits()
  const {
    setShowFirmDetailsModal
  } = useDataStoreActions()

  const selectedComponentIds = usePDFselectedComponentIds()

  const generateColumnBySelectedUnit = (row, paramName, localeType) => {
    let number
    switch (globalParams.selectedUnit) {
      case McPageHelpers.UNITS.QUANTITY_1:
        number = row[paramName + '1']
        break
      case McPageHelpers.UNITS.QUANTITY_2:
        number = row[paramName + '2']
        break
      case McPageHelpers.UNITS.QUANTITY_3:
        number = row[paramName + '3']
        break
      case McPageHelpers.UNITS.QUANTITY_4:
        number = row[paramName + '4']
        break
      default:
        break
    }
    return McPageHelpers.numToLocaleString(number, localeType)
  }

  const key = 'hsTotalQuantity' + globalParams.selectedUnitNumber

  const columns = [
    {
      name: (<div style={{ marginLeft: '14.4px' }}>{t('table_data_table_column_ranking')}</div>),
      selector: row => row.id + 1,
      center: true,
      right: true,
      sortable: true,
      width: '6%'
    },
    {
      name: (<div style={{ textAlign: 'center' }}>{t('table_suitable_importer_name')}</div>),
      selector: row => row.importerName,
      wrap: true,
      left: true,
      width: '20%',
      cell: row => (
        <>
          <div
            data-tag="allowRowEvents">{row.importerName?.length > 40 ? row.importerName.slice(0, 40) + '...' : row.importerName}</div>
          <div className="position-fixed" id={'firmcard' + row.id} style={{
            zIndex: '999',
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.1s linear 1s'
          }}>
            <FirmCard id={'spFirm_' + row.id} firmName={row.importerName}
                      helpText={row.web} hsTotalValue={row.hsTotalValue} growth={row.growth}
                      hsTotalQuantity={row[key]}
                      numberOfShipments={row.numberOfShipments}
                      tableName={McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES}
                      growthValue={row.growthValue}
                      yearChartData={row.yearChartData}
            />
          </div>
        </>
      )
    },
    {
      name: (<div style={{ width: '100px', maxWidth: '100px', textAlign: 'center' }}>{t('table_web')}</div>),
      selector: row => McPageHelpers.generateCardHyperlink(row),
      wrap: true,
      left: true,
      width: '10%'
    },
    {
      name: (<div style={{
        width: '100px',
        maxWidth: '100px',
        textAlign: 'center'
      }}>{globalParams.hsCode} {t('table_hs_total_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.hsTotalValue, 'currency').slice(0, -3),
      right: true,
      sortable: true,
      wrap: true,
      width: '9%'
    },
    {
      name: (<div style={{
        width: '100px',
        maxWidth: '100px',
        textAlign: 'center',
        marginLeft: '14.4px'
      }}>{t('table_sp_total_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.totalValue, 'currency').slice(0, -3),
      right: true,
      sortable: true,
      wrap: true,
      width: '10%'
    },
    {
      name: (<div style={{
        width: '75px',
        maxWidth: '75px',
        textAlign: 'center',
        marginLeft: '14.4px'
      }}>{t('table_market_share')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.marketShare, 'percent', true),
      right: true,
      sortable: true,
      wrap: true,
      width: '7%'
    },
    {
      name: (<div
        style={{
          width: '100px',
          maxWidth: '100px',
          textAlign: 'center',
          marginLeft: '14.4px'
        }}>{t('table_sp_number_of_shipments')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.numberOfShipments),
      right: true,
      sortable: true,
      wrap: true,
      width: '6%'
    },
    {
      name: (<div style={{
        width: '120px',
        maxWidth: '120px',
        textAlign: 'center',
        marginLeft: '14.4px'
      }}>{t('table_avg_price_quantity')}</div>),
      selector: row => generateColumnBySelectedUnit(row, 'avgPrice', 'currency'),
      right: true,
      sortable: true,
      wrap: true,
      width: '8%'
    },
    {
      name: (<div style={{
        width: '100px',
        maxWidth: '100px',
        textAlign: 'center',
        marginLeft: '14.4px'
      }}>{globalParams.hsCode} {t('table_hs_total_quantity')} ({units[globalParams.selectedUnit]?.substr(0, 3) === '***' ? units[globalParams.selectedUnit] : t(`units.${units[globalParams.selectedUnit]?.toLowerCase()}`)})</div>),
      selector: row => generateColumnBySelectedUnit(row, 'hsTotalQuantity'),
      right: true,
      sortable: true,
      wrap: true,
      width: '7%'
    },
    {
      name: (<div style={{
        overflowWrap: 'normal',
        textAlign: 'center',
        paddingRight: '14.4px',
        paddingLeft: '14.4px'
      }}>{t('table_sector_list')}</div>),
      selector: row => (row.sector_list.map(item => item.sector?.substr(0, 3) === '***' ? item.sector : t(`sectors.${item.sector}`))).join(', '),
      minWidth: '10px',
      left: true,
      width: '12%'
    },
    {
      name: (<div style={{
        overflowWrap: 'normal',
        textAlign: 'center'
      }}>{t('table_sp_growth')}</div>),
      selector: row => row.growth,
      center: true,
      width: '5%'
    }
  ]

  const onRowClicked = (id) => {
    setShowFirmDetailsModal(true, id, McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES)
  }
  const columnsForPdf = columns.filter(column => column.name.props.children !== t('table_sector_list'))

  useImperativeHandle(ref, () => ({
    generateImporterTablePdf (id) {
      if (selectedComponentIds.includes(id)) {
        const result = [
          McPageHelpers.generateDataTableContentForPdf(columnsForPdf, filteredImporterTable?.slice(0, 15), true, 'after')
        ]
        if (Array.isArray(importerTableExplanation) && importerTableExplanation.length > 0) {
          const footerItems = importerTableExplanation.map((item, index) => ({
            text: item,
            fontSize: 10,
            margin: index !== importerTableExplanation.length - 1 ? [0, 0, 0, 10] : [0, 0, 0, 20],
            color: 'gray',
            italics: true
          }))
          result.push(...footerItems)
        }
        return result
      } else return null
    }
  }))

  return <McCardTF id={McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES}
                   code={CONSTANTS.BADGE_CODES.IMPORTER_TABLE_SECTION}
                   show={importerSize.raw}
                   header={t('importer_firms')}
                   sub={McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode}
                   rightTxt={t('ai_filter_desc') + ': ' + importerSize.filtered + ' / ' + importerSize.raw}
                   fncResetFilter={resetImporterFilters}
                   data={filteredImporterTable}
                   columns={columns}
                   onRowClicked={onRowClicked}
                   footer={importerTableExplanation}
  />
})

export default ImporterTableSection
