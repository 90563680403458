export const defaultSelectedIds = [
  'summaryTables',
  'importingCountry',
  'yearImporter',
  'monthImporter',
  'exportingCountry',
  'yearExporter',
  'monthExporter',
  'world',
  'st_world_year_importer',
  'st_world_month_importer',
  'st_world_year_exporter',
  'st_world_month_exporter',
  'change',
  'productAnalysis',
  'company_value',
  'only_hs_code',
  'company_count',
  'country_count',
  'sp_company_shipment_count',
  'sp_growth_table_head',
  'world_avg_price',
  'avg_line_graphic_title',
  'ai_sp_price_page_table_title',
  'ai_sp_static_hs_total_value_currency',
  'sp_filter_by_avg_price_hs_total',
  'avg_price_bar_title',
  'clustered_bar_chart_change_of_rates_title',
  'stacked_bar_chart_change_of_rates_title',
  'market_changes_table_title',
  'comtradeMarketChangeValueSearchedCountry',
  'comtradeMarketChangeValueSearchedCountryPercentage',
  'comtradeMarketChangeValueWorld',
  'comtradeMarketChangeValueWorldPercentage',
  'comtradeMarketChangeQuantitySearchedCountry',
  'comtradeMarketChangeQuantitySearchedCountryPercentage',
  'comtradeMarketChangeQuantityWorld',
  'comtradeMarketChangeQuantityWorldPercentage',
  'duties',
  'marketOverview',
  'market_overview_tabs_tab1',
  'market_overview_tabs_tab1_importing_country',
  'market_overview_tabs_tab1_importing_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab1_importing_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab1_importing_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab1_importing_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab1_importing_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab1_importing_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab1_importing_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab1_importing_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab1_exporting_country',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab1_exporting_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab1_world',
  'market_overview_tabs_tab1_world_sp_world_year_importer_table',
  'market_overview_tabs_tab1_world_sp_world_year_importer_graph',
  'market_overview_tabs_tab1_world_sp_world_month_importer_table',
  'market_overview_tabs_tab1_world_sp_world_month_importer_graph',
  'market_overview_tabs_tab1_world_sp_world_year_exporter_table',
  'market_overview_tabs_tab1_world_sp_world_year_exporter_graph',
  'market_overview_tabs_tab1_world_sp_world_month_exporter_table',
  'market_overview_tabs_tab1_world_sp_world_month_exporter_graph',
  'market_overview_tabs_tab2',
  'market_overview_tabs_tab2_importing_country',
  'market_overview_tabs_tab2_importing_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab2_importing_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab2_importing_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab2_importing_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab2_importing_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab2_importing_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab2_importing_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab2_importing_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab2_exporting_country',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab2_exporting_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab2_world',
  'market_overview_tabs_tab2_world_sp_world_year_importer_table',
  'market_overview_tabs_tab2_world_sp_world_year_importer_graph',
  'market_overview_tabs_tab2_world_sp_world_month_importer_table',
  'market_overview_tabs_tab2_world_sp_world_month_importer_graph',
  'market_overview_tabs_tab2_world_sp_world_year_exporter_table',
  'market_overview_tabs_tab2_world_sp_world_year_exporter_graph',
  'market_overview_tabs_tab2_world_sp_world_month_exporter_table',
  'market_overview_tabs_tab2_world_sp_world_month_exporter_graph',
  'market_overview_tabs_tab3',
  'market_overview_tabs_tab3_importing_country',
  'market_overview_tabs_tab3_importing_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab3_importing_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab3_importing_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab3_importing_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab3_importing_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab3_importing_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab3_importing_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab3_importing_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab3_exporting_country',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_year_exporter_table',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_year_exporter_graph',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_month_exporter_table',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_month_exporter_graph',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_year_importer_table',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_year_importer_graph',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_month_importer_table',
  'market_overview_tabs_tab3_exporting_country_sp_values_table_month_importer_graph',
  'market_overview_tabs_tab3_world',
  'market_overview_tabs_tab3_world_sp_world_year_importer_table',
  'market_overview_tabs_tab3_world_sp_world_year_importer_graph',
  'market_overview_tabs_tab3_world_sp_world_month_importer_table',
  'market_overview_tabs_tab3_world_sp_world_month_importer_graph',
  'market_overview_tabs_tab3_world_sp_world_year_exporter_table',
  'market_overview_tabs_tab3_world_sp_world_year_exporter_graph',
  'market_overview_tabs_tab3_world_sp_world_month_exporter_table',
  'market_overview_tabs_tab3_world_sp_world_month_exporter_graph',
  'importer_firms',
  'potential_firms',
  'graph_potential_analysis'
]

export const defaultSelectedComponentIds = [
  'summaryTables',
  'productAnalysis',
  'priceAnalysis',
  'marketChanges',
  'duties',
  'marketOverview',
  'importerCompanies',
  'tableImportYear',
  'tableImportMonth',
  'tableExportYear',
  'tableExportMonth',
  'tableWorldImportYear',
  'tableWorldImportMonth',
  'tableWorldExportYear',
  'tableWorldExportMonth',
  'summaryViewChangesGraph',
  'companyValueChart',
  'onlyHsCount',
  'companyCountChart',
  'countryCount',
  'companyShipmentCount',
  'growth',
  'annualAverageImportUnitPricesMapGraph',
  'priceAnalysisMultiValue',
  'priceTablePdfContent',
  'hsTotalImportCurrencyXFirmNum',
  'averagePriceXTotalImportCurrency',
  'averagePriceChart',
  'clusteredBarChart',
  'stackedBarChart',
  'marketChangesTable',
  'comtradeMarketChangeValueSearchedCountry',
  'comtradeMarketChangeValueSearchedCountryPercentage',
  'comtradeMarketChangeValueWorld',
  'comtradeMarketChangeValueWorldPercentage',
  'comtradeMarketChangeQuantitySearchedCountry',
  'comtradeMarketChangeQuantitySearchedCountryPercentage',
  'comtradeMarketChangeQuantityWorld',
  'comtradeMarketChangeQuantityWorldPercentage',
  'duties',
  'importerCountryYearlyVal',
  'MultipleBarChart-01',
  'importerCountryMonthlyVal',
  'MultipleLineChart-02',
  'destinationCountryCountryYearlyVal',
  'MultipleBarChart-DestinationCountry01',
  'destinationCountryCountryMonthlyVal',
  'MultipleBarChart-DestinationCountry02',
  'sourceCountryCountryYearlyVal',
  'MultipleBarChart-SourceCountry',
  'sourceCountryCountryMonthlyVal',
  'MultipleBarChart-DestinationCountry',
  'exporterCountryYearlyVal',
  'MultipleBarChart-03',
  'exporterCountryMonthlyVal',
  'MultipleLineChart-04',
  'worldImporterCountryYearlyVal',
  'ValueBarChart-09',
  'worldImporterCountryMonthlyVal',
  'ValueLineChart-10',
  'worldExporterCountryYearlyVal',
  'ValueBarChart-11',
  'worldExporterCountryMonthlyVal',
  'ValueLineChart-12',
  'importerCountryYearlyQuantity',
  'MultipleBarChart-07',
  'importerCountryMonthlyQuantity',
  'MultipleLineChart-08',
  'destinationCountryCountryYearlyQuantity',
  'MultipleBarChart-DestinationCountryQuantity01',
  'destinationCountryCountryMonthlyQuantity',
  'MultipleBarChart-DestinationCountryQuantity02',
  'sourceCountryCountryYearlyQuantity',
  'MultipleBarChart-SourceQuantityCountry',
  'sourceCountryCountryMonthlyQuantity',
  'MultipleBarChart-DestinationQuantityCountry',
  'exporterCountryYearlyQuantity',
  'MultipleBarChart-09',
  'exporterCountryMonthlyQuantity',
  'MultipleLineChart-10',
  'worldImporterCountryYearlyQuantity',
  'MultipleBarChart-11',
  'worldImporterCountryMonthlyQuantity',
  'MultipleLineChart-12',
  'worldExporterCountryYearlyQuantity',
  'QuantityBarChartWorld-21',
  'worldExporterCountryMonthlyQuantity',
  'QuantityLineChartWorld-22',
  'importerCountryYearlyAvg',
  'MultipleBarChart-13',
  'importerCountryMonthlyAvg',
  'MultipleLineChart-14',
  'destinationCountryCountryYearlyAvg',
  'MultipleBarChart-DestinationCountryAverage01',
  'destinationCountryCountryMonthlyAvg',
  'MultipleBarChart-DestinationCountryAverage02',
  'sourceCountryCountryYearlyAvg',
  'MultipleBarChart-SourceAverageCountry',
  'sourceCountryCountryMonthlyAvg',
  'MultipleBarChart-DestinationAverageCountry',
  'exporterCountryYearlyAvg',
  'MultipleBarChart-15',
  'exporterCountryMonthlyAvg',
  'MultipleLineChart-16',
  'worldImporterCountryYearlyAvg',
  'MultipleBarChart-17',
  'worldImporterCountryMonthlyAvg',
  'MultipleLineChart-18',
  'worldExporterCountryYearlyAvg',
  'MultipleBarChart-31',
  'worldExporterCountryMonthlyAvg',
  'MultipleLineChart-32',
  'importerTablePdfContent',
  'potentialTablePdfContent',
  'potentialCardMapGraph',
  'H-OT-01',
  'H-OT-02',
  'H-OT-03',
  'H-OT-04',
  'H-OT-05',
  'H-OT-06',
  'H-OT-07',
  'H-OT-08'
]
