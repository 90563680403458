import React, { useEffect } from 'react'
import ErrorImage from '../../images/gfx/error-404.svg'
import { Link } from 'react-router-dom'
import { Block, BlockContent, Button } from '../../components/Component'
import { useGlobalStoreData } from '../../store/globalStore'
import { useTranslation } from 'react-i18next'
import { useSearchDataStoreActions } from '../../store/searchDataStore'

const Error404Modern = () => {
  const { t } = useTranslation()
  const languageGSD = useGlobalStoreData.language()
  const { toggleVisibility } = useSearchDataStoreActions()

  useEffect(toggleVisibility, [])

  return (
    <>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img className="nk-error-gfx" src={ErrorImage} alt="error"/>
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">{t('404_error_header')}</h3>
            <p className="nk-error-text">
              {t('404_error_text')}
            </p>
            <Link to={`${process.env.PUBLIC_URL}/${languageGSD}`}>
              <Button color="primary" size="lg" className="mt-2">
                {t('back_to_home')}
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </>
  )
}
export default Error404Modern
