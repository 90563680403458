import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import MarketOverviewSettings from './MarketOverviewSettings'
import {
  CHART_TYPE,
  getCountryName,
  KEY,
  OVERVIEW_TAB_TYPE,
  TYPE
} from './MarketOverviewTable/MarketOverviewTable.settings'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../../store/globalStore'
import { useDSmarketOverviewData } from '../../../store/dataStore'
import { useSDSsearchParams, useSDSsearchTrigger } from '../../../store/searchDataStore'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'

const AveragePriceSection = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const searchTrigger = useSDSsearchTrigger()
  const searchParams = useSDSsearchParams()

  const marketOverviewData = useDSmarketOverviewData()
  const importer = marketOverviewData?.averagePrice?.importer
  const exporter = marketOverviewData?.averagePrice?.exporter
  const destinationCountry = marketOverviewData?.averagePrice?.destinationCountry
  const sourceCountry = marketOverviewData?.averagePrice?.sourceCountry
  const world = marketOverviewData?.averagePrice?.world

  const [activeAltTab, setActiveAltTab] = useState(OVERVIEW_TAB_TYPE.SOURCE)
  const toggleAltTab = (altTab) => {
    if (activeAltTab !== altTab) setActiveAltTab(altTab)
  }

  const importerCountryYearlyRef = useRef(null)
  const importerCountryMonthlyRef = useRef(null)
  const destinationCountryCountryYearlyRef = useRef(null)
  const destinationCountryCountryMonthlyRef = useRef(null)
  const exporterCountryYearlyRef = useRef(null)
  const exporterCountryMonthlyRef = useRef(null)
  const sourceCountryCountryYearlyRef = useRef(null)
  const sourceCountryCountryMonthlyRef = useRef(null)
  const worldImporterCountryYearlyRef = useRef(null)
  const worldImporterCountryMonthlyRef = useRef(null)
  const worldExporterCountryYearlyRef = useRef(null)
  const worldExporterCountryMonthlyRef = useRef(null)

  useImperativeHandle(ref, () => ({
    generateTablesPdf () {
      return [
        ...importerCountryYearlyRef.current.generateTablePdf('importerCountryYearlyAvg'),
        ...importerCountryMonthlyRef.current.generateTablePdf('importerCountryMonthlyAvg'),
        ...destinationCountryCountryYearlyRef.current.generateTablePdf('destinationCountryCountryYearlyAvg'),
        ...destinationCountryCountryMonthlyRef.current.generateTablePdf('destinationCountryCountryMonthlyAvg'),

        ...exporterCountryYearlyRef.current.generateTablePdf('exporterCountryYearlyAvg'),
        ...exporterCountryMonthlyRef.current.generateTablePdf('exporterCountryMonthlyAvg'),
        ...sourceCountryCountryYearlyRef.current.generateTablePdf('sourceCountryCountryYearlyAvg'),
        ...sourceCountryCountryMonthlyRef.current.generateTablePdf('sourceCountryCountryMonthlyAvg'),

        ...worldImporterCountryYearlyRef.current.generateTablePdf('worldImporterCountryYearlyAvg'),
        ...worldImporterCountryMonthlyRef.current.generateTablePdf('worldImporterCountryMonthlyAvg'),
        ...worldExporterCountryYearlyRef.current.generateTablePdf('worldExporterCountryYearlyAvg'),
        ...worldExporterCountryMonthlyRef.current.generateTablePdf('worldExporterCountryMonthlyAvg')
      ]
    }
  }))

  return (
    <>
      <MarketCalculusCard className={props.null || searchTrigger ? 'loading-div' : 'mt-3'}>
        <Nav tabs className="nav-tabs-s2">
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.SOURCE })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.SOURCE)
              }}
            >
              {getCountryName(searchParams.countryToExport, countriesGSD, 'country_code')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.DESTINATION })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.DESTINATION)
              }}
            >
              {getCountryName(searchParams.yourCountry, countriesGSD, 'country_code')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.WORLD })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.WORLD)
              }}
            >
              {t('world')}
            </NavLink>
          </NavItem>
        </Nav>
      </MarketCalculusCard>

      <TabContent activeTab={activeAltTab}>
        <TabPane tabId={OVERVIEW_TAB_TYPE.SOURCE}>
          <MarketOverviewSettings
            ref={importerCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={importer?.time?.yearly}
            countryValues={importer?.country?.yearly}
            removeNullCols={importer?.country?.yearly ? importer?.country?.yearly[0]?.valuesByTimeTag[0] == null : false}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={importer?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-13"
            tableType={'avg'}
            tableCode="T-PG-25"
            graphicCode="G-PG-25"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={importerCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={importer?.time?.monthly}
            countryValues={importer?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={importer?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-14"
            tableType={'avg'}
            tableCode="T-PG-26"
            graphicCode="G-PG-26"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={destinationCountryCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={destinationCountry?.time?.yearly}
            countryValues={destinationCountry?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={destinationCountry?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-DestinationCountryAverage01"
            tableType={'avg'}
            tableCode="T-PG-27"
            graphicCode="G-PG-27"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={destinationCountryCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={destinationCountry?.time?.monthly}
            countryValues={destinationCountry?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={destinationCountry?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleBarChart-DestinationCountryAverage02"
            tableType={'avg'}
            tableCode="T-PG-28"
            graphicCode="G-PG-28"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
        </TabPane>
        <TabPane tabId={OVERVIEW_TAB_TYPE.DESTINATION}>
          <MarketOverviewSettings
            ref={exporterCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={exporter?.time?.yearly}
            countryValues={exporter?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={exporter?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-15"
            tableType={'avg'}
            tableCode="T-PG-29"
            graphicCode="G-PG-29"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={exporterCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={exporter?.time?.monthly}
            countryValues={exporter?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={exporter?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-16"
            tableType={'avg'}
            tableCode="T-PG-30"
            graphicCode="G-PG-30"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={sourceCountryCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={sourceCountry?.time?.yearly}
            countryValues={sourceCountry?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={sourceCountry?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-SourceAverageCountry"
            tableType={'avg'}
            tableCode="T-PG-31"
            graphicCode="G-PG-31"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={sourceCountryCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={sourceCountry?.time?.monthly}
            countryValues={sourceCountry?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={sourceCountry?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleBarChart-DestinationAverageCountry"
            tableType={'avg'}
            tableCode="T-PG-32"
            graphicCode="G-PG-32"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
        </TabPane>
        <TabPane tabId={OVERVIEW_TAB_TYPE.WORLD}>
          <MarketOverviewSettings
            ref={worldImporterCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.importer?.time?.yearly}
            countryValues={world?.importer?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={world?.importer?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-17"
            tableType={'avg'}
            tableCode="T-PG-33"
            graphicCode="G-PG-33"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
          <MarketOverviewSettings
            ref={worldImporterCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.importer?.time?.monthly}
            countryValues={world?.importer?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={world?.importer?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-18"
            tableType={'avg'}
            tableCode="T-PG-34"
            graphicCode="G-PG-34"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
          <MarketOverviewSettings
            ref={worldExporterCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.exporter?.time?.yearly}
            countryValues={world?.exporter?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={world?.exporter?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-31"
            tableType={'avg'}
            tableCode="T-PG-35"
            graphicCode="G-PG-35"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
          <MarketOverviewSettings
            ref={worldExporterCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.exporter?.time?.monthly}
            countryValues={world?.exporter?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('ai_sp_static_avg_price')}
            chartData={world?.exporter?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-32"
            tableType={'avg'}
            tableCode="T-PG-36"
            graphicCode="G-PG-36"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
        </TabPane>
      </TabContent>
    </>
  )
})

export default AveragePriceSection
