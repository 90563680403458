import TreeList, { Column, HeaderFilter, Item, SearchPanel, Toolbar } from 'devextreme-react/tree-list'
import React, { useCallback, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useSDSsearchParams } from '../../store/searchDataStore'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '../block/Block'
import BadgeCode from '../badge/BadgeCode'
import { EU_COUNTRY_CODES } from '../../utils/Constants'
import { camelToFlat } from '../../utils/Utils'

const McTreeList = ({
  id,
  properties: { data, columns, keyExpr, parentIdExpr, expandedKeys },
  badgeCode,
  title
}) => {
  const { t } = useTranslation()
  const list = useRef(null)
  const [text, setText] = useState(t('collapse_all'))
  const searchParams = useSDSsearchParams()
  const onExpandCollapse = useCallback(
    (e) => {
      const listInstance = list.current.instance
      const expanding = text === t('expand_all')
      if (expanding) {
        listInstance.option('expandedRowKeys', [])
        listInstance.option('autoExpandAll', false)
      } else {
        listInstance.option('expandedRowKeys', [])
        listInstance.option('autoExpandAll', true)
      }
      const newText = expanding ? t('collapse_all') : t('expand_all')
      setText(newText)
    },
    [text]
  )

  return !window.location.pathname.includes('/pdf') && (
    <TreeList
      id={id}
      ref={list}
      dataSource={data}
      showBorders={true}
      hoverStateEnabled={true}
      showRowLines={true}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      defaultExpandedRowKeys={expandedKeys}
      keyExpr={keyExpr}
      parentIdExpr={parentIdExpr}
      itemsExpr="items"
      autoExpandAll={true}
      onCellPrepared={(e) => {
        if (e.rowType === 'header') {
          const tooltips = {
            special: t('custom_special_info'),
            other: t('custom_other_info'),
            general: t('custom_general_info')
          }
          const tooltipText = tooltips[e.column.name]
          if (tooltipText) {
            e.cellElement.setAttribute('title', tooltipText)
          }
        }
      }}
    >
      <SearchPanel visible={true} width={'30vw'} placeholder={t('search')}/>
      <HeaderFilter visible={true}/>
      <Toolbar>
        <Item location="before">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className="left" tag="h5">
                  <BadgeCode color="info" text={badgeCode}/>
                  {title}
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </Item>
        <Item location="after">
          <Button className="btn btn-primary" onClick={onExpandCollapse}>{text}</Button>
        </Item>
        <Item name="exportButton"/>
        <Item name="searchPanel" location="center"/>
      </Toolbar>
      {columns?.map((column, index) => {
        const uniqueKey = `${column}_${index}`
        if (searchParams.countryToExport === 'US' && ['general', 'special', 'other'].includes(column)) {
          return <Column key={uniqueKey}
                         dataField={column}
                         caption={t('columns.' + column)}
                         cellRender={McPageHelpers.generateFootnotes}/>
        }
        if (EU_COUNTRY_CODES.includes(searchParams.countryToExport) && ['duty', 'legalBase', 'measureType'].includes(column)) {
          return <Column key={uniqueKey}
                         dataField={column}
                         caption={t('columns.' + column)}
                         cellRender={cell => cell?.value?.map((item, index) =>
                           (<p className="split-cell"
                               key={index}>{item && item.text ? item.text : item}</p>))}/>
        }
        if (searchParams.countryToExport === 'JO' && ['agreements', 'feesAndTaxes'].includes(column)) {
          return <Column key={uniqueKey}
                         dataField={column}
                         caption={t('columns.' + column)}
                         cellRender={(cell) => cell?.data?.[column]
                           ? <ul>{(Object.keys(cell?.data?.[column])?.map((item, index) => <li
                             key={index}>{item} :
                             {cell?.data?.[column]?.[item]}</li>))}</ul>
                           : null
                         }/>
        }
        if (searchParams.countryToExport === 'CA' && ['preferentialTariffs'].includes(column)) {
          return <Column key={uniqueKey}
                         dataField={column}
                         caption={t('columns.' + column)}
                         cellRender={(cell) => {
                           return cell?.data?.preferentialTariffs
                             ? <ul>{cell?.data?.preferentialTariffs?.map((item, index) => <li
                               key={index}>{item?.countryGroupList} :
                               {item?.dutyRate}</li>)}</ul>
                             : null
                         }
                         }/>
        }
        if (searchParams.countryToExport === 'LB' && ['complementaryNotes', 'tradeAgreements', 'governmentControls'].includes(column)) {
          return <Column key={uniqueKey}
                         dataField={column}
                         caption={t('columns.' + column)}
                         cellRender={(cell) => {
                           return cell?.data?.[column] && typeof cell?.data?.[column] === 'object'
                             ? <ul>{(Object.keys(cell?.data?.[column])?.map((item, index) => {
                               return <li
                                 key={index}>{item} :
                                 {(cell?.data?.[column][item]?.map((innerItem, innerIndex) =>
                                   <ul key={innerIndex}>{Object.keys(innerItem)?.map(innerItemKey => <li
                                     key={innerItemKey}>{`${camelToFlat(innerItemKey)}: ${innerItem?.[innerItemKey]}`}</li>)}
                                   </ul>
                                 ))}
                               </li>
                             }))}
                             </ul>
                             : null
                         }
                         }/>
        }
        if (column === 'memberCountryDuties') {
          return (
            <Column
              key={uniqueKey}
              dataField={column}
              cssClass="member-column"
              caption={McPageHelpers.findCountryName(searchParams.yourCountry)}
            />
          )
        }
        // eslint-disable-next-line no-useless-escape
        if (column.match(/[.,:\[\]]/)) {
          return <Column key={uniqueKey} calculateCellValue={rowData => rowData[column]}
                         caption={t('columns.' + column).replace('columns.', '')}/>
        }
        return <Column key={uniqueKey} dataField={column}
                       caption={t('columns.' + column).replace('columns.', '')}/>
      })}
    </TreeList>
  )
}
export default McTreeList
