import React from 'react'
import { Badge } from 'reactstrap'

const BadgeCode = ({ color, text }) => {
  return (
    <Badge color={color} className="me-2">
      {text}
    </Badge>
  )
}

export default BadgeCode
