import React, { useCallback, useEffect, useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
  ModalHeader
} from 'reactstrap'
import { includes } from 'lodash'
import { toast } from 'react-toastify'

import { useSDSisVisible, useSDSselectBoxData, useSearchDataStoreActions } from '../../store/searchDataStore'
import { useGlobalStoreData } from '../../store/globalStore'
import { useDataStoreActions } from '../../store/dataStore'
import { useEndpointStatusActions } from '../../store/endpointStatus'
import { useRawStoreActions } from '../../store/rawStore'

import { getUrlParamsObj } from '../../services/urlServices'
import McDataServices, { getSearchPanelImporters, aiHsSearch } from '../../services/McDataServices'
import CONSTANTS, { AI_HS_SEARCH_ERROR_TYPES, COUNTRY_ABBREVIATIONS } from '../../utils/Constants'
import McDataProcessor from '../../pages/mc-components/McDataProcessor'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import useOutsideClick from '../../hooks/useOutsideClick'

import { Icon, RSelect } from '../../components/Component'
import Toggle from '../../layout/sidebar/Toggle'
import ListMapDateControl from './ListMapDateControl'
import ListMapContent from './ListMapContent'
import AiHsSearchErrorModalHandler from '../errorModalHandler/AiHsSearchErrorModalHandler'

import './searchPanel.scss'

const labelStyles = {
  fontSize: '.75rem',
  color: '#636b75',
  whiteSpace: 'nowrap'
}

const initial = {
  dropdownOptsState: {
    showHsCodeOptions: false,
    showCountryExportOptions: false,
    showYourCountryOptions: false
  },
  aiFinderState: {
    value: '',
    result: null,
    isResultOpen: false,
    loading: false
  },
  listMapStatus: {
    loading: false,
    isMonthly: false
  },
  values: {
    hsCode: [],
    countryToExport: null,
    yourCountry: null,
    productDesc: ''
  }
}

const SearchPanel = ({ ruleErrorModalRef }) => {
  const { t } = useTranslation()

  const isLoggedInGSD = useGlobalStoreData.isLoggedIn()
  const userGSD = useGlobalStoreData.user()
  const languageGSD = useGlobalStoreData.language()
  const localeGSD = useGlobalStoreData.locale()

  const { setEndpointStatus } = useEndpointStatusActions()

  const selectBoxData = useSDSselectBoxData()
  const isVisible = useSDSisVisible()
  const {
    setSearchParamsWithPrev,
    startSearching,
    setSelectBoxData,
    toggleVisibility
  } = useSearchDataStoreActions()

  const { resetData } = useDataStoreActions()

  const { resetRawStore } = useRawStoreActions()

  const searchCountrySet = userGSD?.rules?.find(item => item.type === 'SEARCH_COUNTRY_SET')
  const allowedCountries = searchCountrySet?.parameters?.allowedSearchCountries
  const isLoadingUserInfo = !userGSD?.rules ?? true
  const userCountryCode = userGSD?.countryCode

  const aiFinderResultRef = useRef()

  useOutsideClick(aiFinderResultRef, () => {
    setAiFinderStateByKey('isResultOpen', false)
  })

  // STATES
  const [values, setValues] = useState(initial.values)
  const [dropdownOptsState, setDropdownOptsState] = useState(initial.dropdownOptsState)
  const [aiFinderState, setAiFinderState] = useState(initial.aiFinderState)
  const [listMapStatus, setListMapStatus] = useState(initial.listMapStatus)
  const [isHsCodeEmpty, setIsHsCodeEmpty] = useState(false)
  const [hsFromAiFinder, setHsFromAiFinder] = useState([])
  const [listMapData, setListMapData] = useState(null)
  const [aiHsSearchErrorType, setAiHsSearchErrorType] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [initialized, setInitialized] = useState(false)

  const isSearchBtnEnabled = (!!values.countryToExport && !!values.yourCountry && !!values.hsCode.length)
  const aiFoundedOption = aiFinderState.result ? selectBoxData?.hsCode?.find((item) => item.value === aiFinderState.result) : null

  // STATE MANAGERS
  const initValues = () => {
    if (!initialized && selectBoxData?.hsCode?.length && selectBoxData?.coverageData?.length && selectBoxData?.countries?.length) {
      const predefinedUrlParams = getUrlParamsObj()

      setValues({
        ...predefinedUrlParams,
        hsCode: selectBoxData.hsCode.filter((x) => predefinedUrlParams?.hsCode?.includes(x.value)),
        countryToExport: selectBoxData.coverageData.find((x) => x.countryCode === predefinedUrlParams?.countryToExport),
        yourCountry: selectBoxData.countries.find((x) => x.countryCode === (predefinedUrlParams?.yourCountry || userCountryCode))
      })
      setInitialized(true)
    }
  }

  const setAiFinderStateByKey = (key, val) => {
    setAiFinderState((prevState) => ({
      ...prevState,
      [key]: val
    }))
  }

  const setValuesByKey = (key, val) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: val
    }))
  }

  const dropdownOptsStateParamChanger = useCallback((paramName, typedOption) => {
    setDropdownOptsState((prevState) => ({
      ...prevState, [paramName]: typedOption?.length >= 3
    }))
  }, [setDropdownOptsState])

  const handleHsCodeChange = (typedOption) => dropdownOptsStateParamChanger('showHsCodeOptions', typedOption)
  const handleExportChange = (typedOption) => dropdownOptsStateParamChanger('showCountryExportOptions', typedOption)
  const handleYourCountryChange = (typedOption) => dropdownOptsStateParamChanger('showYourCountryOptions', typedOption)

  const removeSelectedHs = (hs) => {
    setHsFromAiFinder((prevState) => prevState.filter((item) => item !== hs))
    setValuesByKey('hsCode', values.hsCode?.filter((item) => item.value !== hs))
  }

  // METHODS
  const handlePressEnterKey = (event, elementForFocus, input = false) => {
    if (event.keyCode === 13) {
      const button = document.getElementById('searchBtn')
      if (elementForFocus === 'searchBtn') {
        button.focus()
      } else if (!input) {
        document.getElementById(elementForFocus).getElementsByTagName('input')[0].focus()
      } else { document.getElementById(elementForFocus).focus() }
    }
  }

  const handleSearch = () => {
    const generatedSearchParams = {
      ...values,
      hsCode: values?.hsCode?.map((x) => x?.value)?.join(','),
      countryToExport: values?.countryToExport?.countryCode,
      yourCountry: values?.yourCountry?.countryCode,
      dataCoverage: values?.countryToExport?.dataCoverage
    }

    setSearchParamsWithPrev(generatedSearchParams)

    setEndpointStatus('errorMessage')
    toggleVisibility()
    resetData()
    resetRawStore()
    startSearching()
  }

  const getHsByAiFinder = (searchTerm) => {
    setAiFinderStateByKey('loading', true)

    aiHsSearch({
      queryString: searchTerm
    })
      .then(data => {
        if (data?.length && data?.[0]?.value) {
          setAiFinderState((prevState) => ({
            ...prevState,
            result: data[0].value,
            isResultOpen: true
          }))
        } else {
          toast.error(t('aiHsSearchErrors.notFoundToastErr'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })
        }
      })
      .catch((err) => {
        if (err?.response?.data?.type === 'ThrottlingException') {
          setAiHsSearchErrorType(AI_HS_SEARCH_ERROR_TYPES.THROTTLING)
        } else if (err?.response?.data?.type === 'HsCodeAiSearchDisabled') {
          setAiHsSearchErrorType(AI_HS_SEARCH_ERROR_TYPES.SEARCH_DISABLED)
        } else if (err?.response?.status === 401) {
          setAiHsSearchErrorType(AI_HS_SEARCH_ERROR_TYPES.ANONYMOUS_USER)
        } else {
          toast.error(t('aiHsSearchErrors.toastErr'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })
        }
      })
      .finally(() => {
        setAiFinderStateByKey('loading', false)
      })
  }

  const onDateChange = (date, isMonthly) => {
    const year = date.getFullYear()
    const changedDate = {}

    if (isMonthly) {
      const month = date.getMonth() + 1// +1 for backend dates

      const yearMonth = `${year}${('0' + month.toString()).slice(-2)}`

      changedDate.worldImporterYearMonth = yearMonth
    } else {
      changedDate.worldImporterYear = year
    }

    setListMapStatus((prevState) => ({
      ...prevState,
      loading: true
    }))

    getSearchPanelImporters({
      hsCodes: values?.hsCode?.map((item) => item.value).join(','),
      ...selectedDates,
      ...changedDate
    })
      .then(data => {
        setSelectedDates({
          worldImporterYear: data.comtradeSummary.worldImporterYear,
          worldImporterYearMonth: data.comtradeSummary.worldImporterYearMonth
        })
        McDataProcessor.generateAndSetSummaryTablesSectionData(data.comtradeSummary, setListMapData)
      })
      .finally(() => {
        setListMapStatus((prevState) => ({
          ...prevState,
          loading: false
        }))
      })
  }

  const checkAndSetCountryToExport = (countryOpt, fireSuccesToast = false) => {
    if (allowedCountries && !allowedCountries.includes(countryOpt.countryCode) && !McPageHelpers.findCountryName(allowedCountries).includes(CONSTANTS.WORLD_COUNTRY_CODE)) {
      ruleErrorModalRef.current.fireErrorModal(
        t('error_search_country_set_text', { exporter: McPageHelpers.findCountryName(countryOpt.countryCode) }),
        allowedCountries && allowedCountries.map((obj, index) => <span key={index}>{McPageHelpers.findCountryName(obj)}<br/></span>)
      )
    } else {
      setValuesByKey('countryToExport', countryOpt)

      fireSuccesToast && toast.success(t('importerSelectedToast', { countryName: McPageHelpers.findCountryName(countryOpt.countryCode) }), {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false
      })
    }
  }

  // LIFECYCLE
  useEffect(() => {
    if (isLoggedInGSD === true) {
      McDataServices.getHsCodes().then(result => setSelectBoxData('hsCode', result))
      McDataServices.getCountries().then(result => {
        COUNTRY_ABBREVIATIONS.forEach(item => { // adds country abbreviations for autocomplete search
          result.search_countries.find(country => country.countryCode === item.code).abbreviation = item[languageGSD]
          result.user_countries.find(country => country.countryCode === item.code).abbreviation = item[languageGSD]
        })

        setSelectBoxData('coverageData', result.search_countries)
        setSelectBoxData('countries', result.user_countries)
      })
    }
  }, [languageGSD])

  useEffect(() => {
    if (values.hsCode?.length) {
      setListMapStatus((prevState) => ({
        ...prevState,
        loading: true
      }))

      getSearchPanelImporters({
        hsCodes: values?.hsCode?.map((item) => item.value).join(','),
        ...selectedDates
      })
        .then(data => {
          setSelectedDates({
            worldImporterYear: data.comtradeSummary.worldImporterYear,
            worldImporterYearMonth: data.comtradeSummary.worldImporterYearMonth
          })
          McDataProcessor.generateAndSetSummaryTablesSectionData(data.comtradeSummary, setListMapData)
        })
        .finally(() => {
          setListMapStatus((prevState) => ({
            ...prevState,
            loading: false
          }))
        })
    } else {
      setListMapData(null)
    }
  }, [values.hsCode])

  useEffect(() => {
    initValues()
  }, [selectBoxData])

  if (isLoadingUserInfo) return
  return (
    <>
      <Toggle
        className="nk-nav-toggle nk-quick-nav-icon"
        icon={'chevron-right-c'}
        click={toggleVisibility}
        title={t('searching_parameters')}
      />
      <Modal isOpen={isVisible} toggle={toggleVisibility} size="xl" centered={true} fade={true} scrollable={false} className='searchPanel'>
        <ModalHeader toggle={toggleVisibility}>{t('searching_parameters')}</ModalHeader>
        <ModalBody className='py-1 px-2'>
          <Row>
            <Col lg="4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', height: 127 }}>
              <Label htmlFor="aiFinder" className="form-label mb-0" style={labelStyles}>
                {t('ai_hs_search')}
                <Icon
                  id='aiHsSearchInfoIcon'
                  name='info'
                  style={{
                    marginLeft: 2,
                    fontSize: 14
                  }}
                />
              </Label>

              <UncontrolledTooltip
                placement="top"
                target={'aiHsSearchInfoIcon'}
              >
                {t('ai_hs_search_info')}
              </UncontrolledTooltip>

              <div style={{ position: 'relative' }}>
                <div className="input-group mb-1">
                  <input
                    id='aiFinder'
                    type="text"
                    className="form-control"
                    placeholder={t('ai_hs_search_ph')}
                    value={aiFinderState.value}
                    onChange={(e) => {
                      setAiFinderState((prevState) => ({
                        ...prevState,
                        value: e.target.value,
                        result: null
                      }))
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                        getHsByAiFinder(aiFinderState.value)
                      }
                    }}
                    onClick={() => { aiFinderState.result && setAiFinderStateByKey('isResultOpen', true) }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary btn-dim p-1"
                      type="button"
                      onClick={() => {
                        getHsByAiFinder(aiFinderState.value)
                      }}
                      disabled={aiFinderState.loading}
                    >
                      {aiFinderState.loading
                        ? <div
                            className="spinner-border"
                            style={{
                              width: 22,
                              height: 22,
                              margin: 0
                            }}
                          />
                        : <Icon
                            name='search'
                            style={{
                              fontSize: 22,
                              width: 22,
                              height: 22
                            }}
                          />
                      }
                    </button>
                  </div>
                </div>
                {(aiFinderState.result && aiFinderState.isResultOpen) &&
                  <div
                    ref={aiFinderResultRef}
                    style={{
                      display: 'flex',
                      height: 'auto',
                      overflow: 'hidden',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      border: '1px solid #dbdfea',
                      backgroundColor: '#ffffff',
                      borderRight: 'none',
                      borderRadius: '4px',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '100%',
                      zIndex: 2
                    }}
                  >
                    <div className='p-1' style={{ overflowY: 'auto' }}>
                      <span className="d-block w-100 lh-sm">{aiFinderState.result}</span>
                      <span className="d-block w-100 lh-1" style={{ fontSize: 12 }}>
                        {selectBoxData?.hsCode?.find((item) => item.value === aiFinderState.result)?.label}
                      </span>
                    </div>
                    <button
                      className="btn btn-outline-primary btn-dim p-1"
                      type="button"
                      onClick={() => {
                        if (!includes(values.hsCode, aiFoundedOption)) {
                          setValuesByKey('hsCode', [...values.hsCode, aiFoundedOption])
                          setHsFromAiFinder((prevState) => prevState.includes(aiFinderState.result) ? prevState : prevState.concat([aiFinderState.result]))
                          setAiFinderStateByKey('isResultOpen', false)
                        }
                      }}
                      disabled={includes(values.hsCode, aiFoundedOption)}
                    >
                      <Icon
                        name='plus'
                        style={{
                          fontSize: 22,
                          width: 22,
                          height: 22,
                          cursor: 'pointer'
                        }}
                      />
                    </button>
                  </div>
                }
              </div>

              <Label className="form-label mb-0" style={labelStyles}>
                {t('hs_code')}
              </Label>
              <RSelect
                id="hsCode"
                options={dropdownOptsState.showHsCodeOptions ? selectBoxData.hsCode.filter((x) => !includes(values.hsCode, x)) : []}
                isLoading={!selectBoxData.hsCode?.length}
                isMulti
                placeholder={t('hs_code')}
                formatOptionLabel={(opt) => {
                  if (includes(values.hsCode, opt)) {
                    return (
                      <div>
                        <div style={{ color: 'red' }}>{opt.value}</div>
                      </div>
                    )
                  } else {
                    return (
                      <div>
                        <div className="format-option-label">
                          <span>{opt.label}</span>
                        </div>
                        <div style={{ color: '#ccc' }}>
                          {t('hs_code')}: {opt.value}
                        </div>
                      </div>
                    )
                  }
                }}
                noOptionsMessage={() => dropdownOptsState.showHsCodeOptions ? t('no_option') : t('min_3_char')}
                onInputChange={handleHsCodeChange}
                onChange={(selected) => {
                  setValues((prevState) => ({
                    ...prevState,
                    hsCode: prevState.hsCode.concat(selected)
                  }))
                }}
                onKeyDown={(event) => {
                  if (isHsCodeEmpty) {
                    handlePressEnterKey(event, 'countryToExport')
                  }
                  if (event.keyCode === 13) {
                    setIsHsCodeEmpty(true)
                  } else {
                    setIsHsCodeEmpty(false)
                  }
                }}
                value={[]}
              />
            </Col>
            <Col lg="8" style={{ display: 'flex', flexDirection: 'column', height: 127 }}>
              <div
                style={{
                  border: '1px solid #dbdfea',
                  borderRadius: '4px',
                  backgroundColor: '#ffffff',
                  overflowY: 'auto',
                  flexGrow: 1,
                  flex: '1 1 auto',
                  height: '0px',
                  display: 'flex',
                  alignItems: 'start',
                  alignContent: 'start',
                  flexWrap: 'wrap',
                  padding: '.180rem'
                }}
              >
                {(!!values?.hsCode?.length && typeof values?.hsCode === 'object') &&
                  values.hsCode.map((item, index) => (
                    <div
                      key={index}
                      className='d-flex justify-content-center align-items-center'
                      style={{ padding: '.180rem', width: '20%' }}
                    >
                      <div
                        id={`labelTooltip-${item.value}`}
                        className='p-1 d-flex justify-content-between w-100'
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #dbdfea',
                          backgroundColor: 'var(--bs-modal-bg)'
                        }}
                      >
                        <div className='flex-grow-1 text-nowrap overflow-hidden'>
                          <div className='d-flex align-items-center '>
                            {hsFromAiFinder.includes(item.value) &&
                              <span
                                style={{
                                  display: 'inline-block',
                                  backgroundColor: '#0fc6b9',
                                  color: '#ffffff',
                                  fontSize: 8,
                                  borderRadius: '30%',
                                  padding: '0px 4px',
                                  marginRight: 2,
                                  fontWeight: 'bold'
                                }}
                              >AI</span>
                            }
                            <span
                              className="text-truncate lh-1"
                              style={{ fontSize: '.75rem', fontWeight: 'bold' }}
                            >
                              {item.value}
                            </span>
                          </div>
                          <span
                            className="d-block w-100 text-truncate lh-1"
                            style={{ fontSize: '.7rem' }}
                          >
                            {item.label}
                          </span>
                          <UncontrolledTooltip
                            placement="top"
                            target={`labelTooltip-${item.value}`}
                          >
                            {item.label}
                          </UncontrolledTooltip>
                        </div>
                        <Icon name='cross' role='button' onClick={() => { removeSelectedHs(item.value) }}/>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Col>

            <hr className='my-1'/>

            <Col lg='12'>
              <Row>
                <Col lg='4' className='d-flex flex-column justify-content-end'>
                  <div className='d-flex justify-content-between align-items-end mb-0'>
                    <Label className="form-label mb-0" style={labelStyles}>
                      {t('importing_country')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <RSelect
                      id="countryToExport"
                      options={dropdownOptsState.showCountryExportOptions ? selectBoxData.coverageData : []}
                      isLoading={!selectBoxData.coverageData?.length}
                      onInputChange={handleExportChange}
                      noOptionsMessage={() => dropdownOptsState.showCountryExportOptions ? t('no_option') : t('min_3_char')}
                      onChange={(selected) => {
                        checkAndSetCountryToExport(selected)
                      }}
                      onKeyDown={(event) => handlePressEnterKey(event, 'yourCountry')}
                      filterOption={(option, query) => String(option.data.label + ' ' + (option.data.abbreviation ?? ''))
                        .toLocaleLowerCase(localeGSD)
                        .includes(query.toLocaleLowerCase(localeGSD))
                      }
                      value={values.countryToExport}
                    />
                  </div>
                </Col>
                <Col lg='4' className='d-flex flex-col justify-content-end'>
                  <Label className="form-label mt-1  mb-0" style={labelStyles}>
                    {t('exporting_country')}
                  </Label>
                  <div className="form-control-wrap">
                    <RSelect
                      id="yourCountry"
                      options={dropdownOptsState.showYourCountryOptions ? selectBoxData.countries : []}
                      isLoading={!selectBoxData.countries?.length}
                      noOptionsMessage={() => dropdownOptsState.showYourCountryOptions ? t('no_option') : t('min_3_char')}
                      onInputChange={handleYourCountryChange}
                      onChange={(selected) => { setValuesByKey('yourCountry', selected) }}
                      onKeyDown={(event) => handlePressEnterKey(event, 'productDesc', true)}
                      filterOption={(option, query) => String(option.data.label + ' ' + (option.data.abbreviation ?? ''))
                        .toLocaleLowerCase(localeGSD)
                        .includes(query.toLocaleLowerCase(localeGSD))
                      }
                      value={values.yourCountry}
                    />
                  </div>
                </Col>
                <Col lg='4' className='d-flex flex-col justify-content-end'>
                  <div className="form-group">
                    <Label htmlFor="productDesc" className="form-label mt-1 mb-0" style={labelStyles}>
                      {t('product_desc')}
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        id="productDesc"
                        className="form-control"
                        placeholder={t('product_desc')}
                        onChange={(event) => { setValuesByKey('productDesc', event.target.value) }}
                        onKeyDown={(event) => handlePressEnterKey(event, 'searchBtn')}
                        value={values.productDesc}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <hr className='my-1'/>

            <Col>
              <ListMapDateControl
                selectedDates={selectedDates}
                loading={listMapStatus.loading}
                monthlyText={t('monthly')}
                yearlyText={t('yearly')}
                isMonthly={listMapStatus.isMonthly}
                setIsMonthly={(val) => {
                  setListMapStatus((prevState) => ({ ...prevState, isMonthly: val }))
                }}
                onChange={onDateChange}
              />
              <ListMapContent
                data={listMapData?.world?.import?.[listMapStatus.isMonthly ? 'monthly' : 'yearly']}
                loading={listMapStatus.loading}
                isTableSkeleton={!values.hsCode?.length}
                onClick={(countryCode) => {
                  if (countryCode) {
                    if (countryCode === 'GB') {
                      countryCode = 'UK'
                    } else if (countryCode === 'XK') {
                      countryCode = 'KV'
                    }
                    const clickedCountryObject = selectBoxData.coverageData?.find((x) => x.countryCode === countryCode)
                    checkAndSetCountryToExport(clickedCountryObject, true)
                  }
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='p-1'>
          <div className="d-flex justify-around flex-column align-center w-100">
            <Button
              id="searchBtn"
              className="btn-dim btn-block"
              color="primary"
              onClick={handleSearch}
              disabled={!isSearchBtnEnabled}
            >
              <Icon name="search"/>
              <span>{t('search')}</span>
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <AiHsSearchErrorModalHandler
        errorType={aiHsSearchErrorType}
        clearErrorState={() => { setAiHsSearchErrorType(null) }}
      />
    </>
  )
}

export default SearchPanel
