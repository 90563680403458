import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { uniqBy } from 'lodash'

// Component Imports
import Modal from './Modal'
import MultipleTextInput from '../atoms/MultipleTextInput'
import CustomSelect from '../atoms/CustomSelect'

import { emailRegExp } from '../../../utils/constants'

const emailValidation = (val) => emailRegExp.test(val)

const AdditionalEmailModal = (props) => {
  const {
    open,
    setOpen,
    additionalEmails,
    setAdditionalEmails
  } = props
  const { t } = useTranslation()

  const [unsavedAdditionalEmails, setUnsavedAdditionalEmails] = useState([])
  const [seperator, setSeperator] = useState(' ')

  const seperatorOpts = [
    {
      label: t('mailTemplate.wizard.firmList.additionalEmails.separatorOpts.space'),
      value: ' '
    },
    {
      label: t('mailTemplate.wizard.firmList.additionalEmails.separatorOpts.star'),
      value: '*'
    },
    {
      label: t('mailTemplate.wizard.firmList.additionalEmails.separatorOpts.comma'),
      value: ','
    },
    {
      label: t('mailTemplate.wizard.firmList.additionalEmails.separatorOpts.semicolon'),
      value: ';'
    }
  ]

  useEffect(() => {
    setUnsavedAdditionalEmails(additionalEmails)
  }, [additionalEmails])

  return (
    <Modal
      open={open}
      close={() => {
        setUnsavedAdditionalEmails(additionalEmails)
        setOpen(false)
      }}
      submit={() => {
        setAdditionalEmails(unsavedAdditionalEmails)
        setOpen(false)
      }}
      title={t('mailTemplate.wizard.firmList.additionalEmails.title')}
      subTitle={t('mailTemplate.wizard.firmList.additionalEmails.subTitle')}
      submitText={t('mailTemplate.wizard.firmList.additionalEmails.submitText')}
      cancelText={t('mailTemplate.wizard.firmList.additionalEmails.cancelText')}
    >
      <CustomSelect
        fullWidth
        options={seperatorOpts}
        onChange={(e) => {
          setSeperator(e.target.value)
        }}
        labelKey='label'
        valueKey='value'
        label={t('mailTemplate.wizard.firmList.additionalEmails.separatorLabel')}
        defaultValue=' '
        className='mb-2'
      />
      <MultipleTextInput
        label={t('mailTemplate.wizard.firmList.additionalEmails.emailListLabel')}
        textFieldProps={{
          sx: {
            '& .MuiInputBase-root': {
              maxHeight: 300,
              overflow: 'hidden',
              overflowY: 'auto !important'
            }
          }
        }}
        value={unsavedAdditionalEmails}
        onChange={(e, val) => {
          if (e.target.value === undefined || emailValidation(e.target.value)) {
            setUnsavedAdditionalEmails(val)
          } else {
            const valuesWithSpace = e.target.value?.split(seperator)
            if (valuesWithSpace?.length) {
              const validEmailList = valuesWithSpace.filter((item) => emailValidation(item))
              setUnsavedAdditionalEmails((prevState) => uniqBy(prevState.concat(validEmailList)))
            }
          }
        }}
      />
    </Modal>
  )
}

export default AdditionalEmailModal
