import { create } from 'zustand'
import {
  COUNT_FILTERS as CF,
  HAS_MIN_MAX_IDS,
  FILTER_BUTTON_IDS
} from '../pages/mc-components/McPageHelpers'

const useFilterStore = create((set) => ({
  filterType: '',
  filtering: false,
  importerFilters: {
    importer: CF.ALL_OR_EMPTY,
    supplier: CF.ALL_OR_EMPTY,
    country: CF.ALL_OR_EMPTY,
    hsCode: CF.ALL_OR_EMPTY,
    isLogistic: CF.ALL_OR_EMPTY,
    growth: CF.ALL_OR_EMPTY,
    numberOfShipments: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY
    },
    hsCount: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY
    },
    distinctCountry: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY
    },
    hsTotalImportCurrencyXFirmNum: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY,
      doFilter: false
    },
    averagePriceXTotalImportCurrency: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY,
      doFilter: false
    },
    averagePriceChart: {
      min: CF.ALL_OR_EMPTY,
      max: CF.ALL_OR_EMPTY,
      doFilter: false
    }
  },
  potentialFilters: {
    importer: CF.ALL_OR_EMPTY,
    supplier: CF.ALL_OR_EMPTY,
    country: CF.ALL_OR_EMPTY,
    hsCode: CF.ALL_OR_EMPTY
  },
  outliers: {
    hsTotalImportCurrencyXFirmNum: false,
    averagePriceXTotalImportCurrency: false,
    averagePriceChart: false,
    hsCodeChart: false,
    averagePriceXTotalImportInterest: false
  },
  actions: {
    setOutliers: (paramName, value) => {
      set((prevState) => ({
        outliers: {
          ...prevState.outliers,
          [paramName]: value
        }
      }))
    },
    setImporterFilters: (paramName, data, separateMinMax = false, minOrMax) => {
      if (HAS_MIN_MAX_IDS.includes(paramName)) {
        if (!separateMinMax) {
          set((prevState) => ({
            importerFilters: {
              ...prevState.importerFilters,
              [paramName]: {
                min: prevState.importerFilters[paramName].min === data.min ? CF.ALL_OR_EMPTY : data.min,
                max: prevState.importerFilters[paramName].max === data.max ? CF.ALL_OR_EMPTY : data.max
              }
            }
          }))
        } else if (separateMinMax) {
          set((prevState) => ({
            importerFilters: {
              ...prevState.importerFilters,
              [paramName]: {
                ...prevState.importerFilters[paramName],
                [minOrMax]: data
              }
            }
          }))
        }
      } else if (paramName === FILTER_BUTTON_IDS.GROWTH) {
        set((prevState) => ({
          importerFilters: {
            ...prevState.importerFilters,
            growth: prevState.importerFilters.growth === data ? CF.ALL_OR_EMPTY : data
          }
        }))
      } else {
        set((prevState) => ({
          importerFilters: {
            ...prevState.importerFilters,
            [paramName]: data
          }
        }))
      }
      setTimeout(() => set((prevState) => ({ ...prevState, filtering: true, filterType: 'importer' })), 300)
    },
    setPotentialFilters: (paramName, data) => {
      set((prevState) => ({
        potentialFilters: {
          ...prevState.potentialFilters,
          [paramName]: data
        }
      }))
      setTimeout(() => set((prevState) => ({ ...prevState, filtering: true, filterType: 'potential' })), 300)
    },
    resetImporterFilters: () => {
      set((prevState) => ({
        importerFilters: {
          ...prevState.importerFilters,
          importer: CF.ALL_OR_EMPTY,
          supplier: CF.ALL_OR_EMPTY,
          country: CF.ALL_OR_EMPTY,
          hsCode: CF.ALL_OR_EMPTY,
          isLogistic: false,
          growth: CF.ALL_OR_EMPTY,
          numberOfShipments: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY
          },
          hsCount: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY
          },
          distinctCountry: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY
          },
          hsTotalImportCurrencyXFirmNum: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY,
            doFilter: false
          },
          averagePriceXTotalImportCurrency: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY,
            doFilter: false
          },
          averagePriceChart: {
            min: CF.ALL_OR_EMPTY,
            max: CF.ALL_OR_EMPTY,
            doFilter: false
          }
        },
        outliers: {
          hsTotalImportCurrencyXFirmNum: false,
          averagePriceXTotalImportCurrency: false,
          averagePriceChart: false,
          hsCodeChart: false,
          averagePriceXTotalImportInterest: false
        }
      }))
      setTimeout(() => set((prevState) => ({ ...prevState, filtering: true, filterType: 'importer' })), 300)
    },
    resetPotentialFilters: () => {
      set((prevState) => ({
        potentialFilters: {
          ...prevState.potentialFilters,
          importer: CF.ALL_OR_EMPTY,
          supplier: CF.ALL_OR_EMPTY,
          country: CF.ALL_OR_EMPTY,
          hsCode: CF.ALL_OR_EMPTY
        }
      }))
      setTimeout(() => set((prevState) => ({ ...prevState, filtering: true, filterType: 'potential' })), 300)
    },
    setFiltering: (filtering) => {
      set((prevState) => prevState.filtering !== filtering ? { filtering, filterType: '' } : prevState)
    }
  }
}))

export const useFSfilterType = () => useFilterStore((state) => state.filterType)
export const useFSfiltering = () => useFilterStore((state) => state.filtering)
export const useFSimporterFilters = () => useFilterStore((state) => state.importerFilters)
export const useFSpotentialFilters = () => useFilterStore((state) => state.potentialFilters)
export const useFSoutliers = () => useFilterStore((state) => state.outliers)

export const useFilterStoreActions = () => useFilterStore((state) => state.actions)

export default useFilterStore
