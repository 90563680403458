import { create } from 'zustand'

import { initialRawStore } from './initialStates'

const useRawStore = create((set) => (
  {
    spTable: {},
    spGraph: {},
    rfTable: {},
    spSummary: {},
    monthly: {},
    marketChanges: {},
    comtradeMarketChange: {},
    comtradeMarketOverview: {},
    comtradeSummary: {},
    duties: [],
    actions: {
      setRawBykey: (key, val) => {
        set({
          [key]: val[key]
        })
      },
      resetRawStore: () => set(initialRawStore)
    }
  }
))

export const useRAWspTable = () => useRawStore((state) => state.spTable)
export const useRAWspGraph = () => useRawStore((state) => state.spGraph)
export const useRAWrfTable = () => useRawStore((state) => state.rfTable)
export const useRAWspSummary = () => useRawStore((state) => state.spSummary)
export const useRAWmonthly = () => useRawStore((state) => state.monthly)
export const useRAWmarketChanges = () => useRawStore((state) => state.marketChanges)
export const useRAWcomtradeMarketChange = () => useRawStore((state) => state.comtradeMarketChange)
export const useRAWcomtradeMarketOverview = () => useRawStore((state) => state.comtradeMarketOverview)
export const useRAWcomtradeSummary = () => useRawStore((state) => state.comtradeSummary)
export const useRAWduties = () => useRawStore((state) => state.duties)

export const useRawStoreActions = () => useRawStore((state) => state.actions)

export default useRawStore
