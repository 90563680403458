import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// MUI Imports
import Grid from '@mui/material/Grid'

import ConfirmationModal from './ConfirmationModal'
import CampaignCard from './CampaignCard'

import { deleteCampaign } from '../../../services/B2BServices'

const CampaignCardList = (props) => {
  const {
    data,
    fetchCampaigns,
    campaignReports,
    refreshCampaignReport,
    campaignReportsFetcing
  } = props
  const { t } = useTranslation()

  const [confirmationModalItem, setConfirmationModalItem] = useState(null)
  const [confirmationModalLoading, setConfirmationModalLoading] = useState(false)

  const handleCampaignDelete = () => {
    setConfirmationModalLoading(true)

    deleteCampaign(confirmationModalItem.id)
      .then((res) => {
        setConfirmationModalItem(null)
        fetchCampaigns()
      })
      .catch((err) => {
        err && toast.error(t('mailTemplate.campaigns.deleteModal.toastErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
      .finally(() => {
        setConfirmationModalLoading(false)
      })
  }

  return (
    <Grid item container spacing={4}>
      {data &&
        data.map((item, index) => {
          return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <CampaignCard
                campaignItem={item}
                setConfirmationModalItem={setConfirmationModalItem}
                campaignReport={campaignReports?.[item.id]}
                refreshCampaignReport={refreshCampaignReport}
                campaignReportFetching={campaignReportsFetcing?.[item.id]}
              />
            </Grid>
          )
        })}

      <ConfirmationModal
        open={!!confirmationModalItem}
        close={() => setConfirmationModalItem(null)}
        loading={confirmationModalLoading}
        submit={handleCampaignDelete}
        title={t('mailTemplate.campaigns.deleteModal.title')}
        subTitle={confirmationModalItem?.name}
        text={t('mailTemplate.campaigns.deleteModal.text')}
        submitText={t('mailTemplate.campaigns.deleteModal.submitText')}
        cancelText={t('mailTemplate.campaigns.deleteModal.cancelText')}
        submitBtnColor='error'
      />
    </Grid>
  )
}

export default CampaignCardList
