import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { useTranslation } from 'react-i18next'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import ValueSection from './ValueSection'
import QuantitySection from './QuantitySection'
import AveragePriceSection from './AveragePriceSection'
import { useSDSsearchTrigger } from '../../../store/searchDataStore'

const MarketOverviewSection = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const searchTrigger = useSDSsearchTrigger()
  const [activeAltTab, setActiveAltTab] = useState('1')
  const hasNull = props.null
  const toggleAltTab = (altTab) => {
    if (activeAltTab !== altTab) setActiveAltTab(altTab)
  }

  const valueTablesRef = useRef(null)
  const quantityTablesRef = useRef(null)
  const averageTablesRef = useRef(null)

  useImperativeHandle(ref, () => ({
    generateMarketOverviewTablesPdf () {
      return {
        value: valueTablesRef.current.generateTablesPdf(),
        quantity: quantityTablesRef.current.generateTablesPdf(),
        average: averageTablesRef.current.generateTablesPdf()
      }
    }
  }))

  return (
    <>
      <MarketCalculusCard className={props.null || searchTrigger ? 'loading-div' : ''}>
        <Nav tabs className="nav-tabs-s2">
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeAltTab === '1' })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab('1')
              }}
            >
              {t('market_overview_tabs.tab1')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeAltTab === '2' })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab('2')
              }}
            >
              {t('market_overview_tabs.tab2')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames({ active: activeAltTab === '3' })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab('3')
              }}
            >
              {t('market_overview_tabs.tab3')}
            </NavLink>
          </NavItem>
        </Nav>
      </MarketCalculusCard>
      <TabContent activeTab={activeAltTab}>
        <TabPane tabId="1">
          <ValueSection ref={valueTablesRef} null={hasNull}/>
        </TabPane>
        <TabPane tabId="2">
          <QuantitySection ref={quantityTablesRef} null={hasNull}/>
        </TabPane>
        <TabPane tabId="3">
          <AveragePriceSection ref={averageTablesRef} null={hasNull}/>
        </TabPane>
      </TabContent>
    </>
  )
})

export default MarketOverviewSection
