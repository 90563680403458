import React, { useEffect, useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { useTranslation } from 'react-i18next'
import BadgeCode from '../badge/BadgeCode'
import { Badge } from 'reactstrap'
import { localizationAMChart, numberFormatter } from '../../utils/AMChartsSettings'
import { createAxisAndSeries } from '../../utils/AmChartsUtils'
import { useFilterStoreActions } from '../../store/filterStore'

const McLineChart = (props) => {
  const {
    chartId,
    chart,
    handleChange,
    selectedMin,
    selectedMax,
    hideMinMax,
    doFilter,
    outlierCheck,
    chartTitle,
    showCheckBoxes,
    height,
    xTitle,
    yTitle,
    sliderIsDisabled,
    colorIndex,
    xFormatterType,
    yFormatterType,
    disablePointerEvents,
    plotContainerColor,
    xAxisStrictMinMax,
    yAxisStrictMinMax,
    fillSeries,
    showFilterButtons,
    showBullet,
    code
  } = props
  const rootRef = useRef(null)
  const mountainChartRef = useRef([])
  const xAxisRef = useRef(null)
  const range1Ref = useRef(null)
  const range2Ref = useRef(null)
  const resizeButton1Ref = useRef(null)
  const resizeButton2Ref = useRef(null)
  const { t } = useTranslation()

  const { setOutliers } = useFilterStoreActions()

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        height: height === '300px' ? 300 : 200,
        exportable: true
      })
    )

    const plotColor = plotContainerColor || 0xe6e6e6
    chart.plotContainer.set('background', am5.Rectangle.new(root, {
      fill: am5.color(plotColor),
      fillOpacity: 0.7
    }))

    const color = root.interfaceColors.get('primaryButton')

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
    cursor.lineX.set('forceHidden', true)
    cursor.lineY.set('forceHidden', true)

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
    xRenderer.labels.template.setAll({
      rotation: 0,
      // centerY: am5.p50,
      centerX: am5.p50,
      // paddingRight: 20,
      fontSize: 10
    })

    const tooltipX = am5.Tooltip.new(root, {})

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        strictMinMax: xAxisStrictMinMax ?? true,
        startLocation: 0.5,
        endLocation: 0.5,
        numberFormat: numberFormatter(xFormatterType),
        renderer: xRenderer,
        tooltip: tooltipX
      })
    )

    createAxisAndSeries(root, chart, 0, false, xAxis, 'yValue', 'xValue',
      yFormatterType, showBullet, fillSeries, colorIndex, yAxisStrictMinMax, mountainChartRef)

    xAxisRef.current = xAxis

    const range1 = xAxis.createAxisRange(xAxis.makeDataItem({}))

    range1.get('grid').setAll({
      strokeOpacity: 1,
      stroke: color
    })

    const axisFill = range1.get('axisFill')
    axisFill.setAll({
      fillOpacity: 1,
      fill: am5.color(0xffffff),
      visible: true,
      draggable: false
    })

    axisFill.adapters.add('y', function () {
      return 0
    })

    const plotContainerHeight = chart.plotContainer.height()

    if (showFilterButtons) {
      const resizeButtonYLocation = plotContainerHeight / -2

      const resizeButton1 = am5.Button.new(root, {
        themeTags: ['resize', 'horizontal'],
        icon: am5.Graphics.new(root, {
          themeTags: ['icon']
        })
      })

      resizeButton1.adapters.add('y', () => resizeButtonYLocation)

      resizeButton1.adapters.add('x', (x) => Math.max(0, Math.min(chart.plotsContainer.width(), x)))

      resizeButton1.events.on('dragged', function () {
        const x = resizeButton1.x()
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width())
        const value = xAxis.positionToValue(position)
        range1.set('value', value)
      })

      resizeButton1.events.on('dragstop', function () {
        const x = resizeButton1.x()
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width())

        const value = xAxis.positionToValue(position)

        const x2 = resizeButton2.x()
        const position2 = xAxis.toAxisPosition(x2 / chart.plotContainer.width())

        const value2 = xAxis.positionToValue(position2)

        if (value >= value2) {
          handleChange(chartId, McPageHelpers.MIN_MAX.MAX, value)
        }

        handleChange(chartId, McPageHelpers.MIN_MAX.MIN, value)

        handleChange(chartId, 'doFilter', true)
      })

      range1.set('bullet', am5xy.AxisBullet.new(root, {
        location: 0,
        sprite: resizeButton1
      }))

      const range2 = xAxis.createAxisRange(xAxis.makeDataItem({}))

      range2.get('grid').setAll({
        strokeOpacity: 1,
        stroke: color
      })

      const resizeButton2 = am5.Button.new(root, {
        themeTags: ['resize', 'horizontal'],
        icon: am5.Graphics.new(root, {
          themeTags: ['icon']
        })
      })

      resizeButton2.adapters.add('y', () => resizeButtonYLocation)

      resizeButton2.adapters.add('x', (x) => Math.max(0, Math.min(chart.plotsContainer.width(), x)))

      resizeButton2.events.on('dragged', function () {
        const x = resizeButton2.x()
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width())
        const value = xAxis.positionToValue(position)

        range1.set('endValue', value)
        range2.set('value', value)
      })

      resizeButton2.events.on('dragstop', function () {
        const x = resizeButton2.x()
        const position = xAxis.toAxisPosition(x / chart.plotContainer.width())

        const value = xAxis.positionToValue(position)

        const x2 = resizeButton1.x()
        const position2 = xAxis.toAxisPosition(x2 / chart.plotContainer.width())

        const value2 = xAxis.positionToValue(position2)

        if (value <= value2) {
          handleChange(chartId, McPageHelpers.MIN_MAX.MIN, value)
        }

        handleChange(chartId, McPageHelpers.MIN_MAX.MAX, value)
        handleChange(chartId, 'doFilter', true)
      })

      range2.set('bullet', am5xy.AxisBullet.new(root, {
        location: 0,
        sprite: resizeButton2
      }))

      range1Ref.current = range1
      range2Ref.current = range2
      resizeButton1Ref.current = resizeButton1
      resizeButton2Ref.current = resizeButton2
    }

    // series.appear(1000, 100)
    chart.appear()
    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [])

  useEffect(() => {
    if (outlierCheck) {
      mountainChartRef.current[0].data.setAll(chart.raw.data)
      if (!hideMinMax && !doFilter) handleChange(chartId, McPageHelpers.MIN_MAX.MIN, chart.raw.min)
      if (!hideMinMax && !doFilter) handleChange(chartId, McPageHelpers.MIN_MAX.MAX, chart.raw.max)
    } else {
      mountainChartRef.current[0].data.setAll(chart.out.data)
      if (!hideMinMax && !doFilter) handleChange(chartId, McPageHelpers.MIN_MAX.MIN, chart.out.min)
      if (!hideMinMax && !doFilter) handleChange(chartId, McPageHelpers.MIN_MAX.MAX, chart.out.max)
    }
  }, [chart, outlierCheck])

  useEffect(() => {
    if (showFilterButtons) {
      range1Ref.current.set('value', selectedMin)
      range1Ref.current.set('endValue', selectedMax)
      range2Ref.current.set('value', selectedMax)
      range2Ref.current.set('endValue', selectedMax)
    }
  }, [selectedMin, selectedMax, showFilterButtons])

  useEffect(() => {
    if (sliderIsDisabled && showFilterButtons) {
      const MOUNTAIN_CHART_DISABLED_VALUE = -1
      range1Ref.current.set('value', MOUNTAIN_CHART_DISABLED_VALUE)
      range1Ref.current.set('endValue', MOUNTAIN_CHART_DISABLED_VALUE)
      range2Ref.current.set('value', MOUNTAIN_CHART_DISABLED_VALUE)
      range2Ref.current.set('endValue', MOUNTAIN_CHART_DISABLED_VALUE)
    }
  }, [sliderIsDisabled, showFilterButtons])

  return (
    <div className={disablePointerEvents === true ? 'disabled-full' : ''}>
      <div>
        <div className="card-title mt-3">
          <div className="d-flex align-items-center justify-content-center">
            <BadgeCode color="light" text={code}/>
            <h6 style={{ fontSize: '0.8rem' }} className="title ml-1">{chartTitle}</h6>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '10px',
              marginRight: '-17px'
            }}>
              <p style={{ transform: 'rotate(-90deg)', fontSize: '10px', whiteSpace: 'nowrap' }}>{yTitle}</p>
            </div>

            <div id={chartId} style={{
              width: '90%',
              height,
              flex: '1 100%'
            }}></div>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: !hideMinMax ? 'space-between' : 'center',
            margin: '-0.625rem 0 0.625rem',
            alignItems: 'center'
          }}>
            {!hideMinMax && <Badge pill color="gray" style={{ marginLeft: '1.8rem' }}>
              {selectedMin ? McPageHelpers.numToLocaleString(selectedMin, 'decimal', false, 2) : '0'}
            </Badge>}
            <p className="m-0" style={{ fontSize: '10px' }}>{xTitle}</p>
            {!hideMinMax && <Badge pill color="gray" style={{ marginRight: '0.5rem' }}>
              {selectedMax ? McPageHelpers.numToLocaleString(selectedMax, 'decimal', false, 2) : '0'}
            </Badge>}
          </div>
        </div>
      </div>

      {showCheckBoxes
        ? <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div className="center">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input form-control"
                     id={'customCheckOutliers' + chartId}
                     checked={outlierCheck}
                     onChange={() => setOutliers(chartId, !outlierCheck)}
                     disabled={disablePointerEvents ? true : ''}/>
              <label className="custom-control-label" htmlFor={'customCheckOutliers' + chartId}>
                {t('sp_mountain_avg_outliers_checkbox')}
              </label>
            </div>
          </div>
        </div>
        : ''}
    </div>
  )
}
export default McLineChart
