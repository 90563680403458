import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDSgrowth, useDSgrowthExplanation } from '../../store/dataStore'
import { useFilterStoreActions, useFSimporterFilters } from '../../store/filterStore'
import BadgeCode from '../badge/BadgeCode'
import { Col, Row } from 'reactstrap'
import GrowthBox from './GrowthBox'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import CONSTANTS from '../../utils/Constants'

const GrowthGraph = () => {
  const { t } = useTranslation()
  const growth = useDSgrowth()
  const growthExplanation = useDSgrowthExplanation()

  const { setImporterFilters } = useFilterStoreActions()
  const importerFilters = useFSimporterFilters()

  return (<>
    <div id={'growth' + McPageHelpers.FOR_PDF_ID}>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <div className="d-flex align-items-center">
            <BadgeCode color="light" text={CONSTANTS.BADGE_CODES.GROWTH_GRAPH}/>
            <h6 className="title ml-2 text-center">{t('sp_growth_table_head')}</h6>
          </div>
          <p className="text-center">
            {t('sp_growth_table_head')}
          </p>
        </div>
      </div>
      {
        growth && <Row className="g-3">
          <Col md="6">
            <GrowthBox onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.GROWTH, 'increasing')}
                       growthName={'increasing'}
                       growthTitle={t('sp_growth_table_desc_1')}
                       selected={importerFilters.growth === 'increasing'}
                       growthData={growth}/>
          </Col>
          <Col md="6">
            <GrowthBox onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.GROWTH, 'decreasing')}
                       growthName={'decreasing'}
                       growthTitle={t('sp_growth_table_desc_2')}
                       selected={importerFilters.growth === 'decreasing'}
                       growthData={growth}/>
          </Col>
          <Col md="6">
            <GrowthBox onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.GROWTH, 'irregular')}
                       growthName={'irregular'}
                       growthTitle={t('sp_growth_table_desc_3')}
                       selected={importerFilters.growth === 'irregular'}
                       growthData={growth}/>
          </Col>
          <Col md="6">
            <GrowthBox onClick={() => setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.GROWTH, 'similar')}
                       growthName={'similar'}
                       growthTitle={t('sp_growth_table_desc_4')}
                       selected={importerFilters.growth === 'similar'}
                       growthData={growth}/>
          </Col>
        </Row>
      }
    </div>

    {growthExplanation && Array.isArray(growthExplanation) && growthExplanation.length > 0 && (
      <div className="card-title-group mt-2">
        <div className="card-title">
          <hr/>
          {growthExplanation.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    )}
  </>)
}

export default GrowthGraph
