import BadgeCode from '../badge/BadgeCode'
import React from 'react'

const MCTitleCard = ({ code, chartTitle, chartSubTitle, codeColor, textColor, className }) => {
  return (
    <div className={'card-title-group justify-center mb-1 mt-2'}>
      <div className="card-title text-center">
        <div className="d-flex align-items-center">
          <h5 className="title ml-2" style={{
            whiteSpace: 'pre-line',
            color: textColor || ''
          }}>{chartSubTitle + ' ' + chartTitle}</h5>
        </div>
        <div className={className ?? ''}>
          <BadgeCode color={codeColor ?? 'light'} text={code}/>
        </div>
      </div>
    </div>
  )
}
export default MCTitleCard
