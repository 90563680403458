import React, { useEffect } from 'react'
import { useSDSsearchParams, useSDSselectBoxData, useSDSimaginalUrlSearchParams } from '../../store/searchDataStore'
import { useTranslation } from 'react-i18next'
import McDataProcessor from '../../pages/mc-components/McDataProcessor'
import {
  useDataStoreActions,
  useDSglobalParams,
  useDSrawImporterTable,
  useDSfilteredImporterTable,
  useDSrawPotentialTable,
  useDSfilteredPotentialTable
} from '../../store/dataStore'
import { useGlobalStoreData } from '../../store/globalStore'
import { useFSfilterType, useFSfiltering, useFSimporterFilters, useFSpotentialFilters, useFilterStoreActions } from '../../store/filterStore'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { RESPONSE_OBJ_NAMES as RON } from '../../services/McDataServices'
import useRawStore from '../../store/rawStore'
import { isEmpty } from 'lodash'

const DataCoordinator = () => {
  const { t } = useTranslation()

  const selectBoxData = useSDSselectBoxData()
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const searchParams = useSDSsearchParams()

  const languageGSD = useGlobalStoreData.language()
  const countriesGSD = useGlobalStoreData.countries()

  const globalParams = useDSglobalParams()
  const rawImporterTable = useDSrawImporterTable()
  const filteredImporterTable = useDSfilteredImporterTable()
  const rawPotentialTable = useDSrawPotentialTable()
  const filteredPotentialTable = useDSfilteredPotentialTable()
  const {
    setFilteredImporterTable,
    setFilteredPotentialTable,
    setRawImporterSectionData,
    setRawPotentialSectionData,
    setProductAnalysisSectionData,
    setPriceAnalysisSectionData,
    setMarketOverviewSectionData,
    setMarketChangesSectionData,
    setCustomsDutiesSectionData,
    setSummaryTablesSectionData
  } = useDataStoreActions()

  const rawData = useRawStore()

  const filtering = useFSfiltering()
  const filterType = useFSfilterType()
  const importerFilters = useFSimporterFilters()
  const potentialFilters = useFSpotentialFilters()
  const {
    setFiltering,
    setImporterFilters
  } = useFilterStoreActions()

  const countries = selectBoxData.countries
  const countriesWithCode = countriesGSD
  const selectedUnit = globalParams.selectedUnit
  const searchCountryCode = globalParams.countryCode

  const generateImporterSectionData = () => {
    if (!isEmpty(rawData[RON.SP_TABLE]) || !isEmpty(rawData[RON.SP_GRAPH])) {
      McDataProcessor.generateAndSetImporterSectionData(rawData, countries, selectedUnit, setRawImporterSectionData)
      setImporterFilters(McPageHelpers.FILTER_BUTTON_IDS.IS_LOGISTIC_FIRM, false)
    }
  }

  const generatePotentialSectionData = () => {
    if (!isEmpty(rawData[RON.RF_TABLE]) || !isEmpty(rawData[RON.SP_SUMMARY])) {
      McDataProcessor.generateAndSetPotentialSectionData(rawData, countries, 'avg_price1', setRawPotentialSectionData)
    }
  }

  const generateProductAnalysisSectionData = () => {
    if (!isEmpty(rawData[RON.SP_GRAPH])) {
      McDataProcessor.generateAndSetProductAnalysisSectionData(rawData, setProductAnalysisSectionData)
    }
  }

  const generateMarketOverviewSectionData = () => {
    if (!isEmpty(rawData[RON.COMTRADE_MARKET_OVERVIEW])) {
      McDataProcessor.generateAndSetMarketOverviewSectionData(rawData, countriesWithCode, setMarketOverviewSectionData)
    }
  }

  const generateMarketChangesSectionData = () => {
    if (!isEmpty(rawData[RON.MARKET_CHANGE]) || !isEmpty(rawData[RON.COMTRADE_MARKET_CHANGE])) {
      McDataProcessor.generateAndSetMarketChangesSectionData(rawData, countriesWithCode, searchCountryCode, setMarketChangesSectionData)
    }
  }

  const generateCustomsDutiesSectionData = () => {
    if (!isEmpty(rawData[RON.DUTIES])) {
      McDataProcessor.generateAndSetCustomsDutiesSectionData(rawData, searchParams.countryToExport, setCustomsDutiesSectionData)
    }
  }

  const generateSummaryTablesSectionData = () => {
    if (!isEmpty(rawData[RON.COMTRADE_SUMMARY])) {
      McDataProcessor.generateAndSetSummaryTablesSectionData(rawData[RON.COMTRADE_SUMMARY], setSummaryTablesSectionData)
    }
  }

  // const generateErrorData = () => {
  //   if (!searchTrigger && rawData?.errors) {
  //     McPageHelpers.handleRawError(rawData?.errors)
  //   }
  // }

  const generateAppDataBySelectedUnit = (countries, selectedUnit, selectedUnitNumber, searchCountryCode) => {
    if (!isEmpty(rawData[RON.SP_GRAPH]) || !isEmpty(rawData[RON.MONTHLY]) || !isEmpty(rawData[RON.SP_TABLE]) || !isEmpty(rawData[RON.SP_SUMMARY])) {
      const countriesWithCode = countriesGSD
      const selectedUnit = globalParams.selectedUnit
      const selectedUnitNumber = globalParams.selectedUnitNumber
      const searchCountryCode = globalParams.countryCode

      McDataProcessor.generateAndSetPriceAnalysisSectionData(rawData, countriesWithCode, selectedUnitNumber, selectedUnit, searchCountryCode, setPriceAnalysisSectionData)
    }
  }

  const generateFilteredData = () => {
    if (filtering) {
      switch (filterType) {
        case 'importer':{
          let conditionIF = McPageHelpers.getFilterCondition(importerFilters, 'importerFilters', globalParams.selectedUnitNumber)
          if (!conditionIF.includes('<=')) {
            conditionIF = conditionIF.replace('>=', '>')
          }
          // eslint-disable-next-line no-eval
          setFilteredImporterTable(conditionIF ? rawImporterTable.filter(item => eval(conditionIF)) : rawImporterTable)
          break
        }
        case 'potential':{
          let conditionPF = McPageHelpers.getFilterCondition(potentialFilters, 'potentialFilters')
          if (!conditionPF.includes('<=')) {
            conditionPF = conditionPF.replace('>=', '>')
          }
          // eslint-disable-next-line no-eval
          setFilteredPotentialTable(conditionPF ? rawPotentialTable.filter(item => eval(conditionPF)) : rawPotentialTable)
          break
        }
        default:
          break
      }
      setFiltering(false)
    }
  }

  const showImporterFilterNotification = () => {
    const title = t('importer_firms')
    const link = `/${languageGSD}/importer-companies${(urlSearchParams ? '?' + urlSearchParams + '&scroll=importerCard' : '')}`
    McPageHelpers.showFilterNotification(title, filteredImporterTable, rawImporterTable, link)
  }

  const showPotentialFilterNotification = () => {
    const title = t('potential_firms')
    const link = `/${languageGSD}/importer-companies${(urlSearchParams ? '?' + urlSearchParams + '&scroll=potentialCard' : '')}`
    McPageHelpers.showFilterNotification(title, filteredPotentialTable, rawPotentialTable, link)
  }

  // useEffect(generateAppData, [rawData])
  // useEffect(generateErrorData, [rawData.errors, searchTrigger])

  useEffect(generateImporterSectionData, [rawData[RON.SP_TABLE], rawData[RON.SP_GRAPH]])
  useEffect(generateProductAnalysisSectionData, [rawData[RON.SP_GRAPH]])
  useEffect(generatePotentialSectionData, [rawData[RON.RF_TABLE], rawData[RON.SP_SUMMARY]])
  useEffect(generateMarketOverviewSectionData, [rawData[RON.COMTRADE_MARKET_OVERVIEW]])
  useEffect(generateMarketChangesSectionData, [rawData[RON.MARKET_CHANGE], rawData[RON.COMTRADE_MARKET_CHANGE]])
  useEffect(generateCustomsDutiesSectionData, [rawData[RON.DUTIES]])
  useEffect(generateSummaryTablesSectionData, [rawData[RON.COMTRADE_SUMMARY]])
  useEffect(generateAppDataBySelectedUnit, [rawData[RON.SP_GRAPH], rawData[RON.MONTHLY], rawData[RON.SP_TABLE], rawData[RON.SP_SUMMARY]])

  useEffect(generateAppDataBySelectedUnit, [globalParams.selectedUnit])
  useEffect(generateFilteredData, [filtering])

  useEffect(showImporterFilterNotification, [filteredImporterTable?.length])
  useEffect(showPotentialFilterNotification, [filteredPotentialTable?.length])

  return <></>
}

export default DataCoordinator
