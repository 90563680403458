import React, { useEffect, useState, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import * as qs from 'qs'
import { useTranslation } from 'react-i18next'

// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import TablePagination from '@mui/material/TablePagination'
import MenuItem from '@mui/material/MenuItem'
import { IconButton, Typography } from '@mui/material'

import { ExpandMore, ExpandLess, Edit } from '@mui/icons-material'

// Third-party Imports
import classnames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table'

// Component Imports
import TablePaginationComponent from '../molecules/TablePaginationComponent'
import CustomTextField from '../atoms/CustomTextField'
import AvatarWithTexts from '../atoms/AvatarWithTexts'

// Style Imports
import tableStyles from '../../styles/table.module.scss'

import { useGlobalStoreData } from '../../../../../store/globalStore'

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const DebouncedInput = ({ value: initialValue, onChange, debounce = 500, ...props }) => {
  // States
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <CustomTextField {...props} value={value} onChange={e => setValue(e.target.value)} />
}

// Column Definitions
const columnHelper = createColumnHelper()

const UserListTable = (props) => {
  const { tableData, setEmailEditState } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')

  const languageGSD = useGlobalStoreData.language()

  const columns = useMemo(
    () => [
      columnHelper.accessor('userName', {
        header: t('mailTemplate.users.user'),
        cell: ({ row }) => (
          <AvatarWithTexts
            text={row.original.userName}
            subText={row.original.nameSurname}
          />
        )
      }),
      columnHelper.accessor('email', {
        header: t('mailTemplate.users.email'),
        cell: ({ row }) => (
          <div className='flex items-center gap-4'>
            <div className='flex flex-col'>
              <Typography color='text.primary' className='font-medium'>
                {row.original.emailSettings?.emailUserName}
              </Typography>
            </div>
          </div>
        )
      }),
      columnHelper.accessor('companyName', {
        header: t('mailTemplate.users.companyName'),
        cell: ({ row }) => (
          <div className='flex items-center gap-4'>
            <div className='flex flex-col'>
              <Typography color='text.primary' className='font-medium'>
                {row.original.companyName}
              </Typography>
            </div>
          </div>
        )
      }),
      columnHelper.accessor('action', {
        header: '',
        cell: ({ row }) => (
          <div className='flex items-center'>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setEmailEditState(row.original)
              }}
            >
              <Edit />
            </IconButton>
          </div>
        ),
        enableSorting: false
      })
    ],
    []
  )

  const table = useReactTable({
    data: tableData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      rowSelection,
      globalFilter
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    enableRowSelection: true, // enable row selection for all rows
    enableMultiRowSelection: false,
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  return (
    <Card>
      <CardHeader title={t('mailTemplate.users.users')} className='pbe-4' />
      <div className='flex justify-between flex-col items-start md:flex-row md:items-center p-6 border-bs gap-4'>
        <CustomTextField
          select
          value={table.getState().pagination.pageSize}
          onChange={e => table.setPageSize(Number(e.target.value))}
          className='is-[70px]'
        >
          <MenuItem value='10'>10</MenuItem>
          <MenuItem value='25'>25</MenuItem>
          <MenuItem value='50'>50</MenuItem>
        </CustomTextField>
        <div className='flex flex-col sm:flex-row is-full sm:is-auto items-start sm:items-center gap-4'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder={t('mailTemplate.users.searchUser')}
            className='is-full sm:is-auto'
          />
        </div>
      </div>
      <div className='overflow-x-auto'>
        <table className={tableStyles.table}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : (
                      <>
                        <div
                          className={classnames({
                            'flex items-center': header.column.getIsSorted(),
                            'cursor-pointer select-none': header.column.getCanSort()
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ExpandLess/>,
                            desc: <ExpandMore/>
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </>
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getFilteredRowModel().rows.length === 0
            ? (
            <tbody>
              <tr>
                <td colSpan={table.getVisibleFlatColumns().length} className='text-center'>
                {t('mailTemplate.users.noData')}
                </td>
              </tr>
            </tbody>
              )
            : (
            <tbody>
              {table
                .getRowModel()
                .rows.slice(0, table.getState().pagination.pageSize)
                .map(row => {
                  return (
                    <tr
                      key={row.id}
                      className={classnames({ selected: row.getIsSelected() })}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const queryParams = {
                          b2bAccountId: row.original.id
                        }
                        const queryString = qs.stringify(queryParams)
                        const targetURL = `${process.env.PUBLIC_URL}/${languageGSD}/mailing?${queryString}`
                        navigate(targetURL)
                      }}
                    >
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                  )
                })}
            </tbody>
              )}
        </table>
      </div>
      <TablePagination
        component={() =>
          <TablePaginationComponent
            table={table}
            infoText1={t('mailTemplate.users.tableInfo_1')}
            infoText2={t('mailTemplate.users.tableInfo_2')}
          />
        }
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={table.getState().pagination.pageSize}
        page={table.getState().pagination.pageIndex}
        onPageChange={(_, page) => {
          table.setPageIndex(page)
        }}
      />
    </Card>
  )
}

export default UserListTable
