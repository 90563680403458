import * as am5 from '@amcharts/amcharts5'
import am5localesTr from '@amcharts/amcharts5/locales/tr_TR'
import am5localesEn from '@amcharts/amcharts5/locales/en_US'

export const LANG_CODES = { TURKISH: 'tr', ENGLISH: 'en' }

export const LOCALE = localStorage.getItem('locale')

export const getLocalePrefix = () => {
  return localStorage.getItem('lang')?.toLowerCase() === 'tr' ? LANG_CODES.TURKISH : LANG_CODES.ENGLISH
}

export const IS_LANG_TURKISH = getLocalePrefix() === LANG_CODES.TURKISH
export const NUMBER_FORMATTER_TYPE = {
  PERCENTAGE: 'percentage',
  CURRENCY: 'currency',
  KILOGRAM: 'kg'
}

export const exportingBarChart = []
export const exportingHeatMap = []
export const exportingPieChart = []
export const exportingMountainChart = []

export const exportingMarketChangeChart = []

export const setExportingBarChart = (exp, chartId) => { exportingBarChart[chartId] = exp }

export const setExportingHeatMap = (exp, chartId) => { exportingHeatMap[chartId] = exp }

export const setExportingPieChart = (exp, chartId) => { exportingPieChart[chartId] = exp }

export const setExportingMountainChart = (exp, chartId) => { exportingMountainChart[chartId] = exp }

export const setExportingMarketChangeChart = (chartData, chartId) => { exportingMarketChangeChart[chartId] = chartData }

export const MARKET_CHANGE_CHART_TYPES = {
  VALUE_OR_QUANTITY: 'VALUE_OR_QUANTITY',
  PERCENTAGE: 'PERCENTAGE',
  QUANTITY: 'QUANTITY'
}
export const numberFormatter = (formatterType) => {
  if (formatterType === NUMBER_FORMATTER_TYPE.PERCENTAGE) {
    switch (getLocalePrefix()) {
      case LANG_CODES.TURKISH:
        return '%#.0s'
      case LANG_CODES.ENGLISH:
        return '#.0s%'
      default:
        return '#.0#%'
    }
  } else if (formatterType === MARKET_CHANGE_CHART_TYPES.PERCENTAGE) {
    switch (getLocalePrefix()) {
      case LANG_CODES.TURKISH:
        return '\'%\'#.##a'
      case LANG_CODES.ENGLISH:
        return '#.##a\'%\''
      default:
        return '#.##a\'%\''
    }
  } else if (formatterType === MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY) {
    switch (getLocalePrefix()) {
      case LANG_CODES.TURKISH:
        return '#.##a\'$\''
      case LANG_CODES.ENGLISH:
        return '\'$\'#.##a'
      default:
        return '#.##a\'%\''
    }
  } else { return '#.##a\'' }
  // else if (formatterType === MARKET_CHANGE_CHART_TYPES.QUANTITY) {
  //   return '#,###'
  // }
}

export const bigNumberPrefixes = [
  { number: 1e+3, suffix: 'B' },
  { number: 1e+6, suffix: 'M' },
  { number: 1e+9, suffix: ' Milyar ' },
  { number: 1e+12, suffix: ' Trilyon ' }
]

export const localizationAMChart = (root) => {
  root.locale = getLocalePrefix() === 'tr' ? am5localesTr : am5localesEn
  root.numberFormatter.setAll({
    numberFormat: '#,###.##',
    numericFields: ['valueY', 'valueX'],
    smallNumberThreshold: 0.00000000001
  })

  if (IS_LANG_TURKISH) root.numberFormatter.setAll({ bigNumberPrefixes })
}

export const PALETTE_HEX_1 = [
  am5.color(0xFAD390),
  am5.color(0xF6B93B),
  am5.color(0xE55039),
  am5.color(0xEB2F06),
  am5.color(0x6A89CC),
  am5.color(0x4A69BD),
  am5.color(0x1E3799),
  am5.color(0x0C2461),
  am5.color(0x82CCDD),
  am5.color(0x60A3BC)
]

export const PALETTE_HEX_2 = [
  am5.color(0x6771dc),
  am5.color(0x8067dc),
  am5.color(0xa367dd),
  am5.color(0xc767dc),
  am5.color(0xdd67ce),
  am5.color(0xdd67ab),
  am5.color(0xdd6788),
  am5.color(0xdc6967),
  am5.color(0xffbd71),
  am5.color(0xffdca2)
]

export const PALETTE_1 = [
  'rgb(250, 211, 144)',
  'rgb(246, 185, 59)',
  'rgb(229, 80, 57)',
  'rgb(235, 47, 6)',
  'rgb(106, 137, 204)',
  'rgb(74, 105, 189)',
  'rgb(30, 55, 153)',
  'rgb(12, 36, 97)',
  'rgb(130, 204, 221)',
  'rgb(96, 163, 188)'
]

export const PALETTE_1_PALE = [
  'rgba(250, 211, 144, 0.2)',
  'rgba(246, 185, 59, 0.2)',
  'rgba(229, 80, 57, 0.2)',
  'rgba(235, 47, 6, 0.2)',
  'rgba(106, 137, 204, 0.2)',
  'rgba(74, 105, 189, 0.2)',
  'rgba(30, 55, 153, 0.2)',
  'rgba(12, 36, 97, 0.2)',
  'rgba(130, 204, 221, 0.2)',
  'rgba(96, 163, 188, 0.2)'
]

export const PALETTE_HEX_3 = [
  am5.color(0xF8D228),
  am5.color(0xB33062),
  am5.color(0xF4C92B),
  am5.color(0xB7395F),
  am5.color(0xF1C12E),
  am5.color(0xBA415C),
  am5.color(0xEDB831),
  am5.color(0xBE4A59),
  am5.color(0xE9B034),
  am5.color(0xC25256),
  am5.color(0xE6A737),
  am5.color(0xC55B53),
  am5.color(0xE29F3A),
  am5.color(0xC96350),
  am5.color(0xDF963D),
  am5.color(0xCC6C4D),
  am5.color(0xDB8E40),
  am5.color(0xD0744A),
  am5.color(0xD78543),
  am5.color(0xD47D47)
]
