import React, { useLayoutEffect, useRef, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import {
  localizationAMChart,
  numberFormatter,
  PALETTE_HEX_1,
  PALETTE_HEX_2
} from '../../utils/AMChartsSettings'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import BadgeCode from '../badge/BadgeCode'

const McBarChart = ({
  chartId,
  chartData,
  chartTitle,
  isPercentage,
  yTooltip,
  yTitle,
  xTitle,
  customColor,
  addSelectionClickEvent,
  filterByCountry,
  filterByCountryList,
  cursorPointer,
  reset,
  getClickEventData,
  yFormatterType,
  isHeatChart,
  totalCompanyCount,
  scrollbar,
  selectedChartId,
  height,
  code,
  paramName,
  filters,
  explanation,
  ...props
}) => {
  const rootRef = useRef(null)
  const seriesRef = useRef(null)
  const xAxisRef = useRef(null)
  const [barChartId, setBarChartId] = useState(null)

  useLayoutEffect(() => {
    if (filterByCountry) {
      if (selectedChartId !== barChartId && barChartId) {
        seriesRef?.current?.data.setAll(chartData)
      }
    }
  }, [selectedChartId])

  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      exportable: true
    }))

    if (customColor) {
      chart.get('colors').set('colors', PALETTE_HEX_2)
    } else {
      chart.get('colors').set('colors', PALETTE_HEX_1)
    }

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
    cursor.lineY.set('visible', false)

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 10 })
    xRenderer.labels.template.setAll({
      rotation: isHeatChart ? 0 : -20,
      // centerX: am5.p50,
      centerY: isHeatChart ? am5.p50 : am5.p0,
      fontSize: 8
    })

    const xAxisTooltip = am5.Tooltip.new(root, {})

    xAxisTooltip.get('background').setAll({
      fill: am5.color(0x6771dc)
    })

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      zoomX: false,
      categoryField: 'xValue',
      renderer: xRenderer,
      tooltip: xAxisTooltip
    }))

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      numberFormat: numberFormatter(yFormatterType),
      renderer: am5xy.AxisRendererY.new(root, {})
    }))

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: 'Series 1',
      xAxis,
      yAxis,
      valueYField: `${isPercentage ? 'yValueForAxis' : 'yValue'}`,
      sequencedInterpolation: true,
      categoryXField: 'xValue'
    }))

    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      labelText: `${yTooltip ? '[normal]{description}' : `[bold]${isPercentage ? '{tooltipYValue} ' : '{formatValue}'}[normal]{description}`}`
    })

    tooltip.get('background').setAll({
      fill: am5.color(0x6771dc),
      fillOpacity: 0.8
    })

    tooltip.label.setAll({
      oversizedBehavior: 'wrap',
      maxWidth: 200,
      fontSize: 12
    })

    series.set('tooltip', tooltip)

    if (chartData?.length) {
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: '{formattedYValue}', // valueYWorking.formatNumber('#.')
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.p50,
            fontSize: 10,
            populateText: true
          })
        })
      })
    }

    series.columns.template.onPrivate('height', function (height, target) {
      // eslint-disable-next-line no-unused-expressions
      target.dataItem.bullets && target.dataItem.bullets?.length
        ? am5.array.each(target.dataItem.bullets, function (bullet) {
          if (height > 20) {
            bullet.get('sprite').show()
          } else {
            bullet.get('sprite').hide()
          }
        })
        : ''
    })

    if (scrollbar) {
      const scrollbarX = am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
      chart.set('scrollbarX', scrollbarX)
      chart.bottomAxesContainer.children.push(scrollbarX)
      xAxis.events.once('datavalidated', function (ev) {
        ev.target.zoomToIndexes(0, 10)
      })
    }

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      toggleKey: addSelectionClickEvent || filterByCountry ? 'active' : false,
      strokeWidth: addSelectionClickEvent || filterByCountry ? 2.5 : 0.1
    })
    series.columns.template.set('interactive', true)

    chart.zoomOutButton.set('forceHidden', true)

    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target))
    })

    // series.columns.template.adapters.add('stroke', function (stroke, target) {
    //     return chart.get('colors').getIndex(series.columns.indexOf(target))
    // })

    series.columns.template.states.create('active', {
      fill: am5.color(0x000000),
      stroke: am5.color(0x00c000)
    })

    if (addSelectionClickEvent) {
      let currentSelection
      let xValue = filters[paramName]?.min + (filters[paramName]?.max ? '-' + filters[paramName]?.max : '+')

      if (filters[paramName]?.min === filters[paramName]?.max) {
        xValue = filters[paramName]?.min
      }

      xValue = xValue !== '' ? xValue : undefined

      series.columns.template.events.on('click', function (ev) {
        getClickEventData(chartId, ev.target.dataItem.dataContext)
      })

      series.columns.template.on('active', function (active, target) {
        if (active) {
          if (currentSelection && currentSelection !== target) {
            currentSelection.set('active', false)
          }
          currentSelection = target
        } else {
          currentSelection = undefined
        }
      })

      if (chartId === 'companyShipmentCount') {
        series.columns.template.adapters.add('active', (active, target) => {
          if (active) {
            xValue = target._dataItem.dataContext.xValue
            return true
          } else {
            if (target._dataItem.dataContext.xValue === xValue) {
              currentSelection = target
              return true
            }
            return false
          }
        })
      }
    }

    if (filterByCountry) {
      let currentSelection
      series.columns.template.events.on('click', function (ev) {
        filterByCountryList(ev.target?.dataItem?.dataContext?.country_code, ev.target?.root?.dom?.id)
        setBarChartId(ev.target?.root?.dom?.id)
      })

      series.columns.template.on('active', function (active, target) {
        if (active && currentSelection && currentSelection !== target) {
          currentSelection.set('active', false)
        } else {
          currentSelection = undefined
        }
        if (active) {
          currentSelection = target
        }
      })
    }

    xAxisRef.current = xAxis
    seriesRef.current = series

    rootRef.current = root

    return () => {
      root.dispose()
    }
  }, [chartData])

  useLayoutEffect(() => {
    chartData && xAxisRef.current.data.setAll(chartData)
    chartData && seriesRef.current.data.setAll(chartData)
  }, [chartData])

  useLayoutEffect(() => {
    if (reset) {
      chartData && xAxisRef.current.data.setAll(chartData)
      chartData && seriesRef.current.data.setAll(chartData)
    }
  }, [reset])

  return <>
    {/* <h6 className="text-center mt-3">{chartTitle}</h6> */}

    <div id={chartId + McPageHelpers.FOR_PDF_ID}>
      <div className={isHeatChart ? 'card-title-group justify-center mb-1 mt-2' : 'card-title-group mb-1'}
      >
        <div className="card-title">
          <div className="d-flex align-items-center">
            <BadgeCode color="light" text={code}/>
            <h6 className="title ml-2"
                style={{ whiteSpace: 'pre-line' }}>{chartTitle} {totalCompanyCount ? `(${(McPageHelpers.numToLocaleString(totalCompanyCount))})` : ''}</h6>
          </div>
          <p>
            {/* The investment overview of your platform. */}
          </p>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-90deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitle}</p>
        </div>

        <div id={chartId}
             style={{
               width: '90%',
               height: `${height || '200px'}`,
               cursor: `${cursorPointer ? 'pointer' : 'default'}`
             }}></div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ fontSize: '10px' }}>{xTitle}</p>
      </div>
    </div>
    {explanation && Array.isArray(explanation) && explanation.length > 0 && (
      <div className="card-title-group mt-2">
        <div className="card-title">
          <hr/>
          {explanation.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    )}
  </>
}

export default McBarChart
