import React, { forwardRef } from 'react'
import { Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import MultipleBarChart from '../../../components/amcharts/MultipleBarChart'
import MultipleLineChart from '../../../components/amcharts/MultipleLineChart'
import MarketOverviewTable from './MarketOverviewTable/MarketOverviewTable'
import { CHART_TYPE, getCountryName, KEY, OVERVIEW_TAB_TYPE } from './MarketOverviewTable/MarketOverviewTable.settings'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import McPageHelpers from '../McPageHelpers'
import { useSDSsearchParams, useSDSsearchTrigger } from '../../../store/searchDataStore'
import MCTitleCard from '../../../components/title-card/MCTitleCard'

const MarketOverviewSettings = forwardRef((props, ref) => {
  const {
    type, title, responseCountry, timeTags, countryValues, chartData,
    chartId, chartType, yTitle, yFormatterType, classes, tableType, tableCode,
    graphicCode, tabType, isWorld, removeNullCols, footer
  } = props

  const { t } = useTranslation()

  const searchTrigger = useSDSsearchTrigger()
  const searchParams = useSDSsearchParams()

  const getTranslationKey = (key, type) => {
    const baseKey = key === KEY.YEARLY ? 'year' : 'month'
    return `sp_values_table_${baseKey}_${type}`
  }

  const getWorldTranslationKey = (key, type) => {
    const baseKey = key === KEY.YEARLY ? 'world_year' : 'world_month'
    return `sp_${baseKey}_${type}`
  }

  const getValueType = (tableType) => {
    switch (tableType) {
      case 'value':
        return 'sp_values_thousand'
      case 'quantity':
        return 'sp_quantity_ton'
      default:
        return '$/KG'
    }
  }

  const renderTitle = (key, tableType) => {
    const translationKey = isWorld ? getWorldTranslationKey(key, type) : getTranslationKey(key, type)
    const valuesType = getValueType(tableType)

    return t(translationKey, {
      tableType: t(`sp_market_overview_${tableType}_title`),
      values: t(valuesType)
    })
  }

  const renderCountryOrWorld = () => {
    return isWorld
      ? t('world')
      : getCountryName(
        tabType === OVERVIEW_TAB_TYPE.SOURCE ? searchParams.countryToExport : searchParams.yourCountry,
        responseCountry,
        'country_code'
      )
  }
  const CardHeader = ({ color, code }) => (

    <Col md="12" className={classes}>
      <MCTitleCard
        chartTitle={renderTitle(title, tableType)}
        code={code}
        chartSubTitle={renderCountryOrWorld() + ' ' + searchParams.hsCode}
        codeColor={color}
        textColor={'#09c2de'}
      />
    </Col>
  )
  return (
    <>
      <MarketCalculusCard className={`my-2 ${!searchTrigger ? '' : 'loading-div'}`}
                          id={chartId + '_withTitle' + McPageHelpers.FOR_PDF_ID}>
        <CardHeader color="light" code={graphicCode}/>
        {chartData?.length
          ? <Col md="12">
              {chartType === CHART_TYPE.BAR
                ? (
                    <MultipleBarChart
                      chartId={chartId}
                      chartData={chartData ?? []}
                      yTitle={yTitle}
                      yFormatterType={yFormatterType}
                    />
                  )
                : (
                    <MultipleLineChart
                      chartId={chartId}
                      chartData={chartData ?? []}
                      yFormatterType={yFormatterType}
                      yTitle={yTitle}
                    />
                  )
              }
            </Col>
          : (
              <Col>
                <div className="d-flex justify-content-center align-items-center my-2 border p-2 text-black">
                  {t('no_records')}
                </div>
              </Col>
            )
        }
      </MarketCalculusCard>
      <MarketCalculusCard className={`my-2 ${!searchTrigger ? '' : 'loading-div'}`}>
        <CardHeader color="info" code={tableCode}/>
        <Col md="12" className={classes}>
          <MarketOverviewTable
            title={title}
            tableTitle={renderCountryOrWorld() + ' ' + searchParams.hsCode + ' ' + renderTitle(title, tableType)}
            countryValues={countryValues ?? []}
            removeNullCols={removeNullCols}
            timeTags={timeTags ?? []}
            type={type}
            responseCountry={responseCountry}
            isAverage={tableType === 'avg' ?? true}
            subTitle={tableCode}
            ref={ref}
            footer={footer}
          />
        </Col>
      </MarketCalculusCard>
    </>
  )
})

export default MarketOverviewSettings
