import React from 'react'

import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// MUI Imports
import {
  CircularProgress,
  Typography,
  Backdrop
} from '@mui/material'

// Component Imports
import AvatarWithTexts from '../atomic/atoms/AvatarWithTexts'

// Styles Imports
import styles from '../styles/mailbox.module.scss'

import { extractHtmlContent } from '../../utils/helpers'

const ScrollWrapper = ({ children }) => {
  return <div className='bs-full overflow-y-auto overflow-x-hidden relative'>{children}</div>
}

const BackdropComponent = ({ open }) => {
  return (
    <Backdrop
      open={open}
      className='text-white z-10 bg-textDisabled'
      sx={{
        position: 'absolute',
        opacity: '0.5 !important'
      }}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

const MailContentList = props => {
  const {
    fetching,
    isBelowSmScreen,
    areFilteredEmailsNone,
    searchTerm,
    emailArrays,
    setDrawerOpen,
    setActiveEmailArr
  } = props

  const { t } = useTranslation()

  const userLocale = localStorage.getItem('lang') === 'tr' ? 'tr-TR' : 'en-US'

  const filteredEmailArrays = emailArrays
    .filter(
      emailArr =>
        emailArr?.[0]?.subject?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        emailArr?.[0]?.from_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        emailArr?.[0]?.from_address?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        extractHtmlContent(emailArr?.[0]?.bodyHtml)?.toLowerCase().includes(searchTerm?.toLowerCase())
    )

  return areFilteredEmailsNone
    ? (
        <div className='relative flex justify-center gap-2 grow is-full bg-backgroundPaper'>
          {fetching
            ? <BackdropComponent open={fetching}/>
            : <Typography className='m-3'>{t('mailTemplate.mailbox.list.noData')}</Typography>
          }
        </div>
      )
    : (
        <div className='relative overflow-hidden grow is-full h-full'>
          <ScrollWrapper>
            <div className='flex flex-col'>
              {filteredEmailArrays?.map((emailArr) => (
                <div
                  key={emailArr?.[0]?.messageId}
                  className={cx('p-2 cursor-pointer', styles.emailList, { 'bg-actionHover': true/* email read status */ })}
                  onClick={() => {
                    setActiveEmailArr(emailArr)
                    setDrawerOpen(true)
                  }}
                >
                  <div className='flex items-center justify-between gap-2'>
                    <div className='flex items-center gap-2 overflow-hidden'>
                      <AvatarWithTexts
                        text={emailArr?.[0]?.from_name}
                        subText={emailArr?.[0]?.from_address}
                        style={{
                          minWidth: '280px',
                          maxWidth: '280px'
                        }}
                      />
                      <div className='overflow-hidden'>
                        <Typography variant='body2' noWrap className='pl-2'>
                          {emailArr?.[0]?.subject}
                        </Typography>
                        <Typography variant='body2' noWrap color='text.disabled' className='pl-2'>
                          {extractHtmlContent(emailArr?.[0]?.bodyHtml)}
                        </Typography>
                      </div>
                    </div>
                    {!isBelowSmScreen && (
                      <div
                        className={cx('flex items-center gap-2', styles.emailInfo, {
                          [styles.show]: true
                        })}
                      >
                        <Typography variant='body2' color='text.disabled' className='whitespace-nowrap'>
                          {
                            new Intl.DateTimeFormat(userLocale, {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit'
                            }).format(new Date(emailArr?.[0]?.sentTimeMillis))
                          }
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </ScrollWrapper>
          <BackdropComponent open={fetching}/>
        </div>
      )
}

export default MailContentList
