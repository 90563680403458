import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import {
  ImporterMapGraphSection,
  ImporterTableSection,
  PotentialMapGraphSection,
  PotentialTableSection
} from './mc-components/McPageComponents'
import FirmDetailsModal from '../components/firm-card/FirmDetailsModal'
import McPageHelpers from './mc-components/McPageHelpers'
import {
  useDSimporterGraphMapSectionExplanation,
  useDSpotentialGraphMapSectionDataExplanation,
  useDStotalImporterCompanyCount,
  useDStotalPotentialCompanyCount,
  useDSrawPotentialMap,
  useDSrawImporterMap,
  useDSselectedTableName
} from '../store/dataStore'
import McPageHeader from '../components/page-header/McPageHeader'
import McPdfExport from './mc-components/McPdfExport'
import { useSDSsearchParams } from '../store/searchDataStore'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const ImporterCompanies = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const searchParams = useSDSsearchParams()
  const selectedComponentIds = usePDFselectedComponentIds()

  const importerGraphMapSectionExplanation = useDSimporterGraphMapSectionExplanation()
  const potentialGraphMapSectionDataExplanation = useDSpotentialGraphMapSectionDataExplanation()
  const totalImporterCompanyCount = useDStotalImporterCompanyCount()
  const totalPotentialCompanyCount = useDStotalPotentialCompanyCount()
  const rawPotentialMap = useDSrawPotentialMap()
  const rawImporterMap = useDSrawImporterMap()
  const selectedTableName = useDSselectedTableName()

  const header = t('graph_importer_analysis') + ' (' + totalImporterCompanyCount + ' ' + t('piece') + ')'
  const headerPotential = t('graph_potential_analysis') + ' (' + totalPotentialCompanyCount + ' ' + t('piece') + ')'
  const importerCountryName = McPageHelpers.findCountryName(searchParams.countryToExport)
  const subTitle = importerCountryName + ' ' + searchParams.hsCode
  const subPotTitle = McPageHelpers.findCountryName('World') + ' ' + searchParams.hsCode

  const searchHeader = searchParams.hsCode + ' - ' + importerCountryName + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)

  const handleScrollParamInUrl = () => {
    const predefinedSearchParams = new URL(window.location.href).searchParams
    if (predefinedSearchParams.size && predefinedSearchParams.get('scroll')) {
      McPageHelpers.scrollToElement(predefinedSearchParams.get('scroll'))
    }
  }
  useEffect(handleScrollParamInUrl, [])

  const importerTableRef = useRef(null)
  const potentialTableRef = useRef(null)
  const pdfDownload = (onlyContent = false) => {
    const importerTablePdfContent = importerTableRef.current.generateImporterTablePdf('importerTablePdfContent')
    const potentialTablePdfContent = potentialTableRef.current.generateImporterTablePdf('potentialTablePdfContent')

    const filterImporterMap = rawImporterMap?.filter(item => item.formatValue !== '')
    const filterPotentialMap = rawPotentialMap?.filter(item => {
      return item.formatValue !== '' || item.avgPrice !== ''
    })

    return McPdfExport.ImporterCompanies(onlyContent, searchHeader, filterImporterMap, filterPotentialMap,
      importerTablePdfContent, potentialTablePdfContent, subTitle, subPotTitle, header, headerPotential, selectedComponentIds, [importerGraphMapSectionExplanation, potentialGraphMapSectionDataExplanation])
  }

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true).then(content => content)
      return content
    }
  }))

  const spTable = useEndpointStatusData.spTable()
  const spGraph = useEndpointStatusData.spGraph()
  const rfTable = useEndpointStatusData.rfTable()
  const spSummary = useEndpointStatusData.spSummary()

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_TABLE, spTable), [spTable])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_GRAPH + McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES_MAP_GRAPH, spGraph), [spGraph])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.RF_TABLE, rfTable), [rfTable])
  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.SP_SUMMARY, spSummary), [spSummary])

  return (
    <React.Fragment>
      <Head title={t('menu.importer_companies')}/>
      <Content>
        <McPageHeader title={t('menu.importer_companies')} />
        <ImporterTableSection ref={importerTableRef}/>
        <ImporterMapGraphSection/>
        <PotentialTableSection ref={potentialTableRef}/>
        <PotentialMapGraphSection/>
        <FirmDetailsModal pageName={selectedTableName}/>
      </Content>
    </React.Fragment>
  )
})

export default ImporterCompanies
