import React from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

// MUI Imports
import {
  Grid,
  Button,
  CardContent,
  CircularProgress
} from '@mui/material'

import CustomTextField from '../atoms/CustomTextField'

const Preview = (props) => {
  const {
    onSubmit,
    selectedTemplate,
    nextLoading
  } = props

  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      mailSubject: ''
    }
  })

  return (
    <CardContent>
      <Grid container spacing={6}>
        <Grid item container xs={12} md={8} direction='column' className='gap-4' sx={{ minHeight: 600 }}>
          {/* <iframe src={'data:text/html,' + encodeURIComponent(selectedTemplate?.template)} style={{ height: '100%' }}/> */}
          <object type="text/html" data={'data:text/html,' + encodeURIComponent(selectedTemplate?.template)} style={{ height: '100%' }}></object>
        </Grid>
        <Grid item container xs={12} md={4} direction='column' className='gap-2'>
            <div
              className='border rounded p-2'
              style={{
                maxHeight: 400,
                overflowY: 'auto'
              }}
            >
              <form id='previewForm' onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Controller
                      name='mailSubject'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          fullWidth
                          label={t('mailTemplate.wizard.previewStep.mailSubject')}
                          placeholder={t('mailTemplate.wizard.previewStep.mailSubjectP')}
                          {...(errors.mailSubject && {
                            error: true,
                            helperText: t('mailTemplate.wizard.previewStep.mailSubjectErr')
                          })}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
            <div className='flex justify-normal sm:justify-end xl:justify-normal'>
              <Button
                form='previewForm'
                type='submit'
                className='is-full'
                variant='contained'
                disabled={nextLoading}
                startIcon={nextLoading ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : null}
              >
                {t('mailTemplate.wizard.previewStep.submit')}
              </Button>
            </div>
        </Grid>
      </Grid>
    </CardContent>
  )
}

export default Preview
