import React from 'react'
import EnglishFlag from '../../images/flags/english.png'
import TurkeyFlag from '../../images/flags/turkey.png'
import { DropdownItem, DropdownMenu } from 'reactstrap'
import { useGlobalStoreActions } from '../../store/globalStore'
import { LANGUAGES } from '../../utils/Utils'

const LangDropdown = ({ className, size }) => {
  const { setLanguage } = useGlobalStoreActions()

  const changeLanguage = (e, language) => {
    e.preventDefault()
    setLanguage(language)
  }

  return (
    <DropdownMenu end className={`${size ? `dropdown-menu-${size}` : ''} ${className || ''}`}>
      <ul className="language-list">
        <li>
          <DropdownItem tag="a" href="#item" className="language-item"
                        onClick={(e) => changeLanguage(e, LANGUAGES.ENGLISH)}>
            <img src={EnglishFlag} alt="" className="language-flag"/>
            <span className="language-name">English</span>
          </DropdownItem>
        </li>
        <li>
          <DropdownItem tag="a" href="#item" className="language-item"
                        onClick={(e) => changeLanguage(e, LANGUAGES.TURKISH)}>
            <img src={TurkeyFlag} alt="" className="language-flag"/>
            <span className="language-name">Türkçe</span>
          </DropdownItem>
        </li>
      </ul>
    </DropdownMenu>
  )
}

export default LangDropdown
