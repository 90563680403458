import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo } from 'react'
import Head from '../layout/head/Head'
import Content from '../layout/content/Content'
import { MarketCalculusCard } from '../components/preview/Preview'
import McTreeList from '../components/treelist/McTreeList'
import { useDScustomsDuties } from '../store/dataStore'
import { useTranslation } from 'react-i18next'
import { useSDSfirstMenuItemLink, useSDSsearchParams, useSDSimaginalUrlSearchParams } from '../store/searchDataStore'
import { useNavigate } from 'react-router-dom'
import { useGlobalStoreData } from '../store/globalStore'
import McPageHeader from '../components/page-header/McPageHeader'
import McPageHelpers from './mc-components/McPageHelpers'
import McPdfExport from './mc-components/McPdfExport'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'
import { DUTIES_COUNTRY_CODES } from '../utils/Constants'

const CustomsDuties = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const languageGSD = useGlobalStoreData.language()
  const customsDuties = useDScustomsDuties()
  const selectedComponentIds = usePDFselectedComponentIds()

  const searchParams = useSDSsearchParams()
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const firstMenuItemLink = useSDSfirstMenuItemLink()

  const importerCountryName = McPageHelpers.findCountryName(searchParams.countryToExport)
  const currentCountryName = McPageHelpers.findCountryName(searchParams.yourCountry)

  const preTitle = importerCountryName + ' ' + searchParams.hsCode + ' '
  const searchHeader = searchParams.hsCode + ' - ' + importerCountryName + ' - ' + currentCountryName

  const handleRestrictedAccess = () => {
    if (!window.location.pathname.includes('/pdf') && !DUTIES_COUNTRY_CODES.includes(searchParams.countryToExport) && !DUTIES_COUNTRY_CODES.includes(new URL(window.location.href).searchParams.get('countryToExport'))) {
      navigate(`${process.env.PUBLIC_URL}/${languageGSD}${firstMenuItemLink}${urlSearchParams ? '?' + urlSearchParams : ''}`)
    }
  }
  useEffect(handleRestrictedAccess, [searchParams.countryToExport])

  const pdfDownload = (onlyContent = false) => McPdfExport.CustomsDuties(onlyContent, searchHeader, customsDuties, preTitle, currentCountryName)

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      if (selectedComponentIds.includes('duties')) {
        const content = await pdfDownload(true).then(content => content)
        return content
      } else return null
    }
  }))

  const duties = useEndpointStatusData.duties()

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.DUTIES, duties), [duties])

  let badgeNumber = useMemo(() => 0, [])
  return (
    <Fragment>
      <Head title={t('menu.duties')}/>
      <Content>
        <McPageHeader title={t('menu.duties')}/>
        {customsDuties.map((baseDuty, baseKey) =>
          <div key={baseKey} className="mb-5">
            {baseDuty.dutiesObj.map((item, key) =>
              <MarketCalculusCard key={'duties-' + badgeNumber}>
                <McTreeList
                  id="hsTaxes"
                  properties={item}
                  badgeCode={`T-GV-0${++badgeNumber}`}
                  title={importerCountryName + ' - ' + item?.title}
                  footer={baseDuty.dutiesObj}
                />
              </MarketCalculusCard>
            )}
            {McPageHelpers.generateExplanationFooterWithWhiteBg(baseDuty.explanation)}
          </div>
        )}

      </Content>
    </Fragment>
  )
})

export default CustomsDuties
