import React, { forwardRef } from 'react'

// MUI imports
import MenuItem from '@mui/material/MenuItem'

// Component imports
import CustomTextField from './CustomTextField'

const CustomSelect = forwardRef((props, ref) => {
  const {
    options,
    labelKey,
    valueKey,
    onChange,
    ...restProps
  } = props

  return (
    <CustomTextField
        select
        SelectProps={{
          onChange
        }}
        {...restProps}
    >
        {
          options?.map((item, index) =>
            <MenuItem key={index} value={item?.[valueKey]}>{item?.[labelKey]}</MenuItem>
          )
        }
    </CustomTextField>
  )
})

export default CustomSelect
