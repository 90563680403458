import React from 'react'

// MUI Imports
import {
  Drawer,
  Typography
} from '@mui/material'
import {
  KeyboardDoubleArrowRight
} from '@mui/icons-material'

// Components Imports
import Flag from '../atomic/atoms/Flag'
import HsChipList from '../atomic/molecules/HsChipList'

const ScrollWrapper = ({ children }) => {
  return <div className='bs-full overflow-y-auto overflow-x-hidden'>{children}</div>
}

const SidebarLeft = props => {
  const {
    isBelowMdScreen,
    sidebarOpen,
    setSidebarOpen,
    campaignDetails
  } = props

  return (
    <>
      <Drawer
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        className='bs-full'
        variant={!isBelowMdScreen ? 'permanent' : 'persistent'}
        ModalProps={{ disablePortal: true, keepMounted: true }}
        sx={{
          zIndex: isBelowMdScreen && sidebarOpen ? 11 : 10,
          position: !isBelowMdScreen ? 'static' : 'absolute',
          height: !isBelowMdScreen ? 'auto' : '100%',
          '& .MuiDrawer-paper': {
            boxShadow: 'none',
            overflow: 'hidden',
            width: '260px',
            position: !isBelowMdScreen ? 'static' : 'absolute'
          }
        }}
      >
        <ScrollWrapper>
          {campaignDetails
            ? <div className='flex flex-col gap-1 plb-4 p-4'>
                <div className='flex justify-center align-center'>
                  <Flag code={campaignDetails?.currentCountryCode} square style={{ fontSize: '3rem', borderRadius: '50%', boxShadow: 'var(--mui-customShadows-primary-md)' }} />
                  <KeyboardDoubleArrowRight color='primary'/>
                  <Flag code={campaignDetails?.importerCountryCode} square style={{ fontSize: '3rem', borderRadius: '50%', boxShadow: 'var(--mui-customShadows-primary-md)' }} />
                </div>
                <div>
                  <Typography variant='h5' align='center'>{campaignDetails?.name}</Typography>
                  <Typography variant='h6' align='center' color='text.disabled'>{campaignDetails?.productName}</Typography>
                </div>
                <div className='flex flex-wrap items-center justify-center gap-1 pt-4'>
                  <HsChipList hsArr={campaignDetails?.hsCodes} visibleCount={29}/>
                </div>
              </div>
            : null
          }
        </ScrollWrapper>
      </Drawer>
    </>
  )
}

export default SidebarLeft
