import React from 'react'
import { Outlet } from 'react-router-dom'
import Head from './head/Head'
import Header from './header/Header'
import Footer from './footer/Footer'
import AppRoot from './global/AppRoot'
import AppWrap from './global/AppWrap'
import { am5AddLicense } from '../utils/Utils'
import { Spinner } from 'reactstrap'
import i18n from 'i18next'
import { usePDFisExcel } from '../store/pdfStore'
import Initialize from './coordinators/Initialize'

const LayoutWithSearch = ({ title, tokenChecked }) => {
  const isExcel = usePDFisExcel()
  am5AddLicense()

  return (
    <>
      {tokenChecked && <Initialize/>}
      <Head title={!title && 'Loading'}/>
      <AppRoot>
        <AppWrap>
          <div className={`pdf-waiting ${(!window.location.pathname.includes('/pdf') && !isExcel) && 'd-none'}`}>
            <Spinner color="primary" style={{ width: '3rem', height: '3rem', marginRight: '1rem' }}/>
            <h4>{i18n.t('prepare_report')}
              <hr/>
              {isExcel ? 'Excel' : 'PDF'}
            </h4>
          </div>
          <Header fixed/>
          <Outlet/>
          <Footer/>
        </AppWrap>
      </AppRoot>
    </>
  )
}
export default LayoutWithSearch
