import React, { forwardRef, useImperativeHandle } from 'react'
import {
  useDataStoreActions,
  useDSfilteredPotentialTable,
  useDSfilteredPotentialTableExplanation,
  useDSglobalParams,
  useDSpotentialSize
} from '../../../store/dataStore'
import CONSTANTS from '../../../utils/Constants'
import { useTranslation } from 'react-i18next'
import { useFilterStoreActions } from '../../../store/filterStore'
import McPageHelpers from '../McPageHelpers'
import McCardTF from '../mc-table-with-filters/McCardTF'
import { FirmCard } from '../../../components/Component'
import { usePDFselectedComponentIds } from '../../../store/pdfStore'

const PotentialTableSection = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const { resetPotentialFilters } = useFilterStoreActions()

  const filteredPotentialTable = useDSfilteredPotentialTable()
  const filteredPotentialTableExplanation = useDSfilteredPotentialTableExplanation()
  const globalParams = useDSglobalParams()
  const potentialSize = useDSpotentialSize()
  const { setShowFirmDetailsModal } = useDataStoreActions()

  const selectedComponentIds = usePDFselectedComponentIds()

  const columns = [
    {
      name: (<div style={{ marginLeft: '14.4px' }}>{t('table_data_table_column_ranking')}</div>),
      selector: row => row.id + 1,
      center: true,
      right: true,
      width: '8%',
      sortable: true
    },
    {
      name: (<div style={{ textAlign: 'center' }}>{t('table_suitable_importer_name')}</div>),
      selector: row => row.importerName,
      wrap: true,
      left: true,
      width: '25%',
      cell: row => (
        <>
          <div
            data-tag="allowRowEvents">{row.importerName?.length > 50 ? row.importerName.slice(0, 50) + '...' : row.importerName}</div>
          <div className="position-fixed" id={'recommendedFirmCard-' + row.id} style={{
            zIndex: '999',
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.1s linear 1s'
          }}>
            <FirmCard id={'rfFirm_' + row.id} firmName={row.importerName}
                      helpText={row.web}
                      hsTotalValue={row.totalValue}
                      numberOfShipments={row.numberOfShipments}
                      tableName={McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES}
                      growth={row.growth}
                      growthValue={row.growthValue}
                      yearChartData={row.yearChartData}
            />
          </div>
        </>
      )
    },
    {
      name: (<div style={{ width: '100px', maxWidth: '100px', textAlign: 'center' }}>{t('table_web')}</div>),
      selector: row => McPageHelpers.generateCardHyperlink(row),
      left: true,
      wrap: true,
      width: '15%'
    },
    {
      name: (<div style={{ width: '100px', maxWidth: '100px', textAlign: 'center' }}>{t('table_sp_total_value')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.totalValue, 'currency'),
      right: true,
      sortable: true,
      wrap: true,
      width: '15%'
    },
    {
      name: (<div
        style={{ width: '100px', maxWidth: '100px', textAlign: 'center' }}>{t('table_sp_number_of_shipments')}</div>),
      selector: row => McPageHelpers.numToLocaleString(row.numberOfShipments),
      right: true,
      sortable: true,
      wrap: true,
      width: '10%'
    },
    {
      name: (<div style={{ overflowWrap: 'normal', textAlign: 'center' }}>{t('table_sector_list')}</div>),
      selector: row => (row.sector_list.map(item => item.sector?.substr(0, 3) === '***' ? item.sector : t(`sectors.${item.sector}`))).join(', '),
      minWidth: '18rem',
      left: true,
      width: '20%'
    },
    {
      name: (<div
        style={{ overflowWrap: 'normal', textAlign: 'center' }}>{t('table_sp_growth')}</div>),
      selector: row => row.growth,
      center: true,
      width: '6%'
    }
  ]

  const onRowClicked = (id) => {
    setShowFirmDetailsModal(true, id, McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES)
  }

  const columnsForPdf = columns.filter(column => column.name.props.children !== t('table_sector_list'))

  useImperativeHandle(ref, () => ({
    generateImporterTablePdf (id) {
      if (selectedComponentIds.includes(id)) {
        const result = [
          McPageHelpers.generateDataTableContentForPdf(columnsForPdf, filteredPotentialTable?.slice(0, 15), true, 'after')
        ]
        if (Array.isArray(filteredPotentialTableExplanation) && filteredPotentialTableExplanation.length > 0) {
          const footerItems = filteredPotentialTableExplanation.map((item, index) => ({
            text: item,
            fontSize: 10,
            margin: index !== filteredPotentialTableExplanation.length - 1 ? [0, 0, 0, 10] : [0, 0, 0, 20],
            color: 'gray',
            italics: true
          }))
          result.push(...footerItems)
        }
        return result
      } else return null
    }
  }))

  return <McCardTF id={McPageHelpers.SECTION_IDS.POTENTIAL_COMPANIES}
                   code={CONSTANTS.BADGE_CODES.POTENTIAL_IMPORTER_TABLE_SECTION}
                   show={potentialSize.raw}
                   header={t('potential_firms')}
                   sub={McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode}
                   rightTxt={t('ai_filter_desc') + ': ' + potentialSize.filtered + ' / ' + potentialSize.raw}
                   fncResetFilter={resetPotentialFilters}
                   data={filteredPotentialTable}
                   columns={columns}
                   onRowClicked={onRowClicked}
                   uniqueId={true}
                   footer={filteredPotentialTableExplanation}
  />
})

export default PotentialTableSection
