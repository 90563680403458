import React from 'react'

import cx from 'classnames'

// MUI Imports
import {
  Card,
  CardContent,
  Divider,
  Typography,
  IconButton
} from '@mui/material'
import {
  Reply,
  Navigation
} from '@mui/icons-material'

// Component Imports
import AvatarWithTexts from '../atomic/atoms/AvatarWithTexts'

// Styles Imports
import styles from '../styles/mailbox.module.scss'

const messageTypes = {
  OUTGOING: 'OUTGOING',
  INCOMING: 'INCOMING'
}

const CardHeaderAction = ({ data, isReplies, setRepliedMessage }) => {
  const userLocale = localStorage.getItem('lang') === 'tr' ? 'tr-TR' : 'en-US'

  return (
    <div className='flex flex-col'>
      <div className='flex items-center justify-end gap-4'>
        <Typography color='text.disabled'>
          {data?.sentTimeMillis &&
            new Intl.DateTimeFormat(userLocale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            }).format(new Date(data.sentTimeMillis))
          }
        </Typography>
        <div className='flex items-center gap-1'>
          <IconButton
          onClick={(e) => {
            setRepliedMessage(data)
          }}
          color='primary'
        >
          <Reply/>
          </IconButton>
        </div>
      </div>

      <Typography color='text.disabled'>
        {data?.subject}
      </Typography>
    </div>
  )
}

const MailCard = ({ data, isReplies, setRepliedMessage }) => {
  return (
    <Card className='border'>
      <CardContent className='flex is-full gap-4 py-2'>
        <div className='flex items-center justify-between flex-wrap grow gap-x-4 gap-y-2'>
          <div className='flex items-center'>
            <AvatarWithTexts
              text={data?.from_address}
              subText={data?.from_name}
            />
            <Navigation
              color='primary'
              sx={{
                rotate: data?.type === messageTypes.INCOMING ? '210deg' : '30deg',
                marginLeft: 1
              }}
            />
          </div>
          <CardHeaderAction data={data} isReplies={isReplies} setRepliedMessage={setRepliedMessage}/>
        </div>
      </CardContent>
      <Divider />
      <CardContent>
        <div
          className={cx('text-textSecondary', styles.message)}
          dangerouslySetInnerHTML={{ __html: data?.bodyHtml }}
        />
        {data?.attachments?.length
          ? (
          <div className='flex flex-col gap-4'>
            <hr className='border-be -mli-6 mbs-4' />
            <Typography variant='caption'>Attachments</Typography>
            {data.attachments.map(attachment => (
              <div key={attachment.fileName} className='flex items-center gap-2'>
                <img src={attachment.thumbnail} alt={attachment.fileName} className='bs-6' />
                <Typography>{attachment.fileName}</Typography>
              </div>
            ))}
          </div>
            )
          : null}
      </CardContent>
    </Card>
  )
}

export default MailCard
