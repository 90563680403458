import React from 'react'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import {
  Cached,
  Edit
} from '@mui/icons-material'

const MailContentActions = ({ reloadMessages, fetching, setOpenCompose }) => {
  return (
    <div
      className='flex items-center justify-between gap-4 max-sm:gap-0.5 is-full pli-4 plb-2 border-be'
      style={{ borderBottom: '1px solid var(--mui-palette-divider)' }}
    >
      <div className='flex items-center gap-1 max-sm:gap-0.5'>
      <IconButton
        color='primary'
        onClick={() => setOpenCompose(true)}
      >
        <Edit/>
      </IconButton>
      </div>
      <div className='flex gap-1 max-sm:gap-0.5'>
        <IconButton
          onClick={reloadMessages}
          color='primary'
          disabled={fetching}
        >
          <Cached/>
        </IconButton>
      </div>
    </div>
  )
}

export default MailContentActions
