import React from 'react'

import CustomTextField from '../atoms/CustomTextField'
import CustomAutocomplete from '../atoms/CustomAutocomplete'

const MultipleTextInput = (props) => {
  const {
    label,
    value,
    onChange,
    placeholder,
    errorText,
    textFieldProps
  } = props

  return (
    <CustomAutocomplete
      multiple
      options={[]}
      value={value}
      freeSolo
      autoSelect
      onChange={onChange}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <CustomTextField
          {...textFieldProps}
          {...params}
          placeholder={placeholder}
          value={value}
          error={errorText}
          helperText={errorText}
          label={label}
        />
      )}
    />
  )
}

export default MultipleTextInput
