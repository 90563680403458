import './RichTextEditorTheme.css'

const theme = {
  autocomplete: 'RichTextEditorTheme__autocomplete',
  blockCursor: 'RichTextEditorTheme__blockCursor',
  characterLimit: 'RichTextEditorTheme__characterLimit',
  code: 'RichTextEditorTheme__code',
  codeHighlight: {
    atrule: 'RichTextEditorTheme__tokenAttr',
    attr: 'RichTextEditorTheme__tokenAttr',
    boolean: 'RichTextEditorTheme__tokenProperty',
    builtin: 'RichTextEditorTheme__tokenSelector',
    cdata: 'RichTextEditorTheme__tokenComment',
    char: 'RichTextEditorTheme__tokenSelector',
    class: 'RichTextEditorTheme__tokenFunction',
    'class-name': 'RichTextEditorTheme__tokenFunction',
    comment: 'RichTextEditorTheme__tokenComment',
    constant: 'RichTextEditorTheme__tokenProperty',
    deleted: 'RichTextEditorTheme__tokenProperty',
    doctype: 'RichTextEditorTheme__tokenComment',
    entity: 'RichTextEditorTheme__tokenOperator',
    function: 'RichTextEditorTheme__tokenFunction',
    important: 'RichTextEditorTheme__tokenVariable',
    inserted: 'RichTextEditorTheme__tokenSelector',
    keyword: 'RichTextEditorTheme__tokenAttr',
    namespace: 'RichTextEditorTheme__tokenVariable',
    number: 'RichTextEditorTheme__tokenProperty',
    operator: 'RichTextEditorTheme__tokenOperator',
    prolog: 'RichTextEditorTheme__tokenComment',
    property: 'RichTextEditorTheme__tokenProperty',
    punctuation: 'RichTextEditorTheme__tokenPunctuation',
    regex: 'RichTextEditorTheme__tokenVariable',
    selector: 'RichTextEditorTheme__tokenSelector',
    string: 'RichTextEditorTheme__tokenSelector',
    symbol: 'RichTextEditorTheme__tokenProperty',
    tag: 'RichTextEditorTheme__tokenProperty',
    url: 'RichTextEditorTheme__tokenOperator',
    variable: 'RichTextEditorTheme__tokenVariable'
  },
  embedBlock: {
    base: 'RichTextEditorTheme__embedBlock',
    focus: 'RichTextEditorTheme__embedBlockFocus'
  },
  hashtag: 'RichTextEditorTheme__hashtag',
  heading: {
    h1: 'RichTextEditorTheme__h1',
    h2: 'RichTextEditorTheme__h2',
    h3: 'RichTextEditorTheme__h3',
    h4: 'RichTextEditorTheme__h4',
    h5: 'RichTextEditorTheme__h5',
    h6: 'RichTextEditorTheme__h6'
  },
  hr: 'RichTextEditorTheme__hr',
  image: 'editor-image',
  indent: 'RichTextEditorTheme__indent',
  inlineImage: 'inline-editor-image',
  layoutContainer: 'RichTextEditorTheme__layoutContainer',
  layoutItem: 'RichTextEditorTheme__layoutItem',
  link: 'RichTextEditorTheme__link',
  list: {
    checklist: 'RichTextEditorTheme__checklist',
    listitem: 'RichTextEditorTheme__listItem',
    listitemChecked: 'RichTextEditorTheme__listItemChecked',
    listitemUnchecked: 'RichTextEditorTheme__listItemUnchecked',
    nested: {
      listitem: 'RichTextEditorTheme__nestedListItem'
    },
    olDepth: [
      'RichTextEditorTheme__ol1',
      'RichTextEditorTheme__ol2',
      'RichTextEditorTheme__ol3',
      'RichTextEditorTheme__ol4',
      'RichTextEditorTheme__ol5'
    ],
    ul: 'RichTextEditorTheme__ul'
  },
  ltr: 'RichTextEditorTheme__ltr',
  mark: 'RichTextEditorTheme__mark',
  markOverlap: 'RichTextEditorTheme__markOverlap',
  paragraph: 'RichTextEditorTheme__paragraph',
  quote: 'RichTextEditorTheme__quote',
  rtl: 'RichTextEditorTheme__rtl',
  table: 'RichTextEditorTheme__table',
  tableCell: 'RichTextEditorTheme__tableCell',
  tableCellActionButton: 'RichTextEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer:
    'RichTextEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'RichTextEditorTheme__tableCellEditing',
  tableCellHeader: 'RichTextEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'RichTextEditorTheme__tableCellPrimarySelected',
  tableCellResizer: 'RichTextEditorTheme__tableCellResizer',
  tableCellSelected: 'RichTextEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'RichTextEditorTheme__tableCellSortedIndicator',
  tableResizeRuler: 'RichTextEditorTheme__tableCellResizeRuler',
  tableSelected: 'RichTextEditorTheme__tableSelected',
  tableSelection: 'RichTextEditorTheme__tableSelection',
  text: {
    bold: 'RichTextEditorTheme__textBold',
    code: 'RichTextEditorTheme__textCode',
    italic: 'RichTextEditorTheme__textItalic',
    strikethrough: 'RichTextEditorTheme__textStrikethrough',
    subscript: 'RichTextEditorTheme__textSubscript',
    superscript: 'RichTextEditorTheme__textSuperscript',
    underline: 'RichTextEditorTheme__textUnderline',
    underlineStrikethrough: 'RichTextEditorTheme__textUnderlineStrikethrough'
  }
}

export default theme
