import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

// MUI Imports
import {
  IconButton,
  InputAdornment,
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

// Component Imports
import DialogCloseButton from '../atoms/DialogCloseButton'
import CustomTextField from '../atoms/CustomTextField'
import CustomSelect from '../atoms/CustomSelect'

import { updateUser } from '../../../services/B2BServices'
import { getUserDetailsAndProcess } from '../../../../../services/DataServiceMethods'

import { useGlobalStoreActions } from '../../../../../store/globalStore'

import { emailRegExp } from '../../../utils/constants'

const emailValidation = (v) => {
  // const {t,i18n}=useTranslation();
  if (v) {
    return emailRegExp.test(v) || 'invalidEmailAddress'
  } else return 'isRequired'
}

const McTransportStrategy = [ // mc-backend ENUM
  {
    label: 'SMTP',
    value: 'SMTP'
  },
  {
    label: 'SMTPS',
    value: 'SMTPS'
  },
  {
    label: 'SMTP_TLS',
    value: 'SMTP_TLS'
  },
  {
    label: 'SMTP_OAUTH2',
    value: 'SMTP_OAUTH2'
  }
]

const EmailReceiveProtocol = [ // mc-backend ENUM
  {
    label: 'IMAP', // imap
    value: 'IMAP'
  },
  {
    label: 'IMAPS', // imaps
    value: 'IMAPS'
  },
  {
    label: 'POP3', // pop3
    value: 'POP3'
  }
]

const EditUserEmailSettingsModal = (props) => {
  const {
    userEditState,
    close,
    userGSD,
    open
  } = props
  const { t } = useTranslation()

  const { setUserData } = useGlobalStoreActions()
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    reset({
      emailUserName: userEditState?.emailSettings?.emailUserName || '',
      emailPassword: userEditState?.emailSettings?.emailPassword || '',
      smtpHostName: userEditState?.emailSettings?.smtpHostName || '',
      smtpPort: userEditState?.emailSettings?.smtpPort || '',
      transportStrategy: userEditState?.emailSettings?.transportStrategy || '',
      rxHostName: userEditState?.emailSettings?.rxHostName || '',
      rxPort: userEditState?.emailSettings?.rxPort || '',
      rxProtocol: userEditState?.emailSettings?.rxProtocol || '',
      inboxFolderName: userEditState?.emailSettings?.inboxFolderName || ''
    })
  }, [userEditState])

  const handleClose = () => {
    reset()
    close()
  }

  const handleClickShowPassword = () => setIsPasswordShown((show) => !show)
  const onSubmit = (val) => {
    const payload = {
      emailSettings: {
        emailUserName: val.emailUserName,
        emailPassword: val.emailPassword,
        smtpHostName: val.smtpHostName,
        smtpPort: Number(val.smtpPort),
        transportStrategy: val.transportStrategy,
        rxHostName: val.rxHostName,
        rxPort: Number(val.rxPort),
        rxProtocol: val.rxProtocol,
        inboxFolderName: val.inboxFolderName
      }
    }

    setLoading(true)
    updateUser(userEditState?.id, payload)
      .then((res) => {
        getUserDetailsAndProcess(true, userGSD, setUserData, true)
      })
      .catch((err) => {
        err && toast.error(t('mailTemplate.users.modal.toastErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
      .finally(() => {
        setLoading(false)
        handleClose()
      })
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth='md'
      scroll='body'
      sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
    >
      <DialogCloseButton onClick={close} disableRipple>
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle
        variant='h4'
        className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-6 sm:pli-16'
      >
        {t('mailTemplate.users.modal.title')}
        <Typography component='span' className='flex flex-col text-center'>
          {t('mailTemplate.users.modal.subTitle')}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className='overflow-visible pbs-0 sm:pli-16'>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='emailUserName'
                control={control}
                // rules={{ required: true }}
                rules={{
                  validate: {
                    required: (value) => emailValidation(value)
                  }
                }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.emailUserName')}
                    placeholder={t(
                      'mailTemplate.users.modal.emailUserNameP'
                    )}
                    {...(errors.emailUserName && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.emailUserName'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='emailPassword'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.emailPassword')}
                    placeholder={t(
                      'mailTemplate.users.modal.emailPasswordP'
                    )}
                    type={isPasswordShown ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onClick={handleClickShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                            aria-label='toggle password visibility'
                          >
                            {isPasswordShown
                              ? (
                              <VisibilityOffIcon />
                                )
                              : (
                              <VisibilityIcon />
                                )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...(errors.emailPassword && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.emailPassword'
                      )
                    })}
                  />
                )}
              />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Controller
                name='smtpHostName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.smtpHostName')}
                    placeholder={t(
                      'mailTemplate.users.modal.smtpHostNameP'
                    )}
                    {...(errors.smtpHostName && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.smtpHostName'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='smtpPort'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.smtpPort')}
                    placeholder={t('mailTemplate.users.modal.smtpPortP')}
                    type='number'
                    {...(errors.smtpPort && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.smtpPort'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='transportStrategy'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    fullWidth
                    options={McTransportStrategy}
                    labelKey='label'
                    valueKey='value'
                    label={t('mailTemplate.users.modal.transportStrategy')}
                    defaultValue=''
                    {...(errors.transportStrategy && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.transportStrategy'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='rxHostName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.rxHostName')}
                    placeholder={t('mailTemplate.users.modal.rxHostNameP')}
                    {...(errors.rxHostName && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.rxHostName'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='rxPort'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.rxPort')}
                    placeholder={t('mailTemplate.users.modal.rxPortP')}
                    type='number'
                    {...(errors.rxPort && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.rxPort'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='rxProtocol'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    fullWidth
                    options={EmailReceiveProtocol}
                    labelKey='label'
                    valueKey='value'
                    label={t('mailTemplate.users.modal.rxProtocol')}
                    defaultValue=''
                    {...(errors.rxProtocol && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.rxProtocol'
                      )
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='inboxFolderName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label={t('mailTemplate.users.modal.inboxFolderName')}
                    placeholder={t(
                      'mailTemplate.users.modal.inboxFolderNameP'
                    )}
                    {...(errors.inboxFolderName && {
                      error: true,
                      helperText: t(
                        'mailTemplate.users.modal.errors.inboxFolderName'
                      )
                    })}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='justify-center pbs-0 sm:pbe-16 sm:pli-16'>
          <Button
            variant='contained'
            type='submit'
            disabled={loading}
            startIcon={loading ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : null}
          >
            {t('mailTemplate.users.modal.submit')}
          </Button>
          <Button
            variant='tonal'
            color='secondary'
            type='reset'
            onClick={handleClose}
          >
            {t('mailTemplate.users.modal.cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditUserEmailSettingsModal
