import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './utils/i18n'

import './assets/scss/dashlite.scss'
import './assets/scss/style-email.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </>
)
