import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import LogoDark from '../../images/logo-dark.png'
import Head from '../../layout/head/Head'
import AuthFooter from './AuthFooter'
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  RSelect
} from '../../components/Component'
import { Alert, Spinner } from 'reactstrap'
import { useForm } from 'react-hook-form'
import CONSTANTS from '../../utils/Constants'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../store/globalStore'
import { LANGUAGES } from '../../utils/Utils'
import { countryTR } from '../../assets/data/country-tr'
import { countryEN } from '../../assets/data/country-en'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import McDataServices from '../../services/McDataServices'
import McPageHelpers from '../mc-components/McPageHelpers'

const Register = () => {
  const [passState, setPassState] = useState(false)
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [errorVal, setError] = useState('')
  const [passError, setPassError] = useState('')
  const [passValidate, setPassValidate] = useState('')
  const [token, setToken] = useState()
  const { t } = useTranslation()
  const [selected, setSelected] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  const [isMatch, setIsMatch] = useState(true)
  const captchaRef = useRef(null)
  const [externalRegister, setExternalRegister] = useState(false)
  const [emptyCity, setEmptyCity] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [cityValue, setCityValue] = useState('')

  const languageGSD = useGlobalStoreData.language()
  const [isVisible, setIsVisible] = useState({
    error1: true,
    error2: true
  })

  let country
  if (languageGSD === LANGUAGES.TURKISH) {
    country = countryTR
  } else {
    country = countryEN
  }

  const onVerify = useCallback(token => setToken(token), [])
  const onExpire = useCallback(() => setToken(undefined), [])

  const sortedCountryData = country?.sort((a, b) => {
    const countryNameA = a.countryName.toLowerCase()
    const countryNameB = b.countryName.toLowerCase()

    if (countryNameA < countryNameB) {
      return -1
    }
    if (countryNameA > countryNameB) {
      return 1
    }
    return 0
  })

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,24}$/
    const isValid = regex.test(email)
    setIsValidEmail(isValid)
  }

  const handleFormSubmit = (formData) => {
    setLoading(true)
    const registerDTO = {
      nameSurname: formData.name,
      email: formData.email,
      companyName: formData.companyName,
      password: formData.password,
      city: formData.city,
      confirmPassword: formData.confirmPassword,
      countryCode: selected,
      tel: formData.gsm,
      purpose: formData.purpose,
      roles: [{ name: 'USER' }]
    }

    if (formData.passcode !== formData.confirmPasscode) {
      setLoading(false)
      return setPassError(t('not_match_password'))
    } else if (!formData.city && selected === 'TR') {
      setLoading(false)
      return setEmptyCity(true)
    } else if (!isValidEmail) {
      setLoading(false)
      return setIsValidEmail(false)
    } else if (!token) {
      setLoading(false)
      return setPassError(t('not_captcha_verified'))
    } else {
      McDataServices.register(registerDTO, token).then(result => {
        if (result.status === 200) {
          setTimeout(() => {
            const submitted = externalRegister ? McPageHelpers.REGISTER_STATES.EXTERNAL : McPageHelpers.REGISTER_STATES.SUBMITTED
            window.history.pushState({ form: submitted }, '', `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : `/${languageGSD}/success?email=${registerDTO.email}`}`)
            window.location = `${process.env.PUBLIC_URL}`
          }, 2000)
        }
      }).catch(error => {
        setTimeout(() => {
          if (error.type === 'UniqueException') {
            setLoading(false)
            return setError(t('already_account'))
          }

          error?.detail?.forEach(item => {
            if (item.includes('password')) {
              setError(t('required_pass_control'))
              setLoading(false)
            }
            if (item.includes('countryCode')) {
              setError(t('country_required'))
              setLoading(false)
            }
          })
        }, 2000)
      }).finally(() => {
        captchaRef.current._hcaptcha.reset()
        setToken(undefined)
      })
    }
  }
  const handleCountryChange = (data) => {
    setSelected(data.value)
    return data.value
  }

  const validateNumber = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '')

    if (newValue.length === 11 && newValue === event.target.value) {
      setPassValidate(false)
    } else {
      setPassValidate(true)
    }

    event.target.value = newValue
  }

  const errorVals = {
    error1: errorVal,
    error2: passError
  }

  const toggleAlert = (errorKey) => {
    setIsVisible(prevState => ({
      ...prevState,
      [errorKey]: !prevState[errorKey]
    }))
  }

  const validateCity = (cityValue) => {
    setCityValue(cityValue)

    if (cityValue.trim().length > 0 || selected !== 'TR') {
      setEmptyCity(false)
    } else if (selected === 'TR' && !cityValue.trim().length) {
      setEmptyCity(true)
    }
  }

  useEffect(() => {
    if (selected === 'TR' && !cityValue.trim().length) {
      setEmptyCity(true)
    } else {
      setEmptyCity(false)
    }
  }, [selected, cityValue])

  useEffect(() => {
    if (window.location.href.includes('/register-external')) setExternalRegister(true)
    else setExternalRegister(false)
  }, [window.location.href])

  return <>
    <Head title={t('register')}/>
    <Block className="nk-block-middle nk-auth-body wide-sm">
      {!externalRegister
        ? <div className="brand-logo pb-4 text-center">
        <Link to={`${process.env.PUBLIC_URL + '/' + languageGSD}/`} className="logo-link">
          <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
          <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
        </Link>
      </div>
        : ''}
      <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">{t('register')}</BlockTitle>
            <BlockDes>
              <p>{t('new_account')}</p>
            </BlockDes>
          </BlockContent>
        </BlockHead>

        {isVisible.error1 && errorVal && (
          <Alert color="danger" className="alert-icon" toggle={() => toggleAlert('error1')}>
            {errorVals.error1}
          </Alert>
        )}

        {isVisible.error2 && passError && (
          <Alert color="danger" className="alert-icon" toggle={() => toggleAlert('error2')}>
            {errorVals.error2}
          </Alert>
        )}

        <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="email">
                    {t('email')} *
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    {...register('email', { required: true })}
                    onChange={(e) => validateEmail(e.target.value)}
                    className="form-control-lg form-control"
                    placeholder={t('email')}/>
                  {!isValidEmail && <p className="error-invalid">{t('valid_email')}</p>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="name">
                  {t('name_surname')} *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    {...register('name', { required: true })}
                    placeholder={t('name_surname')}
                    className="form-control-lg form-control"/>
                  {errors.name && <p className="error-invalid">{t('this_field_cannot_be_left_blank')}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="companyName">
                  {t('company_name')} *
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    {...register('companyName', { required: true })}
                    placeholder={t('company_name')}
                    className="form-control-lg form-control"/>
                  {errors.companyName && <p className="error-invalid">{t('this_field_cannot_be_left_blank')}</p>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="gsm">
                  {t('gsm')} *
                </label>
                <div className="form-control-wrap">
                  <input type="tel"
                         id="gsm"
                         name="gsm"
                         {...register('gsm', { required: true })}
                         placeholder="05**"
                         className="form-control-lg form-control"
                         onChange={validateNumber}/>
                  {passValidate && <p className="error-invalid">{t('error_gsm')}</p>}
                </div>
              </div>
            </div>

          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    {t('password')}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setPassState(!passState)
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? 'text' : 'password'}
                    id="password"
                    name="password"
                    {...register('password', {
                      required: true,
                      maxLength: 30,
                      minLength: 8,
                      pattern: {
                        value: '^(?=.[A-Z])(?=.d).{8,}$,'
                      }
                    })}
                    onChange={event => {
                      const target = event.target.value
                      setPassword(target)
                      if (target !== confirmPassword) {
                        return setIsMatch(false)
                      } else return setIsMatch(true)
                    }}
                    placeholder={t('password')}
                    className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}/>
                  {isMatch && errors.password &&
                    <span className="error-invalid">{t('required_pass_control')}</span>}
                  {isMatch && !McPageHelpers.checkPasswordValidity(password) && password &&
                    <span className="error-invalid">{t('required_pass_control')}</span>}
                  {!isMatch && confirmPassword && <span className="error-invalid">{t('not_match_password')}</span>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="confirmPassword">
                    {t('password_again')}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setPassState(!passState)
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? 'text' : 'password'}
                    id="confirmPassword"
                    name="confirmPassword"
                    {...register('confirmPassword', {
                      required: t('required_pass_control'),
                      maxLength: 30,
                      minLength: 8,
                      pattern: {
                        value: '^(?=.[A-Z])(?=.d).{8,}$,'
                      }
                    })}
                    aria-describedby="-error"
                    onChange={event => {
                      const target = event.target.value
                      setConfirmPassword(target)
                      if (target !== password) {
                        return setIsMatch(false)
                      } else setIsMatch(true)
                    }
                    }
                    placeholder={t('password_again')}
                    className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="country">
                  {t('country')} *
                </label>
                <div className="form-control-wrap">
                  <RSelect id="country"
                           name="country"
                           options={sortedCountryData?.map(item => ({
                             value: item.countryCode,
                             label: item.countryName
                           }))}
                           onChange={(selected) => handleCountryChange(selected)}
                  />
                  {!selected && <p className="error-invalid">{t('this_field_cannot_be_left_blank')}</p>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  {t('city')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="city"
                    {...register('city')}
                    placeholder={t('city')}
                    onChange={(e) => validateCity(e.target.value)}
                    className="form-control-lg form-control"
                  />
                  {emptyCity && <p className="error-invalid">{t('this_field_cannot_be_left_blank')}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="purpose">
                  {t('purpose')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="purpose"
                    {...register('purpose')}
                    placeholder={t('purpose')}
                    className="form-control-lg form-control"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-control-wrap">
                  <HCaptcha onVerify={onVerify} sitekey={CONSTANTS.CAPTCHA_SITEKEY}
                            onExpire={onExpire} languageOverride={languageGSD} ref={captchaRef}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-group">
                <Button type="submit" color="primary" size="lg" className="btn-block">
                  {loading ? <Spinner size="sm" color="light"/> : t('register')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </PreviewCard>
      {!externalRegister
        ? <div className="form-note-s2 text-center pt-4">
          {' '}
          {t('already_register_account')}{' '}
          <Link to={`${process.env.PUBLIC_URL + '/' + languageGSD}/login`}>
            <strong>{t('login')}</strong>
          </Link>
        </div>
        : ''}
    </Block>
    {!externalRegister ? <AuthFooter/> : ''}
  </>
}
export default Register
