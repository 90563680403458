import React from 'react'
import cx from 'classnames'

const LineThroughButton = (props) => {
  const { isActive, activeColor, children, ...restProps } = props

  return (
    <button
        className={cx('btn', isActive ? 'btn-primary' : '')}
        style={isActive
          ? {
              backgroundColor: activeColor,
              borderColor: activeColor
            }
          : null
        }
        {...restProps}
    >
        {children}
    </button>
  )
}

export default LineThroughButton
