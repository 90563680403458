import React from 'react'
import EnglishFlag from '../../images/flags/english.png'
import TurkeyFlag from '../../images/flags/turkey.png'
import { Link } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useGlobalStoreActions, useGlobalStoreData } from '../../store/globalStore'
import { LANGUAGES } from '../../utils/Utils'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const languageGSD = useGlobalStoreData.language()
  const versionGSD = useGlobalStoreData.version()
  const { setLanguage } = useGlobalStoreActions()
  const { t } = useTranslation()

  const changeLanguage = (e, language) => {
    e.preventDefault()
    setLanguage(language)
  }

  return (
    <div className="nk-footer bg-white">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            &copy; 2022 - {new Date().getFullYear()} <a href="https://marketcalculus.ai" target="_blank"
                                                        rel="noreferrer">Market
            Calculus</a> {versionGSD}
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <Link to={`${process.env.PUBLIC_URL + '/' + languageGSD}/pages/terms`} className="nav-link">
                  {t('terms')}
                </Link>
              </li>
              <li className="nav-item">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>{languageGSD === LANGUAGES.TURKISH ? 'Türkçe' : 'English'}</span>
                  </DropdownToggle>
                  <DropdownMenu end className="dropdown-menu-sm">
                    <ul className="language-list">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(e) => changeLanguage(e, LANGUAGES.ENGLISH)}
                          className="language-item"
                        >
                          <img src={EnglishFlag} alt="" className="language-flag"/>
                          <span className="language-name">English</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(e) => changeLanguage(e, LANGUAGES.TURKISH)}
                          className="language-item"
                        >
                          <img src={TurkeyFlag} alt="" className="language-flag"/>
                          <span className="language-name">Türkçe</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
