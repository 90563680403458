import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDSglobalParams } from '../../../store/dataStore'
import { useGlobalStoreData } from '../../../store/globalStore'
import McPageHelpers from '../McPageHelpers'
import { McDataTable } from '../../../components/Component'
import { usePDFselectedComponentIds } from '../../../store/pdfStore'

const PriceAnalysisTable = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const globalParams = useDSglobalParams()
  const countriesGSD = useGlobalStoreData.countries()
  const [generateData, setGenerateData] = useState(props?.data)
  const selectedComponentIds = usePDFselectedComponentIds()

  useEffect(() => {
    const newData = props?.data.filter(item => !item['hsQuantityTotalValue' + globalParams.selectedUnitNumber]?.every(val => val === 0))
    setGenerateData(newData)
  }, [props?.data, globalParams.selectedUnitNumber])

  const customStyles = {
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '10px'
      }
    }
  }

  const calculateAvgPrice = (row, index) => {
    const selectedRow = row['hsQuantityTotalValue' + globalParams.selectedUnitNumber]
    return selectedRow && selectedRow[index] ? McPageHelpers.numToLocaleString(selectedRow[index] / row['hsTotalQuantity' + globalParams.selectedUnitNumber][index], 'decimal', false, 2, 2) : '-'
  }

  const getColumnsName = (value, type) => {
    const date = value?.toString() ?? '-'
    const lastTwoCharacter = date.slice(-2)
    const firstFourCharacter = date.slice(0, 4)
    const result = McPageHelpers.getMonthName(lastTwoCharacter)

    if (type === McPageHelpers.KEY.MONTHLY) {
      return firstFourCharacter + '/' + t(`months.${result}`)
    }
    return t(`months.${result}`)
  }

  const columns = [
    {
      name: (<div>{t('market_change_country')}</div>),
      selector: row => McPageHelpers.findCountryName(row.countryCode, countriesGSD),
      width: '8%',
      wrap: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[0], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 0),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[1], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 1),
      right: true,
      width: '7%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[2], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 2),
      right: true,
      width: '7%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[3], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 3),
      right: true,
      width: '7%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[4], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 4),
      right: true,
      width: '7%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[5], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 5),
      right: true,
      width: '7%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[6], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 6),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[7], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 7),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[8], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 8),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[9], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 9),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[10], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 10),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    },
    {
      name: (<div>{getColumnsName(props.yearMonths[11], McPageHelpers.KEY.MONTHLY)}</div>),
      selector: row => calculateAvgPrice(row, 11),
      right: true,
      width: '8%',
      wrap: true,
      sortable: true
    }
  ]

  useImperativeHandle(ref, () => ({
    generatePriceTablePdf (id) {
      if (selectedComponentIds.includes(id)) {
        return McPageHelpers.generateDataTableContentForPdf(columns, generateData, true, 'after')
      } else return null
    }
  }))
  return (
    <>
      <div className={'g-3 w-fit'}>
        <McDataTable
          data={generateData}
          columns={columns}
          id="priceAnalysisTable"
          customSort={McPageHelpers.mcDataTableCustomSort}
          customStyles={customStyles}
          responseCountry={countriesGSD}
          isSearchWithCountry
          pagination
          footer={props.footer}
        /></div>
    </>
  )
})
export default PriceAnalysisTable
