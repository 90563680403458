import B2BAPI from './B2BAPI'

export const getCampaigns = async (params) => {
  try {
    const response = await B2BAPI.get('/campaign', {
      params: {
        ...params
      }
    })
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCampaign = async (b2bCampaignId) => {
  try {
    const response = await B2BAPI.get(`/campaign/${b2bCampaignId}`)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createCampaign = async (payload) => {
  try {
    const response = await B2BAPI.post('/campaign', payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getFirms = async (params) => {
  try {
    const response = await B2BAPI.get('/firms', {
      params: {
        ...params
      }
    })
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getTemplates = async (params) => {
  try {
    const response = await B2BAPI.get('/email-template', {
      params: {
        ...params
      }
    })
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const sendEmail = async (payload) => {
  try {
    const response = await B2BAPI.post('/schedule-email', payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const sendEmailReply = async (payload) => {
  try {
    const response = await B2BAPI.post('/send-reply', payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getMessages = async (params) => {
  try {
    const response = await B2BAPI.get('/campaign/messages', {
      params: {
        ...params
      }
    })
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const receive = async (params) => {
  try {
    const response = await B2BAPI.get('/receive', {
      params: {
        ...params
      }
    })
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUser = async (userId, payload) => {
  try {
    const response = await B2BAPI.put(`/account/${userId}`, payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createTemplate = async (payload) => {
  try {
    const response = await B2BAPI.post('/email-template', payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateTemplate = async (templateId, payload) => {
  try {
    const response = await B2BAPI.put(`/email-template/${templateId}`, payload)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteTemplate = async (templateId) => {
  try {
    const response = await B2BAPI.delete(`/email-template/${templateId}`)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteCampaign = async (campaignId) => {
  try {
    const response = await B2BAPI.delete(`/campaign/${campaignId}`)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getCampaignReport = async (b2bCampaignId) => {
  try {
    const response = await B2BAPI.get(`/report/campaign/${b2bCampaignId}`)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAccountReport = async (b2bAccountId) => {
  try {
    const response = await B2BAPI.get(`/report/account/${b2bAccountId}`)
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
