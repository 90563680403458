import React from 'react'
import { Navigate } from 'react-router-dom'

import { useGlobalStoreData } from '../store/globalStore'

const withAuth = (Component) => (props) => {
  const isLoggedInGSD = useGlobalStoreData.isLoggedIn()
  const languageGSD = useGlobalStoreData.language()

  const redirectPath = languageGSD + '/login'

  return isLoggedInGSD ? <Component {...props}/> : <Navigate to={redirectPath} replace />
}

export default withAuth
