import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { initializeChart } from './ComTradeMarketChangeChart.settings'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import MCTitleCard from '../title-card/MCTitleCard'
import { MARKET_CHANGE_CHART_TYPES } from '../../utils/AMChartsSettings'
import { CUSTOM_PALETTE_HEX } from '../../utils/AmChartsUtils'
import LineThroughButton from '../button/LineThroughButton'

const ComTradeMarketChangeChart = ({
  chartId,
  chartTitle,
  chartData,
  quantityTitle,
  percentageTitle,
  chartSubTitle,
  code,
  footer,
  chartType,
  countries
}) => {
  const { t } = useTranslation()

  const [activeCountryCodes, setActiveCountryCodes] = useState([])
  const [yearMonthVisibility, setYearMonthVisibility] = useState({
    month: true,
    year: true
  })

  const toggleVisibility = {
    month: () => setYearMonthVisibility(prevState => ({
      ...prevState,
      month: !prevState.month
    })),
    year: () => setYearMonthVisibility(prevState => ({
      ...prevState,
      year: !prevState.year
    }))
  }

  const toggleCountry = (countryCode) => {
    setActiveCountryCodes((prevState) => {
      const managed = [...prevState]

      if (prevState.includes(countryCode)) {
        managed.splice(prevState.indexOf(countryCode), 1)
      } else {
        managed.push(countryCode)
      }

      return managed
    })
  }

  useLayoutEffect(() =>
    initializeChart(chartId, chartData, yearMonthVisibility, activeCountryCodes, chartType),
  [
    yearMonthVisibility,
    chartData?.length,
    activeCountryCodes?.length
  ])
  useEffect(() => {
    setActiveCountryCodes(chartData?.map(item => item[0]._country ?? false))
  }, [chartData?.length])

  return (
    <>
      <div id={chartId + McPageHelpers.FOR_PDF_ID} className={chartData?.length ? '' : 'loading-div'}>
        <MCTitleCard chartTitle={chartTitle} chartSubTitle={chartSubTitle} code={code} codeColor={'light'}/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem'
          }}
        >
          {chartData?.length > 1
            ? <div style={{ display: 'flex', gap: '1rem' }}>
                {
                  chartData?.map((item, index) =>
                    <LineThroughButton
                      key={index}
                      id={`country-${chartId + item[0]._country}`}
                      onClick={() => toggleCountry(item[0]._country)}
                      isActive={activeCountryCodes?.includes(item[0]._country)}
                      activeColor={CUSTOM_PALETTE_HEX[index]}
                    >
                      {countries?.filter(country => country.countryCode === item[0]._country)[0]?.countryName ?? item[0]._countryName}
                    </LineThroughButton>
                  )
                }
              </div>
            : null
          }
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              visibility: `${chartType === MARKET_CHANGE_CHART_TYPES.PERCENTAGE ? 'visible' : 'hidden'}`
            }}
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked
                id={chartId + 'monthOnMonth'}
                onClick={toggleVisibility.month}
              />
              <label className="custom-control-label" htmlFor={chartId + 'monthOnMonth'}> {t('monthly')} </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                defaultChecked id={chartId + 'yearOnYear'}
                onClick={toggleVisibility.year}
              />
              <label className="custom-control-label" htmlFor={chartId + 'yearOnYear'}> {t('yearly')} </label>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p
              style={{
                transform: 'rotate(-90deg)',
                fontSize: '10px',
                whiteSpace: 'nowrap',
                width: '20px'
              }}
            >
              {chartType === MARKET_CHANGE_CHART_TYPES.VALUE_OR_QUANTITY || MARKET_CHANGE_CHART_TYPES.QUANTITY ? quantityTitle : percentageTitle}
            </p>
          </div>
          <div
            id={chartId}
            style={{
              width: '90%',
              height: '500px',
              cursor: 'pointer',
              flex: '1 100%'
            }}
          ></div>
        </div>
      </div>
      {McPageHelpers.generateExplanationFooter(footer)}
    </>
  )
}

export default ComTradeMarketChangeChart
