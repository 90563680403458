import React from 'react'
import { Outlet } from 'react-router-dom'
import Head from './head/Head'
import Header from './header/Header'
import Footer from './footer/Footer'
import AppRoot from './global/AppRoot'
import AppWrap from './global/AppWrap'

import FileManagerProvider from '../pages/built-in/app/file-manager/components/Context'
import { am5AddLicense } from '../utils/Utils'

const Layout = ({ title, ...props }) => {
  am5AddLicense()
  return (
    <FileManagerProvider>
      <Head title={!title && 'Loading'}/>
      <AppRoot>
        <AppWrap>
          <Header fixed/>
          <Outlet/>
          <Footer/>
        </AppWrap>
      </AppRoot>
    </FileManagerProvider>
  )
}
export default Layout
