import { create } from 'zustand'

const usePdfStore = create((set) => ({
  isExcel: false,
  selectedIds: [],
  selectedComponentIds: [],
  excelStatus: {
    comtradeMarketOverview: false,
    comtradeSummary: false,
    spTable: false,
    rfTable: false,
    monthly: false,
    duties: false
  },
  actions: {
    setIsExcel: (isExcel) => set({ isExcel }),
    setSelectedIds: (selectedIds) => set({ selectedIds }),
    setSelectedComponentIds: (selectedComponentIds) => set({ selectedComponentIds }),
    setExcelStatus: (paramName, value) => {
      set(
        (prevState) => ({
          ...prevState,
          excelStatus: {
            ...prevState.excelStatus,
            [paramName]: value
          }
        })
      )
    }
  }
}))

export const usePDFisExcel = () => usePdfStore((state) => state.isExcel)
export const usePDFselectedIds = () => usePdfStore((state) => state.selectedIds)
export const usePDFselectedComponentIds = () => usePdfStore((state) => state.selectedComponentIds)
export const usePDFexcelStatus = () => usePdfStore((state) => state.excelStatus)
export const usePdfStoreActions = () => usePdfStore((state) => state.actions)
