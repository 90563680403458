import React from 'react'
import EnglishFlag from '../../../../images/flags/english-sq.png'
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { LangDropdown, UserAvatar } from '../../../../components/Component'
import { useGlobalStoreData } from '../../../../store/globalStore'
import TurkeyFlag from '../../../../images/flags/turkey-sq.png'
import { LANGUAGES } from '../../../../utils/Utils'

const LanguageHead = () => {
  const languageGSD = useGlobalStoreData.language()

  return (
    <UncontrolledDropdown className="language-dropdown">
      <DropdownToggle
        tag="a"
        href="#toggle"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <UserAvatar className="border border-light xs">
          <img src={languageGSD === LANGUAGES.TURKISH ? TurkeyFlag : EnglishFlag} alt=""/>
        </UserAvatar>
      </DropdownToggle>
      <LangDropdown className="dropdown-menu-s1"/>
    </UncontrolledDropdown>
  )
}

export default LanguageHead
