export const countryEN =
  [
    {
      countryCode: 'PR',
      countryName: 'Puerto Rico'
    },
    {
      countryCode: 'PS',
      countryName: 'Palestine'
    },
    {
      countryCode: 'PT',
      countryName: 'Portugal'
    },
    {
      countryCode: 'PW',
      countryName: 'Palau'
    },
    {
      countryCode: 'PY',
      countryName: 'Paraguay'
    },
    {
      countryCode: 'QA',
      countryName: 'Qatar'
    },
    {
      countryCode: 'AD',
      countryName: 'Andorra'
    },
    {
      countryCode: 'AE',
      countryName: 'United Arab Emirates'
    },
    {
      countryCode: 'AF',
      countryName: 'Afghanistan'
    },
    {
      countryCode: 'AG',
      countryName: 'Antigua And Barbuda'
    },
    {
      countryCode: 'AI',
      countryName: 'Anguilla'
    },
    {
      countryCode: 'AL',
      countryName: 'Albania'
    },
    {
      countryCode: 'AM',
      countryName: 'Armenia'
    },
    {
      countryCode: 'AO',
      countryName: 'Angola'
    },
    {
      countryCode: 'AR',
      countryName: 'Argentina'
    },
    {
      countryCode: 'AS',
      countryName: 'American Samoa'
    },
    {
      countryCode: 'AT',
      countryName: 'Austria'
    },
    {
      countryCode: 'AU',
      countryName: 'Australia'
    },
    {
      countryCode: 'AW',
      countryName: 'Aruba'
    },
    {
      countryCode: 'AX',
      countryName: 'Aland Islands'
    },
    {
      countryCode: 'AZ',
      countryName: 'Azerbaijan'
    },
    {
      countryCode: 'RO',
      countryName: 'Romania'
    },
    {
      countryCode: 'BA',
      countryName: 'Bosnia And Herzegovina'
    },
    {
      countryCode: 'BB',
      countryName: 'Barbados'
    },
    {
      countryCode: 'RS',
      countryName: 'Serbia'
    },
    {
      countryCode: 'BD',
      countryName: 'Bangladesh'
    },
    {
      countryCode: 'BE',
      countryName: 'Belgium'
    },
    {
      countryCode: 'RU',
      countryName: 'Russian Federation'
    },
    {
      countryCode: 'BF',
      countryName: 'Burkina Faso'
    },
    {
      countryCode: 'BG',
      countryName: 'Bulgaria'
    },
    {
      countryCode: 'RW',
      countryName: 'Rwanda'
    },
    {
      countryCode: 'BH',
      countryName: 'Bahrain'
    },
    {
      countryCode: 'BI',
      countryName: 'Burundi'
    },
    {
      countryCode: 'BJ',
      countryName: 'Benin'
    },
    {
      countryCode: 'BL',
      countryName: 'Saint Barthélemy'
    },
    {
      countryCode: 'BM',
      countryName: 'Bermuda'
    },
    {
      countryCode: 'BN',
      countryName: 'Brunei Darussalam'
    },
    {
      countryCode: 'BO',
      countryName: 'Bolivia'
    },
    {
      countryCode: 'SA',
      countryName: 'Saudi Arabia'
    },
    {
      countryCode: 'SB',
      countryName: 'Solomon Islands'
    },
    {
      countryCode: 'BR',
      countryName: 'Brazil'
    },
    {
      countryCode: 'SC',
      countryName: 'Seychelles'
    },
    {
      countryCode: 'BS',
      countryName: 'Bahamas'
    },
    {
      countryCode: 'SD',
      countryName: 'Sudan'
    },
    {
      countryCode: 'BT',
      countryName: 'Bhutan'
    },
    {
      countryCode: 'SE',
      countryName: 'Sweden'
    },
    {
      countryCode: 'SG',
      countryName: 'Singapore'
    },
    {
      countryCode: 'SH',
      countryName: 'Saint Helena, Ascension And Tristan Da Cunha'
    },
    {
      countryCode: 'BW',
      countryName: 'Botswana'
    },
    {
      countryCode: 'SI',
      countryName: 'Slovenia'
    },
    {
      countryCode: 'BY',
      countryName: 'Belarus'
    },
    {
      countryCode: 'BZ',
      countryName: 'Belize'
    },
    {
      countryCode: 'SK',
      countryName: 'Slovakia'
    },
    {
      countryCode: 'SL',
      countryName: 'Sierra Leone'
    },
    {
      countryCode: 'SM',
      countryName: 'San Marino'
    },
    {
      countryCode: 'SN',
      countryName: 'Senegal'
    },
    {
      countryCode: 'SO',
      countryName: 'Somalia'
    },
    {
      countryCode: 'CA',
      countryName: 'Canada'
    },
    {
      countryCode: 'SR',
      countryName: 'Suriname'
    },
    {
      countryCode: 'SS',
      countryName: 'South Sudan'
    },
    {
      countryCode: 'ST',
      countryName: 'Sao Tome And Principe'
    },
    {
      countryCode: 'CG',
      countryName: 'Congo'
    },
    {
      countryCode: 'CF',
      countryName: 'Central African Republic'
    },
    {
      countryCode: 'SV',
      countryName: 'El Salvador'
    },
    {
      countryCode: 'CD',
      countryName: 'Congo, The Democratic Republic Of The'
    },
    {
      countryCode: 'CH',
      countryName: 'Switzerland'
    },
    {
      countryCode: 'SX',
      countryName: 'Sint Maarten (Dutch Part)'
    },
    {
      countryCode: 'CI',
      countryName: 'Côte D\'Ivoire'
    },
    {
      countryCode: 'SY',
      countryName: 'Syrian Arab Republic'
    },
    {
      countryCode: 'SZ',
      countryName: 'Swaziland'
    },
    {
      countryCode: 'CK',
      countryName: 'Cook Islands'
    },
    {
      countryCode: 'CL',
      countryName: 'Chile'
    },
    {
      countryCode: 'CM',
      countryName: 'Cameroon'
    },
    {
      countryCode: 'CN',
      countryName: 'China'
    },
    {
      countryCode: 'CO',
      countryName: 'Colombia'
    },
    {
      countryCode: 'CR',
      countryName: 'Costa Rica'
    },
    {
      countryCode: 'TC',
      countryName: 'Turks And Caicos Islands'
    },
    {
      countryCode: 'TD',
      countryName: 'Chad'
    },
    {
      countryCode: 'CU',
      countryName: 'Cuba'
    },
    {
      countryCode: 'CV',
      countryName: 'Cape Verde'
    },
    {
      countryCode: 'TG',
      countryName: 'Togo'
    },
    {
      countryCode: 'CW',
      countryName: 'Curaçao'
    },
    {
      countryCode: 'TH',
      countryName: 'Thailand'
    },
    {
      countryCode: 'CY',
      countryName: 'Southern Cyprus'
    },
    {
      countryCode: 'TJ',
      countryName: 'Tajikistan'
    },
    {
      countryCode: 'CZ',
      countryName: 'Czech Republic'
    },
    {
      countryCode: 'TL',
      countryName: 'Timor-Leste'
    },
    {
      countryCode: 'TM',
      countryName: 'Turkmenistan'
    },
    {
      countryCode: 'TN',
      countryName: 'Tunisia'
    },
    {
      countryCode: 'TO',
      countryName: 'Tonga'
    },
    {
      countryCode: 'TR',
      countryName: 'Turkiye'
    },
    {
      countryCode: 'TT',
      countryName: 'Trinidad And Tobago'
    },
    {
      countryCode: 'DE',
      countryName: 'Germany'
    },
    {
      countryCode: 'TV',
      countryName: 'Tuvalu'
    },
    {
      countryCode: 'TW',
      countryName: 'Taiwan'
    },
    {
      countryCode: 'DJ',
      countryName: 'Djibouti'
    },
    {
      countryCode: 'TZ',
      countryName: 'Tanzania'
    },
    {
      countryCode: 'DK',
      countryName: 'Denmark'
    },
    {
      countryCode: 'DM',
      countryName: 'Dominica'
    },
    {
      countryCode: 'DO',
      countryName: 'Dominican Republic'
    },
    {
      countryCode: 'UA',
      countryName: 'Ukraine'
    },
    {
      countryCode: 'UG',
      countryName: 'Uganda'
    },
    {
      countryCode: 'DZ',
      countryName: 'Algeria'
    },
    {
      countryCode: 'UK',
      countryName: 'United Kingdom'
    },
    {
      countryCode: 'UM',
      countryName: 'United States Minor Outlying Islands'
    },
    {
      countryCode: 'CYN',
      countryName: 'Turkish Republic of Northern Cyprus'
    },
    {
      countryCode: 'EC',
      countryName: 'Ecuador'
    },
    {
      countryCode: 'US',
      countryName: 'United States'
    },
    {
      countryCode: 'EE',
      countryName: 'Estonia'
    },
    {
      countryCode: 'EG',
      countryName: 'Egypt'
    },
    {
      countryCode: 'EH',
      countryName: 'Western Sahara'
    },
    {
      countryCode: 'UY',
      countryName: 'Uruguay'
    },
    {
      countryCode: 'UZ',
      countryName: 'Uzbekistan'
    },
    {
      countryCode: 'VA',
      countryName: 'Holy See (Vatican City State)'
    },
    {
      countryCode: 'ER',
      countryName: 'Eritrea'
    },
    {
      countryCode: 'VC',
      countryName: 'Saint Vincent And The Grenadines'
    },
    {
      countryCode: 'ES',
      countryName: 'Spain'
    },
    {
      countryCode: 'ET',
      countryName: 'Ethiopia'
    },
    {
      countryCode: 'VE',
      countryName: 'Venezuela'
    },
    {
      countryCode: 'VG',
      countryName: 'British Virgin Islands'
    },
    {
      countryCode: 'VI',
      countryName: 'Us Virgin Islands'
    },
    {
      countryCode: 'VN',
      countryName: 'Viet Nam'
    },
    {
      countryCode: 'VU',
      countryName: 'Vanuatu'
    },
    {
      countryCode: 'FI',
      countryName: 'Finland'
    },
    {
      countryCode: 'FJ',
      countryName: 'Fiji'
    },
    {
      countryCode: 'FK',
      countryName: 'Falkland Islands (Malvinas)'
    },
    {
      countryCode: 'FM',
      countryName: 'Micronesia'
    },
    {
      countryCode: 'FO',
      countryName: 'Faroe Islands'
    },
    {
      countryCode: 'FR',
      countryName: 'France'
    },
    {
      countryCode: 'WF',
      countryName: 'Wallis And Futuna'
    },
    {
      countryCode: 'GA',
      countryName: 'Gabon'
    },
    {
      countryCode: 'WS',
      countryName: 'Samoa'
    },
    {
      countryCode: 'GD',
      countryName: 'Grenada'
    },
    {
      countryCode: 'GE',
      countryName: 'Georgia'
    },
    {
      countryCode: 'GG',
      countryName: 'Guernsey'
    },
    {
      countryCode: 'GH',
      countryName: 'Ghana'
    },
    {
      countryCode: 'GI',
      countryName: 'Gibraltar'
    },
    {
      countryCode: 'GL',
      countryName: 'Greenland'
    },
    {
      countryCode: 'GM',
      countryName: 'Gambia'
    },
    {
      countryCode: 'GN',
      countryName: 'Guinea'
    },
    {
      countryCode: 'GQ',
      countryName: 'Equatorial Guinea'
    },
    {
      countryCode: 'GR',
      countryName: 'Greece'
    },
    {
      countryCode: 'GT',
      countryName: 'Guatemala'
    },
    {
      countryCode: 'GU',
      countryName: 'Guam'
    },
    {
      countryCode: 'GW',
      countryName: 'Guinea-Bissau'
    },
    {
      countryCode: 'GY',
      countryName: 'Guyana'
    },
    {
      countryCode: 'HK',
      countryName: 'Hong Kong'
    },
    {
      countryCode: 'HN',
      countryName: 'Honduras'
    },
    {
      countryCode: 'HR',
      countryName: 'Croatia'
    },
    {
      countryCode: 'HT',
      countryName: 'Haiti'
    },
    {
      countryCode: 'YE',
      countryName: 'Yemen'
    },
    {
      countryCode: 'HU',
      countryName: 'Hungary'
    },
    {
      countryCode: 'ID',
      countryName: 'Indonesia'
    },
    {
      countryCode: 'IE',
      countryName: 'Ireland'
    },
    {
      countryCode: 'IL',
      countryName: 'Israel'
    },
    {
      countryCode: 'IM',
      countryName: 'Isle Of Man'
    },
    {
      countryCode: 'IN',
      countryName: 'India'
    },
    {
      countryCode: 'IO',
      countryName: 'British Indian Ocean Territory'
    },
    {
      countryCode: 'ZA',
      countryName: 'South African Republic'
    },
    {
      countryCode: 'IQ',
      countryName: 'Iraq'
    },
    {
      countryCode: 'IR',
      countryName: 'Iran'
    },
    {
      countryCode: 'IS',
      countryName: 'Iceland'
    },
    {
      countryCode: 'IT',
      countryName: 'Italy'
    },
    {
      countryCode: 'ZM',
      countryName: 'Zambia'
    },
    {
      countryCode: 'JE',
      countryName: 'Jersey'
    },
    {
      countryCode: 'ZW',
      countryName: 'Zimbabwe'
    },
    {
      countryCode: 'JM',
      countryName: 'Jamaica'
    },
    {
      countryCode: 'JO',
      countryName: 'Jordan'
    },
    {
      countryCode: 'JP',
      countryName: 'Japan'
    },
    {
      countryCode: 'KE',
      countryName: 'Kenya'
    },
    {
      countryCode: 'KG',
      countryName: 'Kyrgyzstan'
    },
    {
      countryCode: 'KH',
      countryName: 'Cambodia'
    },
    {
      countryCode: 'KI',
      countryName: 'Kiribati'
    },
    {
      countryCode: 'KM',
      countryName: 'Comoros'
    },
    {
      countryCode: 'KN',
      countryName: 'Saint Kitts And Nevis'
    },
    {
      countryCode: 'KP',
      countryName: 'North Korea'
    },
    {
      countryCode: 'KR',
      countryName: 'South Korea'
    },
    {
      countryCode: 'KW',
      countryName: 'Kuwait'
    },
    {
      countryCode: 'KY',
      countryName: 'Cayman Islands'
    },
    {
      countryCode: 'KZ',
      countryName: 'Kazakhstan'
    },
    {
      countryCode: 'LA',
      countryName: 'Lao People\'S Democratic Republic'
    },
    {
      countryCode: 'LB',
      countryName: 'Lebanon'
    },
    {
      countryCode: 'LC',
      countryName: 'Saint Lucia'
    },
    {
      countryCode: 'LI',
      countryName: 'Liechtenstein'
    },
    {
      countryCode: 'LK',
      countryName: 'Sri Lanka'
    },
    {
      countryCode: 'LR',
      countryName: 'Liberia'
    },
    {
      countryCode: 'LS',
      countryName: 'Lesotho'
    },
    {
      countryCode: 'LT',
      countryName: 'Lithuania'
    },
    {
      countryCode: 'LU',
      countryName: 'Luxembourg'
    },
    {
      countryCode: 'LV',
      countryName: 'Latvia'
    },
    {
      countryCode: 'LY',
      countryName: 'Libya'
    },
    {
      countryCode: 'MA',
      countryName: 'Morocco'
    },
    {
      countryCode: 'MC',
      countryName: 'Monaco'
    },
    {
      countryCode: 'MD',
      countryName: 'Moldova'
    },
    {
      countryCode: 'ME',
      countryName: 'Montenegro'
    },
    {
      countryCode: 'MF',
      countryName: 'Saint Martin (French Part)'
    },
    {
      countryCode: 'MG',
      countryName: 'Madagascar'
    },
    {
      countryCode: 'MH',
      countryName: 'Marshall Islands'
    },
    {
      countryCode: 'MK',
      countryName: 'North Macedonia'
    },
    {
      countryCode: 'ML',
      countryName: 'Mali'
    },
    {
      countryCode: 'MM',
      countryName: 'Myanmar'
    },
    {
      countryCode: 'MN',
      countryName: 'Mongolia'
    },
    {
      countryCode: 'MO',
      countryName: 'Macao'
    },
    {
      countryCode: 'MP',
      countryName: 'Northern Mariana Islands'
    },
    {
      countryCode: 'MR',
      countryName: 'Mauritania'
    },
    {
      countryCode: 'MS',
      countryName: 'Montserrat'
    },
    {
      countryCode: 'MT',
      countryName: 'Malta'
    },
    {
      countryCode: 'MU',
      countryName: 'Mauritius'
    },
    {
      countryCode: 'MV',
      countryName: 'Maldives'
    },
    {
      countryCode: 'MW',
      countryName: 'Malawi'
    },
    {
      countryCode: 'MX',
      countryName: 'Mexico'
    },
    {
      countryCode: 'MY',
      countryName: 'Malaysia'
    },
    {
      countryCode: 'MZ',
      countryName: 'Mozambique'
    },
    {
      countryCode: 'NC',
      countryName: 'New Caledonia'
    },
    {
      countryCode: 'NE',
      countryName: 'Niger'
    },
    {
      countryCode: 'NF',
      countryName: 'Norfolk Island'
    },
    {
      countryCode: 'NG',
      countryName: 'Nigeria'
    },
    {
      countryCode: 'NI',
      countryName: 'Nicaragua'
    },
    {
      countryCode: 'NL',
      countryName: 'Netherlands'
    },
    {
      countryCode: 'NO',
      countryName: 'Norway'
    },
    {
      countryCode: 'NP',
      countryName: 'Nepal'
    },
    {
      countryCode: 'NR',
      countryName: 'Nauru'
    },
    {
      countryCode: 'NU',
      countryName: 'Niue'
    },
    {
      countryCode: 'NZ',
      countryName: 'New Zealand'
    },
    {
      countryCode: 'OM',
      countryName: 'Oman'
    },
    {
      countryCode: 'PA',
      countryName: 'Panama'
    },
    {
      countryCode: 'PE',
      countryName: 'Peru'
    },
    {
      countryCode: 'PF',
      countryName: 'French Polynesia'
    },
    {
      countryCode: 'PG',
      countryName: 'Papua New Guinea'
    },
    {
      countryCode: 'PH',
      countryName: 'Philippines'
    },
    {
      countryCode: 'PK',
      countryName: 'Pakistan'
    },
    {
      countryCode: 'PL',
      countryName: 'Poland'
    },
    {
      countryCode: 'PM',
      countryName: 'Saint Pierre And Miquelon'
    },
    {
      countryCode: 'PN',
      countryName: 'Pitcairn'
    }
  ]
