import React from 'react'
import { BlockBetween, BlockHead, BlockHeadContent } from '../../../components/block/Block'
import { Button, Icon } from '../../../components/Component'
import MCTitleCard from '../../../components/title-card/MCTitleCard'

const FilterHeader = ({ content: { code, header, sub, rightTxt, fncResetFilter } }) => {
  return <BlockHead size="sm">
    <BlockBetween>
      <MCTitleCard chartSubTitle={sub} code={code} chartTitle={header} codeColor="info" textColor={'#09c2de'}
                   className="subtitle-left"/>
      <BlockHeadContent>
            <span className="ff-italic me-3">
              {rightTxt}
            </span>
        <Button color="danger" onClick={() => fncResetFilter()}><Icon name="cross-circle"/></Button>
      </BlockHeadContent>
    </BlockBetween>
  </BlockHead>
}

export default FilterHeader
