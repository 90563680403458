import React from 'react'

import { Link, useNavigate } from 'react-router-dom'

// MUI Imports
import {
  IconButton,
  Card,
  CardContent,
  Typography,
  CircularProgress
} from '@mui/material'
import {
  MailOutline,
  DeleteForever,
  KeyboardDoubleArrowRight,
  Refresh
} from '@mui/icons-material'

import Flag from '../atoms/Flag'
import EmailStatusPercentageBar from '../atoms/EmailStatusPercentageBar'
import EmailStatusItemList from '../atoms/EmailStatusItemList'
import HsChipList from './HsChipList'

import { useGlobalStoreData } from '../../../../../store/globalStore'

const CampaignCard = (props) => {
  const {
    campaignItem,
    setConfirmationModalItem,
    campaignReport,
    refreshCampaignReport,
    campaignReportFetching
  } = props
  const navigate = useNavigate()

  const languageGSD = useGlobalStoreData.language()

  const getLinkTarget = (campaignData) => ({
    wizard: `${process.env.PUBLIC_URL}/${languageGSD}/mailing/wizard?b2bAccountId=${campaignData.b2bAccountId}&b2bCampaignId=${campaignData.id}`,
    inbox: `${process.env.PUBLIC_URL}/${languageGSD}/mailing/mailbox?b2bCampaignId=${campaignData.id}`
  })

  return (
    <Link to={getLinkTarget(campaignItem).wizard}>
      <Card sx={{ height: '100%', overflow: 'visible', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <CardContent className='p-2 pb-0'>
          <div className='flex justify-between gap-2'>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all', width: 'calc(100% - 105px)', height: 35 }}>
              <Typography
                noWrap
                variant='h5'
                onClick={e => e.preventDefault()}
                className='hover:text-primary'
                style={{ lineHeight: 1.2 }}
              >
                {campaignItem.name}
              </Typography>
              <Typography
                noWrap
                variant='body2'
                style={{ lineHeight: 1 }}
              >
                {campaignItem.productName}
              </Typography>
            </div>
            <div className='flex align-start'>
              <div className='flex align-center'>
                <Flag code={campaignItem.currentCountryCode} square style={{ fontSize: '1.5rem', borderRadius: '20%', boxShadow: 'var(--mui-customShadows-primary-md)' }} />
                <KeyboardDoubleArrowRight color='primary'/>
                <Flag code={campaignItem.importerCountryCode} square style={{ fontSize: '1.5rem', borderRadius: '20%', boxShadow: 'var(--mui-customShadows-primary-md)' }} />
              </div>
            </div>
          </div>
        </CardContent>

        {/* {campaignReport
          ? <CampaignCardReportSection campaignReport={campaignReport}/>
          : null
        } */}
        <div className='grow flex align-end flex-wrap'>
          <EmailStatusPercentageBar emailStats={campaignReport?.outgoingEmailStats} languageGSD={languageGSD}/>
          <EmailStatusItemList campaignReport={campaignReport} languageGSD={languageGSD}/>
        </div>

        <CardContent
          className='flex justify-between align-center gap-1 px-1 py-0'
          style={{ borderTop: 'thin solid var(--mui-palette-divider)' }}
        >
          <div className='flex'>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                setConfirmationModalItem(campaignItem)
              }}
              color='primary'
            >
              <DeleteForever sx={{ width: 18, height: 18 }}/>
            </IconButton>

            <IconButton
              onClick={(e) => {
                e.preventDefault()
                navigate(getLinkTarget(campaignItem).inbox)
              }}
              color='primary'
            >
              <MailOutline sx={{ width: 18, height: 18 }}/>
            </IconButton>

            <IconButton
              onClick={(e) => {
                e.preventDefault()
                !campaignReportFetching && refreshCampaignReport(campaignItem.id)
              }}
              color='primary'
            >
              {campaignReportFetching
                ? <CircularProgress size={15}/>
                : <Refresh sx={{ width: 18, height: 18 }}/>
              }
            </IconButton>
          </div>

          <div className='flex flex-wrap items-center justify-end gap-1'>
            <HsChipList hsArr={campaignItem?.hsCodes} />
          </div>
        </CardContent>
      </Card>
    </Link>
  )
}

export default CampaignCard
