import McPageHelpers from './McPageHelpers'
import i18n from 'i18next'
import Constants from '../../utils/Constants'

const DIV_IDS = {
  importerCompanies: {
    div1: 'importerCardMapGraph',
    div2: 'potentialCardMapGraph'
  },
  priceAnalysis: {
    div1: 'annualAverageImportUnitPricesMapGraph',
    div2: 'priceAnalysisMultiValue',
    div3: 'hsTotalImportCurrencyXFirmNum',
    div4: 'averagePriceXTotalImportCurrency',
    div5: 'averagePriceChart',
    div6: 'hsCodeChart',
    div7: 'averagePriceXTotalImportInterest'
  },
  marketChanges: {
    div0: 'clusteredBarChart',
    div1: 'stackedBarChart',
    div2: 'comtradeMarketChangeValueSearchedCountry',
    div3: 'comtradeMarketChangeValueSearchedCountryPercentage',
    div4: 'comtradeMarketChangeValueWorld',
    div5: 'comtradeMarketChangeValueWorldPercentage',
    div6: 'comtradeMarketChangeQuantitySearchedCountry',
    div7: 'comtradeMarketChangeQuantitySearchedCountryPercentage',
    div8: 'comtradeMarketChangeQuantityWorld',
    div9: 'comtradeMarketChangeQuantityWorldPercentage',
    div10: 'comtradeMarketChangeValueSeries',
    div11: 'comtradeMarketChangePercentageValueSeries',
    div12: 'comtradeMarketChangeQuantitySeries',
    div13: 'comtradeMarketChangePercentageQuantitySeries'
  },
  productAnalysis: {
    div1: 'companyValueChart',
    div2: 'onlyHsCount',
    div3: 'companyCountChart',
    div4: 'countryCount',
    div5: 'companyShipmentCount',
    div6: 'growth'
  },
  marketOverview: {
    value: [
      'MultipleBarChart-01',
      'MultipleLineChart-02',
      'MultipleBarChart-DestinationCountry01',
      'MultipleBarChart-DestinationCountry02',
      'MultipleBarChart-03',
      'MultipleLineChart-04',
      'MultipleBarChart-SourceCountry',
      'MultipleBarChart-DestinationCountry',
      'ValueBarChart-09',
      'ValueLineChart-10',
      'ValueBarChart-11',
      'ValueLineChart-12'
    ],
    quantity: [
      'MultipleBarChart-07',
      'MultipleLineChart-08',
      'MultipleBarChart-DestinationCountryQuantity01',
      'MultipleBarChart-DestinationCountryQuantity02',
      'MultipleBarChart-09',
      'MultipleLineChart-10',
      'MultipleBarChart-SourceQuantityCountry',
      'MultipleBarChart-DestinationQuantityCountry',
      'MultipleBarChart-11',
      'MultipleLineChart-12',
      'QuantityBarChartWorld-21',
      'QuantityLineChartWorld-22'
    ],
    average: [
      'MultipleBarChart-13',
      'MultipleLineChart-14',
      'MultipleBarChart-DestinationCountryAverage01',
      'MultipleBarChart-DestinationCountryAverage02',
      'MultipleBarChart-15',
      'MultipleLineChart-16',
      'MultipleBarChart-SourceAverageCountry',
      'MultipleBarChart-DestinationAverageCountry',
      'MultipleBarChart-17',
      'MultipleLineChart-18',
      'MultipleBarChart-31',
      'MultipleLineChart-32'
    ],
    change: []
  },
  summaryView: {
    div1: 'H-OT-01',
    div2: 'H-OT-02',
    div3: 'H-OT-03',
    div4: 'H-OT-04',
    div5: 'H-OT-05',
    div6: 'H-OT-06',
    div7: 'H-OT-07',
    div8: 'H-OT-08',
    div9: 'summaryViewChangesGraph'
  }
}

const ImporterCompanies = async (
  onlyContent, searchHeader, rawImporterBar, rawPotentialBar,
  importerTablePdfContent, potentialTablePdfContent, subTitle,
  subPotantialTitle, header, headerPotential, selectedComponentIds,
  explanations) => {
  const importerHeaders = {
    id: i18n.t('country_code'),
    countryName: i18n.t('country'),
    formatValue: i18n.t('sp_company_shipment_y')
  }

  const potentialHeaders = {
    id: i18n.t('country_code'),
    countryName: i18n.t('country'),
    value: i18n.t('sp_company_shipment_y'),
    avgPrice: i18n.t('sp_filter_by_avg_price')
  }

  const titles = [
    subTitle + ' ' + i18n.t('importer_firms'),
    header,
    subPotantialTitle + ' ' + i18n.t('potential_firms'),
    headerPotential]
  const promises = Object.values(DIV_IDS.importerCompanies)
    .map((divId) => McPageHelpers.captureImageById(
      divId + McPageHelpers.FOR_PDF_ID, selectedComponentIds))

  try {
    const capturedImagesData = await Promise.all(promises)

    const content = []

    if (McPageHelpers.cleanseDataForPdf(importerTablePdfContent)) {
      content.push(
        ...McPageHelpers.generateTableTitleAndSubtitleForPdf(titles[0],
          Constants.BADGE_CODES.IMPORTER_TABLE_SECTION, null))
      content.push(importerTablePdfContent)
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[0],
      Constants.TYPE.IMAGE)) {
      content.push(...McPageHelpers.generateTableTitleAndSubtitleForPdf(
        subTitle + ' ' + titles[1], null, null))
      content.push({
        columns: [
          {
            image: capturedImagesData[0],
            width: 800,
            alignment: 'center',
            pageOrientation: 'landscape',
            marginBottom: 30
          }
        ]
      })
      content.push(
        ...McPageHelpers.generateExplanationFooterForPdf(explanations[0]))
      content.push(McPageHelpers.generatePdfTableFromObjects(rawImporterBar,
        importerHeaders))
    }

    if (McPageHelpers.cleanseDataForPdf(potentialTablePdfContent)) {
      content.push(
        ...McPageHelpers.generateTableTitleAndSubtitleForPdf(titles[2],
          Constants.BADGE_CODES.POTENTIAL_IMPORTER_TABLE_SECTION, null))
      content.push(potentialTablePdfContent)
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[1],
      Constants.TYPE.IMAGE)) {
      content.push(...McPageHelpers.generateTableTitleAndSubtitleForPdf(
        subPotantialTitle + ' ' + titles[3], null, null))
      content.push({
        columns: [
          {
            image: capturedImagesData[1],
            width: 800,
            alignment: 'center',
            pageOrientation: 'landscape',
            marginBottom: 30
          }]
      })
      content.push(
        ...McPageHelpers.generateExplanationFooterForPdf(explanations[1]))
      content.push(McPageHelpers.generatePdfTableFromObjects(rawPotentialBar,
        potentialHeaders))
    }

    if (onlyContent) return content
    McPageHelpers.PDFExport(content, i18n.t('menu.importer_companies'),
      searchHeader, null, 'pdf_about.p1_imp')
  } catch (error) {
    console.error('error', error)
  }
}

const PriceAnalysis = async (
  onlyContent, searchHeader, priceBar, priceTablePdfContent, headerPriceTable,
  unitParams, subTitle, selectedComponentIds, allExplanations) => {
  await new Promise(resolve => setTimeout(resolve, 10))

  const titles = [
    ' ' + i18n.t('world_avg_price') + ' ' + unitParams,
    subTitle + ' ' + i18n.t('avg_line_graphic_title', { unit: unitParams }),
    subTitle + ' ' + headerPriceTable,
    subTitle + ' ' + i18n.t('ai_sp_static_hs_total_value_currency'),
    subTitle + ' ' + i18n.t('sp_filter_by_avg_price_hs_total'),
    subTitle + ' ' + i18n.t('sp_filter_by_avg_price'),
    subTitle + ' ' + i18n.t('ai_sp_static_hs_code_count'),
    subTitle + ' ' + i18n.t('sp_filter_by_avg_price_hs_total_percentage')
  ]

  const priceHeaders = {
    id: i18n.t('country_code'),
    countryName: i18n.t('country'),
    formatValue: i18n.t('sp_filter_by_avg_price') + unitParams
  }

  const promises = Object.values(DIV_IDS.priceAnalysis)
    .map((divId) => McPageHelpers.captureImageById(
      divId + McPageHelpers.FOR_PDF_ID, selectedComponentIds))

  try {
    const capturedImagesData = await Promise.all(promises)

    const content = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[0],
      Constants.TYPE.IMAGE) && priceBar?.length) {
      content.push(
        McPageHelpers.generateTableTitleAndSubtitleForPdf(subTitle + titles[0],
          '', null))
      content.push({
        image: capturedImagesData && capturedImagesData[0],
        width: 800,
        alignment: 'center',
        pageOrientation: 'landscape'
      })
      content.push(...McPageHelpers.generateExplanationFooterForPdf(
        allExplanations?.avgPriceExplanations[[0]]))

      content.push(
        McPageHelpers.generatePdfTableFromObjects(priceBar, priceHeaders))
      content.push({
        text: i18n.t('pdf_price_table_info'),
        fontSize: 10,
        alignment: 'left',
        color: 'gray',
        pageOrientation: 'portrait',
        marginBottom: 5
      })
    }
    if (McPageHelpers.cleanseDataForPdf(priceTablePdfContent,
      Constants.TYPE.TABLE)) {
      content.push(McPageHelpers.generateTableTitleAndSubtitleForPdf(titles[2],
        Constants.BADGE_CODES.PRICE_ANALYSIS_TABLE_1, 'before'))
      content.push(priceTablePdfContent)
      content.push(...McPageHelpers.generateExplanationFooterForPdf(
        allExplanations?.tableExplanations[[0]]))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[1],
      Constants.TYPE.IMAGE)) {
      content.push(McPageHelpers.generateTableTitleAndSubtitleForPdf(titles[1],
        Constants.BADGE_CODES.PRICE_ANALYSIS_GRAPH_3, 'before'))
      content.push([
        {
          image: capturedImagesData[1],
          width: 800,
          alignment: 'center',
          pageOrientation: 'landscape',
          marginBottom: 30
        }])
    }
    content.push(...McPageHelpers.generateExplanationFooterForPdf(
      allExplanations?.parityGraphExplanations[[0]]))
    const columns1 = []

    const generateChartAndExplanation = (
      explanation, capturedImageData, title) => {
      const body = [
        [
          {
            image: capturedImageData,
            width: 370,
            alignment: 'center',
            mcTitle: title
          }]]

      const footerItems = explanation?.map((item, index) => ([
        {
          text: item,
          fontSize: 10,
          margin: index !== explanation.length - 1
            ? [10, 10, 0, 10]
            : [
                10,
                10,
                0,
                20],
          color: 'gray',
          italics: true
        }])) || []

      body.push(...footerItems)
      return {
        table: {
          body
        },
        layout: 'noBorders'
      }
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[2],
      Constants.TYPE.IMAGE)) {
      columns1.push(generateChartAndExplanation(
        allExplanations?.hsTotalValFirmNumExplanation, capturedImagesData[2],
        titles[3]))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
      Constants.TYPE.IMAGE)) {
      columns1.push(generateChartAndExplanation(
        allExplanations?.avgPriceHsTotalValExplanations[0],
        capturedImagesData[3], titles[4]))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[2],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: columns1,
        pageOrientation: 'landscape',
        marginBottom: 10,
        marginTop: 10
      })
    }

    const columns2 = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[4],
      Constants.TYPE.IMAGE)) {
      columns2.push(generateChartAndExplanation(
        allExplanations?.avgPriceFirmNumExplanations[0], capturedImagesData[4],
        titles[5]))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[5],
      Constants.TYPE.IMAGE)) {
      columns2.push(generateChartAndExplanation(
        allExplanations?.hsCountHsTotalValExplanation, capturedImagesData[5],
        titles[6]))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[4],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[5],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: columns2,
        pageOrientation: 'landscape',
        marginBottom: 10,
        marginTop: 10
      })
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[6],
      Constants.TYPE.IMAGE)) {
      content.push({
        columns: [
          generateChartAndExplanation(
            allExplanations?.avgPriceHsTotalValPercentageExplanations[0],
            capturedImagesData[6], titles[7])
        ]
      })
    }

    if (onlyContent) return content

    McPageHelpers.PDFExport(content, i18n.t('menu.price_analysis'),
      searchHeader, null, 'pdf_about.p1_pri')
  } catch
  (error) {
    console.error('error', error)
  }
}

const MarketChanges = async (
  onlyContent, searchHeader, marketChangesTable, subTitle, selectedComponentIds,
  explanations) => {
  await new Promise(resolve => setTimeout(resolve, 10))

  const titles = [
    subTitle + ' ' + i18n.t('clustered_bar_chart_change_of_rates_title'),
    subTitle + ' ' + i18n.t('stacked_bar_chart_change_of_rates_title'),
    subTitle + ' ' + i18n.t('market_change_value_searched_country_title'),
    subTitle + ' ' +
    i18n.t('market_change_value_searched_country_title_percentage'),
    subTitle + ' ' + i18n.t('market_change_value_searched_country_title'),
    subTitle + ' ' +
    i18n.t('market_change_value_searched_country_title_percentage'),
    subTitle + ' ' + i18n.t('market_change_quantity_searched_country_title'),
    subTitle + ' ' +
    i18n.t('market_change_quantity_searched_country_title_percentage'),
    subTitle + ' ' + i18n.t('market_change_quantity_searched_country_title'),
    subTitle + ' ' +
    i18n.t('market_change_quantity_searched_country_title_percentage'),
    subTitle + ' ' + i18n.t('market_change_value_series_title'),
    subTitle + ' ' + i18n.t('market_change_value_series_title_percentage'),
    subTitle + ' ' + i18n.t('market_change_quantity_series_title'),
    subTitle + ' ' + i18n.t('market_change_quantity_series_title_percentage')
  ]

  const promises = Object.values(DIV_IDS.marketChanges)
    .map((divId) => McPageHelpers.captureImageById(
      divId + McPageHelpers.FOR_PDF_ID, selectedComponentIds))

  try {
    const capturedImagesData = await Promise.all(promises)

    const content = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[0],
      Constants.TYPE.IMAGE) &&
      McPageHelpers.cleanseDataForPdf(capturedImagesData[1],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: [
          {
            image: capturedImagesData[0],
            width: 360,
            height: 360,
            mcTitle: titles[0]
          },
          {
            image: capturedImagesData[1],
            width: 360,
            height: 360,
            mcTitle: titles[1]
          }
        ],
        pageOrientation: 'landscape',
        marginBottom: 10,
        marginTop: 10
      })

      content.push({
        columns: [
          [...McPageHelpers.generateExplanationFooterForPdf(explanations[0])],
          {
            text: ''
          }
        ],
        pageOrientation: 'landscape',
        marginBottom: 10,
        marginTop: 10
      })
    }

    if (McPageHelpers.cleanseDataForPdf(marketChangesTable,
      Constants.TYPE.TABLE)) {
      content.push(McPageHelpers.generateTableTitleAndSubtitleForPdf(
        subTitle + ' ' + i18n.t('market_changes_table_title'),
        Constants.BADGE_CODES.MARKET_CHANGE_TABLE_1,
        !content.length && 'before'))
      content.push(marketChangesTable)
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[2],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [2, 3], titles, explanations))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[4],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [4, 5], titles, explanations))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[6],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[7],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [6, 7], titles, explanations))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[8],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[9],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [8, 9], titles, explanations))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[10],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[11],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [10, 11], titles, explanations))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[12],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[13],
        Constants.TYPE.IMAGE)) {
      content.push(
        ...McPageHelpers.generateMarketChangeChartForPdf(capturedImagesData,
          DIV_IDS.marketChanges, [12, 13], titles, explanations))
    }

    if (onlyContent) return content
    McPageHelpers.PDFExport(content, i18n.t('menu.market_changes'),
      searchHeader, null, 'pdf_about.p1_pd')
  } catch (error) {
    console.error('error', error)
  }
}

const ProductAnalysis = async (
  onlyContent, searchHeader, selectedComponentIds, ...explanations) => {
  const promises = Object.values(DIV_IDS.productAnalysis)
    .map((divId) => McPageHelpers.captureImageById(
      divId + McPageHelpers.FOR_PDF_ID, selectedComponentIds))
  const titles = [
    i18n.t('company_value_nb'),
    i18n.t('only_hs_code'),
    i18n.t('company_count_nb'),
    i18n.t('country_count'),
    i18n.t('sp_company_shipment_count'),
    i18n.t('sp_growth_table_head')
  ]
  try {
    const capturedImagesData = await Promise.all(promises)

    const generateImageAndExplanation = (key, pageBreak) => {
      const body = [
        [
          {
            image: capturedImagesData[key],
            width: 390,
            alignment: 'center',
            mcTitle: titles[key]
          }]]

      if (Array.isArray(explanations[key]) && explanations[key].length > 0) {
        const footerItems = explanations[key].map((item, index) => ([
          {
            text: item,
            fontSize: 10,
            margin: index !== explanations[key].length - 1
              ? [0, 0, 0, 10]
              : [
                  0,
                  0,
                  0,
                  20],
            color: 'gray',
            italics: true
          }]))

        body.push(...footerItems)
      }

      return ({
        table: {
          body
        },
        layout: 'noBorders',
        pageBreak: pageBreak ?? null
      })
    }

    const content = []

    const columns1 = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[0],
      Constants.TYPE.IMAGE)) {
      columns1.push(generateImageAndExplanation(0))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[1],
      Constants.TYPE.IMAGE)) {
      columns1.push(generateImageAndExplanation(1, 'after'))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[0],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[1],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: columns1,
        pageOrientation: 'landscape',
        marginTop: 20
      })
    }

    const columns2 = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[2],
      Constants.TYPE.IMAGE)) {
      columns2.push(generateImageAndExplanation(2))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
      Constants.TYPE.IMAGE)) {
      columns2.push(generateImageAndExplanation(3, 'after'))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[2],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[3],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: columns2,
        pageOrientation: 'landscape',
        marginBottom: 20
      })
    }

    const columns3 = []

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[4],
      Constants.TYPE.IMAGE)) {
      columns3.push(generateImageAndExplanation(4))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[5],
      Constants.TYPE.IMAGE)) {
      columns3.push(generateImageAndExplanation(5))
    }

    if (McPageHelpers.cleanseDataForPdf(capturedImagesData[4],
      Constants.TYPE.IMAGE) ||
      McPageHelpers.cleanseDataForPdf(capturedImagesData[5],
        Constants.TYPE.IMAGE)) {
      content.push({
        columns: columns3,
        pageOrientation: 'landscape',
        marginBottom: 20
      })
    }

    if (onlyContent) return content
    McPageHelpers.PDFExport(content, i18n.t('menu.product_analysis'),
      searchHeader, null, 'pdf_about.p1_pro')
  } catch (error) {
    console.error('error', error)
  }
}

const MarketOverview = async (
  onlyContent, searchHeader, tables, subtitle, selectedComponentIds) => {
  !onlyContent && McPageHelpers.toggleAllMarketOverview()
  await new Promise(resolve => setTimeout(resolve, 10))

  function filterAndExtractText (val) {
    const notStartsWithTArray = []
    const convertedToGArray = []
    const mcTitles = []

    for (const item of val) {
      if (item && typeof item === 'object' && 'table' in item) {
        continue
      }

      if (Array.isArray(item)) {
        for (const subItem of item) {
          if (subItem && 'text' in subItem && typeof subItem.text ===
            'string') {
            const text = subItem.text
            if (text.startsWith('T-')) {
              const convertedText = 'G' + text.substring(1)
              convertedToGArray.push(convertedText)
            } else {
              notStartsWithTArray.push(text)
            }
          }
        }
      }
    }

    for (let i = 0; i < notStartsWithTArray.length; i++) {
      if (i < convertedToGArray.length) {
        mcTitles.push(`${notStartsWithTArray[i]} - ${convertedToGArray[i]}`)
      } else {
        mcTitles.push(notStartsWithTArray[i])
      }
    }
    return mcTitles.filter(item => item !== '')
  }

  let promises = []
  const content = []

  for (const groupKey in DIV_IDS.marketOverview) {
    if (tables[groupKey] && DIV_IDS.marketOverview[groupKey].length) {
      promises = promises.concat(DIV_IDS.marketOverview[groupKey].map(
        divId => McPageHelpers.captureImageById(
          divId + '_withTitle' + McPageHelpers.FOR_PDF_ID,
          selectedComponentIds)))
    }
  }

  try {
    const capturedImagesData = await Promise.all(promises)

    let imgCounter = 0
    for (const groupKey in DIV_IDS.marketOverview) {
      if (tables[groupKey] && DIV_IDS.marketOverview[groupKey].length) {
        const titles = filterAndExtractText(tables[groupKey])
        const titlesLength = titles.length
        for (let i = 0; i < DIV_IDS.marketOverview[groupKey].length; i++) {
          if (McPageHelpers.cleanseDataForPdf(capturedImagesData[imgCounter],
            Constants.TYPE.IMAGE)) {
            const mcTitleIndex = i % titlesLength
            content.push(
              {
                image: capturedImagesData[imgCounter],
                width: 800,
                alignment: 'center',
                pageOrientation: 'landscape',
                marginBottom: 30,
                pageBreak: 'after',
                mcTitle: !McPageHelpers.cleanseDataForPdf(
                  tables[groupKey][(i * 2) + 1], Constants.TYPE.TABLE)
                  ? titles[mcTitleIndex]
                  : ''
              }
            )
          }
          if (McPageHelpers.cleanseDataForPdf(tables[groupKey][(i * 2) + 1],
            Constants.TYPE.TABLE)) {
            let explanation = ''
            if (tables[groupKey][(i * 2) + 1].explanation) {
              explanation = McPageHelpers.generateExplanationFooterForPdf(
                tables[groupKey][(i * 2) + 1].explanation)
            }
            content.push(tables[groupKey][i * 2], tables[groupKey][(i * 2) + 1],
              ...explanation)
          }

          imgCounter++
        }
      }
    }
    if (onlyContent) return content
    McPageHelpers.PDFExport(content, i18n.t('menu.market_overview'),
      searchHeader, false, 'pdf_about.p1_pg')
  } catch (error) {
    console.error('error', error)
  } finally {
    !onlyContent && McPageHelpers.toggleAllMarketOverview(false)
  }
}

const SummaryTables = async (
  onlyContent,
  searchHeader,
  subtitle,
  selectedComponentIds,
  changeTables,
  isMonthly,
  ...tables
) => {
  const titles = [
    subtitle + ' ' + (isMonthly.change ? i18n.t('summaryView.monthlyChange') : i18n.t('summaryView.yearlyChange'))
  ]

  const subTitles = [
    (isMonthly.change ? Constants.BADGE_CODES.CHANGE_TABLE_10 : Constants.BADGE_CODES.CHANGE_TABLE_09)
  ]
  const allContent = []
  await new Promise(resolve => setTimeout(resolve, 10))

  const promises = Object.values(DIV_IDS.summaryView)
    .map((divId) => McPageHelpers.captureImageById(divId + '_forPdf',
      selectedComponentIds))

  try {
    const capturedImagesData = await Promise.all(promises)
    tables.forEach((table, index) => {
      if (McPageHelpers.cleanseDataForPdf(capturedImagesData[index], Constants.TYPE.IMAGE)) {
        allContent.push({
          image: capturedImagesData[index],
          width: 800,
          alignment: 'center'
          // mcTitle: table[0]?.text + ' ' + i18n.t('sv_graph')
        })
      }
      allContent.push(McPageHelpers.cleanseDataForPdf(table))
    })

    if (changeTables) {
      for (let key = 0; key < changeTables.length; key++) {
        if (McPageHelpers.cleanseDataForPdf(
          capturedImagesData[tables.length + key], Constants.TYPE.IMAGE)) {
          allContent.push({
            image: capturedImagesData[tables.length + key],
            width: 600,
            alignment: 'center'
          })
        }
        if (McPageHelpers.cleanseDataForPdf(changeTables,
          Constants.TYPE.TABLE)) {
          allContent.push(
            McPageHelpers.generateTableTitleAndSubtitleForPdf(titles[key],
              subTitles[key]))
          allContent.push(changeTables[key])
        }
        if (McPageHelpers.cleanseDataForPdf(changeTables,
          Constants.TYPE.TABLE)) {
          key !== changeTables.length - 1 &&
          allContent.push({ text: '', pageBreak: 'after' })
        }
      }
    }
    if (onlyContent) {
      return allContent
    }

    const exportTitle = i18n.t('menu.summary_tables')
    McPageHelpers.PDFExport(allContent, exportTitle, searchHeader, false,
      'pdf_about.p1_og')
  } catch (error) {
    console.error('error', error)
  }
}

const CustomsDuties = async (
  onlyContent, searchHeader, tableContent, preTitle, country) => {
  try {
    const content = []
    let badgeNumber = 0
    // eslint-disable-next-line array-callback-return
    tableContent.map((baseDuty) => {
      const explanation = baseDuty?.explanation
      baseDuty = baseDuty?.dutiesObj.map(content => ({
        ...content,
        pdfColumns: McPageHelpers.generateColumnsForCustomsDutiesPdf(
          content.columns, country)
      }))

      const tables = baseDuty.map((content) => {
        const subTitle = badgeNumber < 10
          ? '0' + (++badgeNumber)
          : ++badgeNumber
        const header = McPageHelpers.generateTableTitleAndSubtitleForPdf(
          content.title, 'T-GV-' + subTitle)
        const table = McPageHelpers.generatePdfTableFromObjects(content.data,
          content.pdfColumns, true, true)
        return [header, table]
      })
      const title = preTitle + baseDuty.map((content) => { return content.title })
      content.push({
        text: title,
        bold: true,
        fontSize: 20,
        alignment: 'center',
        pageOrientation: 'landscape',
        marginTop: 30,
        marginBottom: 30
      })

      for (const table of tables) {
        if (McPageHelpers.cleanseDataForPdf(table, Constants.TYPE.TABLE)) {
          content.push(...table)
        }
      }
      content.push(
        ...McPageHelpers.generateExplanationFooterForPdf(explanation))
    })

    if (onlyContent) return content
    McPageHelpers.PDFExport(content, i18n.t('menu.duties'), searchHeader, false,
      'pdf_about.p1_gv')
  } catch (error) {
    console.error('error', error)
  }
}

const FullPdf = async (searchHeader, summaryTables,
  productAnalysis, priceAnalysis, marketChanges, marketOverview,
  importerCompanies, customsDuties) => {
  try {
    let sectionCounter = 0
    const content = [
      ...McPageHelpers.generateTableOfContents(),
      McPageHelpers.createAboutSection(true, false, 'pdf_about.p1')
    ]

    const processSectionData = (sectionData, sectionLetter) => {
      const processedContent = []
      let subSectionCounter = 1

      sectionData.forEach((item) => {
        if (!item) return

        if (Array.isArray(item) && item[0]?.text) {
          const subSectionTitle = `${sectionLetter} ${subSectionCounter}. ${item[0].text}`

          processedContent.push(
            McPageHelpers.generateSectionTitle(subSectionTitle, null, 'subToc'))
          subSectionCounter++
        }
        if (item.mcTitle) {
          const mcTitleContent = `${sectionLetter} ${subSectionCounter}. ${item.mcTitle}`
          processedContent.push(
            McPageHelpers.generateSectionTitle(mcTitleContent, null, 'subToc'))
          subSectionCounter++
        }
        if (item.columns) {
          item.columns.forEach(column => {
            if (column.mcTitle) {
              const mcTitleContent = `${sectionLetter} ${subSectionCounter}. ${column.mcTitle}`
              processedContent.push(
                McPageHelpers.generateSectionTitle(mcTitleContent, null,
                  'subToc'))
              subSectionCounter++
            }

            column.table && column.table.body && column.table.body.length &&
            column.table.body.forEach(row => {
              row = row[0]
              if (row.mcTitle) {
                const mcTitleContent = `${sectionLetter} ${subSectionCounter}. ${row.mcTitle}`
                processedContent.push(
                  McPageHelpers.generateSectionTitle(mcTitleContent, null,
                    'subToc'))
                subSectionCounter++
              }
            })
          })
        }

        if (item?.image) {
          processedContent.push(item)
        } else if (item instanceof Array && item[0]) {
          item[0].pageBreak = null
          processedContent.push(item)
        } else {
          processedContent.push(item)
        }
      })
      return processedContent
    }

    const addSectionContent = (title, sectionData) => {
      if (sectionData?.length &&
        sectionData?.filter(item => item !== '')?.length) {
        if (title === 'Duties' && sectionData.length <= 1) return
        const sectionLetter = String.fromCharCode(65 + sectionCounter++)
        const mainTitle = `${sectionLetter}. ${i18n.t(
          'menu.' + title.toLowerCase())}`
        content.push(
          McPageHelpers.generateSectionTitle(mainTitle, 'before', 'mainToc'))

        const processedContent = processSectionData(sectionData, sectionLetter)
        content.push(...processedContent)
      }
    }

    addSectionContent('Summary_Tables', summaryTables)
    addSectionContent('Market_Overview', marketOverview)
    addSectionContent('Market_Changes', marketChanges)
    addSectionContent('Product_Analysis', productAnalysis)
    addSectionContent('Price_Analysis', priceAnalysis)
    addSectionContent('Importer_Companies', importerCompanies)
    addSectionContent('Duties', customsDuties)

    // 3 = sıfır verinin content uzunluğu
    if (content.length > 3) {
      McPageHelpers.PDFExport(content, i18n.t('app_name'),
        searchHeader, true, 'pdf_about.p1')
    }
  } catch (error) {
    console.error('error', error)
  }
}

const McPdfExport = {
  ImporterCompanies,
  CustomsDuties,
  PriceAnalysis,
  MarketChanges,
  ProductAnalysis,
  SummaryTables,
  MarketOverview,
  FullPdf
}

export default McPdfExport
