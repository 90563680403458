import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Icon } from '../Component'
import React, { useState } from 'react'
import { useSDSimaginalUrlSearchParams } from '../../store/searchDataStore'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGlobalStoreData } from '../../store/globalStore'
import TreeList, { Column, Selection } from 'devextreme-react/tree-list'
import { usePdfStoreActions } from '../../store/pdfStore'
import { defaultSelectedComponentIds, defaultSelectedIds } from './default-pdf-components'

const PdfDropdown = (props) => {
  const urlSearchParams = useSDSimaginalUrlSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const languageGSD = useGlobalStoreData.language()
  const { setSelectedIds, setSelectedComponentIds } = usePdfStoreActions()

  const [modalSuccess, setModalSuccess] = useState(false)
  const toggleSuccess = () => { props?.onClick ? props.onClick() : setModalSuccess(!modalSuccess) }

  const PdfComponentList = [
    // Select All
    {
      id: 'all',
      parentId: 0,
      text: t('filter_all')
    },

    // Summary Tables
    {
      id: 'summaryTables',
      parentId: 'all',
      text: t('menu.summary_tables')
    },
    {
      id: 'importingCountry',
      parentId: 'summaryTables',
      text: t('importing_country')
    },
    {
      id: 'yearImporterGraphic',
      parentId: 'importingCountry',
      text: t('st_importer') + ' ' + t('sv_graph'),
      content: ['H-OT-01', 'H-OT-02']
    },
    {
      id: 'yearImporter',
      parentId: 'importingCountry',
      text: t('st_importer') + ' ' + t('sv_table'),
      content: ['tableImportYear', 'tableImportMonth']
    },

    {
      id: 'exportingCountry',
      parentId: 'summaryTables',
      text: t('exporting_country')
    },
    {
      id: 'yearExporterGraphic',
      parentId: 'exportingCountry',
      text: t('st_exporter') + ' ' + t('sv_graph'),
      content: ['H-OT-03', 'H-OT-04']
    },
    {
      id: 'yearExporter',
      parentId: 'exportingCountry',
      text: t('st_exporter') + ' ' + t('sv_table'),
      content: ['tableExportYear', 'tableExportMonth']
    },

    {
      id: 'world',
      parentId: 'summaryTables',
      text: t('world')
    },
    {
      id: 'st_world_year_importer_graphic',
      parentId: 'world',
      text: t('st_world_importer') + ' ' + t('sv_graph'),
      content: ['H-OT-05', 'H-OT-06']
    },
    {
      id: 'st_world_year_importer',
      parentId: 'world',
      text: t('st_world_importer') + ' ' + t('sv_table'),
      content: ['tableWorldImportYear', 'tableWorldImportMonth']
    },
    {
      id: 'st_world_year_exporter_graphic',
      parentId: 'world',
      text: t('st_world_exporter') + ' ' + t('sv_graph'),
      content: ['H-OT-07', 'H-OT-08']
    },
    {
      id: 'st_world_year_exporter',
      parentId: 'world',
      text: t('st_world_exporter') + ' ' + t('sv_table'),
      content: ['tableWorldExportYear', 'tableWorldExportMonth']
    },

    {
      id: 'change',
      parentId: 'summaryTables',
      text: t('market_overview_tabs.tab4')
    },
    {
      id: 'summaryViewChanges',
      parentId: 'change',
      text: t('summaryView.change') + ' ' + t('sv_table'),
      content: 'summaryViewChanges'
    },
    {
      id: 'summaryViewChangesGraph',
      parentId: 'change',
      text: t('summaryView.change') + ' ' + t('sv_graph'),
      content: 'summaryViewChangesGraph'
    },

    // Market Overview
    {
      id: 'marketOverview',
      parentId: 'all',
      text: t('menu.market_overview')
    },

    {
      id: 'market_overview_tabs_tab1',
      parentId: 'marketOverview',
      text: t('market_overview_tabs.tab1')
    },
    {
      id: 'market_overview_tabs_tab1_importing_country',
      parentId: 'market_overview_tabs_tab1',
      text: t('importing_country')
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'importerCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-01'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'importerCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleLineChart-02'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'destinationCountryCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationCountry01'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'destinationCountryCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_importing_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab1_importing_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationCountry02'
    },

    {
      id: 'market_overview_tabs_tab1_exporting_country',
      parentId: 'market_overview_tabs_tab1',
      text: t('exporting_country')
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'exporterCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-03'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'exporterCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleLineChart-04'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'sourceCountryCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-SourceCountry'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'sourceCountryCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_exporting_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab1_exporting_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_value_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationCountry'
    },
    {
      id: 'market_overview_tabs_tab1_world',
      parentId: 'market_overview_tabs_tab1',
      text: t('world')
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_year_importer_table',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'worldImporterCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_year_importer_graph',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_value_title'), values: t('graphic') }),
      content: 'ValueBarChart-09'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_month_importer_table',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'worldImporterCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_month_importer_graph',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_value_title'), values: t('graphic') }),
      content: 'ValueLineChart-10'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_year_exporter_table',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'worldExporterCountryYearlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_year_exporter_graph',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_value_title'), values: t('graphic') }),
      content: 'ValueBarChart-11'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_month_exporter_table',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_value_title'), values: t('table') }),
      content: 'worldExporterCountryMonthlyVal'
    },
    {
      id: 'market_overview_tabs_tab1_world_sp_world_month_exporter_graph',
      parentId: 'market_overview_tabs_tab1_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_value_title'), values: t('graphic') }),
      content: 'ValueLineChart-12'
    },

    {
      id: 'market_overview_tabs_tab2',
      parentId: 'marketOverview',
      text: t('market_overview_tabs.tab2')
    },
    {
      id: 'market_overview_tabs_tab2_importing_country',
      parentId: 'market_overview_tabs_tab2',
      text: t('importing_country')
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'importerCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-07'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'importerCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleLineChart-08'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'destinationCountryCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationCountryQuantity01'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'destinationCountryCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_importing_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab2_importing_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationCountryQuantity02'
    },

    {
      id: 'market_overview_tabs_tab2_exporting_country',
      parentId: 'market_overview_tabs_tab2',
      text: t('exporting_country')
    },

    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'exporterCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_year_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-09'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'exporterCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_month_exporter', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleLineChart-10'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'sourceCountryCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_year_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-SourceQuantityCountry'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('table')
      }),
      content: 'sourceCountryCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_exporting_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab2_exporting_country',
      text: t('sp_values_table_month_importer', {
        tableType: t('sp_market_overview_quantity_title'),
        values: t('graphic')
      }),
      content: 'MultipleBarChart-DestinationQuantityCountry'
    },
    {
      id: 'market_overview_tabs_tab2_world',
      parentId: 'market_overview_tabs_tab2',
      text: t('world')
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_year_importer_table',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_quantity_title'), values: t('table') }),
      content: 'worldImporterCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_year_importer_graph',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_quantity_title'), values: t('graphic') }),
      content: 'MultipleBarChart-11'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_month_importer_table',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_quantity_title'), values: t('table') }),
      content: 'worldImporterCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_month_importer_graph',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_quantity_title'), values: t('graphic') }),
      content: 'MultipleLineChart-12'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_year_exporter_table',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_quantity_title'), values: t('table') }),
      content: 'worldExporterCountryYearlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_year_exporter_graph',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_quantity_title'), values: t('graphic') }),
      content: 'QuantityBarChartWorld-21'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_month_exporter_table',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_quantity_title'), values: t('table') }),
      content: 'worldExporterCountryMonthlyQuantity'
    },
    {
      id: 'market_overview_tabs_tab2_world_sp_world_month_exporter_graph',
      parentId: 'market_overview_tabs_tab2_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_quantity_title'), values: t('graphic') }),
      content: 'QuantityLineChartWorld-22'
    },

    {
      id: 'market_overview_tabs_tab3',
      parentId: 'marketOverview',
      text: t('market_overview_tabs.tab3')
    },
    {
      id: 'market_overview_tabs_tab3_importing_country',
      parentId: 'market_overview_tabs_tab3',
      text: t('importing_country')
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'importerCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-13'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'importerCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleLineChart-14'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'destinationCountryCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-DestinationCountryAverage01'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'destinationCountryCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_importing_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab3_importing_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-DestinationCountryAverage02'
    },

    {
      id: 'market_overview_tabs_tab3_exporting_country',
      parentId: 'market_overview_tabs_tab3',
      text: t('exporting_country')
    },

    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_year_exporter_table',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'exporterCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_year_exporter_graph',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-15'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_month_exporter_table',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'exporterCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_month_exporter_graph',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleLineChart-16'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_year_importer_table',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'sourceCountryCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_year_importer_graph',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-SourceAverageCountry'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_month_importer_table',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'sourceCountryCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_exporting_country_sp_values_table_month_importer_graph',
      parentId: 'market_overview_tabs_tab3_exporting_country',
      text: t('sp_values_table_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-DestinationAverageCountry'
    },

    {
      id: 'market_overview_tabs_tab3_world',
      parentId: 'market_overview_tabs_tab3',
      text: t('world')
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_year_importer_table',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'worldImporterCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_year_importer_graph',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_year_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-17'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_month_importer_table',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'worldImporterCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_month_importer_graph',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_month_importer', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleLineChart-18'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_year_exporter_table',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'worldExporterCountryYearlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_year_exporter_graph',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_year_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleBarChart-31'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_month_exporter_table',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('table') }),
      content: 'worldExporterCountryMonthlyAvg'
    },
    {
      id: 'market_overview_tabs_tab3_world_sp_world_month_exporter_graph',
      parentId: 'market_overview_tabs_tab3_world',
      text: t('sp_world_month_exporter', { tableType: t('sp_market_overview_avg_title'), values: t('graphic') }),
      content: 'MultipleLineChart-32'
    },

    // Market Changes
    {
      id: 'marketChanges',
      parentId: 'all',
      text: t('menu.market_changes')
    },
    {
      id: 'clustered_bar_chart_change_of_rates_title',
      parentId: 'marketChanges',
      text: t('clustered_bar_chart_change_of_rates_title'),
      content: 'clusteredBarChart'
    },
    {
      id: 'stacked_bar_chart_change_of_rates_title',
      parentId: 'marketChanges',
      text: t('stacked_bar_chart_change_of_rates_title'),
      content: 'stackedBarChart'
    },
    {
      id: 'market_changes_table_title',
      parentId: 'marketChanges',
      text: t('market_changes_table_title'),
      content: 'marketChangesTable'
    },
    {
      id: 'comtradeMarketChangeValueSearchedCountry',
      parentId: 'marketChanges',
      text: t('market_change_value_searched_country_title'),
      content: 'comtradeMarketChangeValueSearchedCountry'
    },
    {
      id: 'comtradeMarketChangeValueSearchedCountryPercentage',
      parentId: 'marketChanges',
      text: t('market_change_value_searched_country_title_percentage'),
      content: 'comtradeMarketChangeValueSearchedCountryPercentage'
    },
    {
      id: 'comtradeMarketChangeValueWorld',
      parentId: 'marketChanges',
      text: t('market_change_value_searched_country_title'),
      content: 'comtradeMarketChangeValueWorld'
    },
    {
      id: 'comtradeMarketChangeValueWorldPercentage',
      parentId: 'marketChanges',
      text: t('market_change_value_searched_country_title_percentage'),
      content: 'comtradeMarketChangeValueWorldPercentage'
    },
    {
      id: 'comtradeMarketChangeQuantitySearchedCountry',
      parentId: 'marketChanges',
      text: t('market_change_quantity_searched_country_title'),
      content: 'comtradeMarketChangeQuantitySearchedCountry'
    },
    {
      id: 'comtradeMarketChangeQuantitySearchedCountryPercentage',
      parentId: 'marketChanges',
      text: t('market_change_quantity_searched_country_title_percentage'),
      content: 'comtradeMarketChangeQuantitySearchedCountryPercentage'
    },
    {
      id: 'comtradeMarketChangeQuantityWorld',
      parentId: 'marketChanges',
      text: t('market_change_quantity_searched_country_title'),
      content: 'comtradeMarketChangeQuantityWorld'
    },
    {
      id: 'comtradeMarketChangeQuantityWorldPercentage',
      parentId: 'marketChanges',
      text: t('market_change_quantity_searched_country_title_percentage'),
      content: 'comtradeMarketChangeQuantityWorldPercentage'
    },
    {
      id: 'comtradeMarketChangeValueSeries',
      parentId: 'marketChanges',
      text: t('market_change_value_series_title'),
      content: 'comtradeMarketChangeValueSeries'
    },
    {
      id: 'comtradeMarketChangePercentageValueSeries',
      parentId: 'marketChanges',
      text: t('market_change_value_series_title_percentage'),
      content: 'comtradeMarketChangePercentageValueSeries'
    },
    {
      id: 'comtradeMarketChangeQuantitySeries',
      parentId: 'marketChanges',
      text: t('market_change_quantity_series_title'),
      content: 'comtradeMarketChangeQuantitySeries'
    },
    {
      id: 'comtradeMarketChangePercentageQuantitySeries',
      parentId: 'marketChanges',
      text: t('market_change_quantity_series_title_percentage'),
      content: 'comtradeMarketChangePercentageQuantitySeries'
    },

    // PROD_ANALYSIS
    {
      id: 'productAnalysis',
      parentId: 'all',
      text: t('menu.product_analysis')
    },
    {
      id: 'company_value',
      parentId: 'productAnalysis',
      text: t('company_value'),
      content: 'companyValueChart'
    },
    {
      id: 'only_hs_code',
      parentId: 'productAnalysis',
      text: t('only_hs_code'),
      content: 'onlyHsCount'
    },
    {
      id: 'company_count',
      parentId: 'productAnalysis',
      text: t('company_count'),
      content: 'companyCountChart'
    },
    {
      id: 'country_count',
      parentId: 'productAnalysis',
      text: t('country_count'),
      content: 'countryCount'
    },
    {
      id: 'sp_company_shipment_count',
      parentId: 'productAnalysis',
      text: t('sp_company_shipment_count'),
      content: 'companyShipmentCount'
    },
    {
      id: 'sp_growth_table_head',
      parentId: 'productAnalysis',
      text: t('sp_growth_table_head'),
      content: 'growth'
    },

    // Price Analysis
    {
      id: 'priceAnalysis',
      parentId: 'all',
      text: t('menu.price_analysis')
    },
    {
      id: 'world_avg_price',
      parentId: 'priceAnalysis',
      text: t('world_avg_price'),
      content: 'annualAverageImportUnitPricesMapGraph'
    },
    {
      id: 'avg_line_graphic_title',
      parentId: 'priceAnalysis',
      text: t('avg_line_graphic_title', { unit: '' }),
      content: 'priceAnalysisMultiValue'
    },
    {
      id: 'ai_sp_price_page_table_title',
      parentId: 'priceAnalysis',
      text: t('ai_sp_price_page_table_title'),
      content: 'priceTablePdfContent'
    },
    {
      id: 'ai_sp_static_hs_total_value_currency',
      parentId: 'priceAnalysis',
      text: t('ai_sp_static_hs_total_value_currency'),
      content: 'hsTotalImportCurrencyXFirmNum'
    },
    {
      id: 'sp_filter_by_avg_price_hs_total',
      parentId: 'priceAnalysis',
      text: t('sp_filter_by_avg_price_hs_total'),
      content: 'averagePriceXTotalImportCurrency'
    },
    {
      id: 'avg_price_bar_title',
      parentId: 'priceAnalysis',
      text: t('avg_price_bar_title'),
      content: 'averagePriceChart'
    },
    {
      id: 'ai_sp_static_hs_code_count',
      parentId: 'priceAnalysis',
      text: t('ai_sp_static_hs_code_count'),
      content: 'hsCodeChart'
    },
    {
      id: 'sp_filter_by_avg_price_hs_total_percentage',
      parentId: 'priceAnalysis',
      text: t('sp_filter_by_avg_price_hs_total_percentage'),
      content: 'averagePriceXTotalImportInterest'
    },

    // Importer Companies
    {
      id: 'importerCompanies',
      parentId: 'all',
      text: t('menu.importer_companies')
    },
    {
      id: 'importer_firms',
      parentId: 'importerCompanies',
      text: t('importer_firms'),
      content: 'importerTablePdfContent'
    },
    {
      id: 'graph_importer_analysis',
      parentId: 'importerCompanies',
      text: t('graph_importer_analysis'),
      content: 'importerCardMapGraph'
    },
    {
      id: 'potential_firms',
      parentId: 'importerCompanies',
      text: t('potential_firms'),
      content: 'potentialTablePdfContent'
    },
    {
      id: 'graph_potential_analysis',
      parentId: 'importerCompanies',
      text: t('graph_potential_analysis'),
      content: 'potentialCardMapGraph'
    },

    // Customs Duties
    {
      id: 'duties',
      parentId: 'all',
      text: t('menu.duties'),
      content: 'duties'
    }
  ]

  const pdfColumnRender = column => ['all', 0].includes(column.data.parentId)
    ? <b>{column.data.text}</b>
    : column.data.text

  const findRootParentId = item => {
    if (item.parentId === 0) return 'all'
    else if (item.parentId === 'all') return item.id
    else return findRootParentId(PdfComponentList.filter(component => component.id === item.parentId)[0])
  }

  const onSelectionChanged = e => {
    const selectedData = e.component.getSelectedRowsData('leavesOnly').map(item => item.content)
    let rootNodes = e.component.getSelectedRowsData('excludeRecursive').map(item => findRootParentId(item))
    const selectedIds = e.component.getSelectedRowsData('leavesOnly').map(item => item.id)

    const selectedDataSpreaded = selectedData.flat()// for array item.content

    if (rootNodes.includes('all')) rootNodes = PdfComponentList.filter(item => item.parentId === 'all').map(item => item.id)
    setSelectedLocalIds(selectedIds)
    setSelectedLocalComponentIds([...rootNodes, ...selectedDataSpreaded])
  }

  const [selectedLocalIds, setSelectedLocalIds] = useState(defaultSelectedIds)
  const [selectedLocalComponentIds, setSelectedLocalComponentIds] = useState(defaultSelectedComponentIds)

  const pdfDownloadTrigger = () => {
    setSelectedIds(selectedLocalIds)
    setSelectedComponentIds(selectedLocalComponentIds)
    navigate(`${process.env.PUBLIC_URL}/${languageGSD}/pdf${urlSearchParams ? '?' + urlSearchParams : ''}`)
    setModalSuccess(false)
  }

  return (
    <>
      <svg className={`svg-icon ${!urlSearchParams ? 'display-none' : ''}`} viewBox="0 0 32 32"
           xmlns="http://www.w3.org/2000/svg" fill="#000000" onClick={toggleSuccess}>
        <g id="SVGRepo_bgCarrier" strokeWidth={0}/>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="SVGRepo_iconCarrier">
          <title>{t('down_pdf')}</title>
          <path
            d="M24.1,2.072h0l5.564,5.8V29.928H8.879V30H29.735V7.945L24.1,2.072"
            style={{ fill: '#909090' }}
          />
          <path
            d="M24.031,2H8.808V29.928H29.664V7.873L24.03,2"
            style={{ fill: '#f4f4f4' }}
          />
          <path
            d="M8.655,3.5H2.265v6.827h20.1V3.5H8.655"
            style={{ fill: '#7a7b7c' }}
          />
          <path
            d="M22.472,10.211H2.395V3.379H22.472v6.832"
            style={{ fill: '#798bff' }}
          />
          <path
            d="M9.052,4.534h-.03l-.207,0H7.745v4.8H8.773V7.715L9,7.728a2.042,2.042,0,0,0,.647-.117,1.427,1.427,0,0,0,.493-.291,1.224,1.224,0,0,0,.335-.454,2.13,2.13,0,0,0,.105-.908,2.237,2.237,0,0,0-.114-.644,1.173,1.173,0,0,0-.687-.65A2.149,2.149,0,0,0,9.37,4.56a2.232,2.232,0,0,0-.319-.026M8.862,6.828l-.089,0V5.348h.193a.57.57,0,0,1,.459.181.92.92,0,0,1,.183.558c0,.246,0,.469-.222.626a.942.942,0,0,1-.524.114"
            style={{ fill: '#464648' }}
          />
          <path
            d="M12.533,4.521c-.111,0-.219.008-.295.011L12,4.538h-.78v4.8h.918a2.677,2.677,0,0,0,1.028-.175,1.71,1.71,0,0,0,.68-.491,1.939,1.939,0,0,0,.373-.749,3.728,3.728,0,0,0,.114-.949,4.416,4.416,0,0,0-.087-1.127,1.777,1.777,0,0,0-.4-.733,1.63,1.63,0,0,0-.535-.4,2.413,2.413,0,0,0-.549-.178,1.282,1.282,0,0,0-.228-.017m-.182,3.937-.1,0V5.392h.013a1.062,1.062,0,0,1,.6.107,1.2,1.2,0,0,1,.324.4,1.3,1.3,0,0,1,.142.526c.009.22,0,.4,0,.549a2.926,2.926,0,0,1-.033.513,1.756,1.756,0,0,1-.169.5,1.13,1.13,0,0,1-.363.36.673.673,0,0,1-.416.106"
            style={{ fill: '#464648' }}
          />
          <path
            d="M17.43,4.538H15v4.8h1.028V7.434h1.3V6.542h-1.3V5.43h1.4V4.538"
            style={{ fill: '#464648' }}
          />
          <path
            d="M21.781,20.255s3.188-.578,3.188.511S22.994,21.412,21.781,20.255Zm-2.357.083a7.543,7.543,0,0,0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216,14.216,0,0,0,1.658,2.252,13.033,13.033,0,0,0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787,10.787,0,0,1-.517,2.434A4.426,4.426,0,0,1,18.161,13.841ZM13.513,24.354c-.978-.585,2.051-2.386,2.6-2.444C16.11,21.911,14.537,24.966,13.513,24.354ZM25.9,20.895c-.01-.1-.1-1.207-2.07-1.16a14.228,14.228,0,0,0-2.453.173,12.542,12.542,0,0,1-2.012-2.655,11.76,11.76,0,0,0,.623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933,2.013a9.309,9.309,0,0,0,.665,2.338s-.425,1.323-.987,2.639-.946,2.006-.946,2.006a9.622,9.622,0,0,0-2.725,1.4c-.824.767-1.159,1.356-.725,1.945.374.508,1.683.623,2.853-.91a22.549,22.549,0,0,0,1.7-2.492s1.784-.489,2.339-.623,1.226-.24,1.226-.24,1.629,1.639,3.2,1.581,1.495-.939,1.485-1.035"
            style={{ fill: '#798bff' }}
          />
          <path
            d="M23.954,2.077V7.95h5.633L23.954,2.077Z"
            style={{ fill: '#909090' }}
          />
          <path d="M24.031,2V7.873h5.633L24.031,2Z" style={{ fill: '#f4f4f4' }}/>
          <path
            d="M8.975,4.457h-.03l-.207,0H7.668v4.8H8.7V7.639l.228.013a2.042,2.042,0,0,0,.647-.117,1.428,1.428,0,0,0,.493-.291A1.224,1.224,0,0,0,10.4,6.79a2.13,2.13,0,0,0,.105-.908,2.237,2.237,0,0,0-.114-.644,1.173,1.173,0,0,0-.687-.65,2.149,2.149,0,0,0-.411-.105,2.232,2.232,0,0,0-.319-.026M8.785,6.751l-.089,0V5.271H8.89a.57.57,0,0,1,.459.181.92.92,0,0,1,.183.558c0,.246,0,.469-.222.626a.942.942,0,0,1-.524.114"
            style={{ fill: '#fff' }}
          />
          <path
            d="M12.456,4.444c-.111,0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677,2.677,0,0,0,1.028-.175,1.71,1.71,0,0,0,.68-.491,1.939,1.939,0,0,0,.373-.749,3.728,3.728,0,0,0,.114-.949,4.416,4.416,0,0,0-.087-1.127,1.777,1.777,0,0,0-.4-.733,1.63,1.63,0,0,0-.535-.4,2.413,2.413,0,0,0-.549-.178,1.282,1.282,0,0,0-.228-.017m-.182,3.937-.1,0V5.315h.013a1.062,1.062,0,0,1,.6.107,1.2,1.2,0,0,1,.324.4,1.3,1.3,0,0,1,.142.526c.009.22,0,.4,0,.549a2.926,2.926,0,0,1-.033.513,1.756,1.756,0,0,1-.169.5,1.13,1.13,0,0,1-.363.36.673.673,0,0,1-.416.106"
            style={{ fill: '#fff' }}
          />
          <path
            d="M17.353,4.461h-2.43v4.8h1.028V7.357h1.3V6.465h-1.3V5.353h1.4V4.461"
            style={{ fill: '#fff' }}
          />
        </g>
      </svg>

      <Modal isOpen={modalSuccess} toggle={toggleSuccess} size="lg" centered={true} fade={true} scrollable={true}>
        <ModalBody className="text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">{t('customize_pdf')}</h4>
            <div className="d-flex flex-row">
              <TreeList id={'pdfExport_menus'}
                        dataSource={PdfComponentList}
                        showColumnHeaders={false}
                        columnAutoWidth={true}
                        defaultExpandedRowKeys={['all']}
                        selectedRowKeys={selectedLocalIds}
                        keyExpr="id"
                        parentIdExpr="parentId"
                        onSelectionChanged={onSelectionChanged}
              >
                <Selection allowSelectAll={true} recursive={true} mode={'multiple'}/>
                <Column dataField="text" cellRender={pdfColumnRender}/>
              </TreeList>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light">
          <div className="d-flex justify-around flex-column align-center w-100">
            <Button color="primary" className="btn-mw w-40"
                    disabled={(!urlSearchParams && !window.location.pathname.includes('/pdf')) || !selectedLocalIds.length}
                    onClick={pdfDownloadTrigger}
            >
              <Icon name="file-pdf" className="pdf-icon"></Icon>
              <span>{t('down_full_pdf')}</span>
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
export default PdfDropdown
