import React, { useState, createContext, useContext, useEffect } from 'react'
import classNames from 'classnames'

const ThemeContext = createContext()
const ThemeUpdateContext = createContext()

export function useTheme () {
  return useContext(ThemeContext)
}

export function useThemeUpdate () {
  return useContext(ThemeUpdateContext)
}

const ThemeProvider = ({ ...props }) => {
  const defaultTheme = {
    main: 'shady', // other value can be passed "clean,shady,softy"
    sidebarVisibility: false,
    sidebarMobile: false,
    header: 'white', // other value can be passed "light,dark,theme"
    skin: 'light' // other value can be passed "dark"
  }
  const [theme, setTheme] = useState(defaultTheme)

  const themeUpdate = {
    uistyle: function (value) {
      setTheme(prevTheme => ({ ...prevTheme, main: value }))
    },
    sidebarVisibility: function (e) {
      setTheme(prevTheme => ({ ...prevTheme, sidebarVisibility: !prevTheme.sidebarVisibility }))
    },
    sidebarHide: function (e) {
      setTheme(prevTheme => ({ ...prevTheme, sidebarVisibility: false }))
    },
    header: function (value) {
      setTheme(prevTheme => ({ ...prevTheme, header: value }))
    },
    skin: function (value) {
      setTheme(prevTheme => ({ ...prevTheme, skin: value }))
    },
    reset: function (e) {
      setTheme({
        main: defaultTheme.main,
        sidebarVisibility: defaultTheme.sidebarVisibility,
        header: defaultTheme.header,
        skin: defaultTheme.skin
      })
    }
  }

  const bodyClass = classNames({
    'nk-body bg-lighter npc-invest has-touch nk-nio-theme': true
  })

  useEffect(() => {
    const body = document.querySelector('body')
    body.className = bodyClass
  }, [bodyClass])

  useEffect(() => {
    const body = document.querySelector('body')
    if (theme.main === 'default') {
      body.classList.add('ui-default')
      body.classList.remove('ui-clean', 'ui-shady', 'ui-softy')
    }
    if (theme.main === 'clean') {
      body.classList.add('ui-clean')
      body.classList.remove('ui-default', 'ui-shady', 'ui-softy')
    }
    if (theme.main === 'shady') {
      body.classList.add('ui-shady')
      body.classList.remove('ui-default', 'ui-clean', 'ui-softy')
    }
    if (theme.main === 'softy') {
      body.classList.add('ui-softy')
      body.classList.remove('ui-default', 'ui-clean', 'ui-shady')
    }
    if (theme.skin === 'dark') {
      body.classList.add('dark-mode')
    } else {
      body.classList.remove('dark-mode')
    }
    if (theme.sidebarVisibility === true) {
      body.classList.add('nav-shown')
    } else {
      body.classList.remove('nav-shown')
    }
  }, [theme])

  useEffect(() => {
    const body = document.querySelector('body')
    const observer = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width
      if (width < 992) {
        setTheme(prevTheme => ({ ...prevTheme, sidebarMobile: true }))
      } else {
        setTheme(prevTheme => ({ ...prevTheme, sidebarMobile: false, sidebarVisibility: false }))
      }
    })
    observer.observe(body)

    return () => {
      observer.unobserve(body)
    }
  }, [])

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeUpdateContext.Provider value={themeUpdate}>
        {props.children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  )
}
export default ThemeProvider
