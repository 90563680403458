import { create } from 'zustand'
import { REQUEST_STATUSES } from '../pages/mc-components/McPageHelpers'

const useEndpointStatus = create((set) => ({
  comtradeMarketOverview: REQUEST_STATUSES.IDLE,
  comtradeMarketChange: REQUEST_STATUSES.IDLE,
  marketChange: REQUEST_STATUSES.IDLE,
  rfTable: REQUEST_STATUSES.IDLE,
  spTable: REQUEST_STATUSES.IDLE,
  spGraph: REQUEST_STATUSES.IDLE,
  spSummary: REQUEST_STATUSES.IDLE,
  monthly: REQUEST_STATUSES.IDLE,
  comtradeSummary: REQUEST_STATUSES.IDLE,
  duties: REQUEST_STATUSES.IDLE,
  errorMessage: { ruleType: '', customMessage: [] },
  actions: {
    setEndpointStatus: (endpoint, status = '') => {
      set((state) => ({
        [endpoint]: status
      }))
    }
  }
}))

const dataGetter = (key) => useEndpointStatus((state) => state[key])

export const useEndpointStatusData = {
  comtradeMarketOverview: () => dataGetter('comtradeMarketOverview'),
  comtradeMarketChange: () => dataGetter('comtradeMarketChange'),
  marketChange: () => dataGetter('marketChange'),
  rfTable: () => dataGetter('rfTable'),
  spTable: () => dataGetter('spTable'),
  spGraph: () => dataGetter('spGraph'),
  spSummary: () => dataGetter('spSummary'),
  monthly: () => dataGetter('monthly'),
  comtradeSummary: () => dataGetter('comtradeSummary'),
  duties: () => dataGetter('duties'),
  errorMessage: () => dataGetter('errorMessage')
}

export const useEndpointStatusActions = () => useEndpointStatus((state) => state.actions)

export const setEndpointStatusOutside = (endpoint, status = '') => {
  useEndpointStatus.setState((state) => {
    return { [endpoint]: status }
  })
}

export default useEndpointStatus
