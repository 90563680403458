import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import Content from '../layout/content/Content'
import Head from '../layout/head/Head'
import { useTranslation } from 'react-i18next'
import MarketOverviewSection from './mc-components/market-overview/MarketOverviewSection'
import ScrollToTopButton from '../components/scroll-button/ScrollButton'
import McPageHeader from '../components/page-header/McPageHeader'
import McPdfExport from './mc-components/McPdfExport'
import { useSDSsearchParams } from '../store/searchDataStore'
import McPageHelpers from './mc-components/McPageHelpers'
import { useEndpointStatusData } from '../store/endpointStatus'
import { RESPONSE_OBJ_NAMES } from '../services/McDataServices'
import { usePDFselectedComponentIds } from '../store/pdfStore'

const MarketOverview = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const searchParams = useSDSsearchParams()
  const marketOverviewRef = useRef(null)

  const comtradeMarketOverview = useEndpointStatusData.comtradeMarketOverview()
  const selectedComponentIds = usePDFselectedComponentIds()

  const searchHeader = searchParams.hsCode + ' - ' + McPageHelpers.findCountryName(searchParams.countryToExport) + ' - ' + McPageHelpers.findCountryName(searchParams.yourCountry)
  const subtitle = (t('world') + ' ' + searchParams.hsCode)

  const pdfDownload = (onlyContent = false) => {
    const tables = marketOverviewRef.current.generateMarketOverviewTablesPdf()
    return McPdfExport.MarketOverview(onlyContent, searchHeader, tables, subtitle, selectedComponentIds)
  }

  useImperativeHandle(ref, () => ({
    async getPdfContent () {
      const content = await pdfDownload(true)
      return content
    }
  }))

  useEffect(() => McPageHelpers.showErrorToast(RESPONSE_OBJ_NAMES.COMTRADE_MARKET_OVERVIEW, comtradeMarketOverview), [comtradeMarketOverview])

  return (
    <React.Fragment>
      <Head title={t('menu.market_overview')}/>
      <Content>
        <ScrollToTopButton/>
        <McPageHeader title={t('menu.market_overview')}/>
        <MarketOverviewSection ref={marketOverviewRef} null={comtradeMarketOverview !== 'success'}/>
      </Content>
    </React.Fragment>
  )
})

export default MarketOverview
