import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import {
  AccessTime,
  MarkEmailRead,
  Archive,
  KeyboardArrowUp
} from '@mui/icons-material'

import EmailStatusItem from './EmailStatusItem'

const dataKeys = ['incomingEmailCount', 'repliedEmailCount', 'inboxLastChecked']

const icons = {
  incomingEmailCount: Archive,
  repliedEmailCount: MarkEmailRead,
  inboxLastChecked: AccessTime
}

const getPercent = (val, total, precision = 1) => {
  const percent = val * 100 / total

  if (percent === Infinity || isNaN(percent)) {
    return null
  } else {
    return percent % 1 === 0 ? percent : percent.toFixed(precision)
  }
}

const EmailStatusItemList = ({ campaignReport }) => {
  const { t } = useTranslation()
  const [statusItems, setStatusItems] = useState([])

  const initItemListData = () => {
    const userLocale = localStorage.getItem('lang') === 'tr' ? 'tr-TR' : 'en-US'
    const processedStatusItems = []

    dataKeys.forEach((item) => {
      if (campaignReport?.[item] || campaignReport?.[item] === 0) {
        const value = item === 'inboxLastChecked'
          ? new Date(campaignReport[item])
            .toLocaleString(userLocale)
            .split(',')[0]
            .split(' ')[0]
          : campaignReport[item]

        const totalMail = (campaignReport?.outgoingEmailStats?.SENT || 0) + (campaignReport?.outgoingEmailStats?.FAILED || 0)

        const highlightedText = item === 'repliedEmailCount'
          ? getPercent(campaignReport[item], totalMail)
          : null

        const label = t(`mailTemplate.campaigns.emailReport.${item}`)

        processedStatusItems.push({
          label,
          value,
          highlightedText: highlightedText
            ? <>
                <KeyboardArrowUp sx={{ width: 10, height: 10, marginLeft: 2 }}/>{`${highlightedText}%`}
              </>
            : null,
          icon: icons[item],
          infoText: label
        })
      }
    })

    setStatusItems(processedStatusItems)
  }

  useEffect(() => {
    initItemListData()
  }, [campaignReport, t])

  return (
    <div className='w-full flex pb-1'>
      {statusItems.map((item, index) =>
        <div
          key={index}
          className='grow p-1 flex'
          style={{
            borderLeft: index > 0 ? 'thin solid var(--mui-palette-divider)' : 'unset'
          }}
        >
          <EmailStatusItem
            title={item.label}
            text={item.value}
            highlightedTextPart={item.highlightedText}
            Icon={item.icon}
            InfoComponent={item?.infoComponent}
            infoText={item?.infoText}
          />
        </div>
      )}
    </div>
  )
}

export default EmailStatusItemList
