import React, { useLayoutEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5geodataWorldLow from '@amcharts/amcharts5-geodata/worldLow'
import { localizationAMChart } from '../../utils/AMChartsSettings'

const McMap = ({ chartId, chartData, chartTitle, ...props }) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(chartId)
    localizationAMChart(root)

    const chart = root.container.children.push(am5map.MapChart.new(root, {
      exportable: true
    }))

    chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodataWorldLow,
        exclude: ['AQ']
      })
    )

    const bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: 'value',
        calculateAggregates: true,
        polygonIdField: 'id'
      })
    )

    const circleTemplate = am5.Template.new({})

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      const container = am5.Container.new(root, {})

      container.children.push(
        am5.Circle.new(root, {
          radius: 20,
          fillOpacity: 0.7,
          fill: am5.color(0xff0000),
          cursorOverStyle: 'pointer',
          tooltipText: '{name}: [bold]{value}[/]'
        }, circleTemplate)
      )

      // let countryLabel = container.children.push(
      //     am5.Label.new(root, {
      //         text: "{name}",
      //         paddingLeft: 5,
      //         populateText: true,
      //         fontWeight: "bold",
      //         fontSize: 13,
      //         centerY: am5.p50
      //     })
      // );

      // circle.on("radius", function(radius) {
      //     countryLabel.set("x", radius);
      // })

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true
      })
    })

    bubbleSeries.bullets.push(function (root, series, dataItem) {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: '{value}',
          fill: am5.color(0xffffff),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          textAlign: 'center'
        }),
        dynamic: true
      })
    })

    bubbleSeries.data.setAll(chartData)

    return () => {
      root.dispose()
    }
  }, [chartData])

  return <>
    <div className="card-title-group mb-1">
      <div className="card-title">
        <h6 className="title">{chartTitle}</h6>
        <p>
          {/* The investment overview of your platform. */}
        </p>
      </div>
    </div>

    <div id={chartId} style={{ width: '100%', height: '200px' }}></div>
  </>
}

export default McMap
