import React, { useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useTranslation } from 'react-i18next'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'
import { localizationAMChart, PALETTE_HEX_3 } from '../../utils/AMChartsSettings'

function StackedBarChart ({ response, country, chartTitle, xTitle, yTitle, chartSubTitle, code }) {
  const { t } = useTranslation()
  const rootRef = useRef(null)

  useLayoutEffect(() => {
    const root = am5.Root.new('stackedBarChart')
    localizationAMChart(root)

    const myTheme = am5.Theme.new(root)

    myTheme.rule('Grid', ['base']).setAll({
      strokeOpacity: 0.1
    })
    root.setThemes([myTheme])

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
      exportable: true
    }))

    chart.get('colors').set('colors', PALETTE_HEX_3)

    const yRenderer = am5xy.AxisRendererY.new(root, {})
    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'countryCode',
      renderer: yRenderer
    }))

    yAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      maxWidth: 90,
      textAlign: 'right'
    })

    yRenderer.grid.template.setAll({
      location: 1
    })
    response?.stackedData && yAxis.data.setAll(response?.stackedData)

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: '#.00',
      calculateTotals: true,
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1
      })
    }))

    xAxis.get('renderer').grid.template.adapters.add('strokeWidth', (width, target) => {
      if (target.dataItem && target.dataItem.get('value') === 0) {
        return 5
      }
      return width
    })

    function makeSeries (name, fieldName) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        stacked: true,
        xAxis,
        yAxis,
        baseAxis: yAxis,
        valueXField: fieldName,
        maskBullets: false,
        categoryYField: 'countryCode'
      }))
      series.columns.template.setAll({
        tooltipText: `{name}: {${'divided-' + fieldName}} ({${'tooltip-' + fieldName}}) \n${t('stacked_bar_chart_total')} : {totalTooltip}`,
        tooltipY: am5.percent(90)
      })

      series.columns.template.setAll({
        height: response.stackedData?.length > 3 ? am5.percent(80) : am5.percent(30)
      })

      series.data.setAll(response?.stackedData)
      series.appear()
    }

    response?.countryCodes && response?.countryCodes.forEach(item => makeSeries(`${McPageHelpers.findCountryName(item, country)}`, `${item}`))

    rootRef.current = root

    chart.appear()

    return () => {
      root.dispose()
    }
  }, [response, country])

  return (
    <div className="d-flex flex-column">
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-90deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitle}</p>
        </div>

        <div id="stackedBarChart"
             style={{
               width: '90%',
               height: '550px',
               flex: '1 100%'
             }}></div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ fontSize: '10px', marginLeft: '6rem' }}>{xTitle}</p>
      </div>
    </div>

  )
}

export default StackedBarChart
