import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Alert, Form, Spinner } from 'reactstrap'

import Logo from '../../images/logo.png'
import LogoDark from '../../images/logo-dark.png'
import Head from '../../layout/head/Head'
import AuthFooter from './AuthFooter'
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard
} from '../../components/Component'
import McDataServices from '../../services/McDataServices'
import { useGlobalStoreActions, useGlobalStoreData } from '../../store/globalStore'
import { HOMEPAGE_URL } from '../../services/api-common'
import { LANGUAGES } from '../../utils/Utils'

const Login = () => {
  const languageGSD = useGlobalStoreData.language()
  const { setLoggedInStatus } = useGlobalStoreActions()
  const [loading, setLoading] = useState(false)
  const [passState, setPassState] = useState(false)
  const [errorVal, setError] = useState('')
  const { t } = useTranslation()

  const onFormSubmit = (formData) => {
    setLoading(true)

    const loginDTO = {
      userName: formData.name,
      password: formData.passcode
    }

    McDataServices.login(loginDTO).then(result => { // token aktifleştirilirse param olarak ilave edilmesi gerekiyor
      setLoggedInStatus(!!result)
      localStorage.setItem('username', formData.name)

      window.location.href = `${process.env.PUBLIC_URL}/${languageGSD}`// for reset the document.referrer
    }).catch(error => {
      console.error('login:', error)
      setTimeout(() => {
        setError('Cannot login with credentials')
        setLoading(false)
      }, 2000)
    })
  }

  const { register, handleSubmit, formState: { errors } } = useForm()

  const navigateRegister = () => {
    if (languageGSD === LANGUAGES.TURKISH) { return `${HOMEPAGE_URL + '/' + languageGSD}/uyeol` }
    return `${HOMEPAGE_URL + '/' + languageGSD}/register`
  }

  return <>
    <Head title={t('login')}/>
    <Block className="nk-block-middle nk-auth-body wide-xs">
      <div className="brand-logo pb-4 text-center">
        <Link to={`${HOMEPAGE_URL}/${languageGSD}/${languageGSD === LANGUAGES.TURKISH ? 'ana-sayfa' : 'home-page'}`} className="logo-link">
          <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo"/>
          <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark"/>
        </Link>
      </div>

      <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">{t('login')}</BlockTitle>
            <BlockDes>
              <p>{t('access_sign_in')}</p>
            </BlockDes>
          </BlockContent>
        </BlockHead>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle"/> {t('unable_login')}
            </Alert>
          </div>
        )}
        <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                {t('username')} / {t('email')}
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="default-01"
                name="name"
                {...register('name', { required: 'This field is required' })}
                placeholder={t('username') + ' / ' + t('email')}
                className="form-control-lg form-control"
              />
              {errors.name && <span className="invalid">{errors.name.message}</span>}
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="password">
                {t('password')}
              </label>
            </div>
            <div className="form-control-wrap">
              <a
                href="#password"
                onClick={(ev) => {
                  ev.preventDefault()
                  setPassState(!passState)
                }}
                className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={passState ? 'text' : 'password'}
                id="password"
                name="passcode"
                {...register('passcode', { required: 'This field is required' })}
                placeholder={t('password')}
                className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}
              />
              {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
            </div>
          </div>

          <div className="form-group">
            <Button size="lg" className="btn-block" type="submit" color="primary">
              {loading ? <Spinner size="sm" color="light"/> : t('login')}
            </Button>
          </div>
        </Form>
      </PreviewCard>
      <div className="form-note-s2 text-center pt-4">
        {t('no_account')}
        <Link to={navigateRegister()}>
          <strong> {t('register')}</strong>
        </Link>
      </div>
      <div className="form-note-s2 text-center pt-1">
        {t('no_password')}
        <Link to={`${process.env.PUBLIC_URL + '/' + languageGSD}/reset`}>
          <strong> {t('reset_password')}</strong>
        </Link>
      </div>
    </Block>
    <AuthFooter/>
  </>
}
export default Login
