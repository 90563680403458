import React from 'react'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { useTranslation } from 'react-i18next'
import { McLineChart } from '../../../components/Component'
import { NUMBER_FORMATTER_TYPE } from '../../../utils/AMChartsSettings'
import { Col, Row } from 'reactstrap'
import CONSTANTS from '../../../utils/Constants'
import McPageHelpers from '../McPageHelpers'
import {
  useDSglobalParams,
  useDSunits,
  useDShsTotalMountain,
  useDShsCountMountain,
  useDSavgPriceMountain,
  useDSavgCurrencyMountain,
  useDSavgInterestMountain,
  useDSallExplanations
} from '../../../store/dataStore'
import { useFSimporterFilters, useFSoutliers, useFilterStoreActions } from '../../../store/filterStore'

const LineCharts = () => {
  const { t } = useTranslation()

  const globalParams = useDSglobalParams()
  const units = useDSunits()
  const hsTotalMountain = useDShsTotalMountain()
  const hsCountMountain = useDShsCountMountain()
  const avgPriceMountain = useDSavgPriceMountain()
  const avgCurrencyMountain = useDSavgCurrencyMountain()
  const avgInterestMountain = useDSavgInterestMountain()
  const allExplanations = useDSallExplanations()

  const { setImporterFilters } = useFilterStoreActions()
  const outliers = useFSoutliers()
  const importerFilters = useFSimporterFilters()

  const filterByMountainChartsMinMax = (chartId, paramName, value) => {
    setImporterFilters(chartId, value, true, paramName)
  }

  const unitVal = units[globalParams.selectedUnit]
  const managedUnitVal = unitVal?.substr(0, 3) === '***' ? unitVal : t(`units.${unitVal?.toLowerCase()}`)

  return (<>
    <Row className="mt-4">
      <Col>
        <MarketCalculusCard className={hsTotalMountain?.raw.data?.length ? '' : 'loading-div'}
                            id={McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM + McPageHelpers.FOR_PDF_ID}>
          <McLineChart
            chartId={McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM}
            chart={hsTotalMountain}
            colorIndex="1"
            xFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            disablePointerEvents={false}
            handleChange={filterByMountainChartsMinMax}
            doFilter={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM].doFilter}
            selectedMin={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM].min}
            selectedMax={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM].max}
            chartTitle={t('ai_sp_static_hs_total_value_currency')}
            xTitle={t('ai_sp_static_hs_total_value_currency')} yTitle={t('sp_company_shipment_y')}
            height={'200px'}
            fillSeries={true}
            showFilterButtons={true}
            showCheckBoxes={true}
            outlierCheck={outliers[McPageHelpers.FILTER_BUTTON_IDS.HS_TOTAL_IMPORT_CURRENCY_X_FIRM_NUM]}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_4}
          />
        </MarketCalculusCard>
        {!!hsTotalMountain?.raw?.data?.length && McPageHelpers.generateExplanationFooter(allExplanations?.hsTotalValFirmNumExplanation)}
      </Col>
      <Col>
        <MarketCalculusCard
          id={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY + McPageHelpers.FOR_PDF_ID}
          className={avgCurrencyMountain?.raw.data?.length ? '' : 'loading-div'}>
          <McLineChart
            chartId={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY}
            chart={avgCurrencyMountain}
            colorIndex="6"
            xFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            yFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            handleChange={filterByMountainChartsMinMax}
            disablePointerEvents={false}
            doFilter={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY].doFilter}
            selectedMin={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY].min}
            selectedMax={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY].max}
            xTitle={t('avg_price')}
            yTitle={t('ai_sp_static_hs_total_value_currency')}
            chartTitle={t('sp_filter_by_avg_price_hs_total')}
            height={'200px'}
            fillSeries={true}
            showFilterButtons={true}
            showCheckBoxes={true}
            outlierCheck={outliers[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_CURRENCY]}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_5}
          />
        </MarketCalculusCard>
        {!!avgCurrencyMountain?.raw?.data?.length && McPageHelpers.generateExplanationFooter(allExplanations?.avgPriceHsTotalValExplanations[globalParams.selectedUnitNumber - 1])}

      </Col>
      <Col>
        <MarketCalculusCard id={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART + McPageHelpers.FOR_PDF_ID}
                            className={avgPriceMountain?.raw.data?.length ? '' : 'loading-div'}>
          <McLineChart
            chartId={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART}
            chart={avgPriceMountain}
            colorIndex="9"
            xFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            disablePointerEvents={false}
            handleChange={filterByMountainChartsMinMax}
            doFilter={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART].doFilter}
            selectedMin={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART].min}
            selectedMax={importerFilters[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART].max}
            xTitle={`${t('avg_price_bar_title')} ($/${managedUnitVal})`}
            yTitle={t('sp_company_shipment_y')}
            chartTitle={t('sp_filter_by_avg_price')}
            height={'200px'}
            showFilterButtons={true}
            fillSeries={true}
            showCheckBoxes={true}
            outlierCheck={outliers[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_CHART]}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_6}
          />
        </MarketCalculusCard>
        {!!avgPriceMountain?.raw?.data?.length && McPageHelpers.generateExplanationFooter(allExplanations?.avgPriceFirmNumExplanations[globalParams.selectedUnitNumber - 1])}

      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <MarketCalculusCard id={McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_CHART + McPageHelpers.FOR_PDF_ID}
                            className={hsCountMountain?.raw.data?.length ? '' : 'loading-div'}>
          <McLineChart
            chartId={McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_CHART}
            chart={hsCountMountain}
            colorIndex="9"
            yFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            disablePointerEvents={false}
            chartTitle={t('ai_sp_static_hs_code_count')}
            xTitle={t('ai_sp_static_hs_code_count')}
            yTitle={t('ai_sp_static_hs_total_value_currency')}
            height={'300px'}
            fillSeries={true}
            showFilterButtons={false}
            showCheckBoxes={true}
            hideMinMax={true}
            outlierCheck={outliers[McPageHelpers.FILTER_BUTTON_IDS.HS_CODE_CHART]}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_7}
          />
        </MarketCalculusCard>
        {!!hsCountMountain?.raw?.data?.length && McPageHelpers.generateExplanationFooter(allExplanations?.hsCountHsTotalValExplanation)}

      </Col>
      <Col>
        <MarketCalculusCard
          id={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_INTEREST + McPageHelpers.FOR_PDF_ID}
          className={avgInterestMountain?.raw.data?.length ? '' : 'loading-div'}>
          <McLineChart
            chartId={McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_INTEREST}
            chart={avgInterestMountain}
            colorIndex="1"
            xFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
            yFormatterType={NUMBER_FORMATTER_TYPE.PERCENTAGE}
            disablePointerEvents={false}
            chartTitle={t('sp_filter_by_avg_price_hs_total_percentage')}
            xTitle={t('avg_price')}
            yTitle={t('ai_sp_static_hs_total_value_interest')}
            height={'300px'}
            fillSeries={true}
            showFilterButtons={false}
            showCheckBoxes={true}
            hideMinMax={true}
            outlierCheck={outliers[McPageHelpers.FILTER_BUTTON_IDS.AVERAGE_PRICE_X_TOTAL_IMPORT_INTEREST]}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_8}
          />
        </MarketCalculusCard>
        {!!avgInterestMountain?.raw?.data?.length && McPageHelpers.generateExplanationFooter(allExplanations?.avgPriceHsTotalValPercentageExplanations[globalParams.selectedUnitNumber - 1])}

      </Col>
    </Row>
  </>)
}

export default LineCharts
