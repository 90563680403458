import React, { useEffect, useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { getCampaigns, getAccountReport, getCampaignReport } from '../../../services/B2BServices'
import { getUrlParamsObj } from '../../../services/urlServices'

import { getBooleanUserRolesByUser } from '../../../utils/helpers'

import CampaignList from '../organisms/CampaignList'
import EditUserEmailSettingsModal from '../organisms/EditUserEmailSettingsModal'

const Campaigns = ({ userGSD }) => {
  const { t } = useTranslation()
  const queryParams = getUrlParamsObj()
  const userRoles = getBooleanUserRolesByUser(userGSD)
  const childRef = useRef()

  const b2bAccountId = userRoles.B2B_PERSONNEL ? queryParams?.b2bAccountId : userGSD?.ownedB2bAccountId

  const [campaignReports, setCampaignReports] = useState(null)
  const [campaignReportsFetcing, setCampaignReportsFetcing] = useState({})
  const [campaigns, setCampaigns] = useState([])
  const [userData, setUserData] = useState(null)
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const setCampaignReportFetchingStatus = (b2bCampaignId, fetching = false) => {
    setCampaignReportsFetcing((prevState) => ({
      ...prevState,
      [b2bCampaignId]: fetching
    }))
  }

  const refreshCampaignReport = (b2bCampaignId) => {
    if (b2bCampaignId) {
      setCampaignReportFetchingStatus(b2bCampaignId, true)

      getCampaignReport(b2bCampaignId).then((res) => {
        setCampaignReports((prevState) => ({
          ...prevState,
          [b2bCampaignId]: res.data
        }))
      })
        .catch((err) => {
          toast.error(err || t('mailTemplate.campaignReport.fetchErr'), {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false
          })
        })
        .finally(() => {
          setCampaignReportFetchingStatus(b2bCampaignId, false)
        })
    }
  }

  const fetchCampaigns = () => {
    b2bAccountId && getCampaigns({ b2bAccountId }).then((res) => {
      setCampaigns(res.data)

      b2bAccountId && getAccountReport(b2bAccountId).then((res) => {
        setCampaignReports(res.data)
      })

      if (!res?.data?.length) {
        childRef.current.openCampaignModal()
      }
    })
      .catch((err) => {
        toast.error(err || t('mailTemplate.campaigns.fetchErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
  }

  const initEmailEditState = () => {
    const currentUserData = userRoles.B2B_PERSONNEL ? userGSD?.followedB2bAccountIds?.[b2bAccountId] : userGSD
    const userDataWithId = {
      ...currentUserData,
      id: b2bAccountId
    }

    setUserData(userDataWithId)
  }

  useEffect(() => {
    initEmailEditState()
  }, [userGSD])

  useEffect(() => {
    fetchCampaigns()
  }, [])

  return (
    b2bAccountId
      ? <>
          <CampaignList
            ref={childRef}
            b2bAccountId={b2bAccountId}
            campaigns={campaigns}
            campaignReports={campaignReports}
            fetchCampaigns={fetchCampaigns}
            hasEmailSettings={!!userData?.emailSettings}
            setSettingsModalOpen={setSettingsModalOpen}
            refreshCampaignReport={refreshCampaignReport}
            campaignReportsFetcing={campaignReportsFetcing}
          />
          <EditUserEmailSettingsModal
            userGSD={userGSD}
            userEditState={userData}
            open={settingsModalOpen}
            close={() => setSettingsModalOpen(false)}
          />
        </>
      : <div>{t('mailTemplate.campaigns.b2bAccountIdError')}</div>
  )
}

export default Campaigns
