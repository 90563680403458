import React, { forwardRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { CircularProgress } from '@mui/material'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const DialogFullScreen = (props) => {
  const {
    open,
    handleClose,
    onSubmit,
    btnText,
    btnIcon,
    submitDisabled,
    title,
    headerComponents,
    loading,
    children
  } = props

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            sx={{ ml: 2 }}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: '#ffffff', textTransform: 'uppercase', fontWeight: 'bold' }} variant="h6" component="div">
            {title}
          </Typography>
          <div className='flex gap-2'>
            {headerComponents}
            <Button
              autoFocus
              color="inherit"
              onClick={onSubmit}
              variant='outlined'
              disabled={submitDisabled || loading}
              startIcon={loading ? <CircularProgress sx={{ color: '#fff' }} size={20}/> : null}
              endIcon={btnIcon}
            >
              {btnText}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  )
}

export default DialogFullScreen
