import React from 'react'
import EnglishFlag from '../../images/flags/english.png'
import TurkeyFlag from '../../images/flags/turkey.png'
import { Col, Row } from '../../components/Component'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useGlobalStoreActions, useGlobalStoreData } from '../../store/globalStore'
import { LANGUAGES } from '../../utils/Utils'

const AuthFooter = () => {
  const languageGSD = useGlobalStoreData.language()
  const versionGSD = useGlobalStoreData.version()
  const { setLanguage } = useGlobalStoreActions()
  const changeLanguage = (e, language) => {
    e.preventDefault()
    setLanguage(language)
  }
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container-fluid">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item ">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    color="transparent"
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>{languageGSD === LANGUAGES.TURKISH ? 'Türkçe' : 'English'}</span>
                  </DropdownToggle>
                  <DropdownMenu end className="dropdown-menu-sm">
                    <ul className="language-list">
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(e) => changeLanguage(e, LANGUAGES.ENGLISH)}
                          className="language-item"
                        >
                          <img src={EnglishFlag} alt="" className="language-flag"/>
                          <span className="language-name">English</span>
                        </DropdownItem>
                      </li>
                      <li>
                        <DropdownItem
                          tag="a"
                          href="#dropdownitem"
                          onClick={(e) => changeLanguage(e, LANGUAGES.TURKISH)}
                          className="language-item"
                        >
                          <img src={TurkeyFlag} alt="" className="language-flag"/>
                          <span className="language-name">Türkçe</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-start">
              <p className="text-soft">&copy; 2022 - {new Date().getFullYear()} <a href="https://marketcalculus.ai"
                                                                                   target="_blank" rel="noreferrer">Market
                Calculus</a> {versionGSD}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default AuthFooter
