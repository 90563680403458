import React, { useState } from 'react'
import {
  useDSpriceMap,
  useDSpriceBar,
  useDSworldPriceMap,
  useDSworldPriceBar,
  useDSglobalParams,
  useDSunits,
  useDSallExplanations
} from '../../../store/dataStore'
import McCardMB from '../mc-map-and-bar/McCardMB'
import McPageHelpers from '../McPageHelpers'
import CONSTANTS from '../../../utils/Constants'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Icon from '../../../components/icon/Icon'

const AnnualAverageImportUnitPricesSection = () => {
  const { t } = useTranslation()

  const priceMap = useDSpriceMap()
  const priceBar = useDSpriceBar()
  const worldPriceMap = useDSworldPriceMap()
  const worldPriceBar = useDSworldPriceBar()
  const globalParams = useDSglobalParams()
  const units = useDSunits()
  const allExplanations = useDSallExplanations()

  const [modal, setModal] = useState(false)

  const unitVal = units[globalParams.selectedUnit]
  const managedUnitVal = unitVal?.substr(0, 3) === '***' ? unitVal : t(`units.${unitVal?.toLowerCase()}`)

  const header = t('world_avg_price') + ' ($/' + managedUnitVal + ')'
  const headerWorld = t('world_avg_price') + ' ($/' + managedUnitVal + ')'
  const subHeader = McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode
  const subHeaderWorld = t('world') + ' ' + globalParams.hsCode
  const toggle = () => setModal(!modal)

  return (<>
      <McCardMB id={McPageHelpers.SECTION_IDS.ANNUAL_AVERAGE_IMPORT_UNIT_PRICES_MAP_GRAPH}
                code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_MAP_1}
                subCode={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_1}
                show={priceMap?.length && priceBar?.length}
                header={header}
                sub={subHeader}
                map={priceMap}
                graph={priceBar}
                xTitle={t('country_code')}
                yTitle={header}
                height="30rem"
                footer={allExplanations?.avgPriceExplanations[globalParams.selectedUnitNumber - 1]}
                subElement={
                  <p className="sub-description-link">
                    {t('pa_sub_description')}
                    <button className="btn btn-link ps-1 p-0" onClick={toggle}> {t('click')} </button>
                  </p>
                }
      />
      <Modal size="xl" isOpen={modal} toggle={toggle} style={{ maxWidth: 'calc(100% - 10rem)' }}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross"/>
            </button>
          }
        >
        </ModalHeader>
        <ModalBody className="p-0">
          <McCardMB
            id={McPageHelpers.SECTION_IDS.ANNUAL_AVERAGE_IMPORT_UNIT_PRICES_WORLD_MAP_GRAPH}
            code={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_MAP_2}
            subCode={CONSTANTS.BADGE_CODES.PRICE_ANALYSIS_GRAPH_2}
            show={worldPriceMap?.length && worldPriceBar?.length}
            header={headerWorld}
            sub={subHeaderWorld}
            map={worldPriceMap}
            graph={worldPriceBar}
            xTitle={t('country_code')}
            yTitle={t('avg_price_bar_title') + ' ($)'}
            height="30rem"
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default AnnualAverageImportUnitPricesSection
