import React, { useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { localizationAMChart } from '../../utils/AMChartsSettings'
import McPageHelpers from '../../pages/mc-components/McPageHelpers'

function ClusteredBarChart ({ response, code, chartTitle, xTitle, yTitle, chartSubTitle }) {
  const rootRef = useRef(null)

  useLayoutEffect(() => {
    const root = am5.Root.new('clusteredBarChart')
    localizationAMChart(root)

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
      exportable: true
    }))

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: 50,
        x: am5.p50,
        marginTop: 25
      })
    )

    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: 'countryCode',
      renderer: am5xy.AxisRendererY.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })
    }))

    yAxis.get('renderer').labels.template.setAll({
      oversizedBehavior: 'wrap',
      maxWidth: 90,
      textAlign: 'right'
    })

    response?.stackedData && yAxis.data.setAll(response?.stackedData)

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      extraMax: 0.3,
      renderer: am5xy.AxisRendererX.new(root, {})
    }))

    function createSeries (field, txtField, tooltipField, name) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        xAxis,
        yAxis,
        valueXField: field,
        categoryYField: 'countryCode',
        sequencedInterpolation: true
      }))

      series.columns.template.setAll({
        tooltipText: `{${tooltipField}}`
      })
      series.columns.template.setAll({
        height: response?.stackedData?.length > 3 ? am5.percent(80) : am5.percent(40)
      })
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: `{${txtField}}`,
            populateText: true
          })
        })
      })

      series.data.setAll(response?.stackedData)

      legend.data.push(series)

      series.appear()

      return series
    }

    response?.stackedData && createSeries('previous12Months', 'previous12MonthsTxt', 'previous12MonthsTooltip', McPageHelpers.getYearAndMonth(response?.stackedData[0]?.previous12MonthsName))
    response?.stackedData && createSeries('last12Months', 'last12MonthsTxt', 'last12monthsTooltip', McPageHelpers.getYearAndMonth(response?.stackedData[0]?.last12MonthsName))
    rootRef.current = root

    chart.appear()

    return () => {
      root.dispose()
    }
  }, [response])

  return (
    <div className="d-flex flex-column">

      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10px' }}>
          <p style={{
            transform: 'rotate(-90deg)',
            fontSize: '10px',
            whiteSpace: 'nowrap',
            marginBottom: '40px'
          }}>{yTitle}</p>
        </div>

        <div id="clusteredBarChart" style={{
          width: '90%',
          height: '600px',
          flex: '1 100%'
        }}></div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ fontSize: '10px', marginTop: '-4rem', marginLeft: '6rem' }}>{xTitle}</p>
      </div>
    </div>
  )
}

export default ClusteredBarChart
