import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import {
  Search,
  Menu
} from '@mui/icons-material'

const CustomDebouncedInput = (props) => {
  const {
    value: initialValue,
    onChange,
    debounce = 500,
    ...restProps
  } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <InputBase {...restProps} value={value} onChange={e => setValue(e.target.value)} />
}

const MailContentSearch = props => {
  const {
    isBelowScreen,
    searchTerm,
    setSidebarOpen,
    setBackdropOpen,
    setSearchTerm
  } = props

  const { t } = useTranslation()

  // Open sidebar on below md screen
  const handleToggleSidebar = () => {
    setSidebarOpen(true)
    setBackdropOpen(true)
  }

  return (
    <div
      className='flex items-center gap-1 is-full pli-4 border-be'
      style={{ borderBottom: '1px solid var(--mui-palette-divider)' }}
    >
      {isBelowScreen && (
        <IconButton onClick={handleToggleSidebar}>
          <Menu color='primary'/>
        </IconButton>
      )}
      <CustomDebouncedInput
        value={searchTerm ?? ''}
        onChange={value => setSearchTerm(String(value))}
        startAdornment={<Search color='disabled'/>}
        placeholder={t('mailTemplate.mailbox.search')}
        className='bs-[56px]'
        fullWidth
      />
    </div>
  )
}

export default MailContentSearch
