import { MarketCalculusCard } from '../../../components/preview/Preview'
import {
  useDSavgMonthlyLine,
  useDSavgMonthlyTable,
  useDSunits,
  useDSglobalParams,
  useDSallExplanations
} from '../../../store/dataStore'
import { useTranslation } from 'react-i18next'
import { MultiValueLineChart } from '../../../components/Component'
import Constants from '../../../utils/Constants'
import React, { forwardRef } from 'react'
import PriceAnalysisTable from './PriceAnalysisTable'
import McPageHelpers from '../McPageHelpers'
import { NUMBER_FORMATTER_TYPE } from '../../../utils/AMChartsSettings'
import MCTitleCard from '../../../components/title-card/MCTitleCard'
import { useRAWmonthly } from '../../../store/rawStore'

const AverageMonthlyImportUnitPricesByCountriesSection = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const avgMonthlyLine = useDSavgMonthlyLine()
  const avgMonthlyTable = useDSavgMonthlyTable()
  const units = useDSunits()
  const globalParams = useDSglobalParams()
  const allExplanations = useDSallExplanations()

  const monthly = useRAWmonthly()

  const unitVal = units[globalParams.selectedUnit]
  const managedUnitVal = unitVal?.substr(0, 3) === '***' ? unitVal : t(`units.${unitVal?.toLowerCase()}`)

  const header = t('ai_sp_price_page_table_title') + ' ($/' + managedUnitVal + ')'
  const subHeader = McPageHelpers.findCountryName(globalParams.countryCode) + ' ' + globalParams.hsCode
  const lineHeader = t('avg_line_graphic_title', { unit: ' ($/' + managedUnitVal + ')' })
  return (<MarketCalculusCard id={'priceAnalysisMultiValue' + McPageHelpers.FOR_PDF_CONTENT_ID}
                              className={avgMonthlyLine?.length && monthly?.exchangeRates?.length && avgMonthlyTable?.length ? '' : 'loading-div'}>

    <MCTitleCard chartTitle={header} chartSubTitle={subHeader} code={Constants.BADGE_CODES.PRICE_ANALYSIS_TABLE_1}
                 codeColor="light" textColor={'#09c2de'}/>
    <PriceAnalysisTable data={avgMonthlyTable ?? []} yearMonths={monthly?.timeTags ?? []} ref={ref}
                        footer={allExplanations?.tableExplanations[globalParams.selectedUnitNumber - 1]}
    />

    <div className="mt-5"></div>
    <hr/>
    <MCTitleCard chartTitle={lineHeader} chartSubTitle={subHeader} code={Constants.BADGE_CODES.PRICE_ANALYSIS_GRAPH_3}
                 codeColor="light" textColor={'#09c2de'}/>
    {avgMonthlyLine?.length
      ? <MultiValueLineChart chartId={'priceAnalysisMultiValue'}
                           chartData={avgMonthlyLine ?? []}
                           yTitle={t('sp_filter_by_avg_price')}
                           yTitleSecond={monthly?.parityName}
                           secondChartData={McPageHelpers.generatePriceAnalysisMultiValueAxes(monthly?.exchangeRates, monthly?.timeTags)}
                           yFormatterType={NUMBER_FORMATTER_TYPE.CURRENCY}
                           footer={allExplanations?.parityGraphExplanations[globalParams.selectedUnitNumber - 1]}
      />
      : <div className=" py-2 " id={'priceAnalysisMultiValue' + McPageHelpers.FOR_PDF_ID}>
        <div
          className="d-flex justify-content-center align-items-center  text-black border p-2">
          {t('no_records')}
        </div>
      </div>}
  </MarketCalculusCard>)
})

export default AverageMonthlyImportUnitPricesByCountriesSection
