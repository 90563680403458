import React from 'react'
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from '../../../components/block/Block'

const ImporterFilterMapBarHeader = ({ content: { header, sub } }) => {
  return <BlockHead size="sm">
    <BlockBetween className="center">
      <BlockHeadContent>
        <BlockTitle className="center" tag="h5">
          {header}
        </BlockTitle>
        <BlockDes className="text-soft center subTitle">
          <p>{sub}</p>
        </BlockDes>
      </BlockHeadContent>
    </BlockBetween>
  </BlockHead>
}

export default ImporterFilterMapBarHeader
