import React from 'react'

const Flag = props => {
  // https://github.com/lipis/flag-icons
  const { code, square, ...restProps } = props

  const lowerCaseCode = code?.toLowerCase()

  return <span className={`fi fi-${lowerCaseCode} ${square && 'fis'}`} {...restProps} />
}

export default Flag
