import React, { useState, useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import { IconButton } from '@mui/material'
import {
  Edit,
  Add,
  DeleteForever
} from '@mui/icons-material'

import DialogFullScreen from '../molecules/DialogFullScreen'
import RichTextEditor from '../../lexical/RichTextEditor'
import CustomTextField from '../atoms/CustomTextField'
import TemplateCard from '../atoms/TemplateCard'
import ConfirmationModal from '../molecules/ConfirmationModal'

import {
  createTemplate,
  updateTemplate,
  deleteTemplate
} from '../../../services/B2BServices'

const apiCall = (b2bAccountId, updatedTemplate, editTemplate, title) => {
  if (editTemplate) {
    return updateTemplate(
      editTemplate.id,
      {
        name: title,
        template: updatedTemplate
      }
    )
  } else {
    return createTemplate({
      b2bAccountId,
      name: title,
      template: updatedTemplate
    })
  }
}

const TemplateSelection = (props) => {
  const {
    handleNext,
    templates,
    selectedTemplate,
    setSelectedTemplate,
    b2bAccountId,
    getTemplatesWithSelected
  } = props
  const { t } = useTranslation()

  const [editOpen, setEditOpen] = useState(false)
  const [titleVal, setTitleVal] = useState(t('mailTemplate.wizard.rte.newTemplate'))
  const [editTemplate, setEditTemplate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmationModalItem, setConfirmationModalItem] = useState(null)
  const [confirmationModalLoading, setConfirmationModalLoading] = useState(false)

  useEffect(() => {
    setEditTemplate(selectedTemplate)
    setTitleVal(selectedTemplate?.name)
  }, [selectedTemplate])

  const exportHtmlRef = useRef()

  const handleClose = () => {
    setEditOpen(false)
  }

  const handleClickNew = () => {
    setEditTemplate(null)
    setEditOpen(true)
    setTitleVal(t('mailTemplate.wizard.rte.newTemplate'))
  }

  const handleSubmit = (updatedTemplate) => {
    setLoading(true)

    apiCall(b2bAccountId, updatedTemplate, editTemplate, titleVal)
      .then((res) => {
        getTemplatesWithSelected(editTemplate ? editTemplate.id : res.data)
        handleClose()
      })
      .catch((err) => {
        err && toast.error(t('mailTemplate.wizard.rte.toastErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleTemplateDelete = () => {
    setConfirmationModalLoading(true)

    deleteTemplate(confirmationModalItem.id)
      .then((res) => {
        setConfirmationModalItem(null)
        getTemplatesWithSelected(confirmationModalItem.id === editTemplate?.id ? null : editTemplate?.id)
      })
      .catch((err) => {
        err && toast.error(t('mailTemplate.wizard.templates.deleteModal.toastErr'), {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false
        })
      })
      .finally(() => {
        setConfirmationModalLoading(false)
      })
  }

  return (
    <CardContent>
      <DialogFullScreen
        open={editOpen}
        handleClose={handleClose}
        onSubmit={() => {
          const exportedHtml = exportHtmlRef.current.exportHtml()
          handleSubmit(exportedHtml)
        }}
        btnText={t('mailTemplate.wizard.rte.saveBtn')}
        loading={loading}
        title={t('mailTemplate.wizard.rte.editTitle')}
        submitDisabled={!titleVal?.length}
        headerComponents={
          <CustomTextField
            value={titleVal}
            onChange={e => setTitleVal(e.target.value)}
            placeholder={t('mailTemplate.wizard.rte.titlePlaceholder')}
            sx={{
              '& .MuiInputBase-root': {
                color: '#fff',
                borderColor: '#fff',
                '&.Mui-focused': {
                  '&:not(.Mui-error).MuiInputBase-colorPrimary': {
                    borderColor: '#fff'
                  }
                },
                '&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover': {
                  borderColor: '#fff !important'
                }
              }
            }}
            {...(!titleVal?.length && {
              error: true
            })}
          />
        }
      >
        <RichTextEditor templateHtml={editTemplate?.template} ref={exportHtmlRef}/>
      </DialogFullScreen>
      <Grid container spacing={6}>
        <Grid
          item
          container
          xs={12}
          md={8}
          direction='column'
          className='gap-4'
          sx={{
            minHeight: 600,
            position: 'relative'
          }}
        >
          {/* <iframe src={'data:text/html,' + encodeURIComponent(selectedTemplate?.template)} style={{ height: '100%' }}/> */}
          {
            selectedTemplate &&
            <>
              <IconButton
                onClick={(e) => { setEditOpen(true) }}
                color='primary'
                sx={{
                  position: 'absolute',
                  right: 20,
                  top: 20
                }}
              >
                <Edit/>
              </IconButton>
              <object type="text/html" data={'data:text/html,' + encodeURIComponent(selectedTemplate?.template)} style={{ height: '100%' }}></object>
            </>
          }
        </Grid>
        <Grid item container xs={12} md={4} direction='column' className='gap-2'>
          <div
            className='border rounded p-2'
            style={{
              maxHeight: 400,
              overflowY: 'auto'
            }}
          >
            <Grid container spacing={2}>
              <ConfirmationModal
                open={!!confirmationModalItem}
                close={() => setConfirmationModalItem(null)}
                loading={confirmationModalLoading}
                submit={() => {
                  handleTemplateDelete()
                }}
                title={t('mailTemplate.wizard.templates.deleteModal.title')}
                subTitle={confirmationModalItem?.name}
                text={t('mailTemplate.wizard.templates.deleteModal.text')}
                submitText={t('mailTemplate.wizard.templates.deleteModal.submitText')}
                cancelText={t('mailTemplate.wizard.templates.deleteModal.cancelText')}
                submitBtnColor='error'
              />
              {templates?.map((item, index) =>
                <Grid key={index} item xs={12} sm={6}>
                  <TemplateCard
                    isActive={selectedTemplate?.id === item.id}
                    onClick={() => {
                      setSelectedTemplate(item)
                    }}
                    cornerElement={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          setConfirmationModalItem(item)
                        }}
                        size='small'
                      >
                        <DeleteForever/>
                      </IconButton>
                    }
                  >
                    <Typography variant='body2'>{item.name}</Typography>
                  </TemplateCard>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TemplateCard
                  isActive={false}
                  onClick={handleClickNew}
                >
                  <Add fontSize='large'/>
                </TemplateCard>
              </Grid>
            </Grid>
          </div>
          <div className='flex justify-normal sm:justify-end xl:justify-normal'>
            <Button className='is-full' variant='contained' onClick={handleNext}>
              {t('mailTemplate.wizard.templates.nextBtn')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </CardContent>
  )
}

export default TemplateSelection
