import React from 'react'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import FilterHeader from './_header'
import PotentialFilterButtons from './_potentialFilterButtons'
import FilterTable from './_filterTable'
import McPageHelpers from '../McPageHelpers'
import ImporterFilterButtons from './_importerFilterButtons'

const McCardTF = ({
  id,
  code,
  header,
  sub,
  rightTxt,
  show,
  data,
  columns,
  fncResetFilter,
  onRowClicked,
  uniqueId,
  footer
}) => {
  const filterHeaderContent = { code, header, sub, rightTxt, fncResetFilter }
  const filterTableContent = { id, data, columns, onRowClicked, uniqueId, footer }
  return (
    <MarketCalculusCard id={id} className={show ? '' : 'loading-div'}>
      <FilterHeader content={filterHeaderContent}/>
      {id === McPageHelpers.SECTION_IDS.IMPORTER_COMPANIES ? <ImporterFilterButtons/> : <PotentialFilterButtons/>}
      <hr/>
      <FilterTable content={filterTableContent}/>
    </MarketCalculusCard>
  )
}

export default McCardTF
