import { t } from 'i18next'

export const Months = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
}
export const InitialState = {
  isValuesDataLoaded: null,
  response: [],
  responseCountry: [],
  responseMarketShare: null,
  activeTab: '1',
  isValuesTableDataLoaded: null,
  importerCountryValuesMonthly: null,
  importerCountryValuesYearly: [],
  importerTimeTagsValuesYearly: [],
  importerTimeTagsValuesMonthly: [],
  importerTimeTagsYearly: [],
  importerCountryQuantityMonthly: [],
  importerCountryQuantityYearly: [],
  importerTimeTagsQuantityMonthly: [],
  importerTimeTagsQuantityYearly: [],
  exporterCountryValuesYearly: [],
  exporterCountryValuesMonthly: [],
  exporterTimeTagsValuesYearly: [],
  exporterTimeTagsValuesMonthly: [],
  exporterTimeTagsYearly: [],
  exporterCountryQuantityMonthly: [],
  exporterCountryQuantityYearly: [],
  exporterTimeTagsQuantityMonthly: [],
  importerCountryAverageMonthly: [],
  importerCountryAverageYearly: [],
  importerTimeTagsAverageMonthly: [],
  importerTimeTagsAverageYearly: [],
  exporterCountryAverageMonthly: [],
  exporterCountryAverageYearly: [],
  exporterTimeTagsAverageMonthly: [],
  exporterTimeTagsAverageYearly: [],
  exporterTimeTagsQuantityYearly: [],
  valueImporterYearly: null,
  valueExporterYearly: null,
  quantityImporterYearly: null,
  quantityExporterYearly: null,
  valueImporterMonthly: null,
  valueExporterMonthly: null,
  quantityImporterMonthly: null,
  quantityExporterMonthly: null,
  averagePriceImporterYearly: null,
  averagePriceExporterYearly: null,
  averagePriceImporterMonthly: null,
  averagePriceExporterMonthly: null,
  comtradeMarketChangeValueSeries: null,
  comtradeMarketChangeQuantitySeries: null,
  comtradeMarketChangeValueExcludedSeries: null,
  comtradeMarketChangeQuantityExcludedSeries: null
}
export const getMonthName = (value) => {
  if (value === Months.January) {
    return 'january'
  }
  if (value === Months.February) {
    return 'february'
  }
  if (value === Months.March) {
    return 'march'
  }
  if (value === Months.April) {
    return 'april'
  }
  if (value === Months.May) {
    return 'may'
  }
  if (value === Months.June) {
    return 'june'
  }
  if (value === Months.July) {
    return 'july'
  }
  if (value === Months.August) {
    return 'august'
  }
  if (value === Months.September) {
    return 'september'
  }
  if (value === Months.October) {
    return 'october'
  }
  if (value === Months.November) {
    return 'november'
  }
  if (value === Months.December) {
    return 'december'
  }
}
export const KEY = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

export const TYPE = {
  IMPORTER: 'importer',
  WORLD: 'world',
  EXPORTER: 'exporter',
  VALUES: 'values',
  QUANTITY: 'quantity'
}

export const OVERVIEW_TAB_TYPE = {
  DESTINATION: 'destination',
  SOURCE: 'source',
  WORLD: 'world',
  CHANGE: 'change'
}

export const CountryType = {
  COUNTRY_CODE: 'country_code',
  COUNTRY_ID: 'country_İD'
}
export const CHART_TYPE = {
  BAR: 'Bar',
  LINE: 'Line'
}
export const getCountryName = (value, country = JSON.parse(localStorage.getItem('countries')), type) => {
  if (country) {
    if (type === CountryType.COUNTRY_CODE) {
      const founded = country?.find(t => t.countryCode === value)
      return founded?.countryName ?? value
    }

    const founded = country?.find(t => t.countryId === value)
    if (value === -1) {
      return t('sp_other_countries')
    }
    if (value === 0) {
      return t('world')
    }
    return founded?.countryName ?? value
  }
}
