import React from 'react'

import HsChip from '../atoms/HsChip'

import { useGlobalStoreData } from '../../../../../store/globalStore'

const HsChipList = (props) => {
  const {
    hsArr,
    visibleCount = 1
  } = props

  const hsCodesGSD = useGlobalStoreData.hsCodes()

  return (
    (hsCodesGSD?.length && hsArr?.length)
      ? <>
          {hsArr.slice(0, visibleCount).map((hs, index) => {
            return (
              <HsChip
                key={index}
                label={hs}
                tooltipText={hsCodesGSD.find((x) => x.value === hs).label}
              />
            )
          })}
          {hsArr?.length > visibleCount
            ? <HsChip
                label={`+ ${hsArr.length - visibleCount}`}
                tooltipText={
                  hsArr
                    .slice((visibleCount), hsArr.length)
                    .map((hsCode) => Object.values(hsCodesGSD.find((x) => x.value === hsCode)).join('-'))
                    .join(', ')
                }
              />
            : null
          }
        </>
      : null
  )
}

export default HsChipList
