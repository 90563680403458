import React, { useState } from 'react'

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { useLexicalEditable } from '@lexical/react/useLexicalEditable'

import { useSharedHistoryContext } from './context/SharedHistoryContext'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import LinkPlugin from './plugins/LinkPlugin'
import MentionsPlugin from './plugins/MentionsPlugin'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import ImagesPlugin from './plugins/ImagesPlugin'
import ContentEditable from './ui/ContentEditable'

export default function Editor ({ textareaStyles }) {
  const { historyState } = useSharedHistoryContext()
  const isEditable = useLexicalEditable()
  const placeholder = 'Enter some rich text...'
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null)
  const [isLinkEditMode, setIsLinkEditMode] = useState(false)

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <>
      <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
      <div
        className={'editor-container'}>
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <MentionsPlugin />
        <AutoLinkPlugin />
        <>
          <HistoryPlugin externalHistoryState={historyState} />
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller" style={textareaStyles}>
                <div className="editor" ref={onRef}>
                  <ContentEditable placeholder={placeholder} />
                </div>
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <ImagesPlugin/>
          <LinkPlugin />
          <ClickableLinkPlugin disabled={isEditable} />
          {floatingAnchorElem && (
            <>
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </>
          )}
        </>
      </div>
    </>
  )
}
