import React from 'react'

import CustomAutocomplete from './CustomAutocomplete'
import CustomTextField from './CustomTextField'

import Flag from './Flag'

const AutoCompleteCountry = (props) => {
  const {
    value,
    onChange,
    options,
    getOptionLabel,
    label,
    placeholder,
    codeKey,
    inputProperties,
    ...restProps
  } = props

  return (
    <CustomAutocomplete
      autoHighlight
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderOption={(props, option) => (
        <li {...props} key={option?.[codeKey]}>
          <Flag code={option?.[codeKey]} style={{ marginRight: 10 }}/>
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={params => (
        <CustomTextField
          {...params}
          label={label}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
          {...inputProperties}
        />
      )}
      {...restProps}
    />
  )
}

export default AutoCompleteCountry
