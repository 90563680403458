import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import MarketOverviewSettings from './MarketOverviewSettings'
import {
  CHART_TYPE,
  getCountryName,
  KEY,
  OVERVIEW_TAB_TYPE,
  TYPE
} from './MarketOverviewTable/MarketOverviewTable.settings'
import { useTranslation } from 'react-i18next'
import { useGlobalStoreData } from '../../../store/globalStore'
import { useSDSsearchParams, useSDSsearchTrigger } from '../../../store/searchDataStore'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { MarketCalculusCard } from '../../../components/preview/Preview'
import { useDSmarketOverviewData } from '../../../store/dataStore'

const QuantitySection = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const countriesGSD = useGlobalStoreData.countries()

  const searchTrigger = useSDSsearchTrigger()
  const searchParams = useSDSsearchParams()

  const marketOverviewData = useDSmarketOverviewData()
  const importer = marketOverviewData?.quantity?.importer
  const exporter = marketOverviewData?.quantity?.exporter
  const destinationCountry = marketOverviewData?.quantity?.destinationCountry
  const sourceCountry = marketOverviewData?.quantity?.sourceCountry
  const world = marketOverviewData?.quantity?.world

  const [activeAltTab, setActiveAltTab] = useState(OVERVIEW_TAB_TYPE.SOURCE)
  const toggleAltTab = (altTab) => {
    if (activeAltTab !== altTab) setActiveAltTab(altTab)
  }

  const importerCountryYearlyRef = useRef(null)
  const importerCountryMonthlyRef = useRef(null)
  const destinationCountryCountryYearlyRef = useRef(null)
  const destinationCountryCountryMonthlyRef = useRef(null)
  const exporterCountryYearlyRef = useRef(null)
  const exporterCountryMonthlyRef = useRef(null)
  const sourceCountryCountryYearlyRef = useRef(null)
  const sourceCountryCountryMonthlyRef = useRef(null)
  const worldImporterCountryYearlyRef = useRef(null)
  const worldImporterCountryMonthlyRef = useRef(null)
  const worldExporterCountryYearlyRef = useRef(null)
  const worldExporterCountryMonthlyRef = useRef(null)

  useImperativeHandle(ref, () => ({
    generateTablesPdf () {
      return [
        ...importerCountryYearlyRef.current.generateTablePdf('importerCountryYearlyQuantity'),
        ...importerCountryMonthlyRef.current.generateTablePdf('importerCountryMonthlyQuantity'),
        ...destinationCountryCountryYearlyRef.current.generateTablePdf('destinationCountryCountryYearlyQuantity'),
        ...destinationCountryCountryMonthlyRef.current.generateTablePdf('destinationCountryCountryMonthlyQuantity'),

        ...exporterCountryYearlyRef.current.generateTablePdf('exporterCountryYearlyQuantity'),
        ...exporterCountryMonthlyRef.current.generateTablePdf('exporterCountryMonthlyQuantity'),
        ...sourceCountryCountryYearlyRef.current.generateTablePdf('sourceCountryCountryYearlyQuantity'),
        ...sourceCountryCountryMonthlyRef.current.generateTablePdf('sourceCountryCountryMonthlyQuantity'),

        ...worldImporterCountryYearlyRef.current.generateTablePdf('worldImporterCountryYearlyQuantity'),
        ...worldImporterCountryMonthlyRef.current.generateTablePdf('worldImporterCountryMonthlyQuantity'),
        ...worldExporterCountryYearlyRef.current.generateTablePdf('worldExporterCountryYearlyQuantity'),
        ...worldExporterCountryMonthlyRef.current.generateTablePdf('worldExporterCountryMonthlyQuantity')
      ]
    }
  }))

  return (
    <>
      <MarketCalculusCard className={props.null || searchTrigger ? 'loading-div' : 'mt-3'}>
        <Nav tabs className="nav-tabs-s2">
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.SOURCE })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.SOURCE)
              }}
            >
              {getCountryName(searchParams.countryToExport, countriesGSD, 'country_code')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.DESTINATION })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.DESTINATION)
              }}
            >
              {getCountryName(searchParams.yourCountry, countriesGSD, 'country_code')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classnames('btn btn-sm', { 'btn-primary': activeAltTab === OVERVIEW_TAB_TYPE.WORLD })}
              onClick={(ev) => {
                ev.preventDefault()
                toggleAltTab(OVERVIEW_TAB_TYPE.WORLD)
              }}
            >
              {t('world')}
            </NavLink>
          </NavItem>
        </Nav>
      </MarketCalculusCard>

      <TabContent activeTab={activeAltTab}>
        <TabPane tabId={OVERVIEW_TAB_TYPE.SOURCE}>
          <MarketOverviewSettings
            ref={importerCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={importer?.time?.yearly}
            countryValues={importer?.country?.yearly}
            removeNullCols={importer?.country?.yearly ? importer?.country?.yearly[0]?.valuesByTimeTag[0] == null : false}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={importer?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-07"
            tableType={'quantity'}
            tableCode="T-PG-13"
            graphicCode="G-PG-13"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={importerCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={importer?.time?.monthly}
            countryValues={importer?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={importer?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-08"
            tableType={'quantity'}
            tableCode="T-PG-14"
            graphicCode="G-PG-14"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={destinationCountryCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={destinationCountry?.time?.yearly}
            countryValues={destinationCountry?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={destinationCountry?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-DestinationCountryQuantity01"
            tableType={'quantity'}
            tableCode="T-PG-15"
            graphicCode="G-PG-15"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />
          <MarketOverviewSettings
            ref={destinationCountryCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={destinationCountry?.time?.monthly}
            countryValues={destinationCountry?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={destinationCountry?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleBarChart-DestinationCountryQuantity02"
            tableType={'quantity'}
            tableCode="T-PG-16"
            graphicCode="G-PG-16"
            tabType={OVERVIEW_TAB_TYPE.SOURCE}
          />

        </TabPane>
        <TabPane tabId={OVERVIEW_TAB_TYPE.DESTINATION}>
          <MarketOverviewSettings
            ref={exporterCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={exporter?.time?.yearly}
            countryValues={exporter?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={exporter?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-09"
            tableType={'quantity'}
            tableCode="T-PG-17"
            graphicCode="G-PG-17"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={exporterCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={exporter?.time?.monthly}
            countryValues={exporter?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={exporter?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-10"
            tableType={'quantity'}
            tableCode="T-PG-18"
            graphicCode="G-PG-18"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={sourceCountryCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={sourceCountry?.time?.yearly}
            countryValues={sourceCountry?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={sourceCountry?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-SourceQuantityCountry"
            tableType={'quantity'}
            tableCode="T-PG-19"
            graphicCode="G-PG-19"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
          <MarketOverviewSettings
            ref={sourceCountryCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={sourceCountry?.time?.monthly}
            countryValues={sourceCountry?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={sourceCountry?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleBarChart-DestinationQuantityCountry"
            tableType={'quantity'}
            tableCode="T-PG-20"
            graphicCode="G-PG-20"
            tabType={OVERVIEW_TAB_TYPE.DESTINATION}
          />
        </TabPane>
        <TabPane tabId={OVERVIEW_TAB_TYPE.WORLD}>
          <MarketOverviewSettings
            ref={worldImporterCountryYearlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.importer?.time?.yearly}
            countryValues={world?.importer?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={world?.importer?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="MultipleBarChart-11"
            tableType={'quantity'}
            tableCode="T-PG-21"
            graphicCode="G-PG-21"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
          <MarketOverviewSettings
            ref={worldImporterCountryMonthlyRef}
            type={TYPE.IMPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.importer?.time?.monthly}
            countryValues={world?.importer?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={world?.importer?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="MultipleLineChart-12"
            tableType={'quantity'}
            tableCode="T-PG-22"
            graphicCode="G-PG-22"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
          <MarketOverviewSettings
            ref={worldExporterCountryYearlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.exporter?.time?.yearly}
            countryValues={world?.exporter?.country?.yearly}
            title={KEY.YEARLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={world?.exporter?.data?.yearly}
            chartType={CHART_TYPE.BAR}
            chartId="QuantityBarChartWorld-21"
            tableType={'quantity'}
            tableCode="T-PG-23"
            graphicCode="G-PG-23"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld

          />
          <MarketOverviewSettings
            ref={worldExporterCountryMonthlyRef}
            type={TYPE.EXPORTER}
            responseCountry={countriesGSD}
            timeTags={world?.exporter?.time?.monthly}
            countryValues={world?.exporter?.country?.monthly}
            title={KEY.MONTHLY}
            yTitle={t('chart_quantity_yTitle')}
            chartData={world?.exporter?.data?.monthly}
            chartType={CHART_TYPE.LINE}
            chartId="QuantityLineChartWorld-22"
            tableType={'quantity'}
            tableCode="T-PG-24"
            graphicCode="G-PG-24"
            tabType={OVERVIEW_TAB_TYPE.WORLD}
            isWorld
          />
        </TabPane>
      </TabContent>
    </>
  )
})

export default QuantitySection
