import * as qs from 'qs'

export const updateUrlParam = (key, val) => {
  const queryString = new URLSearchParams(window.location.search).toString()
  const queryParams = qs.parse(queryString)
  queryParams[key] = val

  const URL = `${process.env.PUBLIC_URL + '?' + qs.stringify(queryParams)}`
  window.history.pushState(null, 'search', URL)
}

export const getUrlParamsObj = () => {
  const queryString = new URLSearchParams(window.location.search).toString()
  const queryParams = qs.parse(queryString)

  return queryParams
}

export const getUrlParamsByObj = (obj) => qs.stringify({
  hsCode: obj?.hsCode,
  countryToExport: obj?.countryToExport,
  yourCountry: obj?.yourCountry,
  productDesc: obj?.productDesc,
  summary: obj?.summary
}, { skipNulls: true })

const urlServices = {
  updateUrlParam,
  getUrlParamsByObj
}

export default urlServices
