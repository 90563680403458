import { McHeatMap } from '../../../components/Component'
import McBarChart from '../../../components/amcharts/BarChart'
import React from 'react'
import { Col, Row } from 'reactstrap'
import McPageHelpers from '../McPageHelpers'

const ImporterFilterMapBarContent = ({
  content: {
    id,
    code,
    subCode,
    map,
    graph,
    xTitle,
    yTitle,
    height,
    footer
  }
}) => {
  return (
    <>
      <Row id={id + McPageHelpers.FOR_PDF_ID}>
        <Col md="6">
          <McHeatMap chartId={id + '_heatMap'}
                     code={code} chartData={map}
                     height={height}
          />
        </Col>
        <Col md="6">
          <McBarChart chartId={id + '_barChart'}
                      chartData={graph}
                      code={subCode}
                      customColor={true}
                      scrollbar={true}
                      height={height}
                      cursorPointer={true}
                      isHeatChart={true}
                      xTitle={xTitle}
                      yTitle={yTitle}
          />
        </Col>
      </Row>
      {McPageHelpers.generateExplanationFooter(footer)}
    </>
  )
}

export default ImporterFilterMapBarContent
