import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

// MUI Imports
import { useTheme } from '@mui/material/styles'

import PercentageBar from './PercentageBar'

const emailStatuses = ['SENT', 'FAILED', 'WAITING']

const percentLimit = 25

const EmailStatusPercentageBar = ({ emailStats }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [processedStatuses, setProcessedStatuses] = useState([])

  const emailStatValuesArr = Object.values(emailStats || {})
  const totalEmailCount = emailStatValuesArr.reduce((a, b) => a + b, 0)

  const emailStatusColors = {
    SENT: `rgb(${theme.palette.primary.mainChannel} / 1)`,
    WAITING: '#aeb3b8',
    FAILED: '#cc4141'
  }

  const getPercent = (val) => {
    const percent = val * 100 / totalEmailCount

    return percent % 1 === 0 ? percent : percent.toFixed(1)
  }

  useEffect(() => {
    const allStatusObjArr = []

    emailStatuses.forEach((item) => {
      if (emailStats?.[item]) {
        allStatusObjArr.push({
          label: t(`mailTemplate.campaigns.emailReport.${item}`),
          color: emailStatusColors[item],
          value: getPercent(emailStats[item])
        })
      }
    })

    setProcessedStatuses(allStatusObjArr)
  }, [emailStats, t])

  return (
    <div className='p-1 w-full'>
      <PercentageBar
        valueArr={processedStatuses.map((item) => item.value)}
        labelArr={processedStatuses.map((item) => item.label)}
        colorArr={processedStatuses.map((item) => item.color)}
        percentLimit={percentLimit}
        emptyLabel={t('mailTemplate.campaigns.emailReport.SENT')}
        emptyColor={emailStatusColors.WAITING}
      />
    </div>
  )
}

export default EmailStatusPercentageBar
