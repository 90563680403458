import React, { forwardRef, useImperativeHandle } from 'react'
import { getCountryName, getMonthName, KEY, TYPE } from './MarketOverviewTable.settings'
import { useTranslation } from 'react-i18next'
import './marketOverviewTable.scss'
import McPageHelpers from '../../McPageHelpers'
import { McDataTable } from '../../../../components/Component'
import { usePDFselectedComponentIds } from '../../../../store/pdfStore'
import { useSDSsearchTrigger } from '../../../../store/searchDataStore'

const MarketOverviewTable = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const selectedComponentIds = usePDFselectedComponentIds()
  const searchTrigger = useSDSsearchTrigger()
  const {
    countryValues,
    timeTags,
    title,
    tableTitle,
    isAverage,
    responseCountry,
    type,
    subTitle,
    removeNullCols,
    footer
  } = props

  const getColumnsName = (value, type) => {
    const date = timeTags[value]?.toString() ?? '-'
    const lastTwoCharacter = date.slice(-2)
    const firstFourCharacter = date.slice(0, 4)
    const result = getMonthName(lastTwoCharacter)

    if (type === KEY.MONTHLY) {
      return firstFourCharacter + '/' + t(`months.${result}`)
    }
    return t(`months.${result}`)
  }
  const findNumberType = (value) => {
    if (isAverage) {
      return McPageHelpers.numToLocaleString(value, 'decimal', false, 2, 2)
    }
    const newValue = (value / 1000)

    return McPageHelpers.numToLocaleString(Math.round(newValue), 'decimal', false, 0, 0)
  }

  const columns = [
    {
      name: (<div>{type === TYPE.IMPORTER ? t('exporter') : t('importer')} </div>),
      selector: row => getCountryName(row.countryCode, responseCountry),
      left: true,
      width: removeNullCols ? '24%' : '14%'
    },
    {
      name: (<span>{timeTags[4]}</span>),
      selector: row => findNumberType(row.valuesByTimeTag[4]),
      right: true,
      wrap: true,
      sortable: true,
      width: removeNullCols ? '19%' : '12%'
    },
    {
      name: (<span>{timeTags[3]}</span>),
      selector: row => findNumberType(row.valuesByTimeTag[3]),
      right: true,
      sortable: true,
      width: removeNullCols ? '19%' : '12%'

    },
    {
      name: (<span>{timeTags[2]}</span>),
      selector: row => findNumberType(row.valuesByTimeTag[2]),
      right: true,
      sortable: true,
      width: removeNullCols ? '19%' : '12%'
    },
    {
      name: (<>{timeTags && String(timeTags[1]).slice(0, 4) + ' '}<br/>{t('months.january') + '-' + getColumnsName(0)}</>),
      selector: row => findNumberType(row.valuesByTimeTag[1]),
      right: true,
      sortable: true,
      width: '12%'
    },
    {
      name: (<>{timeTags && String(timeTags[0]).slice(0, 4) + ' '}<br/>{t('months.january') + '-' + getColumnsName(0)}</>),
      selector: row => findNumberType(row.valuesByTimeTag[0]),
      right: true,
      sortable: true,
      width: '12%'
    },
    {
      name: (<>{t('sp_change_percent') + ' '}<br/>{timeTags[2] + ' / ' + timeTags[3]}</>),
      selector: row => McPageHelpers.numToLocaleString((row.valuesByTimeTag[2] - row.valuesByTimeTag[3]) / (row.valuesByTimeTag[3]), 'percent', false, 2, 2),
      right: true,
      sortable: true,
      wrap: true,
      width: removeNullCols ? '19%' : '12%'
    },
    {
      name: (<>{t('sp_change_percent') + ' '}<br/>{t('months.january') + '-' + getColumnsName(0)}</>),
      selector: row => McPageHelpers.numToLocaleString((row.valuesByTimeTag[0] - row.valuesByTimeTag[1]) / (row.valuesByTimeTag[1]), 'percent', false, 2, 2),
      right: true,
      sortable: true,
      wrap: true,
      width: '12%'
    }
  ]
  const columnsMonth = [
    {
      name: <span>{type === TYPE.IMPORTER ? t('exporter') : t('importer')}</span>,
      selector: row => getCountryName(row.countryCode, responseCountry, 'country_id'),
      left: true
    },
    {
      name: <span>{getColumnsName(0, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[0]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(1, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[1]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(2, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[2]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(3, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[3]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(4, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[4]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(5, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[5]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(6, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[6]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(7, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[7]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(8, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[8]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(9, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[9]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(10, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[10]),
      right: true,
      wrap: true,
      sortable: true

    },
    {
      name: <span>{getColumnsName(11, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[11]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(12, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[12]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(13, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[13]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(14, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[14]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(15, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[15]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(16, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[16]),
      right: true,
      wrap: true,
      sortable: true
    },
    {
      name: <span>{getColumnsName(17, KEY.MONTHLY)}</span>,
      selector: row => findNumberType(row.valuesByTimeTag[17]),
      right: true,
      wrap: true,
      sortable: true
    }
  ]
  if (removeNullCols) {
    const indexesToBeRemoved = [4, 5, 7] // sorted olması gerekiyor.
    while (indexesToBeRemoved.length) {
      columns.splice(indexesToBeRemoved.pop(), 1)
    }
  }
  const selectedColumns = title === KEY.YEARLY ? columns : columnsMonth
  // McPageHelpers.generateTableSubTitleForPdf(subTitle, null),
  useImperativeHandle(ref, () => ({
    generateTablePdf (id) {
      return [
        McPageHelpers.generateTableTitleAndSubtitleForPdf(tableTitle, subTitle, tableTitle.includes('T-PG-01') ? null : 'before', null),
        selectedComponentIds.includes(id) ? McPageHelpers.generateDataTableContentForPdf(selectedColumns, countryValues ?? [], false, null, footer) : []
      ]
    }
  }))
  return (
    <div className="g-3 w-fit">
      <McDataTable data={countryValues ?? []}
                   className={!searchTrigger && !countryValues ? 'loading-div' : ''}
                   columns={selectedColumns}
                   id={'marketOverviewTable-' + new Date().getTime()}
                   customSort={McPageHelpers.mcDataTableCustomSort}
                   hideHeader
      />
      {McPageHelpers.generateExplanationFooter(footer)}
    </div>
  )
})
export default MarketOverviewTable
