import React from 'react'

const TemplateCard = (props) => {
  const {
    children,
    isActive,
    onClick,
    cornerElement,
    ...restProps
  } = props

  return (
    <div
      className='rounded flex justify-center align-center text-center p-1 relative'
      style={{
        width: '100%',
        height: 100,
        backgroundColor: '#F5F6FA',
        cursor: 'pointer',
        border: isActive ? '2px solid var(--mui-palette-primary-main)' : null
      }}
      onClick={onClick}
      {...restProps}
    >
      <div className='absolute top-1 right-1'>
        {cornerElement}
      </div>
      {children}
    </div>
  )
}

export default TemplateCard
