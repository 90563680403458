import React, { forwardRef } from 'react'

// MUI Imports
import { useTheme } from '@mui/material/styles'
import {
  Tooltip
} from '@mui/material'

const BarItem = forwardRef((props, ref) => {
  const { percent, percentLimit, theme, color, percentText = null, ...restProps } = props

  return (
    <div
      ref={ref}
      className='flex justify-center align-center'
      style={{
        height: '100%',
        backgroundColor: color,
        width: `${percent}%`,
        fontSize: theme.typography.body2.fontSize,
        overflow: 'hidden'
      }}
      {...restProps}
    >{percentText || (percent >= percentLimit ? `${percent}%` : '')}</div>
  )
})

const PercentageBar = (props) => {
  const { labelArr, valueArr, colorArr, percentLimit, emptyLabel, emptyColor } = props
  const theme = useTheme()

  return (
    <>
      <div className='flex' style={{ height: 25, width: '100%', borderRadius: 5, overflow: 'hidden ' }}>
        {valueArr?.length
          ? valueArr?.map((item, index) => {
            const localPercent = valueArr[index]

            return (
              <div
                key={index}
                className='flex flex-col'
                style={{
                  height: '100%',
                  width: `${localPercent}%`
                }}
              >
                {localPercent >= percentLimit
                  ? <>
                      <div style={{ fontSize: 10 }}>{labelArr[index]}</div>
                      <div style={{ borderLeft: '1px solid var(--mui-palette-divider)', height: 5 }}/>
                    </>
                  : null
                }
              </div>
            )
          })
          : <div
              className='flex flex-col'
              style={{
                height: '100%',
                width: '100%'
              }}
            >
              <div style={{ fontSize: 10 }}>{emptyLabel}</div>
              <div style={{ borderLeft: '1px solid var(--mui-palette-divider)', height: 5 }}/>
            </div>
        }
      </div>

      <div className='flex' style={{ height: 20, width: '100%', borderRadius: 5, overflow: 'hidden ' }}>
        {valueArr?.length
          ? valueArr?.map((item, index) => {
            const localPercent = item

            const color = colorArr[index]
            const tooltipColor = colorArr[index]

            return (
              localPercent >= percentLimit
                ? <BarItem
                    key={index}
                    percent={localPercent}
                    percentLimit={percentLimit}
                    theme={theme}
                    color={color}
                  />
                : <Tooltip
                    key={index}
                    title={`${labelArr[index]} ${item}%`}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: theme.palette.text.primary,
                          bgcolor: tooltipColor,
                          '& .MuiTooltip-arrow': {
                            color: tooltipColor
                          }
                        }
                      }
                    }}
                  >
                    <BarItem
                      percent={localPercent}
                      percentLimit={percentLimit}
                      theme={theme}
                      color={color}
                    />
                  </Tooltip>
            )
          })
          : <BarItem
              percent={100}
              percentLimit={0}
              theme={theme}
              color={emptyColor}
              percentText={'0%'}
            />
        }
      </div>
    </>
  )
}

export default PercentageBar
