import { create } from 'zustand'
import { LANGUAGES, LOCALE } from '../utils/Utils'

const useGlobalStore = create((set) => ({
  language: localStorage.getItem('lang') ?? LANGUAGES.ENGLISH,
  locale: localStorage.getItem('locale') ?? LOCALE.EN_US,
  version: '',
  isLoggedIn: true,
  user: {},
  countries: JSON.parse(localStorage.getItem('countries')) ?? [],
  hsCodes: [], // TODO: hs kullanan yerler buradan beslenmeli, language değiştiğinde güncelleniyor
  actions: {
    setUserData: (userData) => {
      set({ user: userData })
    },
    setLanguage: (language) => {
      set({
        language,
        locale: language === LANGUAGES.TURKISH ? LOCALE.TR_TR : LOCALE.EN_US
      })
      localStorage.setItem('lang', language)
      localStorage.setItem('locale', language === LANGUAGES.TURKISH ? LOCALE.TR_TR : LOCALE.EN_US)
    },
    setAppVersion: (version) => {
      set({ version })
    },
    setCountries: (countries) => {
      set({ countries })
    },
    setLoggedInStatus: (isLoggedIn) => {
      set((state) => {
        if (state.isLoggedIn !== isLoggedIn) {
          return { isLoggedIn }
        }
        return { isLoggedIn: state.isLoggedIn }
      })
    },
    setHsCodes: (hsCodes) => {
      set({
        hsCodes
      })
    }
  }
}))

const dataGetter = (key) => useGlobalStore((state) => state[key])

export const useGlobalStoreData = {
  language: () => dataGetter('language'),
  locale: () => dataGetter('locale'),
  version: () => dataGetter('version'),
  isLoggedIn: () => dataGetter('isLoggedIn'),
  user: () => dataGetter('user'),
  countries: () => dataGetter('countries'),
  hsCodes: () => dataGetter('hsCodes')
}

export const useGlobalStoreActions = () => useGlobalStore((state) => state.actions)

export default useGlobalStore
